import axios from "axios";
import { setupCache } from "axios-cache-adapter";

import { handleErrorByCode } from "./errorDisplay";
import { ErrorCode } from "./errors";

// just trick to avoid pushing uploader errors to dashboard page
// we should fix is it other way
const handleErrors =
  (f) =>
  async (...args) => {
    try {
      return await f(...args);
    } catch (error) {
      // handleError(error);
      return null;
    }
  };

const API_URL = process.env.REACT_APP_UPLOADER_API_URL || "/upload-api";

const cache = setupCache({
	maxAge: 60 * 1000,
	exclude: {
		methods: ["patch", "put", "delete"],
	},
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
  baseURL: API_URL,
  timeout: 24 * 60 * 60 * 1000, // 24 hours max upload time
});

export function getJwtTokenFromLocalStorage() {
  return localStorage.getItem("jwtTokenUser");
}

api.interceptors.request.use(
  (config) => {
    const token = getJwtTokenFromLocalStorage();
    const auth = token ? `Bearer ${token}` : "";
    config.headers.common["Authorization"] = auth;
    return config;
  },
  (error) => Promise.reject(error)
);

export const uploadFile = handleErrors(
  async (document, formData, onUploadProgress) => {
    const token = getJwtTokenFromLocalStorage();
    const auth = token ? `Bearer ${token}` : "";

    const response = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Description: document.description,
        Exploratory: document.exploratory,
        Filename: document.filename,
        Filesize: document.file.size,
        Timestamp: Date.now(),
        Authorization: auth
      },
      onUploadProgress,
    });

    if (!response || !response.data) {
      console.log(response);
      return handleErrorByCode(
        ErrorCode.UNKNOWN,
        `Error uploading ${document.filename}`
      );
    }
    return response.data;
  }
);

export const deleteFiles = handleErrors(async (ids) => {
  await api.delete(`/documents`, {
    data: {
      ids,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
});

export const updateFile = handleErrors(async (d) => {
  await api.post(
    `/documents`,
    {
      description: d.description,
      oldfilename: d.id,
      filename: d.filename,
      size: d.size,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
});

export const getFiles = handleErrors(async (cb) => {
  const response = await api.get(`/documents`);

  if (response && response.data) {
    const formattedDocumentsResult = response.data.map((doc) => ({
      ...doc,
      id: doc.filename,
    }));
    cb(formattedDocumentsResult);
  }
});

export const checkTalkwalkerStatus = handleErrors(async (api_key) => {
  const response = await api.post("/data-connectors/talkwalker/status", {
    api_key,
  });
  return response.data || null;
});

export const getDataConnectors = handleErrors(async () => {
  const response = await api.get(`/strapi/data-connectors`);
  return response.data || null;
});

export const getDocumentCount = handleErrors(async (payload) => {
  const response = await api.post(`/data-connectors/talkwalker/topic-count`, payload);
  return response.data || null;
});

export const getDataConnectorById = handleErrors(async (id) => {
  const response = await api.get(`/strapi/data-connectors/${id}`);
  return response.data || null;
});

export const addDataConnector = handleErrors(async (payload) => {
  const response = await api.post(`/strapi/data-connectors`, payload);
  return response.data || null;
});

export const saveDataConnector = handleErrors(async (payload, id) => {
  const response = await api.put(`/strapi/data-connectors/${id}`, payload);
  return response.data || null;
});

export const deleteDataConnector = handleErrors(async (id) => {
  const response = await api.delete(`/strapi/data-connectors/${id}`);
  return response.data || null;
});

export const getDataConnectorJobs = handleErrors(async (config) => {
  const response = await api.get(`/strapi/data-connector-jobs`, config);
  return response.data || null;
});

export const addDataConnectorJob = handleErrors(async (payload) => {
  const response = await api.post(`/strapi/data-connector-jobs`, {
    data: payload,
  });
  return response.data || null;
});

export const saveDataConnectorJob = handleErrors(async (payload, id) => {
  const response = await api.put(`/strapi/data-connector-jobs/${id}`, {
    data: payload,
  });
  return response.data || null;
});

export const deleteDataConnectorJob = handleErrors(async (id) => {
  const response = await api.delete(`/strapi/data-connector-jobs/${id}`);
  return response.data || null;
});

export const getDataConnectorProjects = handleErrors(async (payload, config) => {
  const response = await api.post(
    `/data-connectors/talkwalker/projects`,
    payload,
    config
  );
  return response.data || null;
});

export const getDataConnectorTopics = handleErrors(async (payload, config) => {
  const response = await api.post(
    `/data-connectors/talkwalker/topics`,
    payload,
    config
  );
  return response.data || null;
});

export const getProjectFolders = handleErrors(async (config) => {
  const response = await api.get(`/strapi/topics`, config);
  return response.data || null;
});

export const getOrgUsers = handleErrors(async (id, config) => {
  const response = await api.get(`/strapi/org-users/${id}`, config);
  return response.data || null;
});

export const getJobStatuses = handleErrors(async (payload, config) => {
  const response = await api.post(`/job-statuses`, payload, config);
  return response.data || null;
});

export default api;
