import { makeStyles } from "@mui/styles";
import { getSize, getTime } from "../utils/utils";
import { DELETED, DELETING, STAGED } from "../utils/constants";
import Status from "./Status";
import DataGridTable from "../components/DataGridTable/index";

const useStyles = makeStyles(() => ({
  stagedText: {
    color: "#d32f2f",
  },
  disabledText: {
    color: "#77758E",
  },
  fill: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start",
    padding: "0px 6px",
    alignItems: "center",
    minHeight: "45px",
  },
}));

function DocumentsTable({ documents, selectedDocuments, onDocumentSelect }) {
  const classes = useStyles();

  const columns = [
    {
      field: "filename",
      headerName: "Filename",
      description: "File name",
      flex: 1.33,
      cellClassName: (params) => {
        if (
          params.getValue(params.id, "status") === DELETED ||
          params.getValue(params.id, "status") === DELETING
        ) {
          return classes.disabledText;
        }
      },
    },
    {
      field: "timestamp",
      headerName: "Date Uploaded",
      description: "Date Uploaded",
      valueFormatter: getTime,
      cellClassName: (params) => {
        if (params.getValue(params.id, "status") === STAGED) {
          return classes.stagedText;
        } else if (
          params.getValue(params.id, "status") === DELETED ||
          params.getValue(params.id, "status") === DELETING
        ) {
          return classes.disabledText;
        }
      },
      flex: 1.33,
    },
    {
      field: "size",
      headerName: "File Size",
      description: "File Size",
      valueFormatter: getSize,
      flex: 0.9,
      cellClassName: (params) => {
        if (
          params.getValue(params.id, "status") === DELETED ||
          params.getValue(params.id, "status") === DELETING
        ) {
          return classes.disabledText;
        }
      },
    },
    {
      field: "status",
      headerName: "Upload Status",
      flex: 1.15,
      renderCell: (params) => {
        return (
          <div className={classes.fill}>
            <Status value={params.value} />
          </div>
        );
      },
    },
  ];

  return <DataGridTable 
    columns={columns}
    rows={documents}
    selectedRows={selectedDocuments}
    onRowSelect={onDocumentSelect}
    noDataText="No imported datasets"
  />;
}

export default DocumentsTable;
