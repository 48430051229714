import React, { useState } from "react";
import { Button, Typography, Drawer, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as AboutIcon } from "icons/AboutIcon.svg";

const useStyles = makeStyles((theme) => ({
  root: {},
  btn: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 9999,
    flexShrink: 0,
    width: 380,
    height: "100vh",
  },
  drawerPaper: {
    width: 380,
    background: "#1B1927",
    border: "1px solid " + theme.palette.border.popup,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(80px)",
    borderRadius: "6px 0px 0px 6px",
  },
  header: {
    height: "63.39px !important",
    borderBottom: "1px solid #282C38",
    padding: theme.spacing(3.8, 4),
    display: "flex",
    alignItems: "center",
  },
  content: {
    padding: theme.spacing(4),
  },
  para: {
    color: "rgba(195, 194, 211, 0.8)",
    marginBottom: "30px",
    "& label": {
      color: "rgba(119, 117, 142, 0.8)",
      marginBottom: 12,
    },
    "& p": {
      fontWeight: "normal",
      color: "rgba(195, 194, 211, 0.8)",
      fontSize: 14,
      lineHeight: "20px",
    },
    "& li": {
      fontWeight: "normal",
      color: "rgba(195, 194, 211, 0.8)",
      fontSize: 14,
      lineHeight: "20px",
			padding: "2px 0px",
    },
    "& a": {
      color: "rgba(195, 194, 211, 0.8)",
    },
  },
  closeBtn: {
    backgroundColor: "#2C293A",
    color: "rgba(195, 194, 211, 0.8)",
    "&:hover": {
      backgroundColor: "#2C293A",
    },
  },
}));

export default function AboutPopup() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <Button
        startIcon={<AboutIcon />}
        className={classes.btn}
        onClick={open ? handleClose : handleOpen}
      >
        About
      </Button>

      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={"right"}
        open={open}
        onClose={handleClose}
      >
        <div className={classes.header}>
          <Typography variant="h6">About</Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.para}>
            <label>Current Version:</label>
            <p>2.6</p>
          </div>

          <div className={classes.para}>
            <label>Release Date:</label>
            <p>12/20/2022</p>
          </div>

          <div className={classes.para}>
            <label>Change log:</label>
            <p>
              The 2.6 release of the Blackbird Intelligence Dashboard enables
              users to <strong>import data</strong> from Talkwalker, provides <strong>automated narrative discovery</strong>, redefines{" "}
              <strong>‘Harm’</strong> to <strong>‘Risk’</strong>, greatly{" "}
              <strong>enhances customization</strong> within the platform,
              expands options in the <strong>engagement</strong> and{" "}
              <strong>post count graphs</strong>, and{" "}
              <strong>improves UI/UX</strong> on filters and date ranges.
            </p>

            <label>New features:</label>
            <ul>
              <li>
                Create a project in Constellation by{" "}
                <strong>
                  importing data from top tier social listening tools.
                </strong> Support for Talkwalker has been added with this release – more
                options will be available soon.
              </li>

              <li>
                <strong>Narrative Discovery and Prioritization.</strong> Reduce
                the time to insight by exploring pre-built narratives and
                visually assess the risk and popularity of each. Head to the
                Narrative Builder tab to start tuning the AI Generated Narrative
                Matrix with our full list of cohorts, metrics, and filters. For
                more details, visit our support article{" "}
                <Link
                  underline="always"
                  target="_blank"
                  href="https://blackbirdai.zendesk.com/hc/en-us/articles/10521019308049-Narratives-Discover-Refine"
                >
                  here
                </Link>
                .
              </li>

              <li>
                <strong>1 hop network analysis.</strong> Identify the user/group
                of users engaging with a user/group of users to better
                understand actor relationships (Twitter only).
              </li>

              <li>
                <strong>Tables in the Analyze tab are now customizable.</strong>{" "}
                Add or delete columns to focus on metrics that matter the most
                to your project. Added{" "}
                <strong>columns that quantify comments and quotes</strong> to
                Analyze tabs to analyze all forms of engagement on Twitter.
              </li>

              <li>
                Harm is now universally called <strong>&quot;Risk&quot;</strong>
                , to more accurately describe what is being flagged in the
                Constellation system.{" "}
                <strong>Create Custom Risk Profiles</strong> by navigating to
                the ‘Project Settings’ button in the top right of the dashboard.
                Click the <strong>‘Risk’</strong> tab, and build a risk profile
                by assembling any combination of our full list of metrics with
                Boolean operators. For more information on building custom risk
                profiles, visit our help center article{" "}
                <Link
                  underline="always"
                  target="_blank"
                  href="https://blackbirdai.zendesk.com/hc/en-us/articles/10616605912209"
                >
                  here
                </Link>
                .
              </li>
              <li>
                <strong>Revamped filter UX.</strong> Pick filters you&apos;d
                like to apply and toggle them on or off. Filters can be removed
                with an <strong>&apos;x&apos;</strong> button.
              </li>

              <li>
                Build <strong>multi-series line graphs</strong> to answer more
                questions with data. Select from our full list of metrics and
                signals to create an effective representation of how
                conversations evolved over time. Navigate to the graph in the
                overview and analyze tabs to start visualizing better.
              </li>

              <li>
                <strong>Enhanced Date Filter</strong> featuring custom and fixed
                date ranges.
              </li>
            </ul>

            <p>Platform stability and scalability enhancements, bug fixes.</p>

            <p>
              For more information, visit{" "}
              <Link
                underline="always"
                target="_blank"
                href={"https://blackbirdai.zendesk.com/hc/en-us"}
              >
                our Help Center{" "}
              </Link>
              or reach out to your Blackbird Account Manager.
            </p>
          </div>

          <Button
            variant="contained"
            className={classes.closeBtn}
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </div>
      </Drawer>
    </div>
  );
}
