import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";

export function monitorIdentity({ username, email, id, organization }) {
	Sentry.setUser({ username, id, email });

	const isBugBash = username.includes("bugbuster");
	let r = (Math.random() + 1).toString(36).substring(7);

	LogRocket.identify(isBugBash ? id + r : id, {
		name: isBugBash ? username + r : username,
		email,
	});

	mixpanel.people.set({
		$name: username,
		$email: email,
		Name: username,
		Email: email,
		Org: organization
	});

	mixpanel.identify(id);
}
