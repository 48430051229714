import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import moment from "moment";
import timezones from "utils/timezones.json";
import { EXPLANATION_STYLES, DEFAULT_TOPIC_FOLDER } from "./constants";

export function getRandomId() {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function getTimezone(timezone) {
	return getTimezoneName(true, timezone).replace("GMT", "UTC");
}

export function formatElasticSearchLabel(label) {
	let localLabelArr = label.split("_");
	let newLabel = "";
	for (let x = 0; x < localLabelArr.length; x++) {
		if (localLabelArr[x]) {
			newLabel += localLabelArr[x].charAt(0).toUpperCase();
			newLabel += localLabelArr[x].substr(1);
			if (x < localLabelArr.length - 1) {
				newLabel += " ";
			}
		}
	}
	return newLabel;
}

export function getTimezoneName(isShort, timezone) {
	if (timezone) {
		return timezone;
	}

	const today = new Date();
	const short = today.toLocaleDateString(undefined);
	const full = today.toLocaleDateString(undefined, {
		timeZoneName: isShort ? "short" : "long",
	});

	// Trying to remove date from the string in a locale-agnostic way
	const shortIndex = full.indexOf(short);
	if (shortIndex >= 0) {
		const trimmed =
      full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

		// by this time `trimmed` should be the timezone's name with some punctuation -
		// trim it from both sides
		return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
	} else {
		// in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
		return full;
	}
}

export function formatDate(date) {
	if (date) {
		var month = date.month.low;
		var day = date.day.low;
		return `${date.year.low}-${month < 10 ? "0" + month : month}-${
			day < 10 ? "0" + day : day
		} 00:00:00`;
	}
	return "";
}

export function formatForInput(date) {
	return moment(date).format("YYYY-MM-DDTHH:mm");
}

export function formatMyDate(date) {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${year}-${month < 10 ? "0" + month : month}-${
		day < 10 ? "0" + day : day
	}`;
}

export function getTimeZoneObject(field, value) {
	return timezones.find((timezone) => {
		return timezone[field] === value;
	});
}

function getStartOfDay(isoString) {
	const startOfDay = new Date(isoString);
	startOfDay.setUTCHours(0, 0, 0, 0);
	return startOfDay.getTime();
}

export function getDomain(start_date, end_date) {
	return [start_date, end_date].map(getStartOfDay);
}

export function formatMonthAbrDayFromTimestamp(ts) {
	return formatMonthAbrDay(new Date(ts).toISOString());
}

export function formatMonthAbrDay(date) {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	if (date) {
		const [year, month, day] = date.split("T")[0].split("-");
		const m = +month;
		return `${months[m - 1]} ${+day}, ${year}`;
	}
	return null;
}

export function subtructDays(date, days) {
	const _date = getDate(date);
	return formatWithDash(_date.setDate(_date.getDate() - days));
}

export function addDays(date, days) {
	const _date = getDate(date);
	return formatWithDash(_date.setDate(_date.getDate() + days));
}

export function subtractDaysMoment(date, days) {
	return moment(date).subtract(days, "days").toDate();
}

export function getDate(date) {
	const [year, month, day] = date.split("T")[0].split("-");
	return new Date(+year, +month - 1, +day);
}

export function secondsToDhm(seconds) {
	seconds = Number(seconds);
	if (seconds < 60) return " < 1 minute ";
	const d = Math.floor(seconds / (3600 * 24));
	const h = Math.floor((seconds % (3600 * 24)) / 3600);
	const m = Math.floor((seconds % 3600) / 60);

	const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
	const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
	const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
	return dDisplay + hDisplay + mDisplay;
}

export function formatWithDash(date) {
	return timeFormat("%Y-%m-%d")(date);
}

export function formatDateObj(date) {
	return timeFormat("%b %e, %Y")(date);
}

export function formatDateEntExpl(date) {
	const dateObj = new Date(date);
	return timeFormat("%m-%d-%Y %-I:%M%p")(dateObj);
}

export function formatDateTimePicker(date, offset) {
	let dateObj = new Date(date);
	let myOffset = dateObj.getTimezoneOffset() / 60;
	return moment(dateObj)
		.add(offset + myOffset, "hours")
		.format("MMM. DD, YYYY h:mm A");
}

export const getTimeStr = (date, offset = 0) => {
	let dateObj = new Date(date);
	let myOffset = dateObj.getTimezoneOffset() / 60;
	return moment(dateObj)
		.add(offset + myOffset, "hours")
		.format("HH:mm");
}

export function formatPostTime(date, offset = 0) {
	let dateObj = new Date(date);
	let myOffset = dateObj.getTimezoneOffset() / 60;
	return moment(dateObj)
		.add(offset + myOffset, "hours")
		.format("MMM. DD, YYYY h:mm A");
}

export function formatPostDate(date, offset) {
	let dateObj = new Date(date);
	let myOffset = dateObj.getTimezoneOffset() / 60;
	return moment(dateObj)
		.add(offset + myOffset, "hours")
		.format("MMM. DD, YYYY");
}

export function formatVisualizationTime(date) {
	let dateObj = new Date(date);
	return moment(dateObj).format("MMM. DD, YYYY h:mm A");
}

export function getTimestampOffset(date, offset) {
	let dateObj = new Date(date);
	let myOffset = dateObj.getTimezoneOffset() / 60;
	return moment(dateObj)
		.add(offset + myOffset, "hours")
		.toDate();
}

export function offsetDate(date, timezoneOffset) {
	return moment(date).add(-timezoneOffset, "hours").toISOString();
}

export function formatNumber(num) {
	return format(",")(Math.round(num));
}

export function formatNumberCeil(num) {
	return format(",")(Math.ceil(num));
}

export function formatNumberK(num) {
	return format(".2~s")(Math.round(num));
}

export function formatNumberkANDcomma(val) {
	return val >= 1000 ? formatNumberK(val) : val;
}

export function roundToThree(num) {
	return Math.floor(num * 1000) / 1000;
}

export function roundToTwo(num) {
	return Math.floor(num * 100) / 100;
}

export function roundToOne(num) {
	return Math.round(num * 10) / 10;
}

export function getPercent(num, total, precision = 3) {
	const formatFn =
    precision === 3 ? roundToThree : precision === 2 ? roundToTwo : roundToOne;
	return formatFn((num / (total || 1)) * 100);
}

export function getPercentStr(num, total, precision = 3) {
	return getPercent(num, total, precision) + "%";
}

export function roundUp(n) {
	let multiplier = 10;

	if (n < 10) {
		multiplier = 10;
	} else if (n < 100) {
		multiplier = 100;
	} else if (n < 10000) {
		multiplier = 1000;
	} else if (n < 50000) {
		multiplier = 5000;
	} else if (n < 100000) {
		multiplier = 10000;
	} else if (n < 500000) {
		multiplier = 50000;
	} else {
		multiplier = 100000;
	}

	return Math.ceil(n / multiplier) * multiplier;
}

export function getTicks(ticks, count, domain) {
	count = Math.max(4, count);

	const data = [domain[0], ...ticks, domain[1]];

	const arr = [];
	const step = Math.floor(data.length / count);

	for (let i = 0; i < data.length; i++) {
		const tick = data[i];

		if (
			(i % step === 0 && i <= data.length - 1 - step) ||
      i === data.length - 1 ||
      step === 0
		) {
			arr.push(tick);
		}
	}

	return arr;
}

/** Returns the browser's timezone offset in ISO 8601 format */
export function getIsoOffset() {
	const date = new Date();
	const offsetMinutes = date.getTimezoneOffset();
	const duration = moment.duration(offsetMinutes, "minutes");
	const sign = offsetMinutes >= 0 ? "-" : "+"; // flip the sign
	const hours = String(duration.hours()).padStart(2, "0");
	const minutes = String(duration.minutes()).padStart(2, "0");
	return `${sign}${hours}:${minutes}`;
}

/** Given the offset hours as a float, returns the ISO 8601 timezone offset */
export function formatTimezoneOffset(offsetHours) {
	const duration = moment.duration(Math.abs(offsetHours), "hours");
	const sign = offsetHours >= 0 ? "+" : "-";
	const hours = String(duration.hours()).padStart(2, "0");
	const minutes = String(duration.minutes()).padStart(2, "0");
	return `${sign}${hours}:${minutes}`;
}

export const DEFAULT_START_DATE = "2019-01-01T00:00:00.000Z";
const today = new Date();
export const DEFAULT_END_DATE = today.toISOString();

const buildStyleTag = (obj) => {
	return Object.keys(obj)
		.map((d) => {
			return `${d}: ${obj[d]};`;
		})
		.join("");
};

export const buildExplanationText = (textArr) => {
	return textArr
		.map((d) => {
			if (typeof d === "object") {
				const styleEntity = EXPLANATION_STYLES[d.style_entity];
				if (d.style_entity && styleEntity) {
					return `<span style="${buildStyleTag(styleEntity)}">${d.text}</span>`;
				}
				return d.text;
			}
			return d;
		})
		.join(" ")
		.trim();
};

export const calculateTopicFolders = (projects) => {
	const projectsByTopicName = {};

	for (const p of projects) {
		const project = { ...p };
		const { topics } = project;

		if (topics) {
			for (const topic of topics) {
				const topicName = topic.name;
				if (projectsByTopicName[topicName]) {
					projectsByTopicName[topicName].push(project);
				} else {
					projectsByTopicName[topicName] = [project];
				}
			}
		}

		if (!topics || topics.length === 0) {
			if (projectsByTopicName[DEFAULT_TOPIC_FOLDER]) {
				projectsByTopicName[DEFAULT_TOPIC_FOLDER].push(project);
			} else {
				projectsByTopicName[DEFAULT_TOPIC_FOLDER] = [project];
			}
		}
	}

	return Object.entries(projectsByTopicName)
		.sort((a, b) => b[0].localeCompare(a[0]))
		.map((t) => ({ name: t[0], projects: t[1], expanded: false }));
};

export const replaceLineBreaks = (str) =>
	str
		.split("\n")
		.map((d) => d.trim())
		.filter((d) => d)
		.join(",");

export const getParagraphs = (text) => {
	return (text || "").split("\n").filter((d) => d.trim());
};

// this will get Date instance in UTC
export const getUTCDateTime = (str) => {
	if (str) {
		return new Date(str.replace("Z", ""));
	}
	return new Date(new Date().toISOString().replace("Z", ""));
};

// this will get UTC string from current date
export const getISOString = (date, timeToReplace) => {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const hour = date.getHours();
	const minutes = date.getMinutes();

	const time = timeToReplace || `${hour < 10 ? "0" + hour : hour}:${
		minutes < 10 ? "0" + minutes : minutes
	}`;

	return `${year}-${month < 10 ? "0" + month : month}-${
		day < 10 ? "0" + day : day
	}T${time}:00.000Z`;
};

export const getISOStringMoment = (date, time) => {
  const dateStr = date.format("YYYY-MM-DD");
  return `${dateStr}T${time}:00.000Z`;
}

export const getCorrectDate = (date) => {
  const dateObj = new Date(date);
  const myOffset = dateObj.getTimezoneOffset() / 60;
  const dateMoment = moment(dateObj).add(myOffset, "hours");
  return dateMoment;
}

export const checkIfInFuture = (dateString) => {
	return new Date(dateString) > new Date(getISOString(new Date(), "23:59"));
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/** Automatically generate a display name by adding spaces to an upper camelcase string. */

export const refineDisplayName = (name) => {
  let result = "";
  let previousCharacterWasLetter = false;

	if (name.toLowerCase().includes("anti_") || name.toLowerCase().includes("pro_")) {
		name = name.split("_").map(capitalizeFirstLetter).join("-");
	} else {
		name = name.replaceAll("_", " ");
	}

  for (let i = 0; i < name.length; ++i) {
    const character = name[i];

		const isLetter = !!character.match(/[a-zA-Z]/);

    if (character === character.toUpperCase() && previousCharacterWasLetter && isLetter) {
      result += " " + character;
    } else {
      result += character;
    }

    previousCharacterWasLetter = isLetter;
  }

  return result.split(" ").map(capitalizeFirstLetter).join(" ");
}