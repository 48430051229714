import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Switch } from "@mui/material";
import { useUploaderContext } from "../context/Context";

import { ReactComponent as TalkwalkerIcon } from "icons/Uploader/Talkwalker.svg";
import { ReactComponent as PlugIcon } from "icons/Uploader/PlugIcon.svg";
import DataPartner from "./DataPartner";
import SimpleDialog from "components/UI/SimpleDialog";
import DataConnection from "./DataConnection";
import DataGridTable from "../components/DataGridTable";
import TableActions from "../TableActions";
import { deleteDataConnector, saveDataConnector } from "api/UploadApi";
import { toggleSnack } from "../context/action-creators";
import { SET_DATA_CONNECTORS, SET_DATA_CONNECTORS_REFETCH } from "../utils/constants";
import { formatTime } from "../utils/utils";
import ProviderComingSoon from "./ProviderComingSoon";

const useStyles = makeStyles((theme) => ({
  root: {},
  section: {
    padding: theme.spacing(2, 0),
  },
  sectionTitle: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#ffffff",
    marginBottom: "15px",
  },
  partnerBoxes: {
    display: "flex",
    flexWrap: "wrap",
    width: "calc(100% + 40px)",
    marginLeft: "-20px"
  },
  partnerBox: {
    height: 143,
    width: 355,
    margin: "0px 20px 15px 20px"
  }
}));

const partners = [
  {
    name: "Talkwalker",
    site: "talkwalker.com",
    icon: <TalkwalkerIcon />,
  },
  {
    commingSoon: true,
    text1: "More data providers",
    text2: "COMING SOON"
  },
  {
    commingSoon: true,
    text1: "More data providers",
    text2: "COMING SOON"
  },
  {
    commingSoon: true,
    text1: "More data providers",
    text2: "COMING SOON"
  },
  {
    commingSoon: true,
    text1: "More data providers",
    text2: "COMING SOON"
  },
  {
    commingSoon: true,
    text1: "More data providers",
    text2: "COMING SOON"
  },
];

const getColumns = (handleSwitch, onEdit, onDelete) => [
  {
    field: "dataProvider",
    headerName: "Data source",
    description: "Data source",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Nickname",
    description: "Nickname",
    flex: 1,
  },
  {
    field: "createdBy",
    description: "Created by",
    flex: 1,
    headerName: "Created by",
  },
  {
    field: "createdAt",
    description: "Date created",
    valueFormatter: formatTime,
    flex: 1,
    headerName: "Date created",
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      const checked = params.row.statusToggle;
      return (<Switch
        color="primary"
        size="small"
        checked={checked}
        onChange={e => handleSwitch(e.target.checked, params.row)}
      />);
    }
  },
  {
    field: "id",
    headerName: "Actions",
    renderCell: (params) => {
      return (
        <TableActions
          onEdit={() => onEdit(params.row)}
          onDelete={() => onDelete(params.row)}
        />
      );
    },
  },
];

export default function DataSources() {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentConnection, setCurrentConnection] = useState(null);
  const [connectionEdit, setConnectionEdit] = useState(null);

  const {
    state: { dataConnectors, dataConnectorsRefetch },
    dispatch,
  } = useUploaderContext();

  const handleConnect = (partner) => {
    setDialogOpen(true);
    setCurrentConnection(partner);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setConnectionEdit(null);
  };

  const handleEdit = (row) => {

    setConnectionEdit({ ...row });
    
    // tricky part: hardcode always talkwalker
    setCurrentConnection(partners[0]);

    setDialogOpen(true);
  };

  const handleDelete = async (row) => {
    const res = await deleteDataConnector(row.id);

    if (res) {
      dispatch(
        toggleSnack({
          snackMessage: "Data connector deleted",
          snackOpen: true,
          snackDuration: 3000,
        })
      );
      dispatch({
        type: SET_DATA_CONNECTORS_REFETCH,
        payload: !dataConnectorsRefetch,
      });
    }
  };

  const handleSwitch = async (enabled, datum) => {
    const localDataConnectors = [...dataConnectors];
    const index = localDataConnectors.findIndex(d => d.id === datum.id);

    if (index > -1) {
      await saveDataConnector({
        data: {
          statusToggle: enabled
        }
      }, datum.id);

      localDataConnectors[index] = {
        ...datum,
        statusToggle: enabled
      };

      dispatch({
        type: SET_DATA_CONNECTORS,
        payload: localDataConnectors,
      });
    }
  };

  const columns = getColumns(handleSwitch, handleEdit, handleDelete);

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>Data Partners</div>
        <Box className={classes.partnerBoxes}>
          {partners.map((d, i) => (
            <Box key={i} className={classes.partnerBox}>
              {d.commingSoon ? <ProviderComingSoon {...d} /> : <DataPartner {...d} onConnectclick={() => handleConnect(d)} />}
            </Box>
          ))}
        </Box>
      </div>

      <div className={classes.section}>
        <div className={classes.sectionTitle}>Connected Data Sources</div>
        <div className={classes.tableContainer}>
          <DataGridTable columns={columns} rows={dataConnectors} />
        </div>
      </div>

      <SimpleDialog
        title={<Box sx={{ display: "flex", alignItems: "center" }}>
          <PlugIcon />
          <Box component={"span"} sx={{ marginLeft: "10px" }}>Connect Data Source</Box>
        </Box>}
        open={dialogOpen}
        handleClose={handleDialogClose}
        noActions
      >
        {currentConnection && (
          <DataConnection
            connectionEdit={connectionEdit}
            closePopup={handleDialogClose}
            {...currentConnection}
          />
        )}
      </SimpleDialog>
    </div>
  );
}
