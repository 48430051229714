const COLORS = {
	"gray": {
		"main": "#EAEAEA",
		"$1": "#EAEAEA",
		"$2": "#CCCCCC",
		"$3": "#AFAFAF",
		"$4": "#8F8F8F",
		"$5": "#767676",
		"$6": "#656565",
		"$7": "#5A5A5A",
		"$8": "#424242",
		"$9": "#2D2D2D",
		"$10": "#1A1A1A"
	},
	"chartreuse": {
		"main": "#BDFC95",
		"$1": "#BDFC95",
		"$2": "#8FE14A",
		"$3": "#7AC13E",
		"$4": "#649F31",
		"$5": "#518327",
		"$6": "#467121",
		"$7": "#3D641C",
		"$8": "#2C4A12",
		"$9": "#1D330A",
		"$10": "#0F1E04"
	},
	"green": {
		"main": "#CDF3DA",
		"$1": "#CDF3DA",
		"$2": "#2BE7A0",
		"$3": "#28C76F",
		"$4": "#28A555",
		"$5": "#218746",
		"$6": "#1D743D",
		"$7": "#196736",
		"$8": "#134D28",
		"$9": "#0D341B",
		"$10": "#081F10"
	},
	"cyan": {
		"main": "#D1EFF7",
		"$1": "#D1EFF7",
		"$2": "#8ED7EB",
		"$3": "#43BDDD",
		"$4": "#229CBD",
		"$5": "#1C809C",
		"$6": "#186E86",
		"$7": "#156277",
		"$8": "#104858",
		"$9": "#0B313C",
		"$10": "#061D24"
	},
	"blue": {
		"main": "#DCEBFF",
		"$1": "#DCEBFF",
		"$2": "#ACCEFF",
		"$3": "#79B1FF",
		"$4": "#3E8EFF",
		"$5": "#056EFC",
		"$6": "#1161CF",
		"$7": "#1958AD",
		"$8": "#21436F",
		"$9": "#1F2E43",
		"$10": "#141B25"
	},
	"indigo": {
		"main": "#EDE8FC",
		"$1": "#EDE8FC",
		"$2": "#D1C5F6",
		"$3": "#B3A4F0",
		"$4": "#9281E9",
		"$5": "#7165E0",
		"$6": "#6257C0",
		"$7": "#584DA8",
		"$8": "#423978",
		"$9": "#2E284F",
		"$10": "#1C182A"
	},
	"violet": {
		"main": "#F5E5FB",
		"$1": "#F5E5FB",
		"$2": "#E7BEF4",
		"$3": "#D896EE",
		"$4": "#C768E6",
		"$5": "#B639DE",
		"$6": "#A222CC",
		"$7": "#901EB5",
		"$8": "#6B1687",
		"$9": "#4B0F5E",
		"$10": "#2D0939"
	},
	"fuchsia": {
		"main": "#FFE1F5",
		"$1": "#FFE1F5",
		"$2": "#FEB7E7",
		"$3": "#FD85D7",
		"$4": "#FC3CBF",
		"$5": "#E00399",
		"$6": "#C20385",
		"$7": "#AC0376",
		"$8": "#810259",
		"$9": "#5C013F",
		"$10": "#3A0127"
	},
	"pink": {
		"main": "#FEE3EC",
		"$1": "#FEE3EC",
		"$2": "#FCBAD0",
		"$3": "#FB8DB2",
		"$4": "#F8528A",
		"$5": "#E90954",
		"$6": "#CA0849",
		"$7": "#B30741",
		"$8": "#870531",
		"$9": "#5F0423",
		"$10": "#3C0216"
	},
	"red": {
		"main": "#FFE4E1",
		"$1": "#FFE4E1",
		"$2": "#FEBCB7",
		"$3": "#FD9087",
		"$4": "#FD5447",
		"$5": "#EB1403",
		"$6": "#CC1203",
		"$7": "#B51002",
		"$8": "#880C02",
		"$9": "#600801",
		"$10": "#3B0501"
	},
	"orange": {
		"main": "#FDE7D3",
		"$1": "#FDE7D3",
		"$2": "#FAC190",
		"$3": "#F79847",
		"$4": "#E46E0A",
		"$5": "#BC5B08",
		"$6": "#A24E07",
		"$7": "#904506",
		"$8": "#6B3304",
		"$9": "#492303",
		"$10": "#2B1502"
	},
	"gold": {
		"main": "#F7E9C4",
		"$1": "#F7E9C4",
		"$2": "#EBC96D",
		"$3": "#FFD051",
		"$4": "#F7B916",
		"$5": "#DFA304",
		"$6": "#9A7104",
		"$7": "#674C01",
		"$8": "#563E00",
		"$9": "#332501",
		"$10": "#231900"
	}
};

export const METRIC_COLORS = {
	total: "#AFAFAF",
	risk: "#E46E0A",
	anomalous: "#6257C0",
	bot_like: "#056EFC",
	toxic: "#FD5447",
	influencial: "#F8E644",
	known_hoax: "#e7394e",
	negative_sentiment: "#EB1403",
	positive_sentiment: "#28A555",
	neutral_sentiment: "#767676",
	cohorts: ["#7AC13E", "#FC3CBF", "#43BDDD", "#28C76F", "#E90954", "#B639DE"],
	emotion: ["#B639DE", "#7AC13E", "#E90954", "#28C76F", "#FC3CBF", "#43BDDD"],
	other: ["#E90954", "#28C76F", "#FC3CBF", "#43BDDD", "#7AC13E", "#B639DE"],
};

export default COLORS;