import { useTheme } from "@emotion/react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Container,
  CssBaseline,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { DRAWER_CLOSED_WIDTH, DRAWER_WIDTH } from "../DashboardPage";
import NavigationDrawer from "../DashboardPage/NavigationDrawer";
import { data_tabs } from "utils/constants";
import {
  Link,
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Projects from "./Projects";
import Files from "./Files";
import Connections from "./Connections";
import useTabs from "hooks/useTabs";
import { SET_DATA_CONNECTORS } from "./utils/constants";
import { getDataConnectors } from "api/UploadApi";
import { withUploaderProvider, useUploaderContext } from "./context/Context";
import CreateProject from "pages/DataUploader/CreateProject/CreateProject";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: 0,
    minHeight: "100vh",
  },
  menuIconWrapper: {
    cursor: "pointer",
  },
  menuIcon: {
    marginRight: "29px",
  },
  bannerTexts: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(4, 0),
  },
  tabRoot: {
    borderBottom: "1px solid #282C38 !important",
  },
  tabs: {
    padding: theme.spacing(4, 0),
  },
  indicator: {
    backgroundColor: theme.palette.primary.main + " !important",
  },
  muiTab: {
    fontWeight: 600,
    color: "#fff !important",
    textTransform: "uppercase",
  },
  unSelected: {
    color: "#AFAFAF !important",
  },
  selected: {
    color: theme.palette.primary.main + " !important",
  },
  disabled: {
    color: "#AFAFAF !important",
    opacity: 0.3,
  },
  content: {
    width: "100%",
    flexGrow: 1,
    maxWidth: (props) =>
      `calc(100% - ${
        props.navOpen ? props.drawerWidth : props.drawerClosedWidth
      }px)`,
  },
  subTitle: {
    color: "#6F7494",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "30px",
  },
  mainTitle: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "34px",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DataUploader = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = smallScreen ? 0 : DRAWER_WIDTH;
  const drawerClosedWidth = smallScreen ? 0 : DRAWER_CLOSED_WIDTH;
  const [navOpen, setNavOpen] = useState(false);
  const classes = useStyles({ navOpen, drawerWidth, drawerClosedWidth });

  const { topTab } = useTabs();
  const location = useLocation();

  const isCreatePage = location.pathname.includes("/projects/create");

  const { path, url } = useRouteMatch();
  const [value, setValue] = useState(topTab);

  const {
    state: { dataConnectorsRefetch },
    dispatch,
  } = useUploaderContext();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const loadDataConnectors = async () => {
      const resp = await getDataConnectors();
      const newData = (resp?.data || []).map((d) => {
        return {
          id: d.id,
          ...d.attributes,
          createdBy:
            d.attributes.owner && d.attributes.owner.data
              ? d.attributes.owner.data.attributes.username
              : null,
        };
      });
      dispatch({
        type: SET_DATA_CONNECTORS,
        payload: newData,
      });
    };

    loadDataConnectors();
  }, [dispatch, dataConnectorsRefetch]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      {(!smallScreen || navOpen) && (
        <NavigationDrawer
          setSpikeDate={() => {}}
          navOpen={navOpen}
          setNavOpen={setNavOpen}
          validProject={false}
          hideProjects={true}
        />
      )}

      <main className={classes.content} id="main_content">
        <Container>
          {!isCreatePage && (
            <Box className={classes.bannerTexts}>
              {smallScreen && (
                <div className={classes.menuIconWrapper}>
                  <MenuIcon
                    className={classes.menuIcon}
                    onClick={() => setNavOpen(true)}
                  ></MenuIcon>
                </div>
              )}
              <Box>
                <Typography variant="h4" className={classes.mainTitle}>
                  Manage Your Data
                </Typography>
                <Typography variant="subtitle1" className={classes.subTitle}>
                  Connect external data sources, upload files, create jobs for
                  recurring data ingestion.
                </Typography>
              </Box>
            </Box>
          )}

          {!isCreatePage && (
            <div className={classes.tabs}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor={"primary"}
                indicatorColor={"primary"}
                classes={{
                  root: classes.tabRoot,
                  indicator: classes.indicator,
                }}
              >
                {data_tabs.map((tab, i) => (
                  <Tab
                    key={i}
                    label={tab.name}
                    to={`${url}/${tab.path}`}
                    component={Link}
                    disabled={!tab.active}
                    {...a11yProps(i)}
                    classes={{
                      root: classes.muiTab,
                      textColorPrimary: classes.unSelected,
                      disabled: classes.disabled,
                      selected: classes.selected,
                    }}
                  />
                ))}
              </Tabs>
            </div>
          )}

          <Switch>
            <Route path={`${path}/projects/create`}>
              <CreateProject />
            </Route>

            <Route path={`${path}/projects`}>
              <Projects />
            </Route>

            <Route path={`${path}/connections`}>
              <Connections />
            </Route>

            <Route path={`${path}/files`}>
              <Files />
            </Route>

            <Route
              exact
              path={`${path}/`}
              render={() => {
                return <Redirect to={`${path}/projects`} />;
              }}
            />
          </Switch>
        </Container>
      </main>
    </div>
  );
};

export default withUploaderProvider(DataUploader);
