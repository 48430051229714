import { Button, Typography, Drawer, IconButton, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as XIcon } from "icons/UI/x.svg";
import { Box } from "@mui/system";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useEffect, useState } from "react";
import InputValidate from "components/UI/InputValidate";
import TextAreaValidate from "components/UI/TextAreaValidate";
import TableGrid from "../RichTable/TableGrid";
import { ReactComponent as EditIcon } from "icons/EditNarrative.svg";
import { ReactComponent as DeleteIcon } from "icons/Delete.svg";
import FileUploader from "components/UI/FileUploader";
import {
	createUserGroup,
	deleteUserGroup,
	updateUserGroup,
} from "api/AnalyticsApi";
import { replaceLineBreaks } from "utils/format";

const WIDTH = 560;

const useStyles = makeStyles((theme) => ({
	root: {},
	btn: {
		fontWeight: 600,
		fontSize: 14,
		lineHeight: "16px",
		color: "rgba(255,255,255,0.6)",
	},
	drawer: {
		zIndex: 999999,
		flexShrink: 0,
		width: WIDTH,
		height: "100vh",
	},
	drawerPaper: {
		width: WIDTH,
		background: theme.palette.background.$2,
		border: "1px solid " + theme.palette.border.popup,
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		backdropFilter: "blur(80px)",
		borderRadius: "6px 0px 0px 6px",
	},
	header: {
		fontSize: "16px",
		fontWeight: 700,
		color: "white",
	},
	headerTitle: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		margin: "20px 20px 20px 15px",
	},
	backButton: {
		padding: 0,
		width: "34px",
		height: "34px",
		marginRight: "16px",
	},
	content: {
		padding: theme.spacing(2, 4),
	},
	closeBtn: {
		backgroundColor: "#2C293A",
		color: "rgba(195, 194, 211, 0.8)",
		"&:hover": {
			backgroundColor: "#2C293A",
		},
	},
	btns: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		"&>button": {
			marginLeft: theme.spacing(2),
		},
	},
	divider: {
		borderColor: "rgba(99, 96, 123, 0.2)",
	},
	nameAndButtons: {
		display: "flex",
		alignItems: "flex-start",
		padding: theme.spacing(2, 4),
	},
	or: {
		textAlign: "center",
		position: "relative",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		color: "#8F8F8F",
		"&::before": {
			content: "\"\"",
			width: "calc(50% - 24px)",
			position: "absolute",
			top: "50%",
			left: "0%",
			height: "1px",
			background: "#282C38",
		},
		"&::after": {
			content: "\"\"",
			width: "calc(50% - 24px)",
			position: "absolute",
			top: "50%",
			right: "0%",
			height: "1px",
			background: "#282C38",
		},
	},
}));

const getUserTableColumns = ({ handleEdit, handleDelete }) => [
	{
		field: "name",
		text: "Group name",
	},
	{
		field: "userIds",
		text: "User count",
		cellComponent: ({ value }) => value.length,
	},
	// {
	//   field: "date_created",
	//   text: "Date created",
	//   cellComponent: ({ value }) => formatDateObj(new Date(value)),
	// },
	{
		field: "*",
		text: "Actions",
		cellComponent: ({ value }) => {
			return (
				<div>
					<IconButton onClick={() => handleEdit(value)}>
						<EditIcon />
					</IconButton>
					<IconButton color="error" onClick={() => handleDelete(value)}>
						<DeleteIcon />
					</IconButton>
				</div>
			);
		},
	},
];

const getCsvText = (userGroup) => {
	return (userGroup.userIds || []).join(",");
};

export default function UsersGroupDrawer() {
	const classes = useStyles();
	const {
		dispatch,
		state: { userGroups, userGroupEdit, userGroupDrawerOpen, project },
	} = useAppContext();

	const [name, setName] = useState(userGroupEdit.name || "");
	const [csvText, setCsvText] = useState(getCsvText(userGroupEdit));
	const [groupExists, setGroupExists] = useState(false);
	const [isEmpty, setIsEmpty] = useState(false);
	const [csvTextError, setCsvTextError] = useState(null);

	const tableRows = userGroups.map((d) => {
		return {
			...d,
			disabled: userGroupEdit.id ? userGroupEdit.id !== d.id : false,
		};
	});

	useEffect(() => {
		setName(userGroupEdit.name || "");
		setCsvText(getCsvText(userGroupEdit));
		setGroupExists(false);
		setIsEmpty(false);
		setCsvTextError(null);
	}, [userGroupEdit]);

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleCsvTextChange = (e) => {
		setCsvText(e.target.value);
	};

	const handleDrop = (files) => {
		const reader = new FileReader();
		reader.onload = function () {
			const text = reader.result;
			setCsvText(replaceLineBreaks(text || ""));
		};

		reader.readAsText(files[0]);
	};

	const handleClose = () => {
		dispatch({
			type: ACTIONS.SET_USER_GROUPS_DRAWER_OPEN,
			payload: false,
		});
	};

	const showMessage = (msg, type) => {
		dispatch({
			type: ACTIONS.SHOW_MESSAGE,
			payload: msg
				? {
					message: msg,
					type: type,
				}
				: null,
		});
	};

	const handleSave = async () => {
		const nameEmpty = name.trim() === "";
		const csvEmpty = csvText.trim() === "";
		const formattedText = replaceLineBreaks(csvText.trim());

		setCsvText(formattedText);
		setIsEmpty(nameEmpty);
		setCsvTextError(csvEmpty ? "Users list is empty." : null);

		if (nameEmpty || csvEmpty) return;

		const users = formattedText
			.split(",")
			.map((d) => d.trim())
			.filter((d) => d);

		const hasWhiteSpace = users.some(d => d.includes(" "));

		if (hasWhiteSpace) {
			return setCsvTextError("Username should not contain whitespace.");
		}

		if (userGroupEdit.id) {
			const newUserGroup = {
				id: userGroupEdit.id,
				userIds: users,
			};

			if (userGroupEdit.name !== name) {
				newUserGroup.name = name;
			}

			const updateRes = await updateUserGroup(newUserGroup);

			if (updateRes) {
				dispatch({
					type: ACTIONS.UPDATE_USER_GROUP,
					payload: {
						...newUserGroup,
						name,
					},
				});
				showMessage("User group updated", "success");
				handleClose();
			}
		} else {
			const exists = userGroups.some((d) => d.name === name);
			setGroupExists(exists);

			if (!exists) {
				const res = await createUserGroup({
					name,
					userIds: users,
					projectID: project.id
				});

				if (res) {
					dispatch({
						type: ACTIONS.ADD_USER_GROUP,
						payload: res,
					});
					showMessage("User group created", "success");
					handleClose();
				}
			}
		}
	};

	const handleDelete = async (d) => {
		const res = await deleteUserGroup(d.id);
		if (res) {
			dispatch({
				type: ACTIONS.REMOVE_USER_GROUP,
				payload: d,
			});
			handleClose();
			showMessage("User group deleted", "success");
		}
	};

	const handleEdit = (d) => {
		dispatch({
			type: ACTIONS.EDIT_USER_GROUP,
			payload: d,
		});
	};

	const columns = getUserTableColumns({
		handleEdit,
		handleDelete,
	});

	return (
		<div className={classes.root}>
			<Drawer
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper,
				}}
				anchor={"right"}
				open={userGroupDrawerOpen}
				onClose={handleClose}
			>
				<div className={classes.headerTitle}>
					<IconButton className={classes.backButton} onClick={handleClose}>
						<XIcon />
					</IconButton>
					<Typography className={classes.header}>User Groups</Typography>
				</div>
				<Divider className={classes.divider} />
				<Box className={classes.content}>
					<Typography variant="subtitle2" sx={{ pb: 0.5 }}>
            Create a user group
					</Typography>
					<Box>
						<TextAreaValidate
							minRows={12}
							placeholder="Enter or paste a list of users separated by a comma"
							errorMsg={csvTextError}
							value={csvText}
							onChange={handleCsvTextChange}
						/>
					</Box>
					<Box className={classes.or}>or</Box>
					<Box>
						<FileUploader onDrop={handleDrop} />
					</Box>
				</Box>
				<Box className={classes.nameAndButtons}>
					<Box sx={{ flexGrow: 1 }}>
						<InputValidate
							value={name}
							showError={isEmpty}
							onChange={handleNameChange}
							exists={groupExists}
							emptyWarning={"Name your user group before saving"}
							existsWarning={`${name} already exists.`}
							placeholder={"Name your user group"}
						/>
					</Box>
					<Box className={classes.btns}>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleSave}
						>
              Save User Group
						</Button>
					</Box>
				</Box>
				<Box className={classes.content}>
					<Typography variant="subtitle2" sx={{ pb: 0.5 }}>
            Manage user groups
					</Typography>
					<Box>
						<TableGrid columns={columns} data={tableRows} />
					</Box>
				</Box>
			</Drawer>
		</div>
	);
}
