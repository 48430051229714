import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useUploaderContext } from "../context/Context";
import { ACCEPTED_FILES, PENDING, STAGED } from "../utils/constants";
import { ReactComponent as UploadIcon } from "icons/Uploader/upload-icon.svg";
import ProceedUpload from "./ProceedUpload";
import UploadProgress from "./UploadProgress";
import { replaceSpecialChars, stageDocs } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  dropBox: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: (props) => (props.boxActive ? "#25ACF7" : "#514E71"),
    borderSpacing: 3,
    borderRadius: 12,
    width: "100%",
    height: "180px",
  },
  dragInactiveParent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dropHere: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadText: {
    marginTop: theme.spacing(2),
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#8684A4",
  },
}));

const Uploader = ({
  setStagedDocuments,
  uploadProgress,
  handleUploads,
  cancelUpload,
  resetImport,
  uploadBtnDisabled,
  triggerSnack,
}) => {
  const {
    state: { documents, stagedDocuments, uploading },
  } = useUploaderContext();

  const haveDocumentsToUpload = stagedDocuments.length > 0;
  const boxActive = haveDocumentsToUpload && !uploading;
  const classes = useStyles({ boxActive });

  const onDropAccepted = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const newDocuments = acceptedFiles.map((file) => ({
        file,
        filename: replaceSpecialChars(file.name),
        size: file.size,
        description: "",
        exploratory: false,
        status: STAGED,
        id: replaceSpecialChars(file.name),
      }));

      const newDocs = stageDocs(documents, stagedDocuments, newDocuments);

      setStagedDocuments(newDocs);

      triggerSnack(
        `Selected ${acceptedFiles.length} file${
          acceptedFiles.length > 1 ? "s" : ""
        }. Press "Upload" to start uploading`,
        true,
        false
      );
    }
  };

  const onDropRejected = (rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      const names = rejectedFiles.map(({ file }) => file.path).join(", ");
      triggerSnack(
        rejectedFiles.length === 1
          ? `${names} has an invalid file extension.`
          : `${names} have invalid file extensions.`,
        true,
        true
      );
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: ACCEPTED_FILES,
    onDropAccepted,
    onDropRejected,
    noClick: haveDocumentsToUpload,
  });

  const upload = () =>
    handleUploads(stagedDocuments.map((doc) => ({ ...doc, status: PENDING })));

  return (
    <div className={classes.root}>
      <div {...getRootProps()} className={classes.dropBox}>
        <input {...getInputProps()} />

        {uploading ? (
          <UploadProgress
            uploadProgress={uploadProgress}
            cancelUpload={cancelUpload}
          />
        ) : haveDocumentsToUpload ? (
          <ProceedUpload
            files={stagedDocuments}
            setStagedDocuments={setStagedDocuments}
            onAddMore={open}
            resetImport={resetImport}
            onUpload={upload}
            uploadBtnDisabled={uploadBtnDisabled}
          />
        ) : (
          <DropItHere isDragActive={isDragActive} />
        )}
      </div>
    </div>
  );
};

function DropItHere({ isDragActive }) {
  const classes = useStyles();

  return isDragActive ? (
    <div className={classes.dropHere}>
      <Typography variant="body1" className={classes.uploadText}>
        Drop here
      </Typography>
    </div>
  ) : (
    <div className={classes.dragInactiveParent}>
      <div>
        <UploadIcon />
      </div>
      <Typography variant="subtitle2" className={classes.uploadText}>
        Drag files here or select file(s) manually
      </Typography>
    </div>
  );
}

export default Uploader;
