import { handleError } from "api/errors";
import { useQuery } from "react-query";

const empty = {
	isLoading: false,
	data: null,
};

const parseRequestBody = (apiData) => {
	if (Array.isArray(apiData)) {
		return apiData;
	}

	if (apiData && apiData.data && apiData.data.success) {
		return apiData.data.body;
	}

	if (apiData && apiData.data === null) {
		return null;
	}
  
	return apiData;
};

export default function useApi({ apiKey, apiFn, payload }) {
	const multiple = Array.isArray(payload);

	const cb = () => {
		if (multiple && payload.every((d) => d.db)) {
			const requests = payload.map((d, i) => {
				const fn = Array.isArray(apiFn) ? apiFn[i] : apiFn;
				return fn(d);
			});

			return Promise.all(requests);
		}

		if (payload && payload.db) {
			return apiFn(payload);
		}

		return new Promise((res) => res(empty));
	};

	const queryKey = multiple ? payload : [payload];
	const {
		isLoading,
		data: apiData,
		error,
	} = useQuery([apiKey, ...queryKey], cb);

	let data = null;

	if (apiData) {
		if (multiple) {
			if (Array.isArray(apiData)) {
				data = apiData.map(parseRequestBody);
			}
		} else {
			data = parseRequestBody(apiData);
		}
	}

	if (error) {
		handleError(error);
	}

	return {
		isLoading,
		data,
		error,
	};
}
