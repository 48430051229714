import { Button, Chip, Divider, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomDropdown from "components/UI/CustomDropdown";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { ReactComponent as EditIcon } from "icons/EditNarrative.svg";
import { DEFAULT_CLASSIFIER, LOCAL_STORAGE_KEYS } from "utils/constants";
import {
  // deleteClassifierProfile,
  setClassifierProfileAPI,
} from "api/AnalyticsApi";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  label: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    transform: "translate(0, 0) scale(1)",
  },
  spaceTop: {
    marginTop: theme.spacing(1),
  },
  muiItem: {
    fontSize: 13,
    color: "#E7E7EF",
  },
  profileSelect: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
  },
  profileListItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "60%",
    marginRight: 10,
    borderRadius: 4,
    background: "#302E4F",
    "& .MuiSelect-select": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      paddingRight: "12px !important",
      paddingLeft: "10px !important",
    },
		"& .dropdown_icon": {
      right: "10px !important",
    },
  },
  manage: {
    padding: "10px 0px",
  },
  profileName: {
    flexGrow: 1,
    color: "#afafaf",
    fontSize: 14,
  },
  btns: {},
  chipRoot: {
    height: "24px",
    fontSize: "12px",
    fontWeight: 500,
  },
  chipRootCustom: {
    background: "#0D341B",
    border: "1px solid #218746",
  },
  chipRootDefault: {
    border: "1px solid #6757d4",
    background:
      "linear-gradient(250.87deg, rgba(88, 88, 213, 0.3) -2.92%, rgba(113, 88, 213, 0.3) 106.15%)",
  },
}));

export default function ClassifierTab() {
  const classes = useStyles();

  const {
    dispatch,
    state: { harmClassifiers, harmClassifier, project },
  } = useAppContext();

  const setClassifierProfile = async (newProfile) => {
    const updateRes = await setClassifierProfileAPI(
      newProfile.id ? newProfile.id : null
    );

    if (updateRes) {
      await localStorage.setItem(
        LOCAL_STORAGE_KEYS.PREVIEW_CLASSIFIER_PROFILE_REF,
        newProfile.id
      );
      dispatch({
        type: ACTIONS.SET_HARM_CLASSIFIER,
        payload: newProfile,
      });
    }
  };

  const handleProfileChange = async (e) => {
    const p = harmClassifiers.find((d) => d.id === e.target.value);
    if (p && p.id !== harmClassifier.id) {
      await setClassifierProfile(p);
    }
  };

  const handleSetToDefault = async () => {
    await setClassifierProfile(DEFAULT_CLASSIFIER);
  };

  const handleCreate = () => {
    dispatch({
      type: ACTIONS.EDIT_HARM_CLASSIFIER,
      payload: {},
    });
    dispatch({
      type: ACTIONS.SET_HARM_CLASSIFIER_OPEN,
      payload: true,
    });
    // hidePopup();
  };

  // const handleDelete = async (d) => {
  //   const deleteRes = await deleteClassifierProfile(d.id);
  //   if (deleteRes) {
  //     dispatch({
  //       type: ACTIONS.REMOVE_HARM_CLASSIFIER,
  //       payload: d,
  //     });
  //   }
  // };

  const handleEdit = (d) => {
    dispatch({
      type: ACTIONS.EDIT_HARM_CLASSIFIER,
      payload: d,
    });
  };

  const globalProfile = project.classifierProfile || DEFAULT_CLASSIFIER;
  const chipClass = globalProfile.isDefault
    ? classes.chipRootDefault
    : classes.chipRootCustom;

  return (
    <div>
      {globalProfile && (
        <div className={classes.row}>
          <div className={classes.label}>Global Classifier Profile</div>
          <div className={classes.profileSelect}>
            <Chip
              classes={{
                root: clsx(classes.chipRoot, chipClass),
              }}
              label={globalProfile.name}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
      )}

      <Divider light />

      {harmClassifier && (
        <>
          <Divider light />
          <div className={classes.row}>
            <div className={classes.label}>Preview Classifier Profile</div>
            <div className={classes.profileSelect}>
              <div className={classes.dropdown}>
                <CustomDropdown
                  transparent
                  value={harmClassifier.id}
                  onChange={handleProfileChange}
                  options={harmClassifiers.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </div>
              <Button
                disabled={harmClassifier.isDefault}
                variant="outlined"
                color="primary"
                onClick={() => handleSetToDefault()}
              >
                Reset to default
              </Button>
            </div>
          </div>
        </>
      )}

      <Divider light />

      <div className={classes.row}>
        <div className={classes.label}>Manage Classifier Profiles</div>
        <div className={classes.manage}>
          {harmClassifiers
            .filter((d) => !d.isDefault)
            .map((d) => (
              <div className={classes.profileListItem} key={d.name}>
                <Typography variant="caption" className={classes.profileName}>
                  {d.name}
                </Typography>
                <div className={classes.btns}>
                  <IconButton onClick={() => handleEdit(d)}>
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton color="error" onClick={() => handleDelete(d)}>
                    <DeleteIcon />
                  </IconButton> */}
                </div>
              </div>
            ))}
        </div>
        <Button variant="outlined" color="primary" onClick={handleCreate}>
          Create Classifier Profile
        </Button>
      </div>
    </div>
  );
}
