import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { EXPLANATIONS } from "utils/constants";
import { ReactComponent as CopyClipboardIcon } from "icons/UI/copy.svg";
import { ReactComponent as PropagandaIcon } from "icons/Propaganda.svg";
import { ReactComponent as ToxicIcon } from "icons/Toxicity.svg";
import { ReactComponent as HarmIcon } from "icons/Harm.svg";
import { Button, Typography } from "@mui/material";
import ManipBigIcon from "icons/Explanation/Manip.svg";
import ToxicBigIcon from "icons/Explanation/Toxic.svg";
import HarmBigIcon from "icons/Explanation/Harm.svg";
import { useEffect, useState } from "react";
import { getExplanation } from "api/AnalyticsApi";
import { buildExplanationText } from "utils/format";

const useStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: (props) => props.backgroundColor,
		borderRadius: 6,
	},
	reason: {
		color: (props) => props.textColor,
		fontSize: 12,
		lineHeight: "17px",
		fontWeight: "normal",
		marginBottom: theme.spacing(1.5),
	},
	text: {
		fontSize: 13,
		lineHeight: "19px",
		fontWeight: "normal",
	},
	header: {
		padding: theme.spacing(2),
		borderBottom: props => "1px solid " + props.borderColor,
		display: "flex",
		alignItems: "center",
	},
	icon: {
		borderRadius: 6,
		width: 32,
		height: 32,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginRight: theme.spacing(2),
		backgroundColor: (props) =>
			props.type === EXPLANATIONS.anomalous ? "#2D2B3C" : props.backgroundColor,
	},
	label: {
		fontSize: 15,
		lineHeight: "16px",
		fontWeight: "800",
	},
	content: {
		padding: theme.spacing(2),
		backgroundImage: props => {
			let iconUrl = "";

			if (props.type === EXPLANATIONS.anomalous) {
				iconUrl = ManipBigIcon;
			} else if (props.type === EXPLANATIONS.toxicity) {
				iconUrl = ToxicBigIcon;
			} else if (props.type === EXPLANATIONS.harm) {
				iconUrl = HarmBigIcon;
			}

			return `url(${iconUrl})`;
		},
		backgroundRepeat: "no-repeat",
		backgroundPosition: "90% center",
	},
	copyBtn: {
		color: "#D0D0D3",
		fontWeight: "normal",
	},
}));

const getConfig = (type, theme) => {
	const {background, text, border} = theme.palette;

	let backgroundColor = null;
	let textColor = null;
	let borderColor = null;

	if (type === EXPLANATIONS.harm) {
		backgroundColor = background.harmExpl;
		textColor = text.harmExpl;
		borderColor = border.harmExpl;
	} else if (type === EXPLANATIONS.toxicity) {
		backgroundColor = background.toxicExpl;
		textColor = text.toxicExpl;
		borderColor = border.toxicExpl;
	} else if (type === EXPLANATIONS.anomalous) {
		backgroundColor = background.anomalousExpl;
		textColor = text.anomalousExpl;
		borderColor = border.anomalousExpl;
	}

	const conf = {
		[EXPLANATIONS.harm]: {
			label: "Risk",
			icon: HarmIcon,
			textColor: "",
		},
		[EXPLANATIONS.toxicity]: {
			label: "Toxic",
			icon: ToxicIcon,
		},
		[EXPLANATIONS.anomalous]: {
			label: "Anomalous",
			icon: PropagandaIcon,
		},
	};

	return {
		...conf[type],
		backgroundColor,
		textColor,
		borderColor,
	};
};

export default function Explanation({ type, entityType }) {
	const theme = useTheme();
	const {
		backgroundColor,
		textColor,
		borderColor,
		icon: Icon,
		label,
	} = getConfig(type, theme);

	const classes = useStyles({ backgroundColor, textColor, borderColor, type });

	const [text, setText] = useState("");

	useEffect(() => {
		const loadExpl = async () => {
			const expl = await getExplanation(entityType, type);
			console.log(expl);
			const _text = buildExplanationText(expl);
			setText(_text);
		};
		loadExpl();
	}, [type, entityType]);

	return (
		<div className={classes.card}>
			<div className={classes.header}>
				<div className={classes.icon}>
					<Icon />
				</div>
				<Typography variant="h5" className={classes.label}>
					{label}
				</Typography>
			</div>
			<div className={classes.content}>
				<Typography variant="h5" className={classes.reason}>
          REASON:
				</Typography>

				<Typography
					paragraph
					display={"block"}
					variant={"body2"}
					className={classes.text}
					dangerouslySetInnerHTML={{ __html: text }}
				></Typography>

				<div>
					<Button
						type="text"
						startIcon={<CopyClipboardIcon />}
						className={classes.copyBtn}
					>
            Copy to Clipboard
					</Button>
				</div>
			</div>
		</div>
	);
}
