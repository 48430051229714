import {
  DEFAULT_NARRATIVE,
  PLATFORMS,
  DEFAULT_SORT,
  ALL_PROJECT,
  DEFAULT_PROFILE,
  DEFAULT_CLASSIFIER,
} from "utils/constants";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "utils/format";
import { getFilterObjFromPostFilters } from "pages/DashboardPage/Shared/RichTable/filterConfig";
import { getNarrativeData, getProjectSettings } from "./reducerUtilities.js";

export const initialState = {
  platform: PLATFORMS.twitter,
  platforms: [],

  //project
  projects: [],
  project: {},

  //narrative
  narratives: [],
  narrativeClusters: [],
  narrative: DEFAULT_NARRATIVE,
  loadingNarratives: true,
  isSmallScreen: false,

  //sort
  analyzeSortField: DEFAULT_SORT,
  analyzeSortDir: "DESC",

  //date
  spike_date: null,
  start_date: DEFAULT_START_DATE,
  end_date: DEFAULT_END_DATE,
  timezoneOffset: 0, // UTC
  timezoneName: "",
  timezoneDescr: "",

  keywords: "", // keywords from narrative, shared across multiple tabs
  showStats: false,
  isBase: true,
  analyzeSearch: {},
  user: {},

  //visualize
  visualizeDrawerOpen: false,
  visualizeDrawerText: "",
  visualizeGraphName: "",
  visualizeConcepts: null,
  visualizeNarratives: [],
  visualizeEntityTypes: [],
  visualizeActiveVizID: "",
  visualizeDrawerMode: "",

  // explanation drawer
  explanationDrawerOpen: false,
  explanation: {},

  projectSettings: null,
  cohortNames: [],

  // harm drawer
  harmDrawerOpen: false,
  harmProfiles: [DEFAULT_PROFILE],
  harmProfile: DEFAULT_PROFILE,
  harmProfileEdit: {},

  // harm classifier drawer
  harmClassifierOpen: false,
  harmClassifiers: [DEFAULT_CLASSIFIER],
  harmClassifier: DEFAULT_CLASSIFIER,
  harmClassifierEdit: {},

  // user groups
  userGroupDrawerOpen: false,
  userGroups: [
    // { name: "test users", id: 0, date_created: "2022-06-14T20:00:00", userIds: [], platform: null }
  ],
  userGroupEdit: {},

  // snackbar message
  snackbarMessage: null,

  languages: [],

  unsupportedFeatures: {},
};

export const ACTIONS = {
  LOGOUT: "LOGOUT",
  SET_USER: "SET_USER",
  SET_TIMEZONE: "SET_TIMEZONE",
  SET_IS_BASE: "SET_IS_BASE",
  SET_SHOW_STATS: "SET_SHOW_STATS",
  SET_ANALYZE_SEARCH: "SET_ANALYZE_SEARCH",
  SET_PROJECTS: "SET_PROJECTS",
  SET_UNSUPPORTED_FEATURE: "SET_UNSUPPORTED_FEATURE",
  SELECT_PROJECT: "SELECT_PROJECT",
  SET_PROJECT_DYNAMIC_FILTERS: "SET_PROJECT_DYNAMIC_FILTERS",
  SET_NARRATIVES: "SET_NARRATIVES",
  SET_SIDEBAR_TAB: "SET_SIDEBAR_TAB",
  SELECT_NARRATIVE: "SELECT_NARRATIVE",
  SET_SPIKE_DATE: "SET_SPIKE_DATE",
  ADD_NARRATIVE: "ADD_NARRATIVE",
  UPDATE_NARRATIVE: "UPDATE_NARRATIVE",
  REMOVE_NARRATIVE: "REMOVE_NARRATIVE",
  SET_DATES: "SET_DATES",
  SET_NARRATIVE_CLUSTERS: "SET_NARRATIVE_CLUSTERS",
  SET_PLATFORM: "SET_PLATFORM",
  SET_PLATFORMS: "SET_PLATFORMS",
  CLOSE_VISUALIZE_DRAWER: "CLOSE_VISUALIZE_DRAWER",
  OPEN_VISUALIZE_DRAWER: "OPEN_VISUALIZE_DRAWER",
  EDIT_VISUALIZATION: "EDIT_VISUALIZATION",
  SET_EXPLANATION: "SET_EXPLANATION",
  SET_ANALIZE_SORT: "SET_ANALIZE_SORT",
  SET_SUMMARY_LOADING: "SET_SUMMARY_LOADING",
  SET_HARM_DRAWER_OPEN: "SET_HARM_DRAWER_OPEN",
  SET_HARM_PROFILE: "SET_HARM_PROFILE",
  ADD_HARM_PROFILE: "ADD_HARM_PROFILE",
  SET_HARM_PROFILES: "SET_HARM_PROFILES",
  REMOVE_HARM_PROFILE: "REMOVE_HARM_PROFILE",
  EDIT_HARM_PROFILE: "EDIT_HARM_PROFILE",
  UPDATE_HARM_PROFILE: "UPDATE_HARM_PROFILE",

  SET_HARM_CLASSIFIER_OPEN: "SET_HARM_CLASSIFIER_OPEN",

  SET_HARM_CLASSIFIER: "SET_HARM_CLASSIFIER",

  ADD_HARM_CLASSIFIER: "ADD_HARM_CLASSIFIER",

  SET_HARM_CLASSIFIERS: "SET_HARM_CLASSIFIERS",

  REMOVE_HARM_CLASSIFIER: "REMOVE_HARM_CLASSIFIER",

  EDIT_HARM_CLASSIFIER: "EDIT_HARM_CLASSIFIER",

  UPDATE_HARM_CLASSIFIER: "UPDATE_HARM_CLASSIFIER",

  SET_USER_GROUPS_DRAWER_OPEN: "SET_USER_GROUPS_DRAWER_OPEN",
  SET_USER_GROUPS: "SET_USER_GROUPS",
  ADD_USER_GROUP: "ADD_USER_GROUP",
  REMOVE_USER_GROUP: "REMOVE_USER_GROUP",
  EDIT_USER_GROUP: "EDIT_USER_GROUP",
  UPDATE_USER_GROUP: "UPDATE_USER_GROUP",

  SHOW_MESSAGE: "SHOW_MESSAGE",
  SET_COHORT_NAMES: "SET_COHORT_NAMES",
  SET_LANGUAGES: "SET_LANGUAGES",
  SET_PROJECT_DOCUMENT_COUNT: "SET_PROJECT_DOCUMENT_COUNT",
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  let projectSettings;

  switch (type) {
    case ACTIONS.LOGOUT:
      return initialState;

    case ACTIONS.SET_PLATFORM:
      // If there is a project then set the project settings and cohorts.
      // Otherwise, leave it the same.
      projectSettings = getProjectSettings(state.project, payload);

      return {
        ...state,
        platform: payload,
        projectSettings,
      };

    case ACTIONS.SET_PLATFORMS:
      return {
        ...state,
        platforms: payload,
      };

    case ACTIONS.SET_PROJECT_DYNAMIC_FILTERS: {
      const new_dynamic_filters = payload.map((df) => ({
        field:
          df.field && df.field.constellation_field
            ? df.field.constellation_field
            : "custom_filter" + df.id,
        elasticSearchField: df.field
          ? df.field.elastic_search_field
          : undefined,
        title: df.display_name,
        isParent: df.field ? df.field.is_parent : false,
        type: df.filter_type,
        boolType: df.bool_type,
        showDocumentCount: df.show_document_count,
        excludedValues: df.excluded_values ? df.excluded_values.split(",") : [],
        isTunable: df.field ? df.field.is_tunable : false,
        isFormatted: df.is_formatted,
        maxOptions: df.max_filter_options,
        isDefault: df.custom_options && df.custom_options.length ? true : false,
        options:
          df.custom_options && df.custom_options.length
            ? df.custom_options.map((o) => ({
                ...o,
                elasticSearchField: o.field.elastic_search_field,
                field: o.field.constellation_field,
              }))
            : undefined,
      }));
      return {
        ...state,
        dynamic_filters: new_dynamic_filters,
      };
    }
    case ACTIONS.SET_EXPLANATION: {
      return {
        ...state,
        ...payload,
      };
    }
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: payload || {},
      };
    case ACTIONS.SET_PROJECT_DOCUMENT_COUNT:
      return {
        ...state,
        projectDocumentCount: payload,
      };

    case ACTIONS.SET_TIMEZONE:
      return {
        ...state,
        timezoneOffset: payload.Offset || 0,
        timezoneName: payload.Name || "",
        timezoneDescr: payload.Description || "",
      };
    case ACTIONS.SET_SMALL_SCREEN:
      return {
        ...state,
        isSmallScreen: payload,
      };

    case ACTIONS.SET_IS_BASE:
      return {
        ...state,
        isBase: payload,
      };
    case ACTIONS.SET_NARRATIVE_CLUSTERS:
      return {
        ...state,
        narrativeClusters: payload,
      };
    case ACTIONS.SET_SHOW_STATS:
      return {
        ...state,
        showStats: payload,
      };

    case ACTIONS.SET_ANALIZE_SORT:
      return {
        ...state,
        analyzeSortField: payload.analyzeSortField,
        analyzeSortDir: payload.analyzeSortDir,
      };

    case ACTIONS.SET_ANALYZE_SEARCH:
      return {
        ...state,
        analyzeSearch: payload,
      };

    case ACTIONS.SET_PROJECTS:
      return {
        ...state,
        projects: payload,
      };

    case ACTIONS.SET_UNSUPPORTED_FEATURE:
      return {
        ...state,
        unsupportedFeatures: {
          ...state.unsupportedFeatures,
          [payload.featureName]: !payload.supported,
        },
      };

    case ACTIONS.SELECT_PROJECT:
      // If there is a platform then set the project settings and cohorts.
      // Otherwise, leave it the same.
      projectSettings = getProjectSettings(payload?.project, state.platform);

      return {
        ...state,
        project: payload.project,
        projectPostCount:
          payload.project && payload.project.post_count
            ? parseInt(payload.project.post_count)
            : undefined,
        narratives: payload.narratives,
        narrative: {},
        loadingNarratives: payload.loadingNarratives,
        spike_date: payload.spike_date,
        harmClassifier: payload.classifierProfile
          ? payload.classifierProfile
          : DEFAULT_CLASSIFIER,
        projectSettings,
      };

    case ACTIONS.SET_NARRATIVES: {
      const { narratives, narrativeIndex } = payload;
      const _narrative = narratives.length
        ? narratives[narrativeIndex]
        : DEFAULT_NARRATIVE;

      if (!_narrative.analyze_filters) {
        _narrative.analyze_filters = DEFAULT_NARRATIVE.analyze_filters;
      }

      return {
        ...state,
        narratives,
        narrative: _narrative,
        start_date: narratives.length
          ? narratives[narrativeIndex].start_date
          : DEFAULT_START_DATE,
        end_date: narratives.length
          ? narratives[narrativeIndex].end_date
          : DEFAULT_END_DATE,
        loadingNarratives: false,
      };
    }
    case ACTIONS.SET_SPIKE_DATE: {
      if (!payload) {
        return {
          ...state,
          spike_date: null,
        };
      }
      const date = payload.split("T")[0];
      return {
        ...state,
        spike_date: payload,
        start_date: date + "T00:00:00.000Z",
        end_date: date + "T23:59:59.000Z",
      };
    }

    case ACTIONS.SELECT_NARRATIVE: {
      const narrativeData = getNarrativeData(state.narratives, payload);

      return {
        ...state,
        ...narrativeData,
      };
    }

    case ACTIONS.ADD_NARRATIVE: {
      const narrative = { ...payload };
      const filters = getFilterObjFromPostFilters(
        narrative.post_filters || [],
        state.cohortNames,
        state.dynamic_filters
      );

      narrative.filters = filters.serialized;
      narrative.analyze_filters = filters;

      const newNarratives = [...state.narratives];
      newNarratives.push(narrative);

      return {
        ...state,
        narratives: newNarratives,
        narrative,
        start_date: narrative.start_date,
        end_date: narrative.end_date,
      };
    }

    case ACTIONS.UPDATE_NARRATIVE: {
      const { narrative: newNarrative, index } = payload;
      const _filters = getFilterObjFromPostFilters(
        newNarrative.post_filters || [],
        state.cohortNames,
        state.dynamic_filters
      );
      newNarrative.filters = _filters.serialized;

      const updatedNarratives = [...state.narratives];
      updatedNarratives[index] = newNarrative;

      return {
        ...state,
        narratives: updatedNarratives,
        narrative: newNarrative,
        start_date: newNarrative.start_date,
        end_date: newNarrative.end_date,
      };
    }

    case ACTIONS.REMOVE_NARRATIVE: {
      const narrativesAfterRemove = [...state.narratives];
      narrativesAfterRemove.splice(payload, 1);

      return {
        ...state,
        narratives: narrativesAfterRemove,
      };
    }

    case ACTIONS.SET_DATES:
      return {
        ...state,
        start_date: payload.start,
        end_date: payload.end,
      };

    case ACTIONS.CLOSE_VISUALIZE_DRAWER:
      return {
        ...state,
        visualizeDrawerOpen: payload.visualizeDrawerOpen,
        visualizeGraphName: "",
        visualizeConcepts: null,
        visualizeDrawerMode: "",
        visualizeEntityTypes: [],
        visualizeNarratives: [],
        visualizeActiveVizID: "",
      };

    case ACTIONS.OPEN_VISUALIZE_DRAWER:
      return {
        ...state,
        visualizeDrawerOpen: payload.visualizeDrawerOpen,
        visualizeDrawerText: payload.visualizeDrawerText,
        visualizeDrawerMode: payload.visualizeDrawerMode,
        visualizeGraphName: "",
        visualizeConcepts: null,
        visualizeEntityTypes: [],
        visualizeNarratives: [],
        visualizeActiveVizID: "",
      };

    case ACTIONS.EDIT_VISUALIZATION:
      return {
        ...state,
        visualizeDrawerOpen: true,
        visualizeDrawerMode: payload.visualizeDrawerMode,
        visualizeDrawerText: payload.visualizeDrawerText,
        visualizeGraphName: payload.visualizeGraphName,
        visualizeConcepts: payload.visualizeConcepts,
        visualizeEntityTypes: payload.visualizeEntityTypes,
        visualizeNarratives: payload.visualizeNarratives,
        visualizeActiveVizID: payload.visualizeActiveVizID,
      };

    // HARM PROFILE

    case ACTIONS.SET_HARM_DRAWER_OPEN:
      return {
        ...state,
        harmDrawerOpen: payload,
      };

    case ACTIONS.SET_HARM_PROFILE:
      return {
        ...state,
        harmProfile: payload,
      };

    case ACTIONS.SET_HARM_PROFILES:
      return {
        ...state,
        harmProfiles: payload,
      };

    case ACTIONS.ADD_HARM_PROFILE: {
      const profiles = [...state.harmProfiles];
      profiles.push(payload);
      return {
        ...state,
        harmProfiles: profiles,
        harmProfileEdit: {},
      };
    }
    case ACTIONS.REMOVE_HARM_PROFILE: {
      const profiles = [...state.harmProfiles];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles.splice(index, 1);
      return {
        ...state,
        harmProfiles: profiles,
        harmProfile: profiles[0],
      };
    }

    case ACTIONS.UPDATE_HARM_PROFILE: {
      const profiles = [...state.harmProfiles];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles[index] = payload;
      return {
        ...state,
        harmProfiles: profiles,
        harmProfileEdit: {},
      };
    }

    case ACTIONS.EDIT_HARM_PROFILE:
      return {
        ...state,
        harmProfileEdit: payload,
        harmDrawerOpen: true,
      };

    // HARM CLASSIFIER

    case ACTIONS.SET_HARM_CLASSIFIER_OPEN:
      return {
        ...state,
        harmClassifierOpen: payload,
      };

    case ACTIONS.SET_HARM_CLASSIFIER:
      return {
        ...state,
        harmClassifier: payload,
      };

    case ACTIONS.SET_HARM_CLASSIFIERS:
      return {
        ...state,
        harmClassifiers: payload,
      };

    case ACTIONS.ADD_HARM_CLASSIFIER: {
      const profiles = [...state.harmClassifiers];
      profiles.push(payload);

      return {
        ...state,
        harmClassifiers: profiles,
        harmClassifierEdit: {},
      };
    }
    case ACTIONS.REMOVE_HARM_CLASSIFIER: {
      const profiles = [...state.harmClassifiers];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles.splice(index, 1);
      return {
        ...state,
        harmClassifiers: profiles,
        harmClassifier: profiles[0],
      };
    }

    case ACTIONS.UPDATE_HARM_CLASSIFIER: {
      const profiles = [...state.harmClassifiers];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles[index] = payload;
      return {
        ...state,
        harmClassifiers: profiles,
        harmClassifierEdit: {},
      };
    }

    case ACTIONS.EDIT_HARM_CLASSIFIER:
      return {
        ...state,
        harmClassifierEdit: payload,
        harmClassifierOpen: true,
      };

    // USER GROUPS

    case ACTIONS.SET_USER_GROUPS_DRAWER_OPEN:
      return {
        ...state,
        userGroupDrawerOpen: payload,
      };

    case ACTIONS.SET_USER_GROUPS:
      return {
        ...state,
        userGroups: payload,
      };

    case ACTIONS.ADD_USER_GROUP: {
      const groups = [...state.userGroups];
      groups.push(payload);

      return {
        ...state,
        userGroups: groups,
        userGroupEdit: {},
      };
    }

    case ACTIONS.REMOVE_USER_GROUP: {
      const groups = [...state.userGroups];
      const index = groups.findIndex((d) => d.id === payload.id);
      groups.splice(index, 1);
      return {
        ...state,
        userGroups: groups,
      };
    }

    case ACTIONS.UPDATE_USER_GROUP: {
      const groups = [...state.userGroups];
      const index = groups.findIndex((d) => d.id === payload.id);
      groups[index] = payload;
      return {
        ...state,
        userGroups: groups,
        userGroupEdit: {},
      };
    }

    case ACTIONS.EDIT_USER_GROUP:
      return {
        ...state,
        userGroupEdit: payload,
        userGroupDrawerOpen: true,
      };

    case ACTIONS.SHOW_MESSAGE:
      return {
        ...state,
        snackbarMessage: payload,
      };

    case ACTIONS.SET_COHORT_NAMES:
      return {
        ...state,
        cohortNames: payload.cohortNames,
      };

    case ACTIONS.SET_LANGUAGES:
      return {
        ...state,
        languages: payload,
      };

    case ACTIONS.SET_SUMMARY_LOADING:
      return {
        ...state,
        summaryLoading: payload,
      };

    default:
      return state;
  }
};

export default reducer;
