import DescriptionIcon from "@mui/icons-material/Description";
import UndoIcon from "@mui/icons-material/Undo";
import { Button, Tooltip } from "@mui/material";
import ProceedUploadList from "./ProceedUploadList";
import { makeStyles } from "@mui/styles";

const useUploadStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  filename: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
  },
  filesize: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "35px",
    color: "#77758E",
  },
  buttons: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  button: {
    color: "#77758E",
    fontWeight: 600,
    fontSize: "13px",
    "&:first-child": {
      marginRight: theme.spacing(2),
    },
  },
  uploadBtn: {
    background: "linear-gradient(103.85deg, #40DDFF 40.11%, #40AFFF 92.1%)",
    boxShadow: "0px 4px 34px #3F307B",
    borderRadius: "6px",
    width: "100%",
  },
}));

export default function ProceedUpload({
  files,
  setStagedDocuments,
  onAddMore,
  resetImport,
  onUpload,
  uploadBtnDisabled,
}) {
  const classes = useUploadStyles();

  const uploadBtn = (
    <div>
      <Button
        variant="contained"
        className={classes.uploadBtn}
        onClick={onUpload}
        disabled={uploadBtnDisabled}
      >
        Upload
      </Button>
    </div>
  );

  return (
    <div className={classes.root}>
      <ProceedUploadList documents={files} setStagedDocuments={setStagedDocuments} />

      <div className={classes.buttons}>
        <div>
          <Button
            size="small"
            className={classes.button}
            startIcon={<DescriptionIcon />}
            onClick={onAddMore}
          >
            Add more files
          </Button>
          <Button
            size="small"
            className={classes.button}
            startIcon={<UndoIcon />}
            onClick={resetImport}
          >
            Reset import
          </Button>
        </div>

        {uploadBtnDisabled ? (
          <Tooltip title="Please accept the terms of service">
            {uploadBtn}
          </Tooltip>
        ) : (
          uploadBtn
        )}
      </div>
    </div>
  );
}
