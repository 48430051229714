import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as CloseIcon } from "icons/Close.svg";

const useStyles = makeStyles(() => {
  return {
    paper: {
      background: "#272438",
      position: "relative",
      overflow: "visible"
    },
    dialogTitle: {
      color: "#fff",
      fontSize: "13px",
      textTransform: "uppercase",
      padding: "23px 33px",
      borderBottom: "1px solid #302E4F",
    },
    closeBtn: {
      padding: 0,
      position: "absolute",
      zIndex: 9999,
      top: -16,
      right: -16
    }
  };
});

export default function SimpleDialog({ open, handleClose, title, children, noActions }) {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.paper }}
    >
      <IconButton onClick={handleClose} className={classes.closeBtn}>
        <CloseIcon/>
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {!noActions && <DialogActions>
        <Button
          onClick={handleClose}
          autoFocus
          variant="text"
          color="secondary"
        >
          Close
        </Button>
      </DialogActions>}
    </Dialog>
  );
}
