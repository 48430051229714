import { Box } from "@mui/system";
import makeStyles from "@mui/styles/makeStyles";
import { Button, TextField, Typography } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import { useCallback, useEffect, useState } from "react";
import {
  addDataConnectorJob,
  getOrgUsers,
  getProjectFolders,
} from "api/UploadApi";
import { ascending } from "d3-array";
import { Redirect, useParams } from "react-router-dom";
import { useUploaderContext } from "../context/Context";
import { getProjects } from "api/AnalyticsApi";
import { setNewDocuments, stageDocuments } from "../context/action-creators";
import useApi from "api/hooks/useApi";
import { QUERY_KEYS } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    margin: "0 auto",
    padding: "60px 0px",
  },
  label: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "15px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  input: {
    borderRadius: 2,
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.background.$2,
      boxShadow: "0px 0px 1px #131121",
      borderRadius: 2,
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "15px",
    },
    "& fieldset": {
      borderColor: "#1C192B",
    },
  },
  formRow: {
    padding: "15px 0px",
    "& .MuiSelect-select": {
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      paddingRight: "12px !important",
      paddingLeft: "10px !important",
    },
    "& .dropdown_icon": {
      right: "10px !important",
    },
  },
}));

const requiredText = "This field is required";

const ConfigureProject = ({ onCreate }) => {
  const {
    state: { talkwalkerObject, dataConnectors, selectedFilesForJob },
    dispatch,
  } = useUploaderContext();

  const dataConnectorsList = dataConnectors.map((d) => {
    return {
      value: d.id,
      label: d.name || d.id,
      disabled: !d.statusToggle,
    };
  });

  const { data: projects } = useApi({
    apiKey: QUERY_KEYS.projects,
    apiFn: getProjects,
    payload: { db: "test" },
  });

  const { selectedFlow } = useParams();
  const classes = useStyles();

  const [teamMembers, setTeamMembers] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectFolderId, setProjectFolderId] = useState("");

  const [errorObject, setErrorObject] = useState({});
  const [projectFolders, setProjectFolders] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);

  const [foldersLoading, setFoldersLoading] = useState(false);
  const [orgUsersLoading, setOrgUsersLoading] = useState(false);

  const [generalError, setGeneralError] = useState(null);

  useEffect(() => {
    const loadProjectFolders = async () => {
      setFoldersLoading(true);
      const res = await getProjectFolders();
      setFoldersLoading(false);
      if (res && res.data) {
        setProjectFolders(
          res.data.map((d) => ({ value: d.id, label: d.attributes.name }))
        );
      }
    };
    loadProjectFolders();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const loadOrgUsers = async () => {
      setOrgUsersLoading(true);
      const res = await getOrgUsers(user.orgId);
      setOrgUsersLoading(false);
      setOrgUsers(
        res
          .map((d) => ({ value: d.id, label: d.username }))
          .sort((a, b) => ascending(a.label, b.label))
      );
    };
    loadOrgUsers();
  }, []);

  const setStagedDocuments = (docs) => {
    dispatch(stageDocuments(docs));
  };

  const setDocuments = useCallback(
    (docs) => {
      dispatch(setNewDocuments(docs));
    },
    [dispatch]
  );

  const handleCreate = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    let dataProvider = "";
    let dataConnector = " ";
    let dataProviderProjectId = " ";
    let dataProviderTopicId = " ";
    let selectedPlatform = " ";
    let startDateTime = " ";
    let endDateTime = " ";
    let fileNames = [null];
    let apiKey = "";

    if (selectedFlow === "talkwalker") {
      dataProvider = "Talkwalker";

      if (talkwalkerObject) {
        dataConnector = talkwalkerObject.dataConnector;
        dataProviderProjectId = talkwalkerObject.dataProviderProjectId;
        dataProviderTopicId = talkwalkerObject.dataProviderTopicId;
        selectedPlatform = talkwalkerObject.selectedPlatform;
        startDateTime = talkwalkerObject.startDateTime;
        endDateTime = talkwalkerObject.endDateTime;
        apiKey = talkwalkerObject.apiKey;
      }
    } else if (selectedFlow === "fileupload") {
      dataProvider = "Fileupload";
      fileNames = selectedFilesForJob.map((d) => d.filename);
      selectedPlatform = ["twitter", "reddit"];
      setStagedDocuments([]);
      setDocuments([]);
    }

    const payload = {
      dataProviderName: selectedFlow,
      dataConnector,
      dataProvider,
      dataProviderProjectId,
      dataProviderTopicId,
      selectedPlatform,
      constellationProjectName: projectName,
      constellationTeamMembersWithAccess: teamMembers,
      owner: user.id,
      startDateTime,
      endDateTime,
      topic: projectFolderId,
      organization: { id: user.orgId },
      projectFiles: fileNames,
      apiKey,
    };

    const errorObj = {};

    [
      "constellationProjectName",
      "topic",
      "constellationTeamMembersWithAccess",
    ].forEach((key) => {
      if (
        payload[key] === "" ||
        payload[key] === null ||
        payload[key]?.length === 0
      ) {
        errorObj[key] = true;
      }
    });

    if (selectedFlow === "talkwalker") {
      // make sure data source is not disabled
      const _dataConnector = dataConnectorsList.find(
        (d) => d.value === talkwalkerObject?.dataConnector
      );

      if (_dataConnector && _dataConnector.disabled) {
        errorObj._dataConnector = true;
      }
    }

    if (Object.keys(errorObj).length) {
      return setErrorObject(errorObj);
    }

    // check if project already exists
    if (projects && projects.some((d) => d.displayName === projectName)) {
      return setGeneralError("Project with that name already exists");
    }
      
    setGeneralError(null);
    setErrorObject({});
    addDataConnectorJob(payload);
    onCreate();
  };

  // redirect to proper pages when directly accessing to the page

  const rootPath = "/manage-data/projects/create";

  if (selectedFlow === "talkwalker" && !talkwalkerObject) {
    return <Redirect to={`${rootPath}/talkwalker-connect`}></Redirect>;
  }

  if (selectedFlow === "fileupload" && !selectedFilesForJob?.length) {
    return <Redirect to={`${rootPath}/fileupload`}></Redirect>;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.formRow}>
        <Typography className={classes.label}>Name your project</Typography>
        <TextField
          required={true}
          variant="outlined"
          fullWidth
          size="small"
          className={classes.input}
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          error={errorObject.constellationProjectName && !projectName}
          helperText={
            errorObject.constellationProjectName && !projectName
              ? requiredText
              : ""
          }
        />
      </Box>

      <Box className={classes.formRow}>
        <Typography className={classes.label}>
          Choose a project folder
        </Typography>
        <CustomDropdown
          loading={foldersLoading}
          placeholder={"- Choose project folder -"}
          value={projectFolderId}
          onChange={(e) => setProjectFolderId(e.target.value)}
          options={projectFolders}
          error={errorObject.topic}
          helperText={requiredText}
        />
      </Box>

      <Box className={classes.formRow}>
        <Typography className={classes.label}>Add team members</Typography>
        <CustomDropdown
          loading={orgUsersLoading}
          placeholder={"- Choose team members -"}
          searchEnabled={true}
          multiple
          value={teamMembers}
          onChange={(e) => setTeamMembers(e.target.value)}
          options={orgUsers}
          error={errorObject.constellationTeamMembersWithAccess}
          helperText={requiredText}
        />
      </Box>

      {generalError && (
        <Typography color="error" sx={{ mt: 2, mb: 2 }}>
          {generalError}
        </Typography>
      )}

      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Button
          variant="contained"
          sx={{ minWidth: 180 }}
          onClick={handleCreate}
        >
          Create project
        </Button>
      </Box>
    </Box>
  );
};

export default ConfigureProject;
