import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ACCEPTED_FILES, STAGED } from "../utils/constants";
import { replaceSpecialChars } from "../utils/utils";
import { Box } from "@mui/system";
import UploadProgress from "../Files/UploadProgress";

const useStyles = makeStyles(() => ({
  root: {},
  dropBox: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: (props) => (props.boxActive ? "#25ACF7" : "#6F7494"),
    borderSpacing: "2px 8px",
    borderRadius: 4,
    width: "100%",
    height: "100px",
  },
  dragInactiveParent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dropHere: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#AFAFAF",
  },
}));

const Uploader = ({ onFilesAccepted, onFilesRejected, uploading, processing, uploadProgress }) => {
  const classes = useStyles();

  const onDropAccepted = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const newDocuments = acceptedFiles.map((file) => ({
        file,
        filename: replaceSpecialChars(file.name),
        size: file.size,
        description: "",
        exploratory: false,
        status: STAGED,
        id: replaceSpecialChars(file.name),
      }));
      onFilesAccepted(newDocuments);
    }
  };

  const onDropRejected = (rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      const names = rejectedFiles.map(({ file }) => file.path).join(", ");
      onFilesRejected(names, rejectedFiles);
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: ACCEPTED_FILES,
    onDropAccepted,
    onDropRejected,
    noClick: () => {},
  });

  return (
    <div className={classes.root}>
      <div {...getRootProps()} className={classes.dropBox}>
        <input {...getInputProps()} />

        {uploading || processing ? (
          <UploadProgress uploadProgress={uploadProgress} processing={processing} />
        ) : isDragActive ? (
          <div className={classes.dropHere}>
            <Typography variant="body1" className={classes.uploadText}>
              Drop here
            </Typography>
          </div>
        ) : (
          <div className={classes.dragInactiveParent}>
            <Typography variant="subtitle2" className={classes.uploadText}>
              Drag files here or select file(s) to upload, or
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button size="small" variant="outlined" onClick={open}>
                Browse Files
              </Button>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default Uploader;
