import CountUp from "react-countup";
import { ReactComponent as PostsIcon } from "icons/total-posts.svg";
import { ReactComponent as EngagementsIcon } from "icons/Engagements.svg";
import { ReactComponent as UsersIcon } from "icons/total-users.svg";
import { ReactComponent as CohortsIcon } from "icons/UserGroup.svg";
import { ReactComponent as UpDownIcon } from "icons/ThumbsUpDown.svg";
import { ReactComponent as EmotionsIcon } from "icons/Emotions.svg";
import { getPercent, roundToOne } from "utils/format";
import { descSort } from "utils/sort";
import {
	totalPosts,
	totalEngagements,
	totalUsers,
	emotions,
	sentiments,
} from "api/summaryReturnFields";
import { emotionSumMappings } from "../Insights";
import { isNeutralEmotion } from "utils/emotions";
import { COHORT_TOOLTIPS } from "utils/constants";
import { resolveCohortDisplayName } from "utils/cohorts";

const getStart = (value) => {
	return Math.floor(value / 2);
};

const countupSpeed = 1.1;

const sortedDetails = (
	details,
	fieldsToSort,
	post_ct,
	field = "field_name"
) => {
	return fieldsToSort
		.filter((d) => details[d[field]])
		.map((d) => {
			const v = details[d[field]];
			return {
				percent: post_ct && v ? roundToOne((v / post_ct) * 100) : null,
				value: v,
				label: d.label,
				tooltipText: d.tooltipText,
				sort_bot: isNeutralEmotion(d[field]),
			};
		})
		.sort(descSort);
};

const topNonNeutralSentiment = (summaryData) => {
	if (
		!summaryData.post_ct ||
    summaryData.positive_ct === undefined ||
    summaryData.negative_ct === undefined
	) {
		return "-";
	}
	const { post_ct, positive_ct, negative_ct } = summaryData;
	const maxSentiment = Math.max(positive_ct, negative_ct);
	const maxSentimentLabel =
    maxSentiment === positive_ct ? "Positive" : "Negative";
	const maxPercentage = roundToOne((maxSentiment / post_ct) * 100);
	if (maxPercentage === 0) return "-";
	return `${maxPercentage}% ${maxSentimentLabel}`;
};

const emotionSumFields = Object.keys(emotionSumMappings);

const topNonNeutralEmotion = (summaryData) => {
	if (!summaryData.post_ct || summaryData.hapiness_ct === undefined) {
		return "-";
	}
	const {
		post_ct,
		hapiness_ct,
		anger_ct,
		disgust_ct,
		fear_ct,
		sadness_ct,
		surprise_ct,
	} = summaryData;
	const emotionSummary = {
		post_ct,
		hapiness_ct,
		anger_ct,
		disgust_ct,
		fear_ct,
		sadness_ct,
		surprise_ct,
	};
	const maxEmotion = Math.max(
		hapiness_ct,
		anger_ct,
		disgust_ct,
		fear_ct,
		sadness_ct,
		surprise_ct
	);
	const maxEmotionField = Object.keys(emotionSummary).find(
		(key) =>
			emotionSummary[key] === maxEmotion && emotionSumFields.includes(key)
	);
	const maxEmotionLabel = emotionSumMappings[maxEmotionField];
	const maxPercentage = roundToOne((maxEmotion / post_ct) * 100);
	if (maxPercentage === 0) return "-";
	return `${maxPercentage}% ${
		maxEmotionLabel.charAt(0).toUpperCase() + maxEmotionLabel.slice(1)
	}`;
};

const sortedSentiments = (details, fieldsToSort, post_ct) => {
	const pos_neg = fieldsToSort.slice(0, 2).sort((a, b) => {
		const a_v = details[a.field_name] ?? 0;
		const b_v = details[b.field_name] ?? 0;
		return b_v - a_v;
	});
	return [...pos_neg, ...fieldsToSort.slice(2)].map((d) => {
		const v = details[d.field_name] ?? null;
		return {
			percent: post_ct && v ? roundToOne((v / post_ct) * 100) : null,
			value: v,
			label: d.label,
			tooltipText: d.tooltipText,
		};
	});
};

export const getSummaries = (
	summaryData,
	details,
	loading = false,
	platform,
	userGroups
) => {
	const {
		post_ct: posts,
		user_ct: users,
		engagement: total_engagement,
	} = summaryData;

	const post_ct = posts || 0;
	const user_ct = users || 0;

	const cohortFieldNames = Object.keys(details).filter((key) =>
		key.startsWith("cohort_post_count.")
	);
	const cohortsAreLoaded = cohortFieldNames.length > 0; // TODO not sure why this is necessary
	const cohortInfo = cohortFieldNames.map((cohortFieldName) => {
		const standardCohortName = cohortFieldName.replace(
			"cohort_post_count.",
			""
		);

		return {
			return_name: cohortFieldName,
			label: resolveCohortDisplayName(standardCohortName),
			tooltipText: COHORT_TOOLTIPS[standardCohortName],
		};
	});

	return [
		{
			name: "Total Posts",
			value: post_ct ? (
				<CountUp
					duration={countupSpeed}
					start={getStart(post_ct)}
					end={post_ct}
					separator={","}
				/>
			) : (
				"-"
			),
			tooltipText: null,
			icon: PostsIcon,
			children: totalPosts(platform).map((d) => {
				const v = details[d.field_name] ?? null;
				return {
					percent: post_ct && v ? getPercent(v, post_ct, 1) : null,
					value: v,
					label: d.label,
					tooltipText: d.tooltipText,
				};
			}),
		},
		{
			name: "Engagements",
			value: total_engagement ? (
				<CountUp
					duration={countupSpeed}
					start={getStart(total_engagement)}
					end={total_engagement}
					separator={","}
				/>
			) : (
				"-"
			),
			tooltipText: "A cumulative measure of likes and shares on all posts.",
			icon: EngagementsIcon,
			children: totalEngagements(platform).map((d) => {
				const v = details[d.field_name] ?? null;
				return {
					percent:
            total_engagement && v ? getPercent(v, total_engagement, 1) : null,
					value: v,
					label: d.label,
					tooltipText: d.tooltipText,
				};
			}),
		},
		{
			name: "Total Users",
			value: user_ct ? (
				<CountUp
					duration={countupSpeed}
					start={getStart(user_ct)}
					end={user_ct}
					separator={","}
				/>
			) : (
				"-"
			),
			tooltipText: null,
			icon: UsersIcon,
			children: [
				...totalUsers(platform),
				...(userGroups || []).map((d) => ({
					field_name: d,
					label: d,
				})),
			].map((d) => {
				const v = details[d.field_name] ?? null;
				return {
					percent: user_ct && v ? getPercent(v, user_ct, 1) : null,
					value: v,
					label: d.label,
					tooltipText: d.tooltipText,
				};
			}),
		},
		{
			name: "COHORTS",
			value: cohortsAreLoaded
				? sortedDetails(details, cohortInfo, post_ct, "return_name")?.[0]
					?.label ?? "-"
				: "-",
			tooltipText:
        "An identification of posts as sharing a common set of values, interests, or ideology.",
			icon: CohortsIcon,
			children: loading
				? cohortInfo.slice(0, 4)
				: sortedDetails(details, cohortInfo, post_ct, "return_name"),
		},
		{
			name: "Sentiment",
			value: topNonNeutralSentiment(summaryData),
			icon: UpDownIcon,
			tooltipText: null,
			children: sortedSentiments(details, sentiments, post_ct),
		},
		{
			name: "Emotions",
			value: topNonNeutralEmotion(summaryData),
			tooltipText: null,
			icon: EmotionsIcon,
			children: loading
				? emotions.slice(0, 7)
				: sortedDetails(details, emotions, post_ct),
		},
	];
};
