import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Button, CircularProgress } from "@mui/material";
import { isUploader, login } from "api/AnalyticsApi";
import LoginBackground from "pages/Authentication/LoginBackground";
import InputValidate from "components/UI/InputValidate";
import Message from "./Message";
import { monitorIdentity } from "api/monitoring";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import toast from "react-hot-toast";
import { LOCAL_STORAGE_KEYS } from "utils/constants";
import { getUser } from "api/userApi";

const UPLOADER_URL = process.env.REACT_APP_UPLOADER_URL;

const useStyles = makeStyles((theme) => ({
	forgotPassword: {
		marginTop: "1rem",
		color: "#40DDFF",
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	header: {
		marginTop: 0,
		fontWeight: 800,
		fontSize: "20px",
		lineHeight: "28px",
	},
	submit: {
		backgroundColor: "#40DDFF",
		"&:hover": {
			backgroundColor: "#40DDFF",
		},
	},
}));

function App() {
	const classes = useStyles();
	const [identifier, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState("");
	const [loggedIn, setLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);

	const { dispatch } = useAppContext();

	const history = useHistory();

	const onSubmit = async (event) => {
		event.preventDefault();
		try {
			setLoading(true);

			// Reset error popups
			toast.dismiss();

			// Ensure context is reset
			dispatch({ type: ACTIONS.LOGOUT });

			const res = await login(identifier, password);

			const { username, id, email } = res.user;

			localStorage.setItem("jwtTokenUser", res.jwt);
			const fullUser = await getUser();
			const organization = fullUser.organization;
			const user = JSON.stringify({ username, id, email, orgId: organization.id, orgName: organization.name }, null);
			
			localStorage.setItem("user", user);

			localStorage.setItem(LOCAL_STORAGE_KEYS.PREVIEW_CLASSIFIER_PROFILE_REF, undefined);
			localStorage.setItem(LOCAL_STORAGE_KEYS.PREVIEW_HARM_PROFILE_REF, undefined);
			monitorIdentity({username, id, email, organization: organization.name});

			if (organization) {

				isUploader().then((data) => {
					if (data && data.isUploader){  
						window.location = `${UPLOADER_URL}/redirect?token=${res.jwt}&email=${res.user.email}`;
					}
				});
    
				setLoggedIn(true);
				setLoading(false);
			}
			else {
				// prevent login for users without an organization
				setLoading(false);
				setLoginError("Your user is not part of an organization at blackbird.ai");
				localStorage.clear();
			}
		} catch (error) {
			setLoading(false);
			setLoginError("Incorrect email or password");
			localStorage.clear();
		}
	};

	const forgotPassword = () => {
		history.push("/forgotpassword");
	};

	if (loggedIn) {
		return <Redirect to="/dashboard" />;
	}

	return (
		<LoginBackground>
			<h3 className={classes.header}>Login</h3>

			{loginError && <Message type="error" message={loginError} />}

			<form autoComplete="true" onSubmit={onSubmit}>
				<div className={classes.input}>
					<InputValidate
						label={"Username or E-mail"}
						value={identifier}
						onChange={(event) => setUsername(event.target.value)}
						fieldProps={{
							autoFocus: true,
							disabled: loading
						}}
					/>
				</div>

				<div className={classes.input}>
					<InputValidate
						label={"Password"}
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						fieldProps={{
							type: "password",
							disabled: loading
						}}
					/>
				</div>

				<Button
					type={"submit"}
					size={"medium"}
					variant={"contained"}
					fullWidth
					className={classes.submit}
					disabled={loading}
				>
					{loading ? (
						<CircularProgress color="secondary" size={24} thickness={3} />
					) : (
						"Submit"
					)}
				</Button>
			</form>

			<Button
				className={classes.forgotPassword}
				variant="text"
				size="medium"
				onClick={forgotPassword}
			>
        Forgot Password?
			</Button>
		</LoginBackground>
	);
}

export default App;
