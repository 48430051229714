import React from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	Paper,
	IconButton,
	Snackbar,
	Typography,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Preloader from "components/UI/Preloader";
import Tooltip from "components/UI/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import TableGridRow from "./TableGridRow";

export const StyledTableCell = withStyles((theme) => ({
	root: {
		borderBottom: "none",
		padding: 6,
	},
	head: {
		color: theme.palette.secondary.light,
		fontStyle: "normal",
		fontSize: "14px",
		fontWeight: "normal",
		lineHeight: "16px",
		textAlign: "center",
		textDecoration: "underline",
		textDecorationStyle: "dotted",
		padding: "10px",
		"&.sample_post_col": {
			width: "35%",
			minWidth: 400
		}
	},
	body: {
		fontFamily: "Mulish",
		padding: "6px 10px",
		textAlign: "center",
		color: "#FFFFFF",
		fontSize: "13px",
		fontWeight: "normal",
		lineHeight: "16px",
		"&.sample_post_col": {
			width: "35%",
			minWidth: 400
		}
	},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
	table: {
		maxWidth: "100%",
		minWidth: 500,
		width: "100%",
		margin: 0,
	},
	paper: {
		maxHeight: "calc(100vh - 90px)",
		overflowY: "auto",
	},
	headerRow: {},
	thContent: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	th: {
		top: "0px",
		fontSize: 12,
		color: "#AFAFAF",
		backgroundColor: theme.palette.background.$0,
		"&:hover": {
			backgroundColor: (props) => (props.clickable ? "#333155" : null),
		},
		[theme.breakpoints.down("lg")]: {
			padding: "6px 4px",
		},
	},
	active: {
		backgroundColor: (props) => (props.clickable ? "#333155 !important" : null),
	},
	harmful_engagements: {
		width: "128px",
	},
	fullWidth: {
		textAlign: "center",
		width: "100%",
		fontSize: 15,
		padding: 10,
	},
	notFound: {
		textAlign: "center",
		width: "100%",
	},
	notFoundColumn: {
		minWidth: 200,
	},
}));

export default function TableGrid({
	data,
	columns,
	loading = false,
	stickyHeader = false,
	sortField,
	sortDir,
	filterKey,
	size = "medium",
	onHeaderClick = () => {},
	onFirstColumnClick = () => {},
	onRowClick = () => {},
}) {
	const sortByIndex = columns.findIndex(
		(d) => !d.not_sortable && d.sort_field === sortField
	);

	const classes = useStyles({
		stickyHeader,
		clickable: !!onHeaderClick,
	});

	const [snackOpen, setSnackOpen] = React.useState(false);

	const handleSnackClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackOpen(false);
	};

	const NotFound = (
		<TableRow className={classes.fullWidth}>
			<StyledTableCell
				className={classes.notFoundColumn}
				colSpan={columns.length}
			>
				<Typography className={classes.notFound}>No data found</Typography>
			</StyledTableCell>
		</TableRow>
	);

	const Loading = (
		<TableRow>
			<StyledTableCell colSpan={columns.length}>
				<Preloader />
			</StyledTableCell>
		</TableRow>
	);

	return (
		<div>
			<TableContainer component={Paper} className={classes.paper}>
				<Table
					size={size}
					className={classes.table}
					stickyHeader={stickyHeader}
				>
					<TableHead>
						<TableRow className={classes.headerRow}>
							{columns.map((col, index) => {
								const active = sortByIndex === index;
								return (
									<StyledTableCell
										key={index}
										className={clsx(classes.th, {
											[classes.active]: active,
										}, col.className)}
										onClick={
											onHeaderClick ? () => onHeaderClick(col, index) : null
										}
									>
										<div
											className={clsx(classes.thContent, {
												[classes.harmful_engagements]:
                          col.sort_field === "harmful_engagement",
											})}
										>
											{col.headerComponent || (
												<>
													{col.tooltip !== undefined ? (
														<Tooltip text={col.tooltip} arrow={false}>
															<span>{col.text}</span>
														</Tooltip>
													) : (
														col.text
													)}

													{!col.not_sortable ? (
														sortDir === "ASC" && active ? (
															<ExpandLessIcon fontSize="small" />
														) : sortDir === "DESC" && active ? (
															<ExpandMoreIcon fontSize="small" />
														) : (
															<UnfoldMoreIcon fontSize="small" />
														)
													) : null}
												</>
											)}
										</div>
									</StyledTableCell>
								);
							})}
						</TableRow>
					</TableHead>

					<TableBody>
						{loading ? (
							Loading
						) : (data || []).length > 0 ? (
							<>
								{(data || []).map((d, i) => {
									return (
										<TableGridRow
											key={i}
											data={d}
											columns={columns}
											filterKey={filterKey}
											onFirstColumnClick={onFirstColumnClick}
											snackOpen={snackOpen}
											setSnackOpen={setSnackOpen}
											onViewMoreClick={onRowClick}
										/>
									);
								})}
							</>
						) : (
							NotFound
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Snackbar
				open={snackOpen}
				autoHideDuration={4000}
				onClose={handleSnackClose}
				message={"Successfully Copied to Clipboard"}
				color="primary"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				action={
					<React.Fragment>
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={handleSnackClose}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
			/>
		</div>
	);
}
