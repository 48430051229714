import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./ErrorView.css";

function ErrorView() {
	return (
		<div className="error-view">
			<Box
				sx={{
					textAlign: "center",
					p: 4,
					mt: 4,
					position: "absolute",
					width: "100%",
					top: 0,
					left: 1,
					zIndex: 1000,
				}}
			>
				<Typography variant="h4" sx={{ mb: 3, fontWeight: "normal" }}>
          Oops, something went wrong.
				</Typography>
				<Button
					onClick={() => {
						window.location.reload();
					}}
					sx={{ minWidth: 150 }}
					size="large"
					variant="contained"
				>
          Reload
				</Button>
			</Box>
			<main></main>
			<div className="grid">
				<div className="grid-inner">
					<div className="hori">
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
					</div>
					<div className="vert">
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ErrorView;
