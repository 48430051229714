import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		color: "#E8E9F3",
		fontSize: "14",
		borderWidth: 1,
		"& .MuiInputLabel-root": {
			color: "#6F749C",
			fontSize: "14px",
			lineHeight: "16px",
		},
		"& .MuiFormHelperText-root": {
			marginLeft: 0,
		},
		"& .Mui-error": {
			color: theme.palette.red.$7
		},
		"& .MuiInput-input": {
			backgroundColor: theme.palette.background.$1,
			border: (props) =>
				`1px solid ${props.hasError ? theme.palette.red.$7 : "#302E4F"}`,
			borderRadius: 4,
			padding: theme.spacing(1, 2),
			marginTop: theme.spacing(0),
		},
	},
}));

export default function InputValidate(props) {
	const {
		emptyWarning = "",
		existsWarning = "",
		placeholder = "",
		label,
		exists,
		showError, // force to show error
		disabled,
		onChange,
	} = props;

	const helperText = (showError && props.value === "") ? emptyWarning : exists ? existsWarning : null;
	const classes = useStyles({ hasError: helperText });

	return (
		<TextField
			disabled={disabled}
			value={props.value}
			error={!!helperText}
			helperText={helperText}
			classes={{ root: classes.root }}
			variant="standard"
			size="small"
			label={label}
			placeholder={placeholder}
			onChange={onChange}
			InputLabelProps={{ shrink: true }}
			InputProps={{ disableUnderline: true }}
			{...props.fieldProps}
		/>
	);
}
