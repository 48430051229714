import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import globalMuiTheme from "globalMuiTheme";
import { ContextProvider } from "./context/Context";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ApolloProvider } from "@apollo/client";
import client from "api/graphQL/client";
import { createBrowserHistory } from "history";

import { Router, matchPath } from "react-router-dom";

import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { QueryClient, QueryCache, QueryClientProvider } from "react-query";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import { hotjar } from 'react-hotjar';

import "react-dates/initialize";
import "styles/react-dates-custom.css";

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_ID, // hjid
    6 // hjsv
  );
}

if (process.env.REACT_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

const routes = [
  { path: "/dashboard/:projectName" },
  { path: "/dashboard" },
  { path: "/" },
];

const environment = process.env.REACT_APP_ENV || "development";

export const history = createBrowserHistory({basename: process.env.REACT_APP_BRANCH_PATH});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history,
        routes,
        matchPath
      ),
    }),
  ],
  beforeSend(event) {
    const logRocketSession = LogRocket.sessionURL;
    if (logRocketSession !== null) {
      event.extra["LogRocket"] = logRocketSession;
      return event;
    } else {
      return event;
    }
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => console.error(`Something went wrong: ${error.message}`),
  }),
});

asyncWithLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID,
}).then((LDProvider) => {
  ReactDOM.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={globalMuiTheme}>
          <CssBaseline />
          <ContextProvider>
            <Router basename={process.env.REACT_APP_BRANCH_PATH} history={history}>
              <ApolloProvider client={client}>
                <QueryClientProvider client={queryClient}>
                  <LDProvider>
                    <App />
                  </LDProvider>
                </QueryClientProvider>
              </ApolloProvider>
            </Router>
          </ContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
serviceWorker.unregister();
