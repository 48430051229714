import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import { ReactComponent as QuestionIcon } from "icons/Question.svg";

const useStyles = makeStyles((theme) => ({
	tooltip: {
		maxWidth: "250px !important",
		padding: "5px 10px",
		backgroundColor: theme.palette.background.$3,
		fontWeight: "normal",
		fontSize: "12px",
		lineHeight: "15px"
	},
	popper: {
		zIndex: 9999999,
	},
	icon: {
		width: props => props.width || 20,
		height: props => props.height || 20,
		marginLeft: 6,
		cursor: "pointer",
		"&:hover .question-back": {
			stroke: "#63607B",
			fill: "#63607B",
		},
		"&:hover .question-mark": {
			stroke: "#E8E9F2",
		},
		"&:hover .question-mark-dot": {
			fill: "#E8E9F2",
		},
	},
}));

export default function Hint({ tooltipText, width, height }) {
	const classes = useStyles({ width, height });
	return (
		<Tooltip
			title={tooltipText}
			placement="top"
			classes={{ tooltip: classes.tooltip, popper: classes.popper }}
		>
			<QuestionIcon className={classes.icon} />
		</Tooltip>
	);
}
