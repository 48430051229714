import { gql } from "@apollo/client";

export const PLATFORMS_QUERY = gql`
  query GetPlatforms($project: Project!, $narrative: Narrative!) {
    platforms(project: $project, narrative: $narrative) {
      platforms {
        name
        post_count
        enabled
      }
    }
  }
`;