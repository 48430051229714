import { makeStyles } from "@mui/styles";
import { getTimeZoneObject } from "utils/format";
import timezones from "utils/timezones.json";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAppContext } from "context/Context";
import { setUserSettings } from "api/userApi";
import { ACTIONS } from "context/reducer";

const useStyles = makeStyles((theme) => ({
	row: {
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
	},
	label: {
		color: "#fff",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "22px",
		transform: "translate(0, 0) scale(1)",
	},
	spaceTop: {
		marginTop: theme.spacing(1),
	},
	muiItem: {
		fontSize: 13,
		color: "#E7E7EF",
	},
}));


export default function TimeZoneTab() {
	const classes = useStyles();

	const {
		dispatch,
		state: { timezoneOffset },
	} = useAppContext();

	const handleChange = (event) => {
		let timezone = getTimeZoneObject("Offset", event.target.value);

		setUserSettings({ timezone });

		dispatch({
			type: ACTIONS.SET_TIMEZONE,
			payload: {
				Offset: event.target.value,
				Name: timezone.Name,
				Description: timezone.Description,
			},
		});
	};

	return (
		<div className={classes.row}>
			<InputLabel id="timezone-select" className={classes.label} shrink={true}>
        Set time
			</InputLabel>

			<FormControl size="small" fullWidth className={classes.spaceTop}>
				<Select
					variant="outlined"
					labelId="timezone-select"
					value={timezoneOffset}
					label="Select timezone"
					onChange={handleChange}
					displayEmpty
					notched={false}
				>
					{timezones.map((d) => (
						<MenuItem value={d.Offset} key={d.Name} className={classes.muiItem}>
							{d.Name} | {d.Description} | {d["Relative to GMT"]}{" "}
							{d.Name === "UTC" ? "(Default)" : ""}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
