import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import { makeStyles } from "@mui/styles";
import { useUploaderContext } from "../context/Context";
import {
  getDataConnectorById,
  getDataConnectorProjects,
  getDataConnectorTopics,
  getDocumentCount,
} from "api/UploadApi";
import { SET_TALKWALKER_OBJECT } from "../utils/constants";
import { offsetDate } from "utils/format";
import DateTimePicker from "components/UI/DateTimePicker";
import { useHistory, useRouteMatch } from "react-router-dom";
import { checkIfInFuture, formatNumber } from "utils/format";
import Preloader from "components/UI/Preloader";
import { useAppContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    margin: "0 auto",
    padding: "60px 0px",
    "& .MuiSelect-select": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      paddingRight: "12px !important",
      paddingLeft: "10px !important",
    },
    "& .dropdown_icon": {
      right: "10px !important",
    },
  },
  label: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "15px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  input: {
    borderRadius: 6,
    "& .MuiInputBase-input": {
      backgroundColor: "#1C192B !important",
      boxShadow: "0px 0px 1px #131121",
      borderRadius: 6,
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "15px",
    },
    "& fieldset": {
      borderColor: "#1C192B",
    },
  },
  formRow: {
    padding: "15px 0px",
  },
  datePickerRow: {
    padding: "15px 0px",

    "& .DateInput": {
      width: "calc(50% - 20px)",
      borderRadius: 6,
    },
    "& .DateRangePickerInput_arrow": {
      width: "40px",
      textAlign: "center",
    },
    "& .DateRangePicker": {
      width: "100%",
    },
    "& .DateRangePickerInput": {
      borderRadius: 6,
      width: "100%",
      background: theme.palette.background.$2,
    },
    "& .DateInput_input": {
      borderRadius: 2,
      background: theme.palette.background.$2,
    },
    "& .time_input .MuiInputBase-root": {
      borderRadius: 2,
      background: theme.palette.background.$2,
    },
  },
  continue: {
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  errorColor: {
    color: "#E7394F",
    fontWeight: "normal",
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  timeInput: {
    width: "100%",
    "& .MuiInputBase-root": {
      background: theme.palette.background.$1,
      borderRadius: "5px !important",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "18px",
      border: "nonw",
    },
    "& fieldset": {
      borderRadius: 6,
      borderColor: "transparent",
    },
    "& input::-webkit-calendar-picker-indicator": {
      background: "none",
      display: "none",
    },
  },
  checkboxLabel: {
    color: "#AFAFAF",
    fontSize: "14px",
    lineHeight: "18px",
  },
  docCountSection: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  progressBar: {
    width: "45%",
    marginTop: theme.spacing(1),
  },
  progressBarRoot: {
    backgroundColor: "#302E4F",
    height: 20,
    borderRadius: 2,
  },
  barColorPrimary: {
    background: theme.palette.indigo.$5,
  },
  legendItem: {
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: "normal",
  },
  legend: {
    paddingTop: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  docCountText: {
    color: "#fff",
    fontWeight: "normal",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
}));

const requiredText = "This field is required";

const docLimitErrorText =
  "The size for this import is beyond the allowed size for this data connector. please contact support";

const noData = [{ label: "No data", value: "no", disabled: true }];

const TalkwalkerFlow = () => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();

  const {
    state: { dataConnectors, talkwalkerConnection, talkwalkerObject },
    dispatch,
  } = useUploaderContext();

  const {
    state: { timezoneOffset },
  } = useAppContext();

  const dataConnectorsList = dataConnectors.map((d) => {
    return {
      value: d.id,
      label: d.name || d.id,
      disabled: !d.statusToggle,
    };
  });

  const [dataSource, setDataSource] = useState(
    talkwalkerConnection?.selected || talkwalkerObject?.dataConnector || ""
  ); // dataConnector
  const [projectId, setProjectId] = useState(
    talkwalkerObject?.dataProviderProjectId || ""
  ); // dataProviderProjectId
  const [platform, setPlatform] = useState(
    talkwalkerObject?.selectedPlatform || []
  ); // selectedPlatform
  const [topicId, setTopicId] = useState(
    talkwalkerObject?.dataProviderTopicId || ""
  ); // dataProviderTopicId
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    talkwalkerObject?.startDateTime || null
  );
  const [endDate, setEndDate] = useState(talkwalkerObject?.endDateTime || null);
  const [errorObject, setErrorObject] = useState({});
  const [tosChecked, setTosChecked] = useState(
    talkwalkerObject?.tosChecked || false
  );
  const [maxDocCount, setMaxDocCount] = useState(0);
  const [docCount, setDocCount] = useState(0);
  const [docCountLoading, setDocCountLoading] = useState(false);
  const [apiKey, setApiKey] = useState(talkwalkerObject?.apiKey || null);
  const [generalError, setGeneralError] = useState(null);

  useEffect(() => {
    const loadDocCount = async () => {
      setDocCount(0);
      setDocCountLoading(true);

      const res = await getDocumentCount({
        start_date: offsetDate(startDate, timezoneOffset),
        end_date: offsetDate(endDate, timezoneOffset),
        project_id: projectId,
        topic_id: topicId,
        api_key: apiKey,
        platforms: platform,
      });

      setDocCountLoading(false);

      if (res && res.total_count) {
        setDocCount(res.total_count);
      }
    };

    if (startDate && projectId && apiKey) {
      loadDocCount();
    }
  }, [startDate, endDate, platform, projectId, topicId, apiKey, timezoneOffset]);

  useEffect(() => {
    if (dataSource) {
      const loadDataConnector = async () => {
        const dataConnectorResp = await getDataConnectorById(dataSource);
        if (dataConnectorResp && dataConnectorResp.data[0]) {
          const attributes = dataConnectorResp.data[0].attributes;
          setApiKey(attributes.apiKey);
          setMaxDocCount(+attributes.maxJobExportCount);
        }
      };
      loadDataConnector();
    }
  }, [dataSource]);

  useEffect(() => {
    const loadProjects = async () => {
      setProjectsLoading(true);
      setProjects([]);
      const res = await getDataConnectorProjects({ api_key: apiKey });
      setProjectsLoading(false);
      if (res && res.result_accinfo && res.result_accinfo.projects) {
        setProjects(
          res.result_accinfo.projects.map((d) => ({
            value: d.id,
            label: d.name,
          }))
        );
      } else {
        setProjects(noData);
      }
    };
    if (apiKey) {
      loadProjects();
    }
  }, [apiKey]);

  useEffect(() => {
    const loadTopics = async () => {
      setTopics([]);
      setTopicsLoading(true);
      const res = await getDataConnectorTopics({
        api_key: apiKey,
        project_id: projectId,
      });
      setTopicsLoading(false);
      if (res && res.result_topics && res.result_topics.topic_categories) {
        setTopics(
          res.result_topics.topic_categories
            .map((d) =>
              d.query_topics.map((x, i) => {
                return {
                  value: x.id,
                  label: x.title,
                  groupName: d.title || d.id,
                  showGroup: i === 0,
                };
              })
            )
            .flat()
        );
      } else {
        setTopics(noData);
      }
    };
    if (apiKey && projectId) {
      loadTopics();
    }
  }, [projectId, apiKey]);

  const handleContinue = () => {
    setGeneralError(null);
    const payload = {
      dataConnector: dataSource, // step 1
      dataProviderProjectId: projectId, // step 1
      dataProviderTopicId: topicId, // step 1
      selectedPlatform: platform, // step 1
      startDateTime: offsetDate(startDate, timezoneOffset), // step 1
      endDateTime: offsetDate(endDate, timezoneOffset), // step 1
      tosChecked,
      apiKey,
    };

    const errorObj = {};

    Object.keys(payload).forEach((key) => {
      if (
        payload[key] === "" ||
        payload[key] === null ||
        payload[key]?.length === 0
      ) {
        errorObj[key] = true;
      }
    });

    if (Object.keys(errorObj).length) {
      return setErrorObject(errorObj);
    }

    setErrorObject({});

    if (checkIfInFuture(endDate)) {
      return setGeneralError("End date should not be in the future.");
    }

    if (docCount < maxDocCount) {
      dispatch({
        type: SET_TALKWALKER_OBJECT,
        payload: payload,
      });

      history.push(`${url}/configure`);
    } else {
      setGeneralError(docLimitErrorText);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.formRow}>
        <Typography className={classes.label}>Choose data source</Typography>
        <CustomDropdown
          placeholder={"- Select data source -"}
          helperText={requiredText}
          value={dataSource}
          onChange={(e) => setDataSource(e.target.value)}
          options={dataConnectorsList}
          error={errorObject.dataConnector}
        />
      </Box>

      <Box className={classes.formRow} sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ width: "50%", paddingRight: "20px" }}>
          <Typography className={classes.label}>Choose account</Typography>
          <CustomDropdown
            placeholder={"- Select account -"}
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
            title="PROJECT LIST"
            options={projects}
            loading={projectsLoading}
            error={errorObject.dataProviderProjectId}
            helperText={requiredText}
          />
        </Box>
        <Box sx={{ width: "50%", paddingLeft: "20px" }}>
          <Typography className={classes.label}>Platforms</Typography>
          <CustomDropdown
            placeholder={"- Select platforms -"}
            multiple
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            title="PLATFORMS"
            options={[
              {
                value: "Twitter",
                label: "Twitter",
              },
              {
                value: "Reddit",
                label: "Reddit",
              },
            ]}
            helperText={requiredText}
            error={errorObject.selectedPlatform}
          />
        </Box>
      </Box>

      <Box className={classes.formRow}>
        <Typography className={classes.label}>Choose topics</Typography>
        <CustomDropdown
          placeholder={"- Select topics -"}
          value={topicId}
          onChange={(e) => setTopicId(e.target.value)}
          options={topics}
          loading={topicsLoading}
          helperText={requiredText}
          error={errorObject.dataProviderTopicId}
        />
      </Box>

      <Box className={classes.datePickerRow}>
        <Typography className={classes.label}>Select date range</Typography>
        <DateTimePicker
          error={errorObject.startDateTime || errorObject.endDateTime}
          helperText={requiredText}
          dateFrom={startDate}
          dateTo={endDate}
          onChange={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </Box>

      <Box className={classes.docCountSection}>
        <Typography className={classes.docCountText} component="div">
          {docCountLoading ? (
            <Box component={"span"} sx={{ mr: 1 }}>
              <Preloader size={16} thickness={6} />
            </Box>
          ) : (
            <Box component={"span"} sx={{ mr: 0.5 }}>
              {formatNumber(docCount)}
            </Box>
          )}
          documents selected
        </Typography>

        {/* <Box className={classes.progressBar}>
          <LinearProgress
            classes={{
              root: classes.progressBarRoot,
              barColorPrimary: classes.barColorPrimary,
            }}
            color="primary"
            variant="determinate"
            value={(Math.min(docCount, maxDocCount) / (maxDocCount || 1)) * 100}
          />
          <Box className={classes.legend}>
            <Typography className={classes.legendItem}>
              Current quota
            </Typography>
            <Typography className={classes.legendItem}>
              {formatNumberK(docCount)} of {formatNumberK(maxDocCount)}
            </Typography>
          </Box>
        </Box> */}
      </Box>

      <Box sx={{ mt: "10px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={tosChecked}
              onChange={(e) => setTosChecked(e.target.checked)}
            />
          }
          classes={{
            label: classes.checkboxLabel,
          }}
          label="I have been granted permission to upload this dataset"
        />
      </Box>

      {generalError && (
        <Typography color="error" sx={{ mt: 2, mb: 2 }}>
          {generalError}
        </Typography>
      )}

      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Button
          variant="contained"
          sx={{ minWidth: 180 }}
          onClick={handleContinue}
          disabled={!tosChecked}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default TalkwalkerFlow;
