import TableGridRow from "./TableGridRow";
import { Button, TableRow } from "@mui/material";
import { StyledTableCell } from "./TableGrid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	viewMore: {
		padding: "0px 0px 0px 42px !important",
		backgroundColor: `${theme.palette.background.main} !important`,
	},
	viewMoreInner: {
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		backgroundColor: theme.palette.background.clusterToggler + " !important",
		borderTop: `8px solid ${theme.palette.background.main}`,
	},
}));

export default function PostsList({ sample_posts, onViewMoreClick, ...rest }) {
	const classes = useStyles();

	return (
		<>
			{sample_posts.map((d, i) => (
				<TableGridRow
					key={d.id}
					data={d}
					{...rest}
					is_child={true}
					is_last_clusters_child={i === sample_posts.length - 1}
					is_first_clusters_child={i === 0}
				/>
			))}
			<TableRow>
				<StyledTableCell
					colSpan={rest.columns.length}
					className={classes.viewMore}
				>
					<div className={classes.viewMoreInner}>
						<Button variant="text" onClick={onViewMoreClick}>
              View More
						</Button>
					</div>
				</StyledTableCell>
			</TableRow>
		</>
	);
}
