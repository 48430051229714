import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import Circle from "@mui/icons-material/FiberManualRecord";
import { pieChartColorMappings } from "pages/DashboardPage/Overview/Insights";

const useStyles = makeStyles((theme) => ({
	list: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
	},
	li: {
		display: "flex",
		flexGrow: 1,
		justifyContent: "space-between",
		maxWidth: "auto",
		[theme.breakpoints.down("md")]: {
			maxWidth: "250px"
		}
	},
	liName: {},
	liValue: {
		textAlign: "right",
		color: "#76758D",
	},
}));

export default function PropertyList({ data }) {
	const classes = useStyles();
  
	return (
		<List className={classes.list}>
			{data.map(({ name, value }) => {
				const color = pieChartColorMappings[name.toLowerCase()];
				const percent = value * 100;
				return (
					<ListItem className={classes.li} key={name}>
						<ListItemIcon>
							<Circle style={{ color }} />
						</ListItemIcon>
						<ListItemText className={classes.liName}>{name}</ListItemText>
						<ListItemText className={classes.liValue}>
							{percent.toFixed(1)}%
						</ListItemText>
					</ListItem>
				);
			})}
		</List>
	);
}
