import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { deleteDataConnectorJob, getDataConnectorJobs } from "api/UploadApi";
import DataGridTable from "../components/DataGridTable";
import SimpleDialog from "components/UI/SimpleDialog";
import { useUploaderContext } from "../context/Context";
import { useEffect, useRef, useState } from "react";
import { toggleSnack } from "../context/action-creators";
import { formatTime } from "../utils/utils";
import TableActions from "../TableActions";
import JobCreationForm from "./JobCreationForm";
import { ReactComponent as PlugIcon } from "icons/Uploader/PlugIcon.svg";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getCancelToken } from "api/AnalyticsApi";

const useStyles = makeStyles((theme) => ({
  root: {},
  section: {
    padding: theme.spacing(4, 0),
  },
  sectionTitle: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#ffffff",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const getColumns = (classes, onEdit, onDelete) => [
  {
    field: "dataConnector.data.attributes.name",
    headerName: "Data source",
    flex: 1,
    valueGetter: (params) => {
      if (params.row.dataProviderName === "fileupload") {
        return "File Upload"
      }
      return params.row.dataConnector?.data?.attributes?.name || "";
    },
    renderCell: (params) => {
      return params.row.dataConnector?.data?.attributes?.name;
    },
  },
  {
    field: "constellationProjectName",
    headerName: "Project",
    flex: 1,
  },
  {
    field: "createdBy",
    description: "Created by",
    flex: 1,
    headerName: "Created by",
  },
  {
    field: "createdAt",
    valueFormatter: formatTime,
    headerName: "Date created",
    flex: 1,
  },
  {
    field: "updatedAt",
    valueFormatter: formatTime,
    headerName: "Date updated",
    flex: 1,
  },
  {
    field: "jobStatus",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return params.row.jobStatus?.state;
    },
  },
  {
    field: "id",
    headerName: "Actions",
    sortable: false,
    filterable: false,
    hideable: false,
    renderCell: (params) => {
      return (
        <TableActions
          onView={() => onEdit(params.row)}
          onDelete={() => onDelete(params.row)}
        />
      );
    },
  },
];

export default function Jobs() {
  const classes = useStyles();

  const { dispatch } = useUploaderContext();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [data, setData] = useState([]);
  const [deletedJobs, setDeletedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobEdit, setJobEdit] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [statuses] = useState({});

  const cancelToken = useRef();

  const getJobsCancelToken = () => {
    cancelToken.current && cancelToken.current.cancel();
    cancelToken.current = getCancelToken();
    return { cancelToken: cancelToken.current.token };
  };

  const tableData = data
    .filter(d => !deletedJobs.includes(d.id))
    .map((d) => ({
      ...d,
      jobStatus: statuses[d.id] || d.jobStatus,
    }));

  useEffect(() => {
    let timeout = null;

    const loadData = async () => {
      const config = getJobsCancelToken();
      setLoading(true);
      const resp = await getDataConnectorJobs(config);
      const newData = (resp?.data || [])
        .map((d) => {
          return {
            id: d.id,
            ...d.attributes,
            jobStatus:
              d.attributes.jobStatus && d.attributes.jobStatus.state
                ? d.attributes.jobStatus.state
                : "In Queue",
            dataConnectorId:
              d.attributes.dataConnector && d.attributes.dataConnector.data
                ? d.attributes.dataConnector.data.id
                : null,
            createdBy:
              d.attributes.owner && d.attributes.owner.data
                ? d.attributes.owner.data.attributes.username
                : null,
          };
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setLoading(false);
      setData(newData);

      // sequentially update
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(loadData, 4000);
    };
    loadData();

    return () => {
      cancelToken.current && cancelToken.current.cancel();
      timeout && clearTimeout(timeout);
    };
  }, [refetch]);

  // useEffect(() => {
  // let timeout;

  // disable for predash 2600 troubleshooting
  // const loadStatuses = async () => {
  //   const ids = data.filter(d => REFRESHABLE_JOB_STATUSES.includes(d.jobStatus)).map(d => d.id);

  //   if (ids) {
  //     const resp = await getJobStatuses({ ids });
  //     const statusesArray = Array.isArray(resp) ? resp : resp?.data || null;

  //     if (statusesArray) {
  //       const newStatuses = statusesArray.reduce((prev, d) => {
  //         prev[d.dataConnectorJobId] = d.state;
  //         return prev;
  //       }, {});

  //       setStatuses(newStatuses);
  //     }
  //   }

  //   // // sequentially update
  //   if (timeout) clearTimeout(timeout);
  //   timeout = setTimeout(() => loadStatuses(), 5000);
  // };

  // loadStatuses();

  // return () => {
  //   timeout && clearTimeout(timeout);
  // };
  // }, [data]);

  const handleDialogOpen = () => {
    // setDialogOpen(true);
    history.push(`${url}/create`);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setJobEdit(null);
  };

  const handleEdit = (row) => {
    setJobEdit({
      ...row,
    });
    setDialogOpen(true);
    setRefetch(!refetch);
  };

  const handleDelete = async (row) => {
    const res = await deleteDataConnectorJob(row.id);

    if (res) {
      const deletedArr = [...deletedJobs, row.id];
      setDeletedJobs(deletedArr);

      const newData = data.filter((d) => d.id !== row.id);
      setData(newData);

      dispatch(
        toggleSnack({
          snackMessage: "Job deleted",
          snackOpen: true,
          snackDuration: 3000,
        })
      );
      setRefetch(!refetch);
    }
  };

  const handleSave = () => {
    setRefetch(!refetch);
  };

  const columns = getColumns(classes, handleEdit, handleDelete);

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <span>Manage projects</span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
          >
            Create a project
          </Button>
        </div>
        <div className={classes.tableContainer}>
          <DataGridTable
            loading={loading}
            noDataText="No jobs have been created yet"
            columns={columns}
            rows={tableData}
          />
        </div>
      </div>

      <SimpleDialog
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PlugIcon />
            <Box component={"span"} sx={{ marginLeft: "10px" }}>
              {jobEdit ? "Review Job" : "Create a Job"}
            </Box>
          </Box>
        }
        open={dialogOpen}
        handleClose={handleDialogClose}
        noActions
      >
        <JobCreationForm
          jobEdit={jobEdit}
          closePopup={handleDialogClose}
          onSave={handleSave}
          dialogOpen={dialogOpen}
        />
      </SimpleDialog>
    </div>
  );
}
