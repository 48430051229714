import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useEffect, useState } from "react";
import { Button, Typography, TextField } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import { makeStyles } from "@mui/styles";
import { useUploaderContext } from "../context/Context";
import {
  addDataConnectorJob,
  getDataConnectorProjects,
  getDataConnectorTopics,
  saveDataConnectorJob,
  getProjectFolders,
  getOrgUsers,
  getDataConnectorById,
} from "api/UploadApi";
import { toggleSnack } from "../context/action-creators";
import Preloader from "components/UI/Preloader";
import DateTimePicker from "components/UI/DateTimePicker";
import { ReactComponent as TickIcon } from "icons/UI/tick.svg";
import { ascending } from "d3-array";

const steps = ["Choose data", "Create project"];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 500,
    padding: "30px 40px",
    display: (props) => props.dialogOpen ? null : "none",
    "& .MuiSelect-select": {
      borderRadius: 4,
      background: theme.palette.background.$1,
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      paddingRight: "12px !important",
      paddingLeft: "10px !important",
    },
    "& .dropdown_icon": {
      right: "10px !important",
    },
  },
  inputs: {
    padding: "60px 0px",
  },
  label: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "15px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  input: {
    borderRadius: 6,
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.background.$1,
      boxShadow: "0px 0px 1px #131121",
      borderRadius: 6,
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "15px",
    },
    "& fieldset": {
      borderColor: "#1C192B",
    },
  },
  formRow: {
    padding: "15px 0px",
  },
  datePickerRow: {
    padding: "15px 0px",
    "& .DateInput": {
      width: "calc(50% - 20px)",
      borderRadius: 6,
    },
    "& .DateRangePickerInput_arrow": {
      width: "40px",
      textAlign: "center",
    },
    "& .DateRangePicker": {
      width: "100%",
    },
    "& .DateRangePickerInput": {
      borderRadius: 6,
      backgroundColor: theme.palette.background.$1,
      width: "100%",
    },
    "& .DateInput_input": {
      borderRadius: 6,
    },
    "& .DateInput_input__disabled": {
      border: "none",
      color: "rgba(255,255,255,0.5)",
      backgroundColor: theme.palette.background.$1,
    },
  },
  stepButton: {
    "& .MuiStepLabel-label": {
      color: "#6F7494",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: "#fff",
    },
    "& .MuiStepLabel-label.Mui-completed": {
      color: "#fff",
    },
    "& .MuiStepLabel-iconContainer.Mui-completed": {
      backgroundColor: "#1C192B",
      borderRadius: "50%",
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiSvgIcon-root": {
      color: "#1C192B",
      width: 32,
      height: 32,
    },
    "& .MuiSvgIcon-root.Mui-active": {
      color: "#6257C0",
    },
    "& .MuiSvgIcon-root.Mui-completed": {
      color: "#6257C0",
    },
  },
  stepper: {
    "& .MuiStepConnector-root": {
      top: "16px !important",
    },
    "& .MuiStepConnector-line": {
      borderTopStyle: "dashed",
      borderTopColor: "#6F7494",
    },
  },
  continue: {
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  errorColor: {
    color: "#E7394F",
    fontWeight: "normal",
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  timeInput: {
    width: "100%",
    "& .MuiInputBase-root": {
      background: theme.palette.background.$1,
      borderRadius: "5px !important",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "18px",
      border: "nonw",
    },
    "& fieldset": {
      borderRadius: 6,
      borderColor: "transparent",
    },
    "& input::-webkit-calendar-picker-indicator": {
      background: "none",
      display: "none",
    },
  },
  darkBlueBox: {
    background: theme.palette.background.$1,
    padding: "8px 12px",
    borderRadius: 4,
    color: "rgba(255,255,255,0.5)",
  },
}));

export default function JobCreationForm({ jobEdit, closePopup, onSave, dialogOpen }) {
  const classes = useStyles({ dialogOpen });

  const {
    state: { dataConnectors },
    dispatch,
  } = useUploaderContext();

  const editDisabled = !!jobEdit;

  const dataConnectorsList = dataConnectors.map((d) => {
    return {
      value: d.id,
      label: d.name || d.id,
      disabled: !d.statusToggle,
    };
  });

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setComplete] = useState({});
  const [projectName, setProjectName] = useState(""); // constellationProjectName
  const [dataSource, setDataSource] = useState(""); // dataConnector
  const [projectId, setProjectId] = useState(""); // dataProviderProjectId
  const [platform, setPlatform] = useState([]); // selectedPlatform
  const [topicId, setTopicId] = useState(""); // dataProviderTopicId
  const [teamMembers, setTeamMembers] = useState([]); // constellationTeamMembersWithAccess
  const [projects, setProjects] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [topics, setTopics] = useState([]);
  const [projectFolderId, setProjectFolderId] = useState("");
  const [projectFolders, setProjectFolders] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [saving, setSaving] = useState(null);
  const [apiKey, setApiKey] = useState(null);

  const [topicsLoading, setTopicsLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);

  const [errorObject, setErrorObject] = useState({});
  const requiredText = "This field is required";

  useEffect(() => {
    setProjectName(jobEdit?.constellationProjectName || "");
    setDataSource(jobEdit?.dataConnector?.data?.id || "");
    setProjectId(jobEdit?.dataProviderProjectId || "");
    setPlatform(jobEdit?.selectedPlatform || []);
    setTopicId(jobEdit?.dataProviderTopicId || "");
    setProjectFolderId(jobEdit?.topic?.data?.id || ""); // ???
    setTeamMembers(jobEdit?.constellationTeamMembersWithAccess || []);
    setStartDate(jobEdit?.startDateTime || null);
    setEndDate(jobEdit?.endDateTime || null);
  }, [jobEdit]);

  useEffect(() => {
    const loadProjects = async () => {
      setProjectsLoading(true);
      const res = await getDataConnectorProjects({ api_key: apiKey });
      setProjectsLoading(false);
      if (res && res.result_accinfo && res.result_accinfo.projects) {
        setProjects(
          res.result_accinfo.projects.map((d) => ({
            value: d.id,
            label: d.name,
          }))
        );
      }
    };
    if (apiKey) {
      loadProjects();
    }
  }, [apiKey]);

  useEffect(() => {
    const loadTopics = async () => {
      setTopics([]);
      setTopicsLoading(true);
      const res = await getDataConnectorTopics({
        api_key: apiKey,
        project_id: projectId,
      });
      setTopicsLoading(false);
      if (res && res.result_topics && res.result_topics.topic_categories) {
        setTopics(
          res.result_topics.topic_categories
            .map((d) =>
              d.query_topics.map((x, i) => {
                return {
                  value: x.id,
                  label: x.title,
                  groupName: d.title || d.id,
                  showGroup: i === 0,
                };
              })
            )
            .flat()
        );
      }
    };
    if (apiKey) {
      loadTopics();
    }
  }, [projectId, apiKey]);

  useEffect(() => {
    const loadProjectFolders = async () => {
      const res = await getProjectFolders();
      if (res && res.data) {
        setProjectFolders(
          res.data.map((d) => ({ value: d.id, label: d.attributes.name }))
        );
      }
    };
    loadProjectFolders();
  }, []);

  useEffect(() => {
    const loadOrgUsers = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await getOrgUsers(user.orgId);
      setOrgUsers(
        res
          .map((d) => ({ value: d.id, label: d.username }))
          .sort((a, b) => ascending(a.label, b.label))
      );
    };
    loadOrgUsers();
  }, []);

  useEffect(() => {
    if (dataSource) {
      const loadDataConnector = async () => {
        setProjectsLoading(true);
        setTopicsLoading(true);
        const dataConnectorResp = await getDataConnectorById(dataSource);
        if (dataConnectorResp && dataConnectorResp.data[0]) {
          const attributes = dataConnectorResp.data[0].attributes;
          setApiKey(attributes.apiKey);
        }
      };
      loadDataConnector();
    }
  }, [dataSource, jobEdit]);

  const handleNext = () => {
    if (!jobEdit) {
      const payload = {
        dataConnector: dataSource, // step 1
        dataProviderProjectId: projectId, // step 1
        dataProviderTopicId: topicId, // step 1
        selectedPlatform: platform, // step 1
        startDateTime: startDate, // step 1
        endDateTime: endDate, // step 1
      };

      const errorObj = {};

      Object.keys(payload).forEach((key) => {
        if (
          payload[key] === "" ||
          payload[key] === null ||
          payload[key]?.length === 0
        ) {
          errorObj[key] = true;
        }
      });

      if (Object.keys(errorObj).length) {
        return setErrorObject(errorObj);
      }

      setErrorObject({});
    }

    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    setComplete({ 0: true });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setComplete({});
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleCancel = () => {
    closePopup();
  };

  const handleSave = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      dataConnector: dataSource,
      dataProvider: "Talkwalker",
      dataProviderProjectId: projectId,
      dataProviderTopicId: topicId,
      selectedPlatform: platform,
      constellationProjectName: projectName,
      constellationTeamMembersWithAccess: teamMembers,
      owner: user.id,
      startDateTime: startDate,
      endDateTime: endDate,
      topic: projectFolderId,
      organization: { id: user.orgId },
    };

    const errorObj = {};

    Object.keys(payload).forEach((key) => {
      if (
        payload[key] === "" ||
        payload[key] === null ||
        payload[key]?.length === 0
      ) {
        errorObj[key] = true;
      }
    });

    // make sure data source is not disabled
    const dataConnector = dataConnectorsList.find(
      (d) => d.value === dataSource
    );

    if (dataConnector && dataConnector.disabled) {
      setDataSource("");
      errorObj.dataConnector = true;
    }

    if (Object.keys(errorObj).length) {
      if (
        errorObj.dataConnector ||
        errorObj.dataProviderProjectId ||
        errorObj.selectedPlatform ||
        errorObj.dataProviderTopicId ||
        errorObj.startDateTime ||
        errorObj.endDateTime
      ) {
        setActiveStep(0);
      }

      return setErrorObject(errorObj);
    }

    setErrorObject({});

    setSaving(true);
    const fn = jobEdit ? saveDataConnectorJob : addDataConnectorJob;
    const resp = await fn(payload, jobEdit?.id);
    setSaving(false);

    if (resp) {
      closePopup();
      dispatch(
        toggleSnack({
          snackMessage: jobEdit ? "Job updated" : "Job created",
          snackOpen: true,
          snackDuration: 3000,
        })
      );
      onSave();
    }
  };

  const isFileUpload = jobEdit && jobEdit.dataProviderName === "fileupload";

  return (
    <Box className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index] && activeStep !== index}
          >
            <StepButton
              disabled={false}
              icon={
                completed[index] && activeStep !== index ? <TickIcon /> : null
              }
              color="inherit"
              onClick={handleStep(index)}
              className={classes.stepButton}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box className={classes.inputs}>
          <Box className={classes.formRow}>
            <Typography className={classes.label}>
              Choose data source
            </Typography>

            {isFileUpload ? (
              <Box className={classes.darkBlueBox}>File Upload</Box>
            ) : (
              <CustomDropdown
                placeholder={"- Select data source -"}
                helperText={requiredText}
                disabled={editDisabled}
                value={dataSource}
                onChange={(e) => setDataSource(e.target.value)}
                options={dataConnectorsList}
                error={errorObject.dataConnector}
              />
            )}
          </Box>

          {isFileUpload ? (
            <Box className={classes.formRow}>
              <Typography className={classes.label}>Project files</Typography>
              <Box className={classes.darkBlueBox}>
                <ul style={{margin: 0}}>
                  {(jobEdit?.projectFiles || []).map((d, i) => (
                    <li key={i}>{d}</li>
                  ))}
                </ul>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                className={classes.formRow}
                sx={{ display: "flex", width: "100%" }}
              >
                <Box sx={{ width: "50%", paddingRight: "20px" }}>
                  <Typography className={classes.label}>
                    Choose account
                  </Typography>
                  <CustomDropdown
                    placeholder={"- Select account -"}
                    disabled={editDisabled}
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                    title="PROJECT LIST"
                    options={projects}
                    error={errorObject.dataProviderProjectId}
                    helperText={requiredText}
                    loading={projectsLoading}
                  />
                </Box>
                <Box sx={{ width: "50%", paddingLeft: "20px" }}>
                  <Typography className={classes.label}>Platforms</Typography>
                  <CustomDropdown
                    placeholder={"- Select platforms -"}
                    disabled={editDisabled}
                    multiple
                    value={platform}
                    onChange={(e) => setPlatform(e.target.value)}
                    title="PLATFORMS"
                    options={[
                      {
                        value: "Twitter",
                        label: "Twitter",
                      },
                      {
                        value: "Reddit",
                        label: "Reddit",
                      },
                    ]}
                    helperText={requiredText}
                    error={errorObject.selectedPlatform}
                  />
                </Box>
              </Box>

              <Box className={classes.formRow}>
                <Typography className={classes.label}>Choose topics</Typography>
                <CustomDropdown
                  placeholder={"- Select topics -"}
                  disabled={editDisabled}
                  value={topicId}
                  onChange={(e) => setTopicId(e.target.value)}
                  options={topics}
                  helperText={requiredText}
                  error={errorObject.dataProviderTopicId}
                  loading={topicsLoading}
                />
              </Box>

              <Box className={classes.datePickerRow}>
                <Typography className={classes.label}>
                  Select date range
                </Typography>
                <DateTimePicker
                  error={errorObject.startDateTime || errorObject.endDateTime}
                  helperText={requiredText}
                  disabled={editDisabled}
                  dateFrom={startDate}
                  dateTo={endDate}
                  onChange={(start, end) => {
                    setStartDate(start);
                    setEndDate(end);
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}

      {activeStep === 1 && (
        <Box className={classes.inputs}>
          <Box className={classes.formRow}>
            <Typography className={classes.label}>Name your project</Typography>
            <TextField
              required={true}
              disabled={editDisabled}
              variant="outlined"
              fullWidth
              size="small"
              className={classes.input}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              error={errorObject.constellationProjectName && !projectName}
              helperText={
                errorObject.constellationProjectName && !projectName
                  ? requiredText
                  : ""
              }
            />
          </Box>

          <Box className={classes.formRow}>
            <Typography className={classes.label}>
              Choose a project folder
            </Typography>
            <CustomDropdown
              placeholder={"- Choose project folder -"}
              disabled={editDisabled}
              value={projectFolderId}
              onChange={(e) => setProjectFolderId(e.target.value)}
              options={projectFolders}
              error={errorObject.topic}
              helperText={requiredText}
            />
          </Box>

          <Box className={classes.formRow}>
            <Typography className={classes.label}>Add team members</Typography>
            <CustomDropdown
              placeholder={"- Choose team members -"}
              searchEnabled={true}
              disabled={editDisabled}
              multiple
              value={teamMembers}
              onChange={(e) => setTeamMembers(e.target.value)}
              options={orgUsers}
              error={errorObject.constellationTeamMembersWithAccess}
              helperText={requiredText}
            />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <Button
          color="secondary"
          onClick={handleBack}
          sx={{ mr: 1, visibility: activeStep === 0 ? "hidden" : "visible" }}
        >
          <Box
            component="span"
            sx={{
              fontWeight: 700,
              marginRight: "8px",
              transform: "translateY(-2.5px)",
            }}
          >
            &larr;
          </Box>{" "}
          Back
        </Button>

        {activeStep === 0 ? (
          <Button
            onClick={handleNext}
            className={classes.continue}
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        ) : editDisabled ? null : (
          <Button
            onClick={handleSave}
            className={classes.continue}
            color="primary"
            variant="contained"
            disabled={saving}
          >
            {saving ? <Preloader size={24} /> : "Save job"}
          </Button>
        )}
        <Button
          color="secondary"
          onClick={handleCancel}
          className={classes.cancel}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
