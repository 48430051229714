import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ReactComponent as EditNarrative } from "icons/EditNarrative.svg";
import { ReactComponent as DeleteIcon } from "icons/Delete.svg";
import { ReactComponent as SearchIcon } from "icons/UI/search.svg";
import { ReactComponent as ArrowIcon } from "icons/arrowdown.svg";
import {
  Button,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  ListSubheader,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import Hint from "./Hint";
import clsx from "clsx";
import Preloader from "./Preloader";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: 0,
    borderRadius: 4,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "& > .MuiInputBase-root": {
      borderRadius: 2,
      background: (props) =>
        props.transparent ? "transparent" : theme.palette.background.$2,
    },
    "& .MuiSelect-select": {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "12px",
      minHeight: "0px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  },
  errorBorder: {
    "& > .MuiInputBase-root": {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  paperRoot: {
    borderRadius: "4px",
    background: theme.palette.background.$2,
    boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), inset 0px 0px 1px rgba(255, 255, 255, 0.15)",
    border: "1px solid #302E4F",
    marginTop: 4,
    "& .MuiList-root": {
      padding: 0,
    },
    maxHeight: 300,
  },
  selectedText: {
    background: "transparent",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    borderBottom: (props) =>
      props.underline ? "1px dashed #FFFFFF !important" : null,
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "24px",
    color: "#fff",
  },
  placeholderTxt: {
    color: "#AFAFAF !important"
  },
  selected: {
    color: "#fff !important",
    backgroundColor: "transparent !important",
    borderLeft: `3px solid ${theme.palette.primary.main} !important`,
    "& span": {
      color: "#fff !important",
    },
  },
  iconBtn: {
    color: "rgba(231, 231, 239, 0.6)",
  },
  iconDelete: {
    color: "rgba(242, 133, 133, 0.6)",
  },
  root: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    height: "32px",
    color: "rgba(193, 191, 215, 0.99)",
    borderLeft: "3px solid transparent",
    paddingRight: 5,
    "&:hover": {
      color: "#fff",
      "& span": {
        color: "#fff !important",
      },
    },
  },
  rootText: {
    fontStyle: "normal",
    fontSize: "14px",
    color: "#ccc",
    fontWeight: "normal",
    paddingRight: "32px",
    "&:hover": {
      color: "rgba(231, 231, 239, 1)",
    },
  },
  primaryText: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#AFAFAF",
    display: "flex",
    alignItems: "center",
  },
  subheader: {
    fontSize: "12px",
    lineHeight: "40px",
    fontWeight: 400,
    paddingLeft: 19,
    color: "#ffffff",
    textTransform: "uppercase",
    backgroundColor: theme.palette.background.$2,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.background.dropdownActive}`,
    backgroundColor: theme.palette.background.createNarrativeBG,
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
  },
  dropdownIcon: {
    position: "absolute",
    top: "45%",
    bottom: "55%",
    right: 0,
    display: "inline-block",
    pointerEvents: "none",
  },
  inputItem: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    width: "100%",
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.$2,
  },
  searchInput: {
    width: "100%",
    background: theme.palette.background.$1,
    border: "1px solid #282C38",
    borderRadius: 2,
    fontWeight: "400",

    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0.4, 1, 0.4, 0.5),

      "&::placeholder": {
        color: "#6F7494",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "15px",
      },
    },

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      boxShadow: "0px 0px 1px #000000, 0px 1px 0px rgba(255, 255, 255, 0.06)",
    },
  },
  hidden: {
    display: "none !important",
  },
  popupTitle: {
    color: "#6F7494",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 1.5, 1, 2.1),
    "& p": {
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: "normal",
    },
  },
}));

const CustomDropdown = ({
  options,
  value,
  onChange,
  underline,
  footerText,
  onFooterClick,
  onEdit,
  onDelete,
  disabled,
  placeholder,
  itemIcon,
  searchEnabled,
  title,
  hint,
  transparent,
  multiple = false,
  error,
  helperText,
  loading = false
}) => {
  const classes = useStyles({ transparent, underline });

  const [open, setOpen] = React.useState(false);
  const [visibleOptions, setVisibleOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");

  React.useEffect(() => {
    setVisibleOptions(options.flatMap((d) => [d.value, d.groupName]));
  }, [options]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setSearchTerm("");
    setVisibleOptions(options.flatMap((d) => [d.value, d.groupName]));
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEdit = (e, option) => {
    stopImmediatePropagation(e);
    onEdit(option);
    setOpen(false);
  };

  const handleDelete = (e, option) => {
    stopImmediatePropagation(e);
    onDelete(option);
  };

  const handleFooterClick = () => {
    setOpen(false);
    onFooterClick();
  };

  const renderSelected = (selected) => {
    if (loading) {
      return <Preloader size={18} thickness={7} />
    }

    if (multiple) {
      const selectedOptions = options
        .filter((d) => selected.includes(d.value))
        .map((d) => d.label);

      let label = selectedOptions.join(", ");
      let isPlaceholder = false;

      if (!selected.length && placeholder) {
        label = placeholder;
        isPlaceholder = true;
      }

      return <div className={clsx(classes.selectedText, { [classes.placeholderTxt ]: isPlaceholder })}>{label}</div>;
    }

    let o = null;
    let isPlaceholder = false;

    if (selected === "" && placeholder) {
      o = { label: placeholder };
      isPlaceholder = true;
    } else {
      o = options.find((d) => d.value === selected);
    }

    let label = selected;

    if (o) {
      label = o.selectedLabel || o.label || selected;
    }

    return <div className={clsx(classes.selectedText, { [classes.placeholderTxt ]: isPlaceholder })}>{label}</div>;
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    setSearchTerm(e.target.value);
    const val = e.target.value.toLowerCase();
    const filtered = options
      .filter((d) => {
        return (
          d.label.toLowerCase().includes(val) ||
          (d.groupName || "").toLowerCase().includes(val)
        );
      })
      .flatMap((d) => [d.value, d.groupName]);
    setVisibleOptions(filtered);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  const inputRef = React.useRef();

  React.useEffect(() => {
    let timeout;
    if (open) {
      timeout = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [open]);

  const invalid = error && (!value || (value || []).length === 0);

  return (
    <FormControl
      variant="standard"
      className={invalid ? " " + classes.errorBorder : ""}
      classes={{
        root: classes.formControl,
      }}
    >
      <Select
        displayEmpty={!!placeholder}
        MenuProps={{
          style: { zIndex: 999999 },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          classes: {
            paper: classes.paperRoot,
            select: classes.select,
          },
        }}
        IconComponent={() => <ArrowIcon className={classes.dropdownIcon + " dropdown_icon"} />}
        renderValue={renderSelected}
        value={value}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={onChange}
        disableUnderline
        disabled={!!disabled}
        variant="standard"
        multiple={multiple}
      >
        {title && (
          <div className={classes.popupTitle}>
            <Typography>{title}</Typography>
            {hint && <Hint tooltipText={hint} />}
          </div>
        )}
        {searchEnabled && (
          <div className={classes.inputItem}>
            <TextField
              autoFocus
              placeholder="Search"
              variant="outlined"
              className={classes.searchInput}
              size="small"
              value={searchTerm}
              onKeyDown={handleKeyDown}
              onChange={handleSearch}
              inputRef={inputRef}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        {options.flatMap((option, i) => {
          return [
            option.showGroup && visibleOptions.includes(option.groupName) && (
              <ListSubheader
                key={"group_" + option.value}
                className={classes.subheader}
              >
                {option.groupName}
              </ListSubheader>
            ),
            <MenuItem
              key={option.value + String(i)}
              value={option.value}
              style={{ maxWidth: 600 }}
              classes={{
                selected: classes.selected,
                root: classes.root,
              }}
              disabled={!!option.disabled}
              className={
                searchEnabled && !visibleOptions.includes(option.value)
                  ? classes.hidden
                  : null
              }
            >
              {itemIcon && <ListItemIcon>{itemIcon}</ListItemIcon>}
              {multiple && (
                <Checkbox
                  color="primary"
                  checked={value.indexOf(option.value) > -1}
                />
              )}
              <ListItemText
                classes={{
                  root: classes.rootText,
                  primary: classes.primaryText,
                }}
              >
                {option.label}
              </ListItemText>
              {onEdit && option.editable && (
                <IconButton
                  className={classes.iconBtn}
                  onClick={(e) => handleEdit(e, option.value)}
                >
                  <EditNarrative />
                </IconButton>
              )}
              {onDelete && option.deletable && (
                <IconButton
                  className={classes.iconDelete}
                  onClick={(e) => handleDelete(e, option.value)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </MenuItem>,
          ];
        })}

        {footerText && onFooterClick && (
          <div className={classes.footer}>
            <Button
              sx={{ fontWeight: 600, fontSize: "12px" }}
              startIcon={<AddCircle />}
              onClick={handleFooterClick}
            >
              {footerText}
            </Button>
          </div>
        )}
      </Select>
      {invalid && (
        <FormHelperText variant="outlined" error={invalid}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomDropdown;
