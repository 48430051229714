import { useState, useEffect, useCallback } from "react";
import useStateRef from "react-usestateref";
import { makeStyles } from "@mui/styles";
import { useUploaderContext } from "../context/Context";
import {
  selectDocuments,
  setNewDocuments,
  stageDocuments,
  toggleSnack,
  toggleTOS,
  toggleUploading,
} from "../context/action-creators";
import { DELETING } from "../utils/constants";
import Droparea from "./Droparea";
import Datasets from "./Datasets";
import SelectionBar from "./SelectionBar";
import Sidebar from "./Sidebar";
import { closestFileBySize } from "../utils/utils";
import { deleteFiles, getFiles, updateFile, uploadFile } from "api/UploadApi";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 30,
    paddingBottom: 60,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: `0px ${theme.spacing(3)}px`,
      paddingBottom: 30,
    },
  },
}));

export default function Files() {
  const classes = useStyles();
  const [uploadProgress, setUploadProgress] = useState({
    percent: 0,
    timeLeft: 100,
  });
  const [, setUploadsInProgress, uploadsRef] = useStateRef([]);
  const [, , largestDocRef] = useStateRef({ id: "", size: 0 });
  const [docEditing, setDocEditing] = useState(null);

  const {
    state: { documents, stagedDocuments, selectedDocuments },
    dispatch,
  } = useUploaderContext();

  const documentMap = new Map(documents.map((d) => [d.id, d]));

  const setDocuments = useCallback(
    (docs) => {
      dispatch(setNewDocuments(docs));
    },
    [dispatch]
  );

  const setStagedDocuments = (docs) => {
    dispatch(stageDocuments(docs));
  };

  const setSelectedDocuments = (docs) => {
    dispatch(selectDocuments(docs));
  };

  const setTosChecked = (value) => {
    dispatch(toggleTOS(value));
  };

  const setUploading = (value) => {
    dispatch(toggleUploading(value));
  };

  const triggerSnack = useCallback(
    (snackMessage, snackOpen, snackError) => {
      dispatch(
        toggleSnack({
          snackMessage,
          snackOpen,
          snackError,
        })
      );
    },
    [dispatch]
  );

  const handleUploads = (documentsToUpload) => {
    setUploading(true);
    let largestSize = 0;
    let largestFile = undefined;
    for (let index = 0; index < documentsToUpload.length; index++) {
      const doc = documentsToUpload[index];
      if (doc.size >= largestSize) {
        largestSize = doc.size;
        largestFile = doc;
      }
    }
    largestDocRef.current = largestFile;
    setUploadsInProgress(documentsToUpload);
    setDocuments([...documentsToUpload, ...documents]);
    setStagedDocuments([]);
    for (let i = 0; i < documentsToUpload.length; i++) {
      upload(documentsToUpload[i].id);
    }
  };

  const onUploadProgress = (progress) => {
    const { total, loaded } = progress || { total: 1, loaded: 0 };
    const doc = closestFileBySize(uploadsRef.current, total);
    if (doc.size === largestDocRef.current.size) {
      setUploadProgress({
        percent: ((loaded / total) * 100).toFixed(1),
        timeLeft: 100,
      });
    }
  };

  const upload = async (id) => {
    const index = stagedDocuments.findIndex((doc) => doc.id === id);
    const document = stagedDocuments[index];

    try {
      const formData = new FormData();
      formData.append("files", document.file, document.filename);
      await uploadFile(document, formData, onUploadProgress);
    } catch (error) {
      console.log(error);
      triggerSnack(`Error selecting ${document.filename}`, true, true);
    }
  };

  const cancelUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setUploading(false);
    setTosChecked(false);
    setUploadProgress({ percent: 0, timeLeft: 100 });
  };

  const resetImport = () => {
    setStagedDocuments([]);
  };

  const deleteDocsByIds = async (ids) => {
    await deleteFiles(ids);
  };

  const onDelete = async (docs) => {
    const oldDocs = [...documents];
    const docsToRemove = [];

    for (const doc of docs) {
      const index = oldDocs.findIndex((x) => x.id === doc.id);
      if (index > -1) {
        docsToRemove.push({
          index,
          _id: oldDocs[index]._id,
          filename: oldDocs[index].filename,
        });
        oldDocs[index].status = DELETING;
      }
    }

    setSelectedDocuments([]);
    setTosChecked(false);
    setDocuments(oldDocs);

    await deleteDocsByIds(docsToRemove.map((doc) => doc._id));

    const newDocs = [...oldDocs];
    for (let j = 0; j < docsToRemove.length; j++) {
      const { index } = docsToRemove[j];
      newDocs.splice(index, 1);
    }

    setDocuments(newDocs);
  };

  const handleDelete = () => onDelete(selectedDocuments);

  const onEdit = (doc) => {
    setDocEditing(doc);
    setTosChecked(false);
  };

  const setChecked = (e) => setTosChecked(e.target.checked);

  const clearEditing = () => setDocEditing(null);

  const onEditSave = (d) => {
    const docs = [...documents];
    const index = docs.findIndex((x) => x.id === d.id);

    if (index > -1) {
      docs[index] = d;

      documentMap.set(d.id, d);

      setDocuments(docs);

      setDocEditing(null);
      setSelectedDocuments([]);
    }
  };

  const editDBAndSave = async (d) => {
    await updateFile(d);
    onEditSave(d);
  };

  useEffect(() => {
    const getDocuments = async () => {
      await getFiles(setDocuments);
    };
    getDocuments();
    // disable for predash-2600 troubleshooting
    // const interval = setInterval(() => getDocuments(), 2000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, [setDocuments]);

  return (
    <div>
      <Droparea
        setStagedDocuments={setStagedDocuments}
        handleUploads={handleUploads}
        uploadProgress={uploadProgress}
        setUploadProgress={setUploadProgress}
        setUploading={setUploading}
        cancelUpload={cancelUpload}
        setChecked={setChecked}
        resetImport={resetImport}
        triggerSnack={triggerSnack}
      />

      <div className={classes.tableContainer}>
        <Datasets
          documents={documents}
          selectedDocuments={selectedDocuments}
          onDocumentSelect={(model) => {
            setSelectedDocuments(model.map((d) => documentMap.get(d)));
          }}
        />
      </div>

      {selectedDocuments.length > 0 && (
        <SelectionBar
          selectedDocuments={selectedDocuments}
          onEdit={onEdit}
          onDelete={handleDelete}
        />
      )}

      <Sidebar
        doc={docEditing}
        onClose={clearEditing}
        onSave={onEditSave}
        dbSave={editDBAndSave}
      />
    </div>
  );
}
