import { Box } from "@mui/system";

export default function HighlightedKeyword({ html }) {
	return (
		<Box
			sx={{
				"& em": {
					fontStyle: "normal",
					background: "#7165E0",
					borderRadius: "2px",
					padding: "0px 2px",
				},
			}}
			component={"span"}
			dangerouslySetInnerHTML={{ __html: html }}
		></Box>
	);
}
