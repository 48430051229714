import { Button, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { ReactComponent as EditIcon } from "icons/EditNarrative.svg";
import { ReactComponent as DeleteIcon } from "icons/Delete.svg";
import { ReactComponent as AddIcon } from "icons/Add.svg";
import { deleteUserGroup } from "api/AnalyticsApi";

const useStyles = makeStyles((theme) => ({
	row: {
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
	},
	label: {
		color: "#fff",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "22px",
		transform: "translate(0, 0) scale(1)",
	},
	spaceTop: {
		marginTop: theme.spacing(1),
	},
	muiItem: {
		fontSize: 13,
		color: "#E7E7EF",
	},
	profileSelect: {
		display: "flex",
		alignItems: "center",
		padding: "10px 0px",
	},
	profileListItem: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	dropdown: {
		width: "40%",
		marginRight: 10,
		padding: 6,
		borderRadius: 4,
		background: "#302E4F",
	},
	manage: {
		padding: "10px 0px",
	},
	profileName: {
		flexGrow: 1,
		color: "#afafaf",
		fontSize: 14,
	},
	btns: {},
}));

export default function UserGroupTab({ hidePopup }) {
	const classes = useStyles();

	const {
		dispatch,
		state: { userGroups },
	} = useAppContext();

	const handleCreate = () => {
		dispatch({
			type: ACTIONS.EDIT_USER_GROUP,
			payload: {},
		});
		hidePopup();
	};

	const showMessage = (msg, type) => {
		dispatch({
			type: ACTIONS.SHOW_MESSAGE,
			payload: msg
				? {
					message: msg,
					type: type,
				}
				: null,
		});
	};

	const handleDelete = async (d) => {
		const res = await deleteUserGroup(d.id);
		if (res) {
			dispatch({
				type: ACTIONS.REMOVE_USER_GROUP,
				payload: d,
			});
			showMessage("User group deleted", "success");
		}
		hidePopup();
	};

	const handleEdit = (d) => {
		dispatch({
			type: ACTIONS.EDIT_USER_GROUP,
			payload: d,
		});
		hidePopup();
	};

	return (
		<div>
			<div className={classes.row}>
				<div className={classes.label}>Manage user groups</div>
				<div className={classes.manage}>
					{userGroups.map((d) => (
						<div className={classes.profileListItem} key={d.name}>
							<Typography variant="caption" className={classes.profileName}>
								{d.name}
							</Typography>
							<div className={classes.btns}>
								<IconButton
									disabled={d.isDefault}
									onClick={() => handleEdit(d)}
								>
									<EditIcon />
								</IconButton>
								<IconButton
									color="error"
									disabled={d.isDefault}
									onClick={() => handleDelete(d)}
								>
									<DeleteIcon />
								</IconButton>
							</div>
						</div>
					))}
				</div>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<AddIcon />}
					onClick={handleCreate}
				>
          Create a user group
				</Button>
			</div>
		</div>
	);
}
