import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Hint from "components/UI/Hint";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		height: "75px",
		paddingRight: 0,
	},
	value: {
		height: "100%",
		flexGrow: 1,
		paddingRight: theme.spacing(1),
		borderRight: (props) =>
			props.isLast ? "none" : "1px solid " + theme.palette.border.section,
	},
	icon: {
		backgroundColor: "#2D283F",
		marginRight: 15,
		minWidth: 42,
		height: 42,
		width: 42,
		borderRadius: 4,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		display: "flex",
		alignItems: "center",
	},
	label: {
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "12px",
		lineHeight: "20px",
		color: "#757B8C",
		textTransform: "uppercase",
	},
	question: {
		marginLeft: 8,
		width: 20,
		height: 20,
	},
	number: {
		fontStyle: "normal",
		fontSize: "16px",
		lineHeight: "20px",
		fontWeight: "bold",
		color: "#ffffff",
	},
}));

export default function SummaryItem(props) {
	const Icon = props.icon;
	const classes = useStyles({
		iconColor: props.iconColor,
		isLast: props.isLast,
	});

	return (
		<Grid container alignItems="center" wrap="nowrap" className={classes.root}>
			<Grid
				item
				container
				alignItems="center"
				justifyContent="center"
				className={classes.icon}
			>
				<Icon style={{ width: 24, height: 24 }} />
			</Grid>

			<Grid item className={classes.value}>
				<div className={classes.title}>
					<Typography variant={"body2"} noWrap className={classes.label}>
						{props.name}
					</Typography>

					{props.tooltipText && <Hint tooltipText={props.tooltipText} />}
				</div>

				<Typography variant={"h6"} className={classes.number} noWrap>
					{props.value}
				</Typography>
			</Grid>
		</Grid>
	);
}
