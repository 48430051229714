import { Link } from "react-router-dom";
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	TextField,
	InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as ExpandMore } from "icons/UI/Expand.svg";
import { ReactComponent as DBIcon } from "icons/DB.svg";
import { ReactComponent as DoritoDownFolderClosedIcon } from "icons/DoritoDownFolderClosed.svg";
import { ReactComponent as ProjectIcon } from "icons/Project.svg";
import clsx from "clsx";
import { useAppContext } from "context/Context";
import { calculateTopicFolders } from "utils/format";
import { useMemo, useState, useEffect, useRef } from "react";
import { ReactComponent as SearchIcon } from "icons/UI/search.svg";

const OTHER_ELEMENTS_HEIGHT_TOTAL = 500;

const useStyles = makeStyles((theme) => ({
	project: {
		paddingRight: 15,
	},
	projectChild: {
		paddingLeft: theme.spacing(5),
		paddingRight: 15,
	},
	listItem: {
		padding: theme.spacing(1, 2.8),
		display: "flex",
		alignItems: "center",
		flexDirection: (props) => (props.navOpen ? "row" : "column"),
		"& .MuiListItemIcon-root": {
			margin: "0 auto",
			textAlign: "center",
			minWidth: (props) => (props.navOpen ? 32 : 0),
			"& svg": {
				maxWidth: 32,
				margin: "0 auto",
			},
		},
	},
	listItemText: {
		color: theme.palette.text.grey,
		fontWeight: "normal",
		"& .MuiTypography-root": {
			fontWeight: "inherit",
			fontSize: "inherit",
			whiteSpace: "inherit",
			lineHeight: "inherit",
		},
		paddingLeft: (props) => (props.navOpen ? "14px" : 0),
		textAlign: (props) => (props.navOpen ? "left" : "center"),
		marginTop: (props) => (props.navOpen ? 0 : 8),
		lineHeight: "17px",
		marginBottom: 0,
		fontSize: (props) => (props.navOpen ? "14px" : "12px"),
		whiteSpace: (props) => (props.navOpen ? "nowrap" : "normal"),
	},
	dbIcon: {
		minWidth: 26,
		width: 24,
		height: 24,
		color: theme.palette.text.grey,
		"& > svg": {
			marginTop: 2,
		},
	},
	collapse: {
		marginLeft: 15,
		display: (props) => (props.navOpen ? "" : "none"),
		"& svg": {
			transform: "rotate(180deg)",
		},
	},
	rotate: {
		transform: "rotate(180deg)",
	},
	projectsParent: {
		paddingRight: 5,
	},
	scroll: {
		minHeight: 100,
		maxHeight: `calc(100vh - ${OTHER_ELEMENTS_HEIGHT_TOTAL}px)`,
		overflowY: "auto",
		overflowX: "hidden",
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#9F9CBC",
			width: 4,
		},
		"&::-webkit-scrollbar": {
			width: 4,
		},
	},
	projects: {
		paddingLeft: 12,
	},
	loadMoreBtn: {
		color: "#B5B2CB",
		fontSize: 12,
		lineHeight: "15px",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		"& svg": {
			marginRight: 10,
		},
	},
	inputItem: {
		position: "sticky",
		top: 0,
		zIndex: 2,
		width: "100%",
		padding: theme.spacing(0.5, 1.5, 0.5, 2.8),
	},
	searchInput: {
		width: "100%",
		background: "#272438",
		border: "1px solid #1C192B",
		borderRadius: 0,
		"& .MuiOutlinedInput-input": {
			padding: theme.spacing(0.7, 1.2, 0.7, 0.7),
			fontWeight: 400,
		},
		"& .MuiInputBase-root": {
			borderRadius: 0,
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #1C192B !important",
		},
	},
	hidden: {
		display: "none !important",
	},
}));

export default function ProjectsList({ navOpen, setNavOpen }) {
	const classes = useStyles({ navOpen });
	const {
		state: { projects, project: activeProject },
	} = useAppContext();

	const hierarchy = useMemo(
		() => calculateTopicFolders(projects || []),
		[projects]
	);

	const list = useMemo(
		() =>
			hierarchy.flatMap((d) =>
				d.projects.map((x) => ({
					label: x.name,
					value: String(x.projectId),
					groupName: d.name,
				}))
			),
		[hierarchy]
	);

	const [projectsVisible, setProjectsVisible] = useState(false);
	const [projectMap, setProjectMap] = useState(
		hierarchy.reduce((o, d) => ({ ...o, [d.name]: false }), {})
	);
	const projectMapRef = useRef(null);
	const [visibleProjects, setVisibleProjects] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		setVisibleProjects(list.flatMap((d) => [d.value, d.groupName]));
	}, [list]);

	const handleProjectClick = (name) => {
		const _projectMap = { ...projectMap };
		_projectMap[name] = !_projectMap[name];
		setProjectMap(_projectMap);
	};

	const handleSearch = (e) => {
		e.stopPropagation();
		setSearchTerm(e.target.value);

		const val = e.target.value.toLowerCase();
		const groups = new Set();
		const filtered = list
			.filter((d) => {
				return (
					d.label.toLowerCase().includes(val) ||
          d.groupName.toLowerCase().includes(val)
				);
			})
			.flatMap((d) => {
				groups.add(d.groupName);
				return [d.value, d.groupName];
			});

		// automatically expand all projects
		if (val.length > 0) {
			const _projectMap = hierarchy.reduce(
				(o, d) => ({ ...o, [d.name]: true }),
				{}
			);
			setProjectMap(_projectMap);

			if (
				!projectMapRef.current ||
        (projectMapRef.current && !projectMapRef.current.firstEnter)
			) {
				// save the projectMap
				projectMapRef.current = {
					firstEnter: true,
					projectMap,
				};
			}
		} else if (projectMapRef.current && projectMapRef.current.projectMap) {
			setProjectMap(projectMapRef.current.projectMap);
			// reset the projectMap back
			projectMapRef.current = {
				firstEnter: false,
				projectMap: null,
			};
		}

		setVisibleProjects(filtered);
	};

	const handleFocus = () => {
		projectMapRef.current = {
			firstEnter: false,
			projectMap: null,
		};
	};

	const handleKeyDown = (e) => {
		e.stopPropagation();
	};

	const handleClick = () => {
		setNavOpen(true);
		const visible = navOpen ? !projectsVisible : true;
		setProjectsVisible(visible);
	};

	return (
		<>
			<ListItem button dense onClick={handleClick} className={classes.listItem}>
				<ListItemIcon>
					<ProjectIcon />
				</ListItemIcon>

				<ListItemText primary="Projects" className={classes.listItemText} />

				{navOpen && <ExpandMore
					className={clsx({
						[classes.rotate]: projectsVisible,
					})}
				/>}
			</ListItem>

			{navOpen && hierarchy && (
				<Collapse in={projectsVisible} timeout="auto" unmountOnExit>
					<div
						className={clsx({ [classes.projectsParent]: true })}
					>
						<div className={classes.inputItem}>
							<TextField
								autoFocus
								variant="outlined"
								className={classes.searchInput}
								size="small"
								value={searchTerm}
								onKeyDown={handleKeyDown}
								onChange={handleSearch}
								onFocus={handleFocus}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</div>
						<div className={clsx(classes.projects, { [classes.scroll]: true })}>
							{hierarchy.map((project, index) => {
								return (
									<div key={index}>
										<ListItem
											button
											dense
											onClick={() => handleProjectClick(project.name)}
											className={clsx(classes.listItem, {
												[classes.hidden]: !visibleProjects.includes(
													project.name
												),
											})}
										>
											<ListItemIcon>
												<DoritoDownFolderClosedIcon />
											</ListItemIcon>

											<ListItemText
												primary={project.displayName || project.name}
												className={classes.listItemText}
											/>

											<ExpandMore
												className={clsx({
													[classes.rotate]: projectMap[project.name],
												})}
											/>
										</ListItem>

										<Collapse
											in={projectMap[project.name]}
											timeout="auto"
											unmountOnExit
										>
											{project.projects.map((child, index) => {
												return (
													<ListItem
														key={index}
														button
														dense
														component={Link}
														selected={child.name === activeProject.name}
														to={`/dashboard/${child.name}`}
														className={clsx(classes.projectChild, {
															[classes.hidden]: !visibleProjects.includes(
																String(child.projectId)
															),
														})}
													>
														<ListItemIcon className={classes.dbIcon}>
															<DBIcon />
														</ListItemIcon>

														<ListItemText
															primary={child.displayName || child.name}
															className={classes.listItemText}
														/>
													</ListItem>
												);
											})}
										</Collapse>
									</div>
								);
							})}
						</div>
					</div>
				</Collapse>
			)}
		</>
	);
}
