import React from "react";
import { useAppContext } from "context/Context";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ALL_PROJECT_INDEX } from "utils/constants";
import { formatMonthAbrDay } from "utils/format";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "0px 0px 5px 0px",
    margin: 0,
    overflow: "auto",
    height: "275px",
  },
  header: {
    color: "#6F749C",
    margin: "15px 0px 4px 0px",
  },
  title: {
    marginTop: "8px",
  },
  text: {
    margin: "0px 0px 16px 0px",
  },
}));

export default function CollectionRules() {
  const classes = useStyles();

  const {
    state: { project, narratives },
  } = useAppContext();

  const { name, displayName, createdAt } = project;

  const timeframe =
    narratives && narratives.length
      ? `${formatMonthAbrDay(
          narratives[ALL_PROJECT_INDEX].start_date
        )} to ${formatMonthAbrDay(narratives[ALL_PROJECT_INDEX].end_date)}`
      : "";

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.header}>
        Overview
      </Typography>

      {name && timeframe ? (
        <Typography variant="body2" className={classes.title}>
          {displayName || name} from {timeframe}
        </Typography>
      ) : null}

      {createdAt ? (
        <>
          <Typography variant="subtitle1" className={classes.header}>
            Creation date
          </Typography>

          <Typography variant="body2" className={classes.title}>
            {formatMonthAbrDay(createdAt)}
          </Typography>
        </>
      ) : null}
    </div>
  );
}
