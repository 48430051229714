import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import ComingSoonIcon from "icons/Uploader/ComingSoon.svg";

export default function ProviderComingSoon({ text1, text2 }) {
  return (
    <Box
      sx={{
        height: 143,
        width: "100%",
        backgroundImage: `url(${ComingSoonIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "50px",
        borderRadius: 2,
        border: "1px dashed #302E4F",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography sx={{ color: "#6F7494", fontSize: "13px", lineHeight: "20px", whiteSpace: "nowrap" }}>{text1}</Typography>
        <Typography sx={{ color: "#ffffff", fontSize: "14px", lineHeight: "18px", whiteSpace: "nowrap" }}>{text2}</Typography>
      </Box>
    </Box>
  );
}
