import React from "react";
import EmptyBanner from "./EmptyBanner";
import DocumentsTable from "./Table";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(247.48deg, #1E1C2C 11.19%, #1D1B2D 73.37%)",
    border: "1px solid #201C39",
    borderRadius: "6px",
  },
  headerTitle: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#fff",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  content: {
    paddingTop: 0,
  },
  actions: {
    background: "#242234",
    color: "#77758E",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  button: {
    background: "#312F45",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",

    "&:first-child": {
      marginRight: 12,
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.headerTitle}>IMPORTED DATASETS</Typography>
      {props.documents.length ? <DocumentsTable {...props} /> : <EmptyBanner />}
    </div>
  );
}
