import React from "react";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormControlLabel, List, ListItemText, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 140,
    marginBottom: theme.spacing(3),
  },
  listSection: {
    backgroundColor: "inherit",
    display: "flex",
    flexdirection: "column",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
    flexGrow: 0,
  },
  fill: {
    display: "flex",
    flex: 1,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  exploratory: {
    marginLeft: theme.spacing(1),
  }
}));

export default function ProceedUploadList({ documents, setStagedDocuments }) {
  const classes = useStyles();

  const handleChangeDescription = (e) => {
    const newDocuments = [...documents];
    const index = newDocuments.findIndex(
      (doc) => `section-${doc.filename}` === e.target.id
    );
    newDocuments[index].description = e.target.value;
    setStagedDocuments(newDocuments);
  };

  const handleChangeExploratory = (e) => {
    const newDocuments = [...documents];
    const index = newDocuments.findIndex(
      (doc) => `section-checkbox-${doc.filename}` === e.target.id
    );
    newDocuments[index].exploratory = e.target.checked;
    setStagedDocuments(newDocuments);
  };

  return (
    <List className={classes.root} subheader={<li />}>
      {documents.map((doc) => (
        <div key={`section-${doc.filename}`} className={classes.listSection}>
          <ListItemText className={classes.ul}>{doc.filename}</ListItemText>
          <TextField
            id={`section-${doc.filename}`}
            className={classes.fill}
            variant="outlined"
            label="Description"
            value={doc.description}
            onChange={handleChangeDescription}
            inputProps={{
              style: {
                padding: "9px",
                marginTop: "3px",
              },
            }}
          />
          <FormControlLabel
            className={classes.exploratory}
            control={<Checkbox color='primary' id={`section-checkbox-${doc.filename}`} checked={doc.exploratory} onChange={handleChangeExploratory} />}
            label="Exploratory"
          />
        </div>
      ))}
    </List>
  );
}
