import { DEFAULT_NARRATIVE } from "utils/constants";

export function getNarrativeData(narratives, narrativeIndex) {
	const narrative = narratives[narrativeIndex] || DEFAULT_NARRATIVE;
	if (!narrative.analyze_filters) {
		narrative.analyze_filters = DEFAULT_NARRATIVE.analyze_filters;
	}
	return {
		narrative,
		start_date: narrative.start_date,
		end_date: narrative.end_date,
	};
}

export function getProjectSettings(project, platform) {
	const allProjectSettings = project?.projectConfigs?.data;
	if (allProjectSettings && platform) {
		return allProjectSettings.find(ps =>
			ps?.attributes?.platform?.data?.attributes?.name === platform)?.attributes;
	}
	return undefined;
}

export function removeHarmFromFilters(filters) {
	const obj = filters?.obj || {};
	const serialized = filters?.serialized || {};
	const analyze_filters = {
		obj: {},
		serialized: {}
	};
	Object.keys(obj).forEach(key => {
		if (key !== "harm" && key !== "is_harmful") {
			analyze_filters.obj[key] = obj[key];
		}
	});
	Object.keys(serialized).forEach(key => {
		if (key !== "harm" && key !== "is_harmful") {
			analyze_filters.serialized[key] = serialized[key];
		}
	});
	return analyze_filters;
}