import React from "react";
import { roundToTwo, formatNumber } from "utils/format";
import CohortChip from "components/UI/CohortChip";
import { ReactComponent as UpvotesIcon } from "icons/social-media/Upvotes.svg";
import { ReactComponent as ThickIcon } from "icons/ThickGreen.svg";
import { ReactComponent as AttentionIcon } from "icons/attention.svg";
import { pieChartColorMappings } from "../Overview/Insights";
import Hint from "components/UI/Hint";
import { TextField } from "@mui/material";
import { harmFilter } from "pages/DashboardPage/Shared/RichTable/filterConfig";
import { makeStyles } from "@mui/styles";
import { DEFAULT_PROFILE, EXPLANATIONS, FIELD_NAMES, PLATFORMS } from "utils/constants";
import BigText from "./Settings/BigText";
import array from "lodash/array";
import HarmDot from "components/UI/HarmDot";
import { resolveCohortDisplayName } from "utils/cohorts";
import { Box } from "@mui/system";
import { METRIC_COLORS } from "colors";

const useStyles = makeStyles(() => ({
	justFlex: {
		display: "flex",
		alignItems: "center",
	},
	statBubble: {
		background: "#302E4F",
		padding: "6px 8px",
		color: "#E8E9F3",
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "12px",
		borderRadius: 16,
		whiteSpace: "nowrap",
	},
	moreCohorts: {
		border: "2px solid #302E4F",
		padding: "6px 8px",
		color: "#5B5883",
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "12px",
		borderRadius: 16,
	},
	harmText: {
		marginLeft: 5,
	},
	sent: { marginRight: 10, height: 18 },
}));

const StylesProvider = ({ children }) => {
	const classes = useStyles();
	return children(classes);
};

export const Circle = ({ color, onClick }) => {
	return (
		<div
			onClick={
				onClick
					? (e) => {
						e.preventDefault();
						e.stopPropagation();
						onClick();
					}
					: null
			}
			style={{
				backgroundColor: color,
				border: null,
				width: 12,
				height: 12,
				borderRadius: "50%",
				margin: "0 auto",
			}}
		></div>
	);
};

export const ReasonCodesColumns = (showText, onClick, platform, showKnownHoax) => {
	const commonCols = [
		{
			text: "Anomalous",
			field: "is_manipulated",
			sort_field: "bbmi",
			togglable: true,
			cellComponent: ({ value, datum }) => {
				if (showText) {
					return roundToTwo(datum["bbmi"]);
				}

				if (value) {
					return (
						<Circle
							color={METRIC_COLORS.anomalous}
							onClick={() => onClick(EXPLANATIONS.anomalous, datum)}
						/>
					);
				}

				return null;
			},
		},
		...(showKnownHoax ? [{
			text: "Known Hoax",
			field: "*",
			sort_field: FIELD_NAMES.known_hoax,
			togglable: true,
			cellComponent: ({ value }) => {
				if (showText) {
					return roundToTwo(value.known_hoax);
				}

				if (value.known_hoax > 0.5) {
					return (
						<Circle
							color={METRIC_COLORS.known_hoax}
							onClick={() => onClick(FIELD_NAMES.known_hoax, value)}
						/>
					);
				}

				return null;
			},
		}] : []),
		{
			text: "Toxic",
			field: "is_toxic",
			sort_field: "toxicity",
			togglable: true,
			cellComponent: ({ value, datum }) => {
				if (showText) {
					return roundToTwo(datum["toxicity"]);
				}

				if (value) {
					return (
						<Circle
							color={METRIC_COLORS.toxic}
							onClick={() => onClick(EXPLANATIONS.toxicity, datum)}
						/>
					);
				}

				return null;
			},
		},
		{
			text: "Influential",
			field: "is_influential",
			sort_field: "pagerank",
			togglable: true,
			cellComponent: ({ value, datum }) => {
				if (showText) {
					return roundToTwo(datum["pagerank"]);
				}

				if (value) {
					return <Circle color={METRIC_COLORS.influencial} />;
				}

				return null;
			},
		},
	];

	const twitterCols = [
		{
			text: <Box sx={{ whiteSpace: "nowrap" }}>Bot-like</Box>,
			field: "is_bot",
			sort_field: "bot_score",
			togglable: true,
			cellComponent: ({ value, datum }) => {
				if (showText) {
					return roundToTwo(datum["bot_score"]);
				}

				if (value) {
					return <Circle color={METRIC_COLORS.bot_like} />;
				}

				return null;
			},
		},
	];

	return [
		...(platform === PLATFORMS.twitter ? twitterCols : []),
		...commonCols,
	];
};

export const CohortColumns = () => {
	return [
		{
			text: "Cohorts",
			field: "cohorts",
			tooltip:
        "An identification of posts as sharing a common set of values, interests, or ideology",
			not_sortable: true,
			not_export: true,
			togglable: true,
			cellComponent: ({ value }) => {
				if (!value) {
					// The cohorts field is missing from a post or a sample post.
					return null;
				}

				if (!Array.isArray(value)) {
					// The cohorts field needs to be an array.
					return null;
				}

				return value.map(cohortName => (
					<div
						key={cohortName}
						style={{ marginBottom: 2, marginTop: 2, textAlign: "center" }}
					>
						<CohortChip cohortName={cohortName} />
					</div>
				));
			},
		},
	];
};

const getEmotionThreshold = (emotionThresholds, emotion) => {
	return emotionThresholds ? emotionThresholds[emotion] : 0.5; 
};
export const getEmoSent = (value, showText, emotionThresholds, isSentiment) => {
	const { happiness, anger, disgust, sadness, surprise, fear, neutral } = value;

	const labels = [
		["Happy", happiness, pieChartColorMappings.happiness, "Positive", getEmotionThreshold(emotionThresholds, "happiness")],
		["Surprised", surprise, pieChartColorMappings.surprise, "Neutral", getEmotionThreshold(emotionThresholds, "surpise")],
		["Neutral", neutral, pieChartColorMappings.neutral, "Neutral", getEmotionThreshold(emotionThresholds, "neutral")],
		["Angry", anger, pieChartColorMappings.anger, "Negative", getEmotionThreshold(emotionThresholds, "anger")],
		["Disgust", disgust, pieChartColorMappings.disgust, "Negative", getEmotionThreshold(emotionThresholds, "disgust")],
		["Sad", sadness, pieChartColorMappings.sadness, "Negative", getEmotionThreshold(emotionThresholds, "sadness")],
		["Fear", fear, pieChartColorMappings.fear, "Negative", getEmotionThreshold(emotionThresholds, "fear")],
	];

	const emotions = labels.filter(d => d[1] >= d[4]);
	const sorted = emotions.slice().sort((a, b) => b[1] - a[1]);

	const sentiment = sorted.length ? (showText ? roundToTwo(sorted[0][1]) : sorted[0][3]) : "";

	const style = {
		height: "100%",
		display: "flex",
		alignItems: "center",
	};

	if (isSentiment) {
		return <div style={style}>{sentiment}</div>;
	}

	return <div style={style}>{emotions.map(d => d[0]).join(", ")}</div>;
};

export const EmotionSentiment = (showText, emotionThresholds) => {
	return [
		{
			text: "Sentiment",
			field: "*",
			id: "sentiment_col",
			label: "Sentiment",
			sort_field: "sentiment",
			only_desc: true,
			not_export: true,
			togglable: true,
			tooltip: "Sentiment",
			cellComponent: ({ value }) => getEmoSent(value, showText, emotionThresholds, true),
		},
		{
			text: "Emotion",
			field: "*",
			id: "emotion_col",
			not_sortable: true, // as per PREDASH-804
			sort_field: "emotion",
			not_export: true,
			togglable: true,
			cellComponent: ({ value }) => getEmoSent(value, showText, emotionThresholds, false),
		},
	];
};

export const VersionColumns = [
	{
		text: "-",
		field: "label",
	},
	{
		text: "Version",
		field: "version",
	},
];

export const ReturnFieldsColumns = [
	{
		text: "-",
		field: "label",
	},
	{
		text: "Source",
		field: "*",
		Component: ({ value }) => {
			return (
				<BigText title={value.label} text={value.source} />
			);
		}
	}
];

export const SettingColumns = [
	{
		text: "-",
		field: "label",
	},
	{
		text: "Threshold",
		field: "threshold",
		headerComponent: () => (
			<>
				<span>Threshold</span>
				<Hint tooltipText="Values will extend to the current project only." />
			</>
		),
		Component: ({ value, error }) => {
			return (
				<TextField
					error={error}
					type="number"
					value={value}
					size="small"
					disabled={error === undefined}
				/>
			);
		},
	},
];

export const getSinglePostStats = (platform, harmProfile, thresholds) => {
	const baseStats = [
		{
			label: "Risk",
			field: "*",
			render: ({ data }) => {
				const score = data.harm;
				let icon = null;
				let txt = "";

				if (harmProfile.id !== DEFAULT_PROFILE.id) {
					txt = <HarmDot />;
				} else if (score < harmFilter.options[0].num_threshold) {
					icon = <ThickIcon />;
					txt = "Low";
				} else if (score < harmFilter.options[1].num_threshold) {
					txt = "Medium";
				} else if (score < harmFilter.options[2].num_threshold) {
					icon = <AttentionIcon />;
					txt = "High";
				} else {
					icon = <AttentionIcon />;
					txt = "Very High";
				}

				return (
					<StylesProvider>
						{(classes) => (
							<div className={classes.justFlex}>
								{icon} <span className={classes.harmText}>{txt}</span>
							</div>
						)}
					</StylesProvider>
				);
			},
		},
		{
			field:
        platform === PLATFORMS.twitter ? "engagement" : "harmful_engagement",
			label: "Engagements",
			format: (val) => formatNumber(val),
		},
		{
			field: "*",
			label: "Emotion",
			render: ({ data }) => {
				const sent = getEmoSent(data, false, thresholds, true);
				const emo = getEmoSent(data, false, thresholds, false);

				return (
					<StylesProvider>
						{(classes) => (
							<div className={classes.justFlex}>
								<div className={classes.sent}>{sent}</div>
								{emo}
							</div>
						)}
					</StylesProvider>
				);
			},
		},
		{
			field: "*",
			label: "Cohorts",
			render: ({ data }) => { // TODO use CohortChip component here if possible

				const cohorts_found = data?.cohorts;

				return cohorts_found.length ? (
					<StylesProvider>
						{(classes) => (
							<div className={classes.justFlex}>
								<div className={classes.statBubble}>{
									resolveCohortDisplayName(cohorts_found[0])
								}</div>
								{cohorts_found.length > 1 && (
									<div className={classes.moreCohorts}>
                    +{cohorts_found.length - 1}
									</div>
								)}
							</div>
						)}
					</StylesProvider>
				) : null;
			},
		},
		{
			field: "*",
			label: "Logos",
			render: ({ data }) => {
				const allLogos = [];
				const logoStrings = data?.sample_posts?.[0]?.images?.[0]?.logos;

				if (logoStrings) {
					for (const logoString of logoStrings) {
						for (const logo of logoString.split(",")) {
							if (logo !== "") {
								allLogos.push(logo);
							}
						}
					}
				}

				const uniqueLogos = array.uniq(allLogos); 

				if (uniqueLogos.length > 0) {
					return (
						<StylesProvider>
							{(classes) => (
								<div className={classes.justFlex}>
									{uniqueLogos.map((logo, i) =>
										(<div key={i} className={classes.statBubble}>{logo}</div>)
									)}
								</div>
							)}
						</StylesProvider>
					);
				}
				else {
					return null;
				}
			},
		},
	];

	const twitterStats = [
		{
			label: "Partisanship",
			render: ({ data }) => {
				const score = data.partisanship;

				let txt = "Not Partisan";

				if (score <= -0.5) {
					txt = "Left";
				} else if (score >= 0.5) {
					txt = "Right";
				}

				return txt;
			},
		},
	];

	const redditStats = [
		{
			field: "comment_ct",
			label: "Comments",
			format: (val) => (isNaN(val) ? "-" : formatNumber(val)),
		},
		{
			field: "up_votes",
			label: "Upvotes",
			format: (val) => (isNaN(val) ? "-" : formatNumber(val)),
			render: ({ data, stat }) => {
				const up_votes = stat.format(data.up_votes);
				return <Box component={"span"}>
					<UpvotesIcon />{" "}{up_votes}
				</Box>;
			}
		},
		// {
		//   field: "down_votes",
		//   label: "Downvotes",
		//   format: (val) => (isNaN(val) ? "-" : formatNumber(val)),
		//   render: ({ data, stat }) => {
		//     const down_votes = stat.format(data.down_votes);
		//     return <Box component={"span"}>
		//       <DownvotesIcon />{" "}{down_votes}
		//     </Box>
		//   }
		// },
	];

	return [
		...baseStats,
		...(platform === PLATFORMS.twitter ? twitterStats : redditStats),
	];
};
