import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "./format";
import { ReactComponent as TwitterIcon } from "icons/social-media/Twitter-colored.svg";
import { ReactComponent as RedditIcon } from "icons/social-media/Reddit-colored.svg";
// import { ReactComponent as FacebookIcon } from "icons/social-media/facebook.svg";
// import { ReactComponent as InstagramIcon } from "icons/social-media/instagram.svg";
import langcodes from "./langcodes.json";

export const ACCEPTED_FILES = [".csv"];
export const DEFAULT_PROFILE = {id: 0, name: "Blackbird  default", isDefault: true};
export const DEFAULT_CLASSIFIER = {id: 0, name: "Blackbird  default", isDefault: true};
export const HARMFUL_ENGAGEMENT_POSTS_LABEL = "Engagements on High Risk Posts";
export const DEFAULT_SORT = "engagement";

export const LOCAL_STORAGE_KEYS = {
	PREVIEW_HARM_PROFILE_REF: "previewHarmProfileRef",
	PREVIEW_CLASSIFIER_PROFILE_REF: "previewClassifierProfileRef",
};
export const COHORT_TOOLTIPS = {
	"Anti-Vax": "Advocates against or expresses disapproval of vaccinations, including sharing misleading information or conspiracy theories.",
	"Anti-Masks": "Advocates against or expresses disapproval of wearing masks, including sharing misleading information or conspiracy theories.",
	"Boycott": "Advocates for or promotes boycott activities, such as the abstention from using or engaging with a given product, company, organization or person.",
	"FarRightExtremists": "Advocates for or promotes extremist right-wing entities or ideologies outside of mainstream discourse in a way that promotes divisive or high risk rhetoric or ideas.",
	"FarLeftExtremists": "Advocates for or promotes extremist left-wing entities or ideologies outside of mainstream discourse in a way that promotes divisive or high risk rhetoric or ideas.",
	"WhiteSupremacy": "Advocates for or promotes the spurious belief that White people are inherently superior to other races.",
	"Activist": "Advocates for or promotes activist activities or activist entities, including protests, strikes, marches, boycotts, or expressions of support for known activist organizations and public figures.",
	"LaborOrganizing": "Advocates or promotes labor organizing activities or entities, whereby workers typically unite as a single, representative entity to improve working conditions.",
	"Suicide":
    "Advocating for or expressing an inclination toward committing the act of suicide.",
	"SelfHarm": "Advocating for or expressing an inclination toward committing acts of nonsuicidal self-injury, such as self-mutilation, cutting or burning the skin, pulling out hair, etc., typically in response to emotional distress.",
	"AntiCorrpucionColombia": // typo intentional
    "Lega de Gobernantes Anticorrupción",
	"EquipoColombia": "Coalicion Equipo por Colombia",
	"EsperanzaColombia": "Coalicion Centro Esperanza",
	"LeftwingColombia": "Pacto Historico Por Colombia",

	// TODO The cohorts listed below no longer exist in staging ES.
	"Boogaloo": "Far-right anti-government extremist militant movement.",
	"Militants": "Aggressive behavior or attitudes involving armed insurgency.",
	"Antifa": "Conversations related to a protest movement affiliated by their militant opposition to fascism.",
	"Anti-USA": "High risk to America or American policy and/or citizens.",
	"CCP Propaganda": "Propaganda indicative of Chinese Communist Party.",
	"CCP State Media": "Chinese State Media outlet.",
	"Anti-CCP": "Anti-Chinese Community Party.",
	"Russian Propaganda": "Propaganda indicative of Russian Federation.",
	"Russian State Media": "Russian State Media outlet.",
	"Violence": "Bodily harm and death threats.",
	"Conspiracist": "Broad conspiracies and other pseudo-science related beliefs.",
	"Qanon": "Far-right conspiracists believing multiple conspiracy theories. Believe in a secret plan by the \"deep state.\"",
};

export const PARENT_USERS = "parent_users";

//tabs
export const dashboard_tabs = [
  { name: "Overview", active: true, path: "overview", basePath: "/dashboard" },
  { name: "Analyze", active: true, path: "analyze", basePath: "/dashboard" },
  { name: "Visualize", active: true, path: "visualize", basePath: "/dashboard" },
];

export const OVERVIEW_TAB = 0;
export const ANALYZE_TAB = 1;
export const VISUALIZE_TAB = 2;

export const builder_tabs = [
  { name: "Discover", active: true, path: "discover", basePath: "/dashboard" },
  { name: "Refine", active: true, path: "refine", basePath: "/dashboard" },
];

export const data_tabs = [
	{
		name: "Projects",
		active: true,
		path: "projects",
		basePath: "/manage-data"
	},
	// {
	// 	name: "Connections",
	// 	active: true,
	// 	path: "connections",
	// 	basePath: "/manage-data"
	// },
	// {
	// 	name: "Files",
	// 	active: true,
	// 	path: "files",
	// 	basePath: "/manage-data"
	// }
];

export const DISCOVER_TAB = 0;
export const REFINE_TAB = 1;
export const DATA_TAB = 2;

export const TAB_OPTIONS = [dashboard_tabs, builder_tabs, data_tabs];

export const DASHBOARD_TAB = 0;
export const NARRATIVE_BUILDER_TAB = 1;

export const ALL_PROJECT = "All";
export const ALL_PROJECT_INDEX = 0;

// display names for cohorts
export const COHORT_LABEL_MAPPINGS = {
	"Activist": "Activism",
	"Anti-Vax": "Anti-Vax",
	"Anti-Masks": "Anti-Mask",
	"Boycott": "Boycott",
	"FarRightExtremists": "Far Right Extremism",
	"FarLeftExtremists": "Far Left Extremism",
	"Union": "Labor Organizing",
	"AntiCorrpucionColombia": "Anticorrupción Colombia",
	"EquipoColombia": "Equipo Colombia",
	"EsperanzaColombia": "Esperanza Colombia",
	"LeftwingColombia": "Leftwing Colombia",
	"Suicide": "Suicide",
};

// display names for toxicity
export const TOXICITY_LABEL_MAPPINGS = {
	"anti_lgbtq": "Anti-LGBTQ",
	"anti_semitic": "Anti-Semitic",
	"anti_blackness": "Anti-Blackness"
};

// TODO we should use only nouns or only adjectives instead of mixing them.
export const EMOTION_MAPPINGS = {
	happiness: "Happy",
	anger: "Angry",
	disgust: "Disgust",
	sadness: "Sad",
	surprise: "Surprised",
	neutral: "Neutral",
	fear: "Fear",
};

export const EMOTIONS = Object.keys(EMOTION_MAPPINGS);

export const SENTIMENT_MAPPINGS = {
	positive_sentiment: "Positive",
	neutral_sentiment: "Neutral",
	negative_sentiment: "Negative",
};

export const SENTIMENTS = Object.keys(SENTIMENT_MAPPINGS);

// TODO this is now deprecated and it should be removed eventually.
// Also any code that checks this list is probably unnecessary.
// We might have to add these fields to BOOLEAN_FIELDS.
export const THRESHOLD_FIELDS = [
	...EMOTIONS,
	...SENTIMENTS,
	"bot_score",
	"bbmi",
	"toxicity",
	"pagerank",
	"min_bot_score",
	"min_bbmi",
	"min_toxicity",
	"min_pagerank",
];

export const BOOLEAN_FIELDS = {
	is_base: "is_base",
	is_orig: "is_orig",
	is_share: "is_share",
	skip_verified: "skip_verified",
	is_known_hoax: "is_known_hoax",
};
export const INVERTED_BOOLEANS = {
	is_base: "is_base",
	is_orig: "is_orig",
	is_share: "is_share",
	skip_verified: "skip_verified",
};
export const DEFAULT_NARRATIVE = {
	start_date: DEFAULT_START_DATE,
	end_date: DEFAULT_END_DATE,
	name: ALL_PROJECT,
	keywords: "*",
	post_filters: [],
	analyze_filters: {
		arr: [],
		obj: {},
		serialized: {},
	},
};

export const DEFAULT_TOPIC_FOLDER = "Default";
export const POST_COUNT_TEXT = "Post Count";
export const POST_COUNT_FIELD = "post_ct";
export const DESC_SORT_DIR = "DESC";
export const ASC_SORT_DIR = "ASC";
export const PLATFORMS = {
	// facebook: "facebook",
	// instagram: "instagram",
	reddit: "reddit",
	weber_reddit_csv: "reddit",
	brandwatch_reddit_csv: "reddit",
	twitter: "twitter",
	deepweb: "deepweb"
};
export const PLATFORM_BASE_URLS = {
	twitter: "https://twitter.com",
	reddit: "https://www.reddit.com",
	deepweb: "",
};
export const PLATFORM_USER_PATH = {
	twitter: "",
	reddit: "/user",
	deepweb: ""
};

export const LABELS_MAP = {
	"bbmi": "Anomalous",
	"BBMI": "Anomalous",
	"pagerank": "Influencer",
	"Pagerank": "Influencer",
	"fear": "Fear",
	"anger": "Angry",
	"disgust": "Disgust",
	"neutral": "Neutral",
	"sadness": "Sadness",
	"surprise": "Surprise",
	"happiness": "Happiness"
};

export const PERCENTAGE_POSTS_AI_METRICS_FLAGGED = [0, 15, 30, 100];

export const SORT_OPTIONS = {
	[PLATFORMS.twitter]: {
		[POST_COUNT_TEXT]: POST_COUNT_FIELD,
		Engagement: "engagement",
		[HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
		Shares: "share_ct",
		Likes: "like_ct",
		Risk: "harm",
		"Bot-like": "bot_score",
		"Right Partisan": "partisanship",
		"Left Partisan": "partisanship",
		"Not Partisan": "partisan_nonpartisan",
		Anomalous: "bbmi",
		"Positive Sentiment": "positive_sentiment",
		"Neutral Sentiment": "neutral_sentiment",
		"Negative Sentiment": "negative_sentiment",
		// Emotion: "emotion",
		Anger: "anger",
		Disgust: "disgust",
		Fear: "fear",
		Happiness: "happiness",
		Neutral: "neutral",
		Sadness: "sadness",
		Surprise: "surprise",
		Toxicity: "toxicity",
		"Original Post Count": "orig_post_ct",
	},
	[PLATFORMS.reddit]: {
		[POST_COUNT_TEXT]: POST_COUNT_FIELD,
		Engagement: "harmful_engagement",
		Risk: "harm",
		"Right Partisan": "partisanship",
		"Left Partisan": "partisanship",
		"Not Partisan": "partisan_nonpartisan",
		Anomalous: "bbmi",
		"Positive Sentiment": "positive_sentiment",
		"Neutral Sentiment": "neutral_sentiment",
		"Negative Sentiment": "negative_sentiment",
		// Emotion: "emotion",
		Anger: "anger",
		Disgust: "disgust",
		Fear: "fear",
		Happiness: "happiness",
		Neutral: "neutral",
		Sadness: "sadness",
		Surprise: "surprise",
		Toxicity: "toxicity",
	},
	[PLATFORMS.deepweb]: {
		[POST_COUNT_TEXT]: POST_COUNT_FIELD,
		Engagement: "engagement",
		[HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
		Shares: "share_ct",
		Likes: "like_ct",
		Risk: "harm",
		"Bot-like": "bot_score",
		"Right Partisan": "partisanship",
		"Left Partisan": "partisanship",
		"Not Partisan": "partisan_nonpartisan",
		Anomalous: "bbmi",
		"Positive Sentiment": "positive_sentiment",
		"Neutral Sentiment": "neutral_sentiment",
		"Negative Sentiment": "negative_sentiment",
		// Emotion: "emotion",
		Anger: "anger",
		Disgust: "disgust",
		Fear: "fear",
		Happiness: "happiness",
		Neutral: "neutral",
		Sadness: "sadness",
		Surprise: "surprise",
		Toxicity: "toxicity",
		"Original Post Count": "orig_post_ct",
	}
};

export const REVERSED_SORTS = ["Left Partisan"];
export const PARTISAN_SORT_AUTO_FILTERS = {
	"Left Partisan": ["left"],
	"Right Partisan": ["right"],
};

export const EXPLANATIONS = {
	harm: "harm",
	toxicity: "toxicity",
	anomalous: "anomalous",
};

export const ENTITY_TYPES = {
	cluster: "cluster",
	post: "post",
	user: "user",
	url: "url",
	hashtag: "hashtag",
	domain: "domain"
};

export const ENTITY_TYPES_MAP = {
	users: ENTITY_TYPES.user,
	hashtags: ENTITY_TYPES.hashtag,
	urls: ENTITY_TYPES.url,
	postsInCluster: ENTITY_TYPES.post,
	clusters: ENTITY_TYPES.cluster,
	domains: ENTITY_TYPES.domain
};

export const EXPLANATION_STYLES = {
	toxicity: {
		"font-weight": "bold",
		color: "#b135ff",
	},
	negative_emotion_anger: {
		"font-weight": "bold",
		color: "rgb(255, 162, 163)",
	},
	yellow: {
		"font-weight": "bold",
		color: "#F1BA7A",
	},
	white: {
		"font-weight": "bold",
		color: "#fff",
	},
	hoax: {
		"font-weight": "bold",
		color: "#e7394e",
	},
};
export const PROJECTS_PAGE_SIZE = 3;
export const FIELD_NAMES = {
	known_hoax: "known_hoax",
};

export const VALUE_FILTERS = {
	is_base: "is_base",
	is_orig: "is_orig",
	is_share: "is_share",
	skip_verified: "skip_verified",
	is_known_hoax: "is_known_hoax",
	partisanship: "partisanship",
	harm: "harm",
	is_harmful: "is_harmful",
};

export const BOOLEAN_GRAPHQL_FILTERS = {
	is_base: "is_base",
	is_orig: "is_orig",
	is_share: "is_share",
	skip_verified: "skip_verified",
	is_known_hoax: "is_known_hoax",
};

export const CACHE_AND_NETWORK = "cache-and-network";
export const KEY_ENTITY_TABS = {
	users: "Username",
	hashtags: "Hashtags",
	urls: "Urls",
	subreddits: "Subreddits",
	domains: "Domains"
};

export const PLATFORM_CONFIG = {
	twitter: {
		name: "Twitter",
		value: PLATFORMS.twitter,
		icon: TwitterIcon,
	},
	weber_reddit_csv: {
		name: "Reddit",
		value: PLATFORMS.reddit,
		icon: RedditIcon,
	},
	brandwatch_reddit_csv: {
		name: "Reddit",
		value: PLATFORMS.reddit,
		icon: RedditIcon,
	},
	reddit: {
		name: "Reddit",
		value: PLATFORMS.reddit,
		icon: RedditIcon,
	},
	deepweb: {
		name: "Deepweb",
		value: PLATFORMS.deepweb,
		icon: null
	}
	// instagram: {
	//   name: "Instagram",
	//   value: PLATFORMS.instagram,
	//   icon: InstagramIcon
	// },
	// facebook: {
	//   name: "Facebook",
	//   value: PLATFORMS.facebook,
	//   icon: FacebookIcon
	// }
};

export const EXPORT_ENABLED_ORG_IDS = process.env
	.REACT_APP_EXPORT_ENABLED_ORG_IDS
	? process.env.REACT_APP_EXPORT_ENABLED_ORG_IDS.split(",")
	: [];

export const QUERY_KEYS = {
	summary: "summary",
	posts: "posts",
	users: "users",
	hashtags: "hashtags",
	urls: "urls",
	activity: "activity",
	concepts: "concepts",
	impact: "impact",
	impactSummary: "impactSummary",
	postsInCluster: "postsInCluster",
	clusters: "clusters",
	hoax: "hoax",
	subreddits: "subreddits",
	field_entries: "field_entries",
	group_labels: "group_labels",
	ai_narratives: "ai_narratives",
	domains: "domain",
	projects: "projects",
	parents: "parents"
};

export const LEAD_CATEGORIES = {
	PHRASE: "Phrase",
	HASHTAG: "Hashtag",
};
export const INVOLVED_PARTIES = {
	"USER MENTIONS": "User Mentions",
	PERSON: "Person",
	ORGANIZATION: "Organization",
	TITLE: "Title",
};
export const CONTEXT_DRIVERS = {
	IDEOLOGY: "Ideology",
	RELIGION: "Religion",
	// "CAUSE OF DEATH": "Cause of Death",
	// "CRIMINAL CHARGE": "Criminal Charge",
	MISC: "Misc",
	URL: "Urls",
};
export const PLACE = {
	NATIONALITY: "Nationality",
	LOCATION: "Location",
};
export const PROXIMITY_TO_EVENT = {
	DURATION: "Duration",
	DATE: "Date",
	TIME: "Time",
	SET: "Set",
};

export const VISUALIZE_CONCEPTS = {
	...INVOLVED_PARTIES,
	...LEAD_CATEGORIES,
	...CONTEXT_DRIVERS,
	...PLACE,
};

export const CONCEPTS = [
	"PHRASE",
	"HASHTAG",
	"USER MENTIONS",
	"PERSON",
	"ORGANIZATION",
	"TITLE",
	"IDEOLOGY",
	"RELIGION",
	"CAUSE OF DEATH",
	"CRIMINAL CHARGE",
	"MISC",
	"URL",
	"NATIONALITY",
	"LOCATION",
	"DURATION",
	"DATE",
	"TIME",
	"SET",
];

export const tagMap = {
	Phrase: "Phrase",
	Hashtag: "Hashtag",
	Person: "Person",
	"User Mentions": "User Mentions",
	"Criminal Charge": "Criminal Charge",
	"Cause of Dealth": "CoD",
	"Cause of Death": "CoD",
	Location: "LOC",
	Organization: "ORG",
	Title: "Title",
	Duration: "Duration",
	Ideology: "Ideology",
	Nationality: "Nationality",
	Religion: "Religion",
	Misc: "Misc",
	Date: "Date",
	Time: "Time",
	Set: "Set",
	URLs: "URL",
	Url: "URL",
	Urls: "URL",
};

export const prod_env = "production";
export const staging_env = "staging";
export const dev_env = "development";
export const latest_env = "latest";

export const DEFAULT_INCLUDE_QUERY = [
	{
		"field": "is_manipulated",
		"operator": "and",
		"value": true,
		"min": 0,
		"max": 100
	}
];

export const FILTER_TARGETS = {
	narrative_builder: "narrative_builder",
	overview: "overview",
	analyze: "analyze"
};

export const LANGUAGES = {
	...langcodes,
	unknown: {
		name: "Unknown",
		emoji: "❓"
	}
};

export const LANGUAGES_LIMIT = 0.002;

export const EXCLUDED_COHORTS = [
	"DescriptiveContent"
];

export const POST_CHAR_LIMIT = 400;

export const SAVED_FILTERS_FIELD = "VISIBLE_FILTERS";
export const SAVED_METRICS_FIELD = "VISIBLE_METRICS";
export const SAVED_PAGE_SIZE_FIELD = "PAGE_SIZE";
export const AI_ENHANCED_FIELD = false;

const REDDIT_RELEASE_DATE = new Date(2005, 5, 23);
const TWITTER_RELEASE_DATE = new Date(2006, 2, 21);

export const MIN_DATES = {
	[PLATFORMS.reddit]: REDDIT_RELEASE_DATE,
	[PLATFORMS.twitter]: TWITTER_RELEASE_DATE,
}; 

export const SAVED_COLUMNS_FIELD = "VISIBLE_COLUMNS";

const aiNarOptions = new Map([
	{ value: "orig_post_ct", label: "Original posts", sortField: "orig_post_ct", type: "count" },
	{ value: "post_ct", label: POST_COUNT_TEXT, type: "count", sortField: "post_ct" },
  { value: "engagement", label: "Engagement", type: "count", sortField: "engagement" },
  { value: "harmful_engagement", label: "Risky Engagements", type: "count", sortField: "harmful_engagement" },
	{ value: "share_ct", label: "Shares", type: "count", sortField: "share_ct" },
  { value: "like_ct", label: "Likes", type: "count", sortField: "like_ct" },
  { value: "is_harmful", label: "Risk", sortField: "harm" },
	{ value: "is_manipulated", label: "Anomalous", sortField: "bbmi" },
	{ value: "is_bot_avg", label: "Bot-like", sortField: "bot_score" },
	{ value: "is_toxic_avg", label: "Toxic", sortField: "toxicity" },
	{ value: "is_influential", label: "Influential", sortField: "is_influential"  },
  { value: "is_partisan_right_avg", label: "Right Partisan", sortField: "partisanship" },
  { value: "is_partisan_left_avg", label: "Left Partisan", sortField: "partisanship" },
  { value: "is_non_partisan_avg", label: "Not Partisan", sortField: "partisan_nonpartisan" },
	{ value: "is_positive_avg", label: "Positive Sentiment", sortField: "positive_sentiment" },
  { value: "is_neutral_sentiment_avg", label: "Neutral Sentiment", sortField: "neutral_sentiment" },
	{ value: "is_negative_avg", label: "Negative Sentiment", sortField: "negative_sentiment" },
	{ value: "is_anger_avg", label: "Anger", sortField: "anger" },
	{ value: "is_disgust_avg", label: "Disgust", sortField: "disgust" },
	{ value: "is_fear_avg", label: "Fear", sortField: "fear" },
	{ value: "is_happiness_avg", label: "Happiness", sortField: "happiness" },
  { value: "is_neutral_emotion_avg", label: "Neutral", sortField: "neutral" },
	{ value: "is_sadness_avg", label: "Sadness", sortField: "sadness" },
	{ value: "is_surprise_avg", label: "Surprise", sortField: "surprise" },
].map(d => [d.value, d]));

const makeGroup = (options, groupName) => {
	return options.map((d, i) => ({ ...d, groupName, showGroup: i === 0 }));
}

const volume_metrics = makeGroup([
	aiNarOptions.get("orig_post_ct"),
	aiNarOptions.get("post_ct"),
	aiNarOptions.get("engagement"),
	aiNarOptions.get("harmful_engagement"),
	aiNarOptions.get("share_ct"),
	aiNarOptions.get("like_ct"),
], "VOLUME");

const risk_signals_metrics = makeGroup([
	aiNarOptions.get("is_harmful"),
	aiNarOptions.get("is_manipulated"),
	aiNarOptions.get("is_bot_avg"),
	aiNarOptions.get("is_toxic_avg"),
	aiNarOptions.get("is_influential"),
	aiNarOptions.get("is_negative_avg"),
], "RISK SIGNALS");

const partisanship_metrics = makeGroup([
	aiNarOptions.get("is_partisan_right_avg"),
	aiNarOptions.get("is_partisan_left_avg"),
	aiNarOptions.get("is_non_partisan_avg"),
], "PARTISANSHIP");

const sentiment_metrics = makeGroup([
	aiNarOptions.get("is_positive_avg"),
	aiNarOptions.get("is_negative_avg"),
	aiNarOptions.get("is_neutral_sentiment_avg"),
], "SENTIMENT");

export const getAiNarMetrics = ({ cohorts, emotions }) => {
	const emotion_metrics = emotions.map((d, i) => ({
		groupName: "EMOTION",
		showGroup: i === 0,
		value: "is_" + d.fieldName + "_avg",
		label: d.displayName,
	}));

	const cohort_metrics = cohorts.map((d, i) => ({
		groupName: "COHORTS",
		showGroup: i === 0,
		value: d.fullFieldName,
		label: d.displayName,
	}));

	return {
		all_options: new Map([
			...aiNarOptions,
			...emotion_metrics.map(d => [d.value, d]),
			...cohort_metrics.map(d => [d.value, d]),
		]),
		x_axis: [
			...volume_metrics,
			...risk_signals_metrics,
			...partisanship_metrics,
			...sentiment_metrics,
			...emotion_metrics,
			...cohort_metrics,
		],
		y_axis: [
			...risk_signals_metrics,
			...volume_metrics,
			...partisanship_metrics,
			...sentiment_metrics,
			...emotion_metrics,
			...cohort_metrics
		],
		node_size: [
			...volume_metrics,
			...risk_signals_metrics,
			...partisanship_metrics.filter(d => d.value !== "is_non_partisan_avg"),
			...sentiment_metrics.filter(d => d.value !== "is_neutral_sentiment_avg"),
			...emotion_metrics,
			...cohort_metrics,
		],
		node_color: [
			...risk_signals_metrics,
			...volume_metrics,
			...partisanship_metrics.filter(d => d.value !== "is_non_partisan_avg"),
			...sentiment_metrics.filter(d => d.value !== "is_neutral_sentiment_avg"),
			...emotion_metrics,
			...cohort_metrics
		],
		limit_by: [
			...volume_metrics,
			...risk_signals_metrics,
			...partisanship_metrics,
			...sentiment_metrics,
			...emotion_metrics,
			// ...cohort_metrics
		]
	};
};