import {
  Button,
  Typography,
  Drawer,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InputValidate from "components/UI/InputValidate";
import { ReactComponent as XIcon } from "icons/UI/x.svg";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import QueryBuilder from "components/UI/QueryBuilder";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import CloseIcon from "@mui/icons-material/Close";
import {
  COHORT_LABEL_MAPPINGS,
  DEFAULT_INCLUDE_QUERY,
  LOCAL_STORAGE_KEYS,
  PLATFORMS,
  TOXICITY_LABEL_MAPPINGS,
} from "utils/constants";
import {
  createHarmProfile,
  setHarmProfile,
  updateHarmProfile,
} from "api/AnalyticsApi";
import { resolveCohortDisplayName } from "utils/cohorts";
import { removeHarmFromFilters } from "context/reducerUtilities";
import useEventTracker from "api/hooks/useEventTracker";
import { useParams } from "react-router";

const WIDTH = 500;

const getMetrics = (cohortNames, toxicitySubcategoryThresholds, platform) => {
  return [
    {
      value: "is_manipulated",
      label: "Anomalous",
      groupName: "Risk signals",
      showGroup: true,
    },
    ...(platform === PLATFORMS.reddit ? [] : [{ value: "is_bot", label: "Bot-Like", groupName: "Risk signals" }]),
    { value: "is_toxic", label: "Toxic", groupName: "Risk signals" },
    {
      value: "is_anger",
      label: "Angry",
      groupName: "Emotion",
      showGroup: true,
    },
    { value: "is_disgust", label: "Disgust", groupName: "Emotion" },
    { value: "is_sadness", label: "Sad", groupName: "Emotion" },
    { value: "is_fear", label: "Fear", groupName: "Emotion" },
    { value: "is_happiness", label: "Happy", groupName: "Emotion" },
    { value: "is_surprised", label: "Surprised", groupName: "Emotion" },
    {
      value: "is_positive",
      label: "Positive",
      groupName: "Sentiment",
      showGroup: true,
    },

    {
      value: "is_neutral",
      label: "Neutral",
      groupName: "Sentiment",
    },

    {
      value: "is_negative",
      label: "Negative",
      groupName: "Sentiment",
    },
    {
      value: "is_partisan_left",
      label: "Left partisan",
      groupName: "Partisanship",
      showGroup: true,
    },
    {
      value: "is_partisan_right",
      label: "Right partisan",
      groupName: "Partisanship",
    },
    {
      value: "is_partisan_nonpartisan",
      label: "Nonpartisan",
      groupName: "Partisanship",
    },
    ...(cohortNames || []).map((cohort, i) => {
      const displayName =
        COHORT_LABEL_MAPPINGS[cohort] || resolveCohortDisplayName(cohort);
      return {
        label: displayName,
        value: "is_" + cohort.toLowerCase(),
        groupName: "Cohorts",
        showGroup: i === 0,
      };
    }),
    ...(toxicitySubcategoryThresholds || [])
      .filter((t) => t !== "toxic")
      .map((toxicityThreshold, i) => {
        const toxicityTitle =
          TOXICITY_LABEL_MAPPINGS[toxicityThreshold] ||
          convertCamelToTitleCase(toxicityThreshold);
        return {
          label: toxicityTitle,
          value: toxicityThreshold.toLowerCase(),
          groupName: "Toxicity",
          showGroup: i === 0,
        };
      }),
  ];
};

const convertCamelToTitleCase = (camelString) => {
  return camelString
    .split("_")
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
};

const useStyles = makeStyles((theme) => ({
  root: {},
  btn: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 999999,
    flexShrink: 0,
    width: WIDTH,
    height: "100vh",
  },
  drawerPaper: {
    width: WIDTH,
    background: theme.palette.background.$2,
    border: "1px solid " + theme.palette.border.popup,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(80px)",
    borderRadius: "6px 0px 0px 6px",
  },
  header: {
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 20px 20px 15px",
  },
  backButton: {
    padding: 0,
    width: "34px",
    height: "34px",
    marginRight: "16px",
  },
  alert: {
    padding: theme.spacing(2, 4),
  },
  content: {
    padding: theme.spacing(2, 4),
    flexGrow: 1,
    overflowY: "auto",
  },
  queryBuilder: {},
  closeBtn: {
    backgroundColor: "#2C293A",
    color: "rgba(195, 194, 211, 0.8)",
    "&:hover": {
      backgroundColor: "#2C293A",
    },
  },
  btns: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: 17,
    "&>button": {
      minWidth: "25%",
    },
  },
  divider: {
    borderColor: "rgba(99, 96, 123, 0.2)",
  },
  nameAndButtons: {
    // display: "flex",
    // alignItems: "flex-start",
    padding: theme.spacing(2, 4),
  },
  previewSnack: {
    background: "#272438",
    borderWidth: "1px 1px 1px 6px",
    borderStyle: "solid",
    borderColor: "#3A364D #3A364D #3A364D #81DFA3",
    padding: 30,
    borderRadius: 6,
    minWidth: 550,
  },
  preview: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formLabel: { fontSize: 14, fontWeight: "normal" },
}));

export default function HarmDrawer() {
	const classes = useStyles();
	const tracker = useEventTracker();

	const {
		dispatch,
		state: {
      platform,
			harmDrawerOpen,
			harmProfileEdit,
			harmProfiles,
			harmProfile,
			cohortNames,
			narrative,
      projectSettings,
		},
	} = useAppContext();
	const narrativeIndex = parseInt(useParams().narrativeId) || 0;

  const [previewModeOn, setPreviewModeOn] = useState(false);
  const [apply, setApply] = useState(false);
  const [name, setName] = useState(harmProfileEdit.name || "");
  const [includeQuery, setIncludeQuery] = useState(
    harmProfileEdit.includeQuery || DEFAULT_INCLUDE_QUERY
  );
  const [profileExists, setProfileExists] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isQueryBuilderEmpty, setIsQueryBuilderEmpty] = useState(false);

  const isDisabled = harmProfileEdit.isGlobal;
  const metricOptions = getMetrics(
    cohortNames,
    Object.keys(projectSettings.toxicitySubcategoryThresholds || {}),
    platform,
  );

  useEffect(() => {
    setName(harmProfileEdit.name || "");
    setIncludeQuery(harmProfileEdit.includeQuery || DEFAULT_INCLUDE_QUERY);
    setApply(harmProfileEdit.id === harmProfile.id);
    setProfileExists(false);
    setIsEmpty(false);
  }, [harmProfileEdit, harmProfile]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleApplyCheck = (e) => {
    setApply(e.target.checked);
  };

  const handleClose = () => {
    dispatch({
      type: ACTIONS.SET_HARM_DRAWER_OPEN,
      payload: false,
    });
  };

  // const handlePreview = () => {
  //   if (name.trim() === "") return setIsEmpty(true);
  //   if (includeQuery.length === 0 && excludeQuery.length === 0) {
  //     return setIsQueryBuilderEmpty(true);
  //   }
  //   setIsQueryBuilderEmpty(false);
  //   setPreviewModeOn(true);
  //   dispatch({
  //     type: ACTIONS.SET_HARM_PROFILE,
  //     payload: {
  //       id: harmProfileEdit.id,
  //       name,
  //       includeQuery,
  //       excludeQuery,
  //       isDefault: false,
  //     },
  //   });
  //   handleClose();
  // };

  const showMessage = (msg, type) => {
    dispatch({
      type: ACTIONS.SHOW_MESSAGE,
      payload: msg
        ? {
            message: msg,
            type: type,
          }
        : null,
    });
  };

  const closeSnackBar = () => {
    setPreviewModeOn(false);
    dispatch({
      type: ACTIONS.SET_HARM_PROFILE,
      payload: harmProfiles[0],
    });
  };

  const resetHarm = () => {
    const analyze_filters = removeHarmFromFilters(narrative.analyze_filters);
    dispatch({
      type: ACTIONS.UPDATE_NARRATIVE,
      payload: {
        narrative: {
          ...narrative,
          analyze_filters,
        },
        index: narrativeIndex,
      },
    });
  };

  const handleEdit = () => {
    dispatch({
      type: ACTIONS.EDIT_HARM_PROFILE,
      payload: { ...harmProfile },
    });
    setPreviewModeOn(false);
  };

  const handleProfileChange = async (p) => {
    if (p && p.id !== harmProfile.id) {
      const setHarmProfileRes = await setHarmProfile(p.isDefault ? 0 : p.id);
      if (setHarmProfileRes) {
        await localStorage.setItem(
          LOCAL_STORAGE_KEYS.PREVIEW_HARM_PROFILE_REF,
          p.id
        );
        dispatch({
          type: ACTIONS.SET_USER,
          payload: {
            ...setHarmProfileRes,
          },
        });
        resetHarm();
      }
    }
  };

  const handleSave = async () => {
    if (name.trim() === "") return setIsEmpty(true);
    if (includeQuery.length === 0) {
      return setIsQueryBuilderEmpty(true);
    }

    setIsQueryBuilderEmpty(false);

    if (harmProfileEdit.id) {
      const newProfile = {
        id: harmProfileEdit.id,
        name,
        includeQuery,
        excludeQuery: [],
      };

      const updateRes = await updateHarmProfile(newProfile);

        if (updateRes) {
            dispatch({
                type: ACTIONS.UPDATE_HARM_PROFILE,
                payload: {
                    ...updateRes.data.attributes,
                    id: updateRes.data.id,
                },
            });

        tracker.track(
            "Saved risk profile",
            "save",
            newProfile.name,
            "Custom Risk Profile"
        );

        showMessage("Risk profile updated", "success");
        handleClose();
        setPreviewModeOn(false);

        if (harmProfile.id === harmProfileEdit.id) {
          dispatch({
            type: ACTIONS.SET_HARM_PROFILE,
            payload: newProfile,
          });
        }

        if (apply) {
          handleProfileChange(newProfile);
        }
      }
    } else {
      const exists = harmProfiles.some((d) => d.name === name);
      setProfileExists(exists);

			if (!exists) {
				const createRes = await createHarmProfile({
					name,
					includeQuery,
					excludeQuery: [],
					isDefault: false,
				});
				if (createRes) {
					dispatch({
						type: ACTIONS.ADD_HARM_PROFILE,
						payload: {
							...createRes,
						},
					});
					tracker.track(
						"Created new risk profile",
						"add",
						"",
						"Custom Risk Profile"
					);
					showMessage("Risk profile created", "success");
					handleClose();
					setPreviewModeOn(false);
				}
				if (apply) {
					handleProfileChange(createRes);
				}
			}
		}
	};

	const handleIncludeQueryChange = (query, key, value) => {
		setIncludeQuery(query);
		setIsQueryBuilderEmpty(false);

		if (key === "select") {
			tracker.track(
				"Added risk profile boolean",
				"select",
				value
			);
		} else if (key === "remove") {
			tracker.track(
				"Removed risk profile boolean",
				"remove",
				value
			)
		}
	};

  const handleCloseGlobal = () => {
    if (apply && harmProfileEdit.isGlobal) {
      handleProfileChange(harmProfileEdit);
    }

    handleClose();
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={"right"}
        open={harmDrawerOpen}
        onClose={handleClose}
      >
        <div className={classes.headerTitle}>
          <IconButton className={classes.backButton} onClick={handleClose}>
            <XIcon />
          </IconButton>
          <Typography className={classes.header}>
            {harmProfileEdit.name || "Create a risk profile"}
          </Typography>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.alert}>
          <Alert severity="warning" variant="filled">
            Risk profiles use boolean logic and can configured to override your
            default Risk settings.
          </Alert>
        </div>
        <div className={classes.content}>
          <div className={classes.queryBuilder}>
            <QueryBuilder
              title={"Build your risk profile boolean"}
              value={includeQuery}
              metricOptions={metricOptions}
              onChange={handleIncludeQueryChange}
              showError={isQueryBuilderEmpty}
              disabled={isDisabled}
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <Box className={classes.nameAndButtons}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" sx={{ pb: 0.5 }}>
              Name your risk profile
            </Typography>
            <InputValidate
              value={name}
              showError={isEmpty}
              onChange={handleNameChange}
              exists={profileExists}
              emptyWarning={"Name your configuration"}
              existsWarning={`${name} already exists.`}
              placeholder={"e.g. Toxic bots"}
              disabled={isDisabled}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={apply}
                onChange={handleApplyCheck}
                name="gilad"
              />
            }
            classes={{ label: classes.formLabel }}
            label="Preview this profile in current project"
          />
          <div className={classes.btns}>
            {/* <Button
              color="secondary"
              variant="contained"
              className={classes.closeBtn}
              disableElevation
              onClick={handlePreview}
            >
              Preview
            </Button> */}
            {harmProfileEdit.isGlobal ? (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleCloseGlobal}
              >
                Close
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSave}
              >
                Save
              </Button>
            )}
          </div>
        </Box>
      </Drawer>
      <Snackbar
        open={previewModeOn}
        autoHideDuration={null}
        message={""}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.previewSnack}
      >
        <div className={classes.preview}>
          <div>
            <Typography variant="subtitle1">
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackBar}
                sx={{ mr: 1 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              PREVIEW MODE: {name}
            </Typography>
          </div>
          <div>
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={handleEdit}
            >
              Edit
            </Button>
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Snackbar>
    </div>
  );
}
