import ExplanationDrawer from "../Explanation/ExplanationDrawer";
import HarmDrawer from "./HarmDrawer";
import UsersGroupDrawer from "./UsersGroupDrawer";
import { useFlags } from "launchdarkly-react-client-sdk";
import HarmClassifierDrawer from "./HarmClassifierDrawer";
import { useAppContext } from "context/Context";

export default function Drawers() {
	const flags = useFlags();

	const {
		state: { harmDrawerOpen, userGroupDrawerOpen, harmClassifierOpen, explanationDrawerOpen },
	} = useAppContext();

	return (
		<>
			{flags.isBlackBirdEmployee && explanationDrawerOpen && <ExplanationDrawer />}
			{harmClassifierOpen && <HarmClassifierDrawer />}
			{harmDrawerOpen && <HarmDrawer />}
			{userGroupDrawerOpen && <UsersGroupDrawer />}
		</>
	);
}
