import { COHORT_LABEL_MAPPINGS } from "utils/constants";
import { refineDisplayName } from "./format";


export function resolveCohortDisplayName(cohort) {
  const displayName = COHORT_LABEL_MAPPINGS[cohort];

  if (displayName) {
    return displayName;
  } else {
    return refineDisplayName(cohort);
  }
}
