import { useEffect, useMemo, useState } from "react";
import { Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SummaryItem from "./summaryItem";
import ChildSummary from "./childSummary";
import { ReactComponent as ArrowIcon } from "icons/Union.svg";
import {
	totalPosts,
	totalEngagements,
	totalUsers,
	emotions,
	sentiments,
	totalUsersReturnField,
} from "api/summaryReturnFields";
import { loadSummary, loadSummaryUserGroups } from "api/AnalyticsApi";
import { getSummaries } from "./summaryConfig";
import { useAppContext } from "context/Context";
import useApi from "api/hooks/useApi";
import { QUERY_KEYS } from "utils/constants";
import useEventTracker from "api/hooks/useEventTracker";
import useTabs from "hooks/useTabs";

const MIN_WIDTH = 190;
const MAX_WIDTH = 300;

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: "#151221",
		overflow: "auto",
		display: "flex",
		borderBottom: (props) =>
			props.open ? "1px solid " + theme.palette.border.section : null,
	},
	content: {
		flexGrow: 1,
	},
	item: {
		flex: "1 1 0px",
		maxWidth: MAX_WIDTH,
		minWidth: MIN_WIDTH,
		height: "100%",
		padding: theme.spacing(0),
	},
	summaryItems: {
		width: "100%",
		alignItems: "flex-start",
		display: "flex",
		borderBottom: "1px solid " + theme.palette.border.section,
	},
	row: {
		minWidth: "30%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		backgroundColor: theme.palette.widget.main,
		height: "75px",
		flexGrow: 1,
	},
	toggler: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "76px",
		borderBottom: "1px solid " + theme.palette.border.section,
		[theme.breakpoints.down("xl")]: {
			maxWidth: 100,
		},
	},
	togglerBtn: {
		width: "100%",
		color: "rgba(255,255,255,0.44)",
		fontWeight: "normal",
		fontSize: 12,
	},
	arrowUp: {
		marginRight: 8,
		paddingTop: 4,
		transform: "rotate(-90deg)",
	},
	arrowDown: {
		marginRight: 8,
		paddingTop: 4,
		transform: "rotate(90deg)",
	},
	details: {
		width: "100%",
		maxHeight: "50vh",
		overflowY: "auto",
		alignItems: "flex-start",
		display: "flex",
	},
	detail: {
		flex: "1 1 0px",
		minWidth: MIN_WIDTH,
		maxWidth: MAX_WIDTH,
		borderRight: "1px solid " + theme.palette.border.section,
		height: "100%",
		padding: theme.spacing(0),
		"&:nth-child(even)": {
			backgroundColor: "rgba(48, 45, 65, 0.08)",
		},
		"&:last-child": {
			borderRight: "none",
		},
	},
}));

export default function Summary(props) {
	const [detailsOn, setDetailsOn] = useState(false);
	const [details, setDetails] = useState({});
	const tracker = useEventTracker();
	const {topTab} = useTabs();

	const {
		state: {
			analyzeSearch,
			narrative,
			start_date,
			end_date,
			platform,
			harmProfile,
			harmClassifier,
			userGroups,
		},
	} = useAppContext();

	const analyzeFiltersObj = useMemo(
		() =>
			narrative.analyze_filters ? narrative.analyze_filters.serialized : {},
		[narrative.analyze_filters]
	);

	const toggleDetails = () => {
		tracker.track(
			`Expanded detailed stats [${detailsOn ? "hide" : "show"}]`,
			detailsOn ? "Collapse" : "Expand",
			detailsOn ? "Collapse" : "Expand",
			"Detailed stats widget"
		);
		setDetailsOn(!detailsOn);
	};

	const payloadMemo = useMemo(() => {
		return {
			db: narrative.db,
			req: {
				...analyzeSearch,
				...analyzeFiltersObj,
				topic: narrative.name,
				start_date,
				end_date,
				platform,
				harmProfile,
				harmClassifier,
			},
		};
	}, [
		harmProfile,
		harmClassifier,
		narrative.db,
		narrative.name,
		start_date,
		end_date,
		analyzeSearch,
		analyzeFiltersObj,
		platform,
	]);

	const { data: cohortDetails } = useApi({
		apiKey: QUERY_KEYS.summary,
		apiFn: loadSummary,
		payload: payloadMemo,
	});

	// Detailed stats if open
	const detailedMemo = useMemo(() => {
		if (!detailsOn) return { db: null }; // this will skip the request

		const allReturnFields = [
			totalPosts(platform),
			totalEngagements(platform),
			totalUsers(platform),
			emotions,
			sentiments,
		];

		return {
			db: narrative.db,
			req: {
				// instead of Promise.all, what if we unite returnFields like this?
				returnFields: allReturnFields.flatMap((d) => d),
				latestKeywords: narrative.keywords,
				...analyzeSearch,
				...analyzeFiltersObj,
				topic: narrative.name,
				start_date,
				end_date,
				platform,
				harmProfile,
				harmClassifier,
			},
		};
	}, [
		harmProfile,
		harmClassifier,
		detailsOn,
		narrative.keywords,
		narrative.db,
		narrative.name,
		start_date,
		end_date,
		analyzeSearch,
		analyzeFiltersObj,
		platform,
	]);

	const { data: allDetails, isLoading: loading } = useApi({
		apiKey: QUERY_KEYS.summary,
		apiFn: loadSummary,
		payload: detailedMemo,
	});

	const userGroupNames = useMemo(() => {
		if (analyzeFiltersObj.user_group_names) {
			return analyzeFiltersObj.user_group_names;
		}
		return userGroups.map((d) => d.name);
	}, [userGroups, analyzeFiltersObj.user_group_names]);

	const detailsByUGMemo = useMemo(() => {
		return {
			db: !detailsOn || userGroupNames.length === 0 ? null : narrative.db,
			requests: userGroupNames.map((group) => ({
				returnFields: totalUsersReturnField,
				latestKeywords: narrative.keywords,
				...analyzeSearch,
				...analyzeFiltersObj,
				topic: narrative.name,
				start_date,
				end_date,
				platform,
				harmProfile,
				harmClassifier,
				user_group_names: [group],
			})),
		};
	}, [
		detailsOn,
		platform,
		start_date,
		end_date,
		harmProfile,
		harmClassifier,
		narrative.db,
		narrative.name,
		narrative.keywords,
		analyzeSearch,
		analyzeFiltersObj,
		userGroupNames,
	]);

	const { data: detailsByUG } = useApi({
		apiKey: QUERY_KEYS.summary + "user_groups",
		apiFn: loadSummaryUserGroups,
		payload: detailsByUGMemo,
	});

	// End of Detailed stats

	let summaries = getSummaries(
		props.summaryData,
		details,
		loading,
		platform,
		userGroupNames
	);

	const classes = useStyles({
		n: Math.max(...summaries.map((d) => d.children.length)),
		open: detailsOn,
	});

	useEffect(() => {
		let newDetails = {};

		if (cohortDetails) {
			newDetails = { ...newDetails, ...cohortDetails[0] };
		}

		if (detailsByUG) {
			newDetails = { ...newDetails, ...detailsByUG };
		}

		if (allDetails && detailedMemo.req) {
			detailedMemo.req.returnFields.forEach(({ field_name, return_name }) => {
				newDetails[field_name] = allDetails[0][return_name];
			});
		}

		setDetails(newDetails);
	}, [cohortDetails, detailsByUG, allDetails, detailedMemo]);

	useEffect(() => {
		setDetailsOn(false);
	}, [topTab]);

	const icon = detailsOn ? (
		<span className={classes.arrowUp}>
			<ArrowIcon />
		</span>
	) : (
		<span className={classes.arrowDown}>
			<ArrowIcon />
		</span>
	);

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Grid
					container
					alignItems="center"
					wrap="nowrap"
					className={classes.summaryItems}
				>
					{summaries.map((s, i) => (
						<Grid item key={`root-stat-${i}`} className={classes.item}>
							<SummaryItem {...s} isLast={i === summaries.length - 1} />
						</Grid>
					))}
				</Grid>

				{detailsOn && (
					<div className={classes.details}>
						{summaries.map((s, i) => (
							<div key={"key-" + i} className={classes.detail}>
								{(s.children || []).map((child, j) => {
									return <ChildSummary {...child} key={j} loading={loading} />;
								})}
							</div>
						))}
					</div>
				)}
			</div>
			<div className={classes.toggler}>
				<Button
					className={classes.togglerBtn}
					color="inherit"
					onClick={toggleDetails}
					endIcon={icon}
				>
					{detailsOn ? "Minimize Stats Widget" : "Show Detailed Stats"}
				</Button>
			</div>
		</div>
	);
}
