import { Divider, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatNumberkANDcomma, getPercentStr } from "utils/format";
import { InputLabel } from "@mui/material";
import { useState, useEffect } from "react";
import {
  getOrganizationPostCountByPlatform,
  getOrganizationPostCountCap,
} from "api/AnalyticsApi";
import { useAppContext } from "context/Context";
import { LANGUAGES, PLATFORMS, PLATFORM_CONFIG } from "utils/constants";
import { rollups, sum } from "d3-array";
import { ReactComponent as DocumentIcon } from "icons/UI/doc-count.svg";
import { ReactComponent as EnterArrowIcon } from "icons/EnterArrow.svg";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  label: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    transform: "translate(0, 0) scale(1)",
  },
  count: {
    color: "#76748D",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  lighter: {
    color: "#fff",
  },
  progressBarHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  progressBar: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  progressBarRoot: {
    backgroundColor: "#312D46",
    height: 21,
    borderRadius: 3,
  },
  spaceTop: {
    marginTop: theme.spacing(1),
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "28px",
    marginBottom: 8,
    color: "#E8E9F3",
  },
  name: {
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "28px",
    color: "#E8E9F3",
    "& svg": {
      marginRight: 12,
    },
  },
  value: {
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "28px",
    color: "rgba(232, 233, 243, 0.55)",
    "& svg": {
      width: 12,
      height: 15,
      marginRight: 6,
      transform: "translateY(-2px)",
    },
  },
  fakeIcon: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
  languageIcon: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 36,
    "& svg": {
      marginRight: 8,
    },
  },
  countryEmoji: {
    fontSize: "1.5rem",
    marginRight: 8,
  },
}));

export default function DocumentsTab() {
  const classes = useStyles();

  const [postCount, setPostCount] = useState({});
  const [postCountCap, setPostCountCap] = useState(1500000);

  const {
    state: { platform, languages },
  } = useAppContext();

  const flags = useFlags();

  useEffect(() => {
    const getOrgCount = async () => {
      const organizationPostCount = await getOrganizationPostCountByPlatform();
      setPostCount(organizationPostCount);
      const organizationPostCountCap = await getOrganizationPostCountCap();
      if (organizationPostCountCap) {
        setPostCountCap(organizationPostCountCap);
      }
    };
    getOrgCount();
  }, []);

  const documents = Object.keys(PLATFORMS).map((d) => {
    return {
      label: PLATFORMS[d],
      value: postCount[d],
    };
  });

  const totalSum = sum(documents, (d) => d.value);
  const documentsByPlatform = rollups(
    documents,
    (arr) => {
      const summed = sum(arr, (d) => d.value);
      const platform = arr[0].label; // platform

      const langs = (languages[platform] || [])
        .filter((x) => LANGUAGES[x.name])
        .map((x) => {
          return {
            label: LANGUAGES[x.name]?.name,
            lang: x.name,
            value: x.post_ct,
            percent: getPercentStr(x.post_ct, summed),
          };
        });

      return {
        value: summed,
        platform: platform,
        label: PLATFORM_CONFIG[platform]?.name,
        icon: PLATFORM_CONFIG[platform]?.icon,
        languages: langs,
        percent: getPercentStr(summed, totalSum),
      };
    },
    (d) => d.label
  ).map((d) => d[1]);

  const postCountForPlatform =
    sum(
      documentsByPlatform.filter((d) => d.platform === platform),
      (d) => d.value
    ) || 0;

  const documentsByLanguage = rollups(
    documentsByPlatform.flatMap((d) => d.languages),
    (arr) => {
      const summed = sum(arr, (d) => d.value);
      const label = arr[0].label;
      return {
        value: summed,
        label: label,
        emoji: LANGUAGES[arr[0].lang]?.emoji,
        percent: getPercentStr(summed, totalSum),
      };
    },
    (d) => d.label
  ).map((d) => d[1]);

  return (
    <div className={classes.row}>
      <div className={classes.progressBarHeader}>
        <InputLabel className={classes.label} shrink={true}>
          Document Usage:
        </InputLabel>
        <div className={classes.count}>
          <span>{formatNumberkANDcomma(postCountForPlatform)}</span>
          <span className={classes.lighter}>
            {" "}
            / {formatNumberkANDcomma(postCountCap)}
          </span>
        </div>
      </div>
      <div className={classes.progressBar}>
        <LinearProgress
          classes={{
            root: classes.progressBarRoot,
          }}
          color="primary"
          variant="determinate"
          value={
            (Math.min(postCountForPlatform, postCountCap) / postCountCap) * 100
          }
        />
      </div>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <div>
        <Typography className={classes.title}>Documents by platform</Typography>
        {documentsByPlatform.map((d) => {
          const Icon = d.icon;
          return (
            <div key={d.label}>
              <div className={classes.flexRow}>
                <Typography className={classes.name}>
                  {Icon ? <Icon /> : <div className={classes.fakeIcon}></div>}
                  {d.label}
                </Typography>
                <Typography className={classes.value}>
                  <DocumentIcon />
                  {formatNumberkANDcomma(d.value)} ({d.percent})
                </Typography>
              </div>
              {d.languages.map((x) => (
                <div className={classes.flexRow} key={x.label}>
                  <Typography className={classes.name}>
                    <div className={classes.languageIcon}>
                      <EnterArrowIcon />
                    </div>
                    {x.label}
                  </Typography>
                  <Typography className={classes.value}>
                    <DocumentIcon />
                    {formatNumberkANDcomma(x.value)} ({x.percent})
                  </Typography>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {flags.multilingual && (
        <div>
          <Typography className={classes.title}>
            Documents by language
          </Typography>
          {documentsByLanguage.map((d) => {
            return (
              <div key={d.label}>
                <div className={classes.flexRow}>
                  <Typography className={classes.name}>
                    {d.emoji && (
                      <span className={classes.countryEmoji}>{d.emoji}</span>
                    )}
                    {d.label}
                  </Typography>
                  <Typography className={classes.value}>
                    <DocumentIcon />
                    {formatNumberkANDcomma(d.value)} ({d.percent})
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
