import React, { createContext, useReducer, useContext } from "react";
import reducer, { initialState } from "./reducer";

const Context = createContext({ state: initialState, dispatch: () => {} });

function ContextProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
	);
}

const useAppContext = () => useContext(Context);

export { ContextProvider, useAppContext };
