import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import COLORS from "./colors";

let globalMuiTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 990,
			lg: 1200,
			xl: 1536,
		},
	},
	palette: {
		mode: "dark",
		action: {
			disabled: "#5A5F89",
		},
		text: {
			grey: "#757B8C",
			harmExpl: "#8F94B3",
			anomalousExpl: "#8F94B3",
			toxicExpl: "#9179BD",
			light_2: "#E8E9F3"
		},
		border: {
			section: "#232032",
			popup: "#3A364D",
			harmExpl: "#292D46",
			anomalousExpl: "#292D46",
			toxicExpl: "#2E2442",
			input: "#3C3949",
		},
		primary: {
			main: "#39F3C5",
			contrastText: "#ffffff",
		},
		white: {
			main: "#ffffff",
		},
		grey: {
			main: "rgba(255,255,255,0.6)",
		},
		secondary: {
			main: "#5A5F89",
		},
		warning: {
			main: "#FF7681",
		},
		error: {
			main: "#FD5447",
		},
		slider: {
			main: "#7165E0"
		},
		switchBtn: {
			main: "#AFAFAF",
		},
		background: {
			main: "#181527",
			default: "#181527",
			paper: "#181527",
			post: "#1E1B2E",
			stack: "#2A283B",
			dropdownActive: "#302c3c",
			dropdownInactive: "#232134",
			createNarrativeBG: "#1D1B2D",
			clusterToggler: "#28243A",
			popup: "#272438",
			breadcrumbProject: "#1A1729",
			breadcrumbNarrative: "#212334",
			harmExpl: "rgba(53, 131, 247, 0.08)",
			anomalousExpl: "rgba(34, 32, 50, 0.99)",
			toxicExpl: "rgba(148, 53, 247, 0.08)",
			input: "#171622",
			$0: "#131121",
			$1: "#1C192B",
			$2: "#272438",
			$3: "#302E4F",
		},
		widget: {
			main: "#1C192B",
			contrastText: "#ffffff",
		},
		tile: {
			main: "#242234",
			contrastText: "#ffffff",
		},
		tileActive: {
			main: "#2A283C",
		},
		...COLORS
	},
	typography: {
		fontWeightLight: 600,
		fontWeightRegular: 600,
		fontWeightMedium: 800,
		fontWeightBold: 800,
		fontFamily: "Mulish, 'Courier New', Courier, monospace, sans-serif",
		h3: {
			fontStyle: "normal",
			fontSize: "50px",
			fontWeight: 800,
		},
		h4: {
			fontWeight: 800,
		},
		button: {
			textTransform: "none",
		},
	},

	components: {
		MuiLink: {
			defaultProps: {
				underline: "none",
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					WebkitFontSmoothing: "auto",
				},
				body: {},
			},
		},

		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					textTransform: "none",
				},
				containedPrimary: {
					fontWeight: 600,
					color: "#110F1C",
					background: "#39F3C5",
				},
				containedSecondary: {
					fontWeight: 600,
					color: "#76748D",
					background: "#2C293A",
				},
			},
		},

		MuiInput: {
			styleOverrides: {
				input: {
					"&::placeholder": {
						color: "gray",
					},
					color: "white", // if you also want to change the color of the input, this is the prop you'd use
				},
			},
		},

		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 35,
				},
			},
		},

		MuiListItemText: {
			styleOverrides: {
				root: {
					color: "white",
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				backgroundColor: "rgba(59, 56, 80, 0.7)",
				color: "#E8E9F3",
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					borderRadius: 2,
					color: "#EAEAEA",
					fontSize: 14,
					lineHeight: "22px",
					fontWeight: "normal",
					alignItems: "center",
				},

				icon: {
					padding: 0
				},

				message: {
					padding: 0
				},

				filledSuccess: {
					backgroundColor: "#0D341B",
					border: "1px solid #196736",
				},
				filledError: {
					backgroundColor: "#3B0501",
					border: "1px solid #880C02",
				},
				filledInfo: {
					backgroundColor: "#0B313C",
					border: "1px solid #186F87",
				},
				filledWarning: {
					backgroundColor: "#2D0939",
					border: "1px solid #6B1687",
				},
        
				standardSuccess: {
					backgroundColor: "#0D341B",
				},
				standardError: {
					backgroundColor: "#3B0501",
				},
				standardInfo: {
					backgroundColor: "#0B313C",
				},
				standardWarning: {
					backgroundColor: "#2D0939",
				},

				outlinedSuccess: {
					border: "1px solid #196736",
				},
				outlinedError: {
					border: "1px solid #880C02",
				},
				outlinedInfo: {
					border: "1px solid #186F87",
				},
				outlinedWarning: {
					border: "1px solid #6B1687",
				},
			}
		}
	},
});

globalMuiTheme = responsiveFontSizes(globalMuiTheme);

export default globalMuiTheme;
