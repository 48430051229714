import React, { useState, useCallback, memo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, useMediaQuery } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { useTheme } from "@emotion/react";

const RADIAN = Math.PI / 180;
const LABEL_DISTANCE_MULTIPLIER_X = 2.4;
const LABEL_DISTANCE_MULTIPLIER_Y = 2.0;

const distance = (x1, x2, y1, y2) =>
	Math.sqrt(Math.pow(x1 - x2, 2), Math.pow(y1 - y2, 2));

const useStyles = makeStyles((theme) => ({
	tooltip: {
		backgroundColor: "rgba(25, 24, 38, 0.8)",
		borderRadius: 5,
		border: "none",
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	label: {
		color: "white",
	},
}));

const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	color,
	name,
	opacity,
}) => {
	if (percent === 0) return null;

	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x =
    cx +
    radius * Math.cos(-midAngle * RADIAN) * LABEL_DISTANCE_MULTIPLIER_X +
    (midAngle <= 180 ? -20 : 20) * (1 - Math.abs(Math.sin(-midAngle * RADIAN)));
	const y =
    cy +
    (midAngle <= 180 ? -12 : 12) +
    radius * Math.sin(-midAngle * RADIAN) * LABEL_DISTANCE_MULTIPLIER_Y;
	const name_y =
    cy +
    (midAngle <= 180 ? -30 : 30) +
    radius * Math.sin(-midAngle * RADIAN) * LABEL_DISTANCE_MULTIPLIER_Y;

	return (
		<>
			<text
				x={x}
				y={name_y}
				fill={"white"}
				textAnchor="middle"
				dominantBaseline="central"
			>
				{name}
			</text>
			<text
				x={x}
				y={y}
				fill={opacity === 0.15 ? "#C3D3FD" : color}
				textAnchor="middle"
				dominantBaseline="central"
			>
				{`${(percent * 100).toFixed(2)}%`}
			</text>
		</>
	);
};

const renderCustomizedLabelLine = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	opacity,
	color,
	percent,
}) => {
	if (percent === 0) return null;

	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const label_x =
    cx +
    radius * Math.cos(-midAngle * RADIAN) * LABEL_DISTANCE_MULTIPLIER_X +
    (midAngle <= 180 ? -20 : 20) * (1 - Math.abs(Math.sin(-midAngle * RADIAN)));
	const label_y =
    cy + radius * Math.sin(-midAngle * RADIAN) * LABEL_DISTANCE_MULTIPLIER_Y;
	const ring_x = cx + radius * Math.cos(-midAngle * RADIAN) * 1.3;
	const ring_y = cy + radius * Math.sin(-midAngle * RADIAN) * 1.3;

	return (
		<>
			{distance(ring_x, label_x, ring_y, ring_y) > radius / 15 && (
				<line
					x1={ring_x}
					y1={ring_y}
					x2={label_x}
					y2={ring_y}
					strokeWidth="1"
					stroke={opacity === 0.98 ? color : "#C3D3FD"}
					strokeOpacity={opacity === 0.98 ? "100%" : "75%"}
				/>
			)}
			<line
				x1={label_x}
				y1={ring_y}
				x2={label_x}
				y2={label_y}
				strokeWidth="1"
				stroke={opacity === 0.98 ? color : "#C3D3FD"}
				strokeOpacity={opacity === 0.98 ? "100%" : "75%"}
			/>
		</>
	);
};

const CustomTooltip = (props) => {
	const { active, payload } = props;
	const classes = useStyles();
	if (active && payload && payload.length > 0) {
		const { name, value } = payload[0];
		const { color } = payload[0].payload;
		return (
			<div className={classes.tooltip}>
				<Typography variant="subtitle1" className={classes.label}>
					{name}
				</Typography>
				<Typography
					variant="subtitle1"
					className={classes.label}
					style={{ color }}
				>
					{(value * 100).toFixed(2)}%
				</Typography>
			</div>
		);
	}

	return null;
};

const Chart = ({
	data,
	setData,
	shouldReanimate,
	name,
	showLabels,
	height,
	useTooltip,
}) => {

	const [animating, setAnimating] = useState(true);
	const theme = useTheme();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const containerWidth = isSmallScreen ? "50%" : "100%";

	const doneAnimating = useCallback(
		() =>
			setTimeout(() => {
				setAnimating(false);
			}, 1),
		[setAnimating]
	);

	const onMouseEnter = (o) => {
		const newData = [...data].map((d) => ({
			...d,
			opacity: 0.15,
			active: false,
		}));
		const activeDataIndex = newData.findIndex(({ color }) => color === o.color);
		newData[activeDataIndex].opacity = 0.98;
		newData[activeDataIndex].active = true;
		setData(newData);
		doneAnimating();
	};

	const onMouseLeave = () => {
		const newData = [...data].map((d) => ({ ...d, opacity: 1 }));
		setData(newData);
		doneAnimating();
	};

	return (
		<ResponsiveContainer width={containerWidth} height={height}>
			<PieChart key={`${shouldReanimate}-${name}`}>
				<Pie
					data={data}
					innerRadius={60}
					outerRadius={80}
					dataKey="value"
					nameKey="name"
					label={showLabels ? renderCustomizedLabel : null}
					labelLine={renderCustomizedLabelLine}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onAnimationEnd={doneAnimating}
					isAnimationActive={animating}
					animationBegin={800}
				>
					{data.map((entry, index) => (
						<Cell
							key={`${entry}-cell`}
							fill={data[index].color}
							strokeWidth={0}
						/>
					))}
				</Pie>
				{useTooltip && <Tooltip content={<CustomTooltip />} />}
			</PieChart>
		</ResponsiveContainer>
	);
};

export default memo(Chart);
