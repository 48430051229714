import { Button, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { StyledTableCell } from "./TableGrid";
import { ReactComponent as ExpandIcon } from "icons/NarBuilder/expand-circle.svg";
import { ReactComponent as CollapseIcon } from "icons/NarBuilder/collapse-circle.svg";
import { useState } from "react";
import { formatNumber } from "utils/format";
import PostsList from "./PostsList";
import copyEntityToClipboad from "pages/DashboardPage/Shared/clipboard";
import { QUERY_KEYS } from "utils/constants";

const noop = () => {};

const getValue = (col, d) => {
  if (col.field === "*") {
    return d;
  }

  let value = d[col.field];

  if (value === null || value === undefined) {
    return "-";
  }

  return typeof col.format === "function" ? col.format(value) : value;
};

const useStyles = makeStyles((theme) => ({
  firstColumn: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 1,
    color: "#E8E9F3",
    padding: (props) =>
      props.is_child
        ? "0px 0px 0px 20px !important"
        : "0px 0px 0px 10px !important",
    height: "100%",
    textAlign: "left !important",
    backgroundColor: theme.palette.background.$0,
    borderBottom: "none !important",
    borderTop: "none !important",
  },
  firstColumnInner: {
    position: "relative",
    padding: (props) => (props.is_child ? "0px 0px 0px 20px" : "0px"),
    borderLeft: (props) => {
      if (props.is_child) {
        return "2px solid rgba(150, 148, 180, 0.1)";
      }

      return "none";
    },
    "&::before": {
      content: (props) => (props.is_child ? '""' : ""),
      position: "absolute",
      top: "50%",
      left: -2,
      width: 22,
      borderTop: (props) => {
        if (props.is_last_clusters_child) {
          return "2px solid rgba(150, 148, 180, 0.1)";
        }

        return "none";
      },
      height: (props) => (props.is_last_clusters_child ? "50%" : 2),
      backgroundColor: (props) => {
        if (props.is_last_clusters_child) {
          return theme.palette.background.$0;
        }

        return "rgba(150, 148, 180, 0.1)";
      },
    },
  },
  borderInner: {
    backgroundColor: (props) =>
      props.is_child ? `${theme.palette.background.$0} !important` : "inherit",
    borderTop: (props) => {
      if (props.is_child && props.is_first_clusters_child) {
        return `8px solid ${theme.palette.background.main} !important`;
      }
      return "none !important";
    },
    borderBottom: (props) => {
      if (props.is_child && !props.is_last_clusters_child) {
        return `8px solid ${theme.palette.background.main} !important`;
      }
      return "none !important";
    },
  },
  tableRow: {
    opacity: (props) => (props.disabled ? 0.5 : null),
    pointerEvents: (props) => (props.disabled ? "none" : null),
    "& *": {
      pointerEvents: (props) => (props.disabled ? "none" : null),
    },
    backgroundColor: "transparent",
  },
  td: {
    "&:not(:first-child)": {
      borderBottom: (props) => {
        if (props.is_child && !props.is_last_clusters_child) {
          return `8px solid ${theme.palette.background.main} !important`;
        }
        return "none !important";
      },
      borderTop: (props) => {
        if (props.is_child && props.is_first_clusters_child) {
          return `8px solid ${theme.palette.background.main} !important`;
        }
        return "none !important";
      },
    },
    backgroundColor: theme.palette.background.$0,
    [theme.breakpoints.down("lg")]: {
      padding: "4px",
    },
  },
  expandCollapseRow: {},
  expandCollapse: {
    padding: "0px !important",
    backgroundColor: `${theme.palette.background.main} !important`,
  },
  clusterTogler: {
    textAlign: "center",
    backgroundColor: theme.palette.background.clusterToggler,
    paddingTop: 6,
    paddingBottom: 6,
  },
  postCount: {
    paddingBottom: "4px",
    color: theme.palette.primary.main,
    opacity: (props) => (props.is_excluded ? 0.4 : 1),
    textDecoration: "none",
    border: "none",
    borderRadius: 0,
    marginTop: 0,
    fontWeight: "normal",
  },
  greyText: {
    marginLeft: 8,
    color: "#D4D7ED",
  },
  snackTrailParent: {
    backgroundColor: `${theme.palette.background.main} !important`,
    padding: "0px 0px 0px 0px !important",
  },
  stackTail: {
    marginLeft: 4,
    marginRight: 4,
    backgroundColor: theme.palette.background.stack,
    height: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  separator: {
    height: "8px",
    padding: "0px !important",
    width: "100%",
    backgroundColor: `${theme.palette.background.main} !important`,
  },
}));

export default function TableGridRow({
  data: d,
  columns,
  filterKey,
  is_child,
  is_last_clusters_child,
  is_first_clusters_child,
  setSnackOpen,
  onViewMoreClick,
  onFirstColumnClick = noop,
  onRowClick = noop,
}) {
  const isCluster = filterKey === QUERY_KEYS.clusters;
  const nPosts = d.sample_posts ? d.sample_posts.length : 0;

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const classes = useStyles({
    disabled: d.disabled,
    is_child,
    is_cluster_table: isCluster,
    is_cluster: isCluster && nPosts > 1,
    is_open: open,
    is_last_clusters_child,
    is_first_clusters_child,
  });
  const FirstColumnComponent = columns[0].cellComponent;

  const handleCopy = () => {
    if (d) {
      copyEntityToClipboad(d, columns[0].target || "tweets")
        .then(() => {
          setSnackOpen(true);
        })
        .catch(() => {
          setSnackOpen(false);
        });
    }
  };

  const expandCollapse = isCluster && nPosts > 1 && (
    <TableRow className={classes.expandCollapseRow}>
      <StyledTableCell
        className={classes.expandCollapse}
        colSpan={columns.length}
      >
        <div className={classes.clusterTogler}>
          <Button
            className={classes.postCount}
            variant="text"
            onClick={toggleOpen}
            startIcon={open ? <CollapseIcon /> : <ExpandIcon />}
          >
            {open ? "Collapse" : "Expand"} Cluster{" "}
            <span className={classes.greyText}>
              ({formatNumber(d.post_ct)} post{d.post_ct !== 1 && "s"})
            </span>
          </Button>
        </div>
      </StyledTableCell>
    </TableRow>
  );

  const list = isCluster && nPosts > 1 && (
    <PostsList
      sample_posts={d.sample_posts}
      columns={columns}
      setSnackOpen={setSnackOpen}
      onViewMoreClick={() => onViewMoreClick && onViewMoreClick(d)}
    />
  );

  const separator =
    isCluster && !is_child ? (
      <TableRow>
        <StyledTableCell
          colSpan={columns.length}
          className={classes.separator}
        ></StyledTableCell>
      </TableRow>
    ) : null;

  return (
    <>
      {separator}
      <TableRow className={classes.tableRow} onClick={() => onRowClick(d)}>
        <StyledTableCell
          className={clsx(
            classes.td,
            classes.firstColumn,
            columns[0].className
          )}
          onClick={() => onFirstColumnClick(d)}
        >
          <div className={classes.firstColumnInner}>
            <div className={classes.borderInner}>
              {FirstColumnComponent ? (
                <FirstColumnComponent
                  is_child={is_child}
                  value={getValue(columns[0], d)}
                  onCopy={handleCopy}
                />
              ) : (
                getValue(columns[0], d)
              )}
            </div>
          </div>
        </StyledTableCell>

        {columns.slice(1).map((col, i) => {
          const Comp = col.cellComponent;
          const value = getValue(col, d);

          let content = null;

          if (Comp) {
            content = <Comp value={value} datum={d} />;
          } else {
            content = value;
          }

          return (
            <StyledTableCell key={i} className={classes.td}>
              {content}
            </StyledTableCell>
          );
        })}
      </TableRow>
      {expandCollapse}
      {open ? list : null}
      {isCluster && nPosts > 1 && !open && (
        <TableRow>
          <StyledTableCell
            className={classes.snackTrailParent}
            colSpan={columns.length}
          >
            <div className={classes.stackTail}></div>
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
}
