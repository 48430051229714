import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Drawer,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
  Slider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InputValidate from "components/UI/InputValidate";
import { ReactComponent as XIcon } from "icons/UI/x.svg";
import { Box } from "@mui/system";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import CloseIcon from "@mui/icons-material/Close";
import CustomTabs from "components/UI/TabsNew";
import { getRandomId, refineDisplayName } from "utils/format";
import {
  createClassifierProfile,
  setClassifierProfileAPI,
  updateClassifierProfile,
} from "api/AnalyticsApi";
import { LABELS_MAP, LOCAL_STORAGE_KEYS } from "utils/constants";
import { resolveCohortDisplayName } from "utils/cohorts";

const WIDTH = 500;
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const useStyles = makeStyles((theme) => ({
  root: {},
  btn: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 999999,
    flexShrink: 0,
    width: WIDTH,
    height: "100vh",
  },
  drawerPaper: {
    width: WIDTH,
    background: theme.palette.background.$2,
    border: "1px solid " + theme.palette.border.popup,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(80px)",
    borderRadius: "6px 0px 0px 6px",
  },
  header: {
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 20px 20px 15px",
  },
  backButton: {
    padding: 0,
    width: "34px",
    height: "34px",
    marginRight: "16px",
  },
  alert: {
    padding: theme.spacing(2, 4, 4, 2),
  },
  content: {
    padding: theme.spacing(2, 4),
    flexGrow: 1,
    overflowY: "auto",
  },
  queryBuilder: {},
  closeBtn: {
    backgroundColor: "#2C293A",
    color: "rgba(195, 194, 211, 0.8)",
    "&:hover": {
      backgroundColor: "#2C293A",
    },
  },
  btns: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: 17,
    "&>button": {
      minWidth: "25%",
    },
  },
  divider: {
    borderColor: "rgba(99, 96, 123, 0.2)",
  },
  nameAndButtons: {
    padding: theme.spacing(2, 4),
  },
  previewSnack: {
    background: "#272438",
    borderWidth: "1px 1px 1px 6px",
    borderStyle: "solid",
    borderColor: "#3A364D #3A364D #3A364D #81DFA3",
    padding: 30,
    borderRadius: 6,
    minWidth: 550,
  },
  preview: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formLabel: { fontSize: 14, fontWeight: "normal" },
  sliderRoot: {
    padding: theme.spacing(1, 3),
  },
  sliderWrap: {
    width: "100%",
    "& .MuiSlider-thumb": {
      height: 23,
      width: 23,
      borderRadius: 4,
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 12,
      fontWeight: "normal",
      top: 24,
      backgroundColor: "unset",
      color: theme.palette.background.$3,
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.background.$3,
      },
    },
    '& .MuiSlider-mark[data-index="0"]': {
      display: "none",
    },
    '& .MuiSlider-mark[data-index="2"]': {
      display: "none",
    },
    '& .MuiSlider-mark[data-index="1"]': {
      backgroundColor: theme.palette.violet.$5,
      height: 32,
      width: 2,
      opacity: 1,
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: theme.palette.violet.$5,
      },
    },
  },
  sliderLabel: {
    color: "#AFAFAF",
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "normal",
    marginBottom: theme.spacing(1),
  },
  markLabel: {
    color: "#AFAFAF",
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: "normal",
    paddingTop: theme.spacing(1),
  },
}));

const tabNameMap = {
  risk_signals: "Risk Signals",
  emotion: "Emotion",
  cohorts: "Cohorts",
  toxicitySubcategory: "Toxicity",
};

const createTabs = (chosenConfig, projectSettings) => {
  if (!projectSettings) {
    projectSettings = chosenConfig;
  }

  const tabNames = [
    tabNameMap.risk_signals,
    tabNameMap.emotion,
    tabNameMap.cohorts,
    tabNameMap.toxicitySubcategory,
  ];

  const tabs = tabNames.map((tab) => ({
    name: tab,
    sliders: [],
  }));

  const harmScoreRows = [];
  const { reasonCodeThresholds } = chosenConfig;
  const projectSettingReasonCodeThresholds =
    projectSettings.reasonCodeThresholds;

  if (reasonCodeThresholds) {
    for (const reasonCodeThresholdKey of Object.keys(reasonCodeThresholds)) {
      if (
        reasonCodeThresholdKey === "known_hoax" &&
        reasonCodeThresholdKey === "partisanship"
      ) {
        continue;
      }

      const profileReasonCode = reasonCodeThresholds[reasonCodeThresholdKey];
      let bbDefault =
        projectSettingReasonCodeThresholds[reasonCodeThresholdKey];

      let sliderValue = profileReasonCode;

      if (reasonCodeThresholdKey !== "pagerank") {
        bbDefault = Math.round(bbDefault * 100);
        sliderValue = Math.round(profileReasonCode * 100);
      }

      const row = {
        label: LABELS_MAP[reasonCodeThresholdKey] || refineDisplayName(reasonCodeThresholdKey),
        field: reasonCodeThresholdKey,
        bbDefault,
        value: sliderValue,
        min: 0,
        max:
          reasonCodeThresholdKey === "pagerank"
            ? Math.max(100, bbDefault * 1.5)
            : 100,
      };
      harmScoreRows.push(row);
    }
    const harmScoreTab = tabs.find((x) => x.name === tabNameMap.risk_signals);
    harmScoreTab.sliders = harmScoreRows;
  }

  const emotionRows = [];
  const { emotionThresholds } = chosenConfig;
  const projectSettingEmotionThresholds = projectSettings.emotionThresholds;
  if (emotionThresholds) {
    for (const key of Object.keys(emotionThresholds)) {
      const row = {
        label: LABELS_MAP[key] || refineDisplayName(key),
        field: key,
        bbDefault: Math.round(projectSettingEmotionThresholds[key] * 100),
        value: Math.round(emotionThresholds[key] * 100),
        min: 0,
        max: 100,
      };
      emotionRows.push(row);
    }
    const emotionTab = tabs.find((x) => x.name === tabNameMap.emotion);
    emotionTab.sliders = emotionRows;
  }

  const toxicitySubcategoryRows = [];
  const { toxicitySubcategoryThresholds } = chosenConfig;
  const projectSettingToxicitySubcategoryThresholds =
    projectSettings.toxicitySubcategoryThresholds;


    if (toxicitySubcategoryThresholds) {
    for (const key of Object.keys(toxicitySubcategoryThresholds)) {
      // TO-DO: Revisit and centralize all 3 toxicity scores so "toxicity"
      // The three fields are: toxicity (runtime field), analysis.toxicity.toxic, analysis.toxicity.toxicity
      if (key === "toxic" || key === "toxicity") {
        continue;
      }

      const row = {
        label: LABELS_MAP[key] || refineDisplayName(key),
        field: key,
        bbDefault: Math.round(
          projectSettingToxicitySubcategoryThresholds[key] * 100
        ),
        value: Math.round(toxicitySubcategoryThresholds[key] * 100),
        min: 0,
        max: 100,
      };
      toxicitySubcategoryRows.push(row);
    }
    const toxicitySubcategoryTab = tabs.find(
      (x) => x.name === tabNameMap.toxicitySubcategory
    );
    toxicitySubcategoryTab.sliders = toxicitySubcategoryRows;
  }

  //const sentimentRows = [];
  // const { sentimentThresholds } = chosenConfig;
  // const projectSettingSentimentThresholds = projectSettings.sentimentThresholds;
  // if (sentimentThresholds) {
  //   for (const key of Object.keys(sentimentThresholds)) {
  //     const row = {
  //       label: LABELS_MAP[key] || refineDisplayName(key),
  //       field: key,
  //       bbDefault: Math.round(projectSettingSentimentThresholds[key] * 100),
  //       value: Math.round(sentimentThresholds[key] * 100),
  //       min: 0,
  //       max: 100,
  //     };
  //     sentimentRows.push(row);
  //   }
  //   const sentimentTab = tabs.find((x) => x.name === "Sentiment");
  //   if(sentimentTab) {
  //     sentimentTab.sliders = sentimentRows;
  //   }
  // }

  const cohortThresholdRows = [];
  if (chosenConfig.cohortThresholds && chosenConfig.cohortThresholds.data) {
    // To-DO figure out why it's data -remove the whole data thing

    for (const cohortThreshold of chosenConfig.cohortThresholds.data) {
      const row = {
        label: resolveCohortDisplayName(cohortThreshold.attributes.cohort.data.attributes.displayName),
        field: cohortThreshold.attributes.cohort.data.attributes.fieldName,
        bbDefault: Math.round(cohortThreshold.attributes.threshold * 100),
        value: Math.round(cohortThreshold.attributes.threshold * 100),
        min: 0,
        max: 100,
      };

      cohortThresholdRows.push(row);
    }
  } else {
    if (chosenConfig.cohortThresholds) {
      const cohortThresholds = chosenConfig.cohortThresholds;
      const projectSettingsCohortThresholds = projectSettings.cohortThresholds
        .data
        ? projectSettings.cohortThresholds.data
        : projectSettings.cohortThresholds;
      for (const key of Object.keys(cohortThresholds)) {
        const projectSettingsCohort = projectSettings.cohortThresholds.data
          ? projectSettingsCohortThresholds.find(
              (x) => x.attributes.cohort.data.attributes.fieldName === key
            )
          : cohortThresholds[key];
        const bbDefault =
          projectSettingsCohort && projectSettingsCohort.attributes
            ? projectSettingsCohort.attributes.threshold * 100
            : projectSettingsCohort
            ? projectSettingsCohort * 100
            : cohortThresholds[key] * 100;

        const row = {
          label: resolveCohortDisplayName(key),
          field: key,
          bbDefault: Math.round(bbDefault),
          value: Math.round(cohortThresholds[key] * 100),
          min: 0,
          max: 100,
        };

        cohortThresholdRows.push(row);
      }
    }
  }
  const cohortTab = tabs.find((x) => x && x.name === tabNameMap.cohorts);
  cohortTab.sliders = cohortThresholdRows;

  return tabs;
};

export default function HarmClassifierDrawer() {
  const classes = useStyles();
  const {
    dispatch,
    state: {
      harmClassifierOpen,
      harmClassifierEdit,
      harmClassifiers,
      harmClassifier,
      projectSettings,
    },
  } = useAppContext();

  const [previewModeOn, setPreviewModeOn] = useState(false);
  const [apply, setApply] = useState(false);
  const [name, setName] = useState(harmClassifierEdit.name || "");
  const [profileExists, setProfileExists] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [tab, setTab] = useState(0);
  const [classifierTabs, setClassifierTabs] = useState([]);
  const sliders = classifierTabs[tab]?.sliders;
  const isDisabled = harmClassifierEdit.isGlobal;

  useEffect(() => {
    setName(harmClassifierEdit.name || "");
    setClassifierTabs(harmClassifierEdit.classifierTabs || []);
    setProfileExists(false);
    setIsEmpty(false);
    setTab(0);
  }, [harmClassifierEdit]);

  async function setClassifierProfile(newProfile, callback) {
    const updateRes = await setClassifierProfileAPI(newProfile.id);
    if (updateRes) {
      await localStorage.setItem(
        LOCAL_STORAGE_KEYS.PREVIEW_CLASSIFIER_PROFILE_REF,
        newProfile.id
      );
      if (callback) {
        callback();
      }
      dispatch({
        type: ACTIONS.SET_HARM_CLASSIFIER,
        payload: newProfile,
      });
    }
  }

  useEffect(() => {
    if (projectSettings && !harmClassifierEdit.id) {
      const tabs = createTabs(projectSettings);
      setClassifierTabs(tabs);
      setTab(0);
    } else {
      const tabs = createTabs(harmClassifierEdit, projectSettings);
      setClassifierTabs(tabs);
      setTab(0);
    }

    // populate/create tabs from saved classifierprofile
  }, [
    projectSettings,
    harmClassifierEdit.id,
    harmClassifier,
    harmClassifierEdit,
  ]);

  const handleTabChange = (e, v) => {
    const newClassTabs = [...classifierTabs];
    newClassTabs[tab].sliders = sliders;
    setClassifierTabs(newClassTabs);
    setTab(v);
  };

  const handleSliderUpdate = (index, value) => {
    const classTabs = [...classifierTabs];
    const activeTab = classTabs[tab];
    const activeSliders = [...activeTab.sliders];
    const datum = { ...activeSliders[index] };
    datum.value = value;
    activeSliders[index] = datum;
    activeTab.sliders = activeSliders;
    classTabs[tab] = activeTab;
    setClassifierTabs(classTabs);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleApplyCheck = (e) => {
    setApply(e.target.checked);
  };

  const handleClose = () => {
    dispatch({
      type: ACTIONS.SET_HARM_CLASSIFIER_OPEN,
      payload: false,
    });
  };

  const showMessage = (msg, type) => {
    dispatch({
      type: ACTIONS.SHOW_MESSAGE,
      payload: msg
        ? {
            message: msg,
            type: type,
          }
        : null,
    });
  };

  const closeSnackBar = async () => {
    await setClassifierProfile(harmClassifiers[0], () => {
      setPreviewModeOn(false);
    });
  };

  const handleEdit = () => {
    dispatch({
      type: ACTIONS.EDIT_HARM_CLASSIFIER,
      payload: { ...harmClassifier },
    });
    setPreviewModeOn(false);
  };

  const createProfileFieldsFromTabs = (tabs) => {
    const reasonCodeThresholds = Object.assign(
      {},
      ...tabs
        .find((x) => x.name === tabNameMap.risk_signals)
        .sliders.map((x) => {
          return { [x.field]: x.value / 100 };
        })
    );

    // Do not divide PageRank by 100
    const pageRankSliderValue = tabs
      .find((x) => x.name === tabNameMap.risk_signals)
      ?.sliders.find(({ field }) => field === "pagerank")?.value;
    reasonCodeThresholds.pagerank = pageRankSliderValue;

    return {
      reasonCodeThresholds,
      cohortThresholds: Object.assign(
        {},
        ...tabs
          .find((x) => x.name === tabNameMap.cohorts)
          .sliders.map((x) => {
            return { [x.field]: x.value / 100 };
          })
      ),
      emotionThresholds: Object.assign(
        {},
        ...tabs
          .find((x) => x.name === tabNameMap.emotion)
          .sliders.map((x) => {
            return { [x.field]: x.value / 100 };
          })
      ),
      toxicitySubcategoryThresholds: Object.assign(
        {},
        ...tabs
          .find((x) => x.name === tabNameMap.toxicitySubcategory)
          .sliders.map((x) => {
            return { [x.field]: x.value / 100 };
          })
      ),
      // sentimentThresholds: Object.assign({}, ...(tabs.find(x => x.name === "Sentiment").sliders.map(x => { return {[x.field]: x.value/100}}))),
    };
  };

  const handleSave = async () => {
    if (name.trim() === "") return setIsEmpty(true);

    if (harmClassifierEdit.id) {
      const newProfile = {
        id: harmClassifierEdit.id,
        name,
        isDefault: false,
        ...createProfileFieldsFromTabs(classifierTabs),
      };
      const updateRes = await updateClassifierProfile(newProfile);
      if (updateRes) {
        dispatch({
          type: ACTIONS.UPDATE_HARM_CLASSIFIER,
          payload: newProfile,
        });

        showMessage("Risk profile updated", "success");
        handleClose();
        setPreviewModeOn(false);
        if (apply || harmClassifierEdit.id === harmClassifier.id) {
          await setClassifierProfile(newProfile);
        }
      }
    } else {
      const exists = harmClassifiers.some((d) => d.name === name);
      setProfileExists(exists);

      if (!exists) {
        const newProfile = {
          id: getRandomId(),
          name,
          ...createProfileFieldsFromTabs(classifierTabs),
          isDefault: false,
        };
        const createRes = await createClassifierProfile(newProfile);
        if (createRes) {
          newProfile.id = createRes.id;
          dispatch({
            type: ACTIONS.ADD_HARM_CLASSIFIER,
            payload: newProfile,
          });

          showMessage("Risk profile created", "success");
          handleClose();
          setPreviewModeOn(false);

          if (apply) {
            await setClassifierProfile(newProfile);
          }
        }
      }
    }
  };

  const handleCloseGlobal = () => {
    if (apply && harmClassifierEdit.isGlobal) {
      setClassifierProfile(harmClassifierEdit);
    }

    handleClose();
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={"right"}
        open={harmClassifierOpen}
        onClose={handleClose}
      >
        <div className={classes.headerTitle}>
          <IconButton className={classes.backButton} onClick={handleClose}>
            <XIcon />
          </IconButton>
          <Typography className={classes.header}>
            {harmClassifierEdit.name || "Create a classifier profile"}
          </Typography>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.alert}>
          <Alert severity="warning" variant="filled">
            Fine tune any classifier to enhance signals within your dataset. The
            pink line represents the default value.
          </Alert>
        </div>
        <div className={classes.tabs}>
          <CustomTabs
            value={tab}
            onChange={handleTabChange}
            tabs={classifierTabs}
            tabColor="#272438"
          />
        </div>
        <div className={classes.content}>
          {(sliders || []).map((slider, i) => {
            return (
              <div className={classes.sliderRoot} key={slider.field}>
                <Typography variant="subtitle2" className={classes.sliderLabel}>
                  {slider.label}
                </Typography>
                <div className={classes.sliderWrap}>
                  <Slider
                    size="medium"
                    color="slider"
                    className={classes.slider}
                    value={slider.value}
                    min={slider.min}
                    max={slider.max}
                    step={1}
                    classes={{
                      markLabel: classes.markLabel,
                    }}
                    onChange={(e) => handleSliderUpdate(i, e.target.value)}
                    marks={[
                      { value: slider.min, label: slider.min },
                      { value: slider.bbDefault },
                      { value: slider.max, label: slider.max },
                    ]}
                    valueLabelDisplay="on"
                    disabled={isDisabled}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Divider className={classes.divider} />
        <Box className={classes.nameAndButtons}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" sx={{ pb: 0.5 }}>
              Name your classifier profile
            </Typography>
            <InputValidate
              value={name}
              showError={isEmpty}
              onChange={handleNameChange}
              exists={profileExists}
              emptyWarning={"Name your configuration"}
              existsWarning={`${name} already exists.`}
              placeholder={"e.g. Toxic bots"}
              disabled={isDisabled}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={apply}
                onChange={handleApplyCheck}
                name="gilad"
              />
            }
            classes={{ label: classes.formLabel }}
            label="Preview this profile in current project"
          />
          <div className={classes.btns}>
            {/* <Button
              color="secondary"
              variant="contained"
              className={classes.closeBtn}
              disableElevation
              onClick={handlePreview}
            >
              Preview
            </Button> */}
            {harmClassifierEdit.isGlobal ? (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleCloseGlobal}
              >
                Close
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSave}
              >
                Save
              </Button>
            )}
          </div>
        </Box>
      </Drawer>
      <Snackbar
        open={previewModeOn}
        autoHideDuration={null}
        message={""}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.previewSnack}
      >
        <div className={classes.preview}>
          <div>
            <Typography variant="subtitle1">
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackBar}
                sx={{ mr: 1 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              PREVIEW MODE: {name}
            </Typography>
          </div>
          <div>
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={handleEdit}
            >
              Edit
            </Button>
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Snackbar>
    </div>
  );
}
