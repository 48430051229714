import {
  initialState,
  TOGGLE_SNACK,
  STAGE_DOCS,
  SET_DOCS,
  SELECT_DOCS,
  TOGGLE_UPLOADING,
  TOGGLE_TOS_CHECKED,
  SET_DATA_CONNECTORS,
  SET_DATA_CONNECTORS_REFETCH,
  SET_TALKWALKER_OBJECT,
  SET_SELECTED_FILES,
  SET_TALKWALKER_CONNECTION,
  ADD_DOCS,
} from "../utils/constants";

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_DOCS: {
      return {
        ...state,
        documents: [
          ...action.payload,
          ...state.documents,
        ]
      }
    }
    case SET_DOCS:
      return {
        ...state,
        documents: action.payload,
      };
    case SELECT_DOCS:
      return {
        ...state,
        selectedDocuments: action.payload,
      };
    case STAGE_DOCS:
      return {
        ...state,
        stagedDocuments: action.payload,
      };
    case TOGGLE_UPLOADING:
      return {
        ...state,
        uploading: action.payload,
      };
    case TOGGLE_TOS_CHECKED:
      return {
        ...state,
        tosChecked: action.payload,
      };
    case TOGGLE_SNACK:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DATA_CONNECTORS:
      return {
        ...state,
        dataConnectors: action.payload
      }
    case SET_DATA_CONNECTORS_REFETCH:
      return {
        ...state,
        dataConnectorsRefetch: action.payload
      }
    case SET_TALKWALKER_OBJECT:
      return {
        ...state,
        talkwalkerObject: action.payload
      }
    case SET_SELECTED_FILES:
      return {
        ...state,
        selectedFilesForJob: action.payload
      }
    case SET_TALKWALKER_CONNECTION: 
      return {
        ...state,
        talkwalkerConnection: action.payload
      }
    default:
      return { ...state };
  }
};

export default reducer;
