import moment from "moment";
import { months } from "./constants";

function fileSizeStr(bytes, si) {
  var thresh = si ? 1000 : 1024;
  if (bytes < thresh) return bytes + " B";
  var units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (bytes >= thresh);
  return bytes.toFixed(2) + " " + units[u];
}

const getSize = (params) => {
  return fileSizeStr(params.value, true);
};

const dateStr = (timestamp) => {
  if (!timestamp) {
    return "UPLOADING...";
  }
  const date = new Date(parseInt(timestamp, 10));
  let hours = date.getHours();
  if (hours < 10) {
    hours = `0${hours}`;
  }
  let mins = date.getMinutes();
  if (mins < 10) {
    mins = `0${mins}`;
  }
  let secs = date.getSeconds();
  if (secs < 10) {
    secs = `0${secs}`;
  }
  return `${
    months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()} ${hours}:${mins}:${secs}`;
};

const getTime = (params) => {
  return dateStr(params.value);
};

const formatTime = (params) => {
  return moment(new Date(params.value)).format("M/DD/YYYY HH:mm A");
}

const closestFileBySize = (docs, total) => {
  return docs.reduce(function (prev, curr) {
    return Math.abs(curr.size - total) < Math.abs(prev.size - total)
      ? curr
      : prev;
  });
};

// eslint-disable-next-line no-control-regex
const replaceSpecialChars = (str) => str.replace(/[^\x00-\x7F]/g, "");

const generateName = (originalName, i) => {
  let name =
    originalName.substring(0, originalName.lastIndexOf(".")) || originalName;
  if (i > 0) name += `(${i.toString()})`;
  const extension =
    originalName.substring(
      originalName.lastIndexOf(".") + 1,
      originalName.length
    ) || "";
  return `${name}.${extension}`;
};

const stageDocs = (oldDocuments, oldStagedDocuments, newStaged) => {
  const seen = new Map(oldDocuments.map((doc) => [doc.filename, 1]));
  const stagedDocuments = [...oldStagedDocuments, ...newStaged];
  for (let i = 0; i < stagedDocuments.length; i++) {
    let sameNameFound = true;
    let count = 0;
    while (sameNameFound) {
      const newName = generateName(stagedDocuments[i].filename, count);
      const alreadySeenCount = seen.get(newName);
      if (alreadySeenCount === undefined) {
        seen.set(newName, 1);
        stagedDocuments[i].filename = newName;
        stagedDocuments[i].id = newName;
        sameNameFound = false;
      } else {
        count++;
      }
    }
  }
  return stagedDocuments;
};

const checkSpecialChars = (str) => {
  const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return format.test(str);
}

export const getCorrectDate = (date) => {
  const dateObj = new Date(date);
  const myOffset = dateObj.getTimezoneOffset() / 60;
  const dateMoment = moment(dateObj).add(myOffset, "hours");
  return dateMoment;
}

export const getISOString = (date, time) => {
  const dateStr = date.format("YYYY-MM-DD");
  return `${dateStr}T${time}:00.000Z`;
}

export const getTimeStr = (date) => {
  let dateObj = new Date(date);
  let myOffset = dateObj.getTimezoneOffset() / 60;
  return moment(dateObj)
    .add(myOffset, "hours")
    .format("HH:mm");
}

export {
  fileSizeStr,
  dateStr,
  getTime,
  getSize,
  replaceSpecialChars,
  generateName,
  closestFileBySize,
  stageDocs,
  formatTime,
  checkSpecialChars
};
