import { Avatar, Button, ListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Popover } from "@mui/material";
import { ReactComponent as LogoutIcon } from "icons/UI/logout.svg";

const useStyles = makeStyles((theme) => ({
	userRoot: {
		padding: ({ showOnlyAvatar }) =>
			showOnlyAvatar ? theme.spacing(2.1) : theme.spacing(2, 3),
		display: "flex",
		alignItems: "center",
	},
	avatar: {
		marginRight: ({ showOnlyAvatar }) =>
			showOnlyAvatar ? 0 : theme.spacing(2),
	},
	right: {
		whiteSpace: "normal",
	},
	username: {
		fontWeight: "800",
		fontSize: "14px",
	},
	email: {
		fontSize: "13px",
		color: "#76748D",
	},
	popoverRoot: {},
	popoverPaper: {
		background: theme.palette.background.popup,
		padding: theme.spacing(1),
	},
	logoutBtn: {
		backgroundColor: "#2A283A",
		color: "#76748D",
		width: "100%",
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "20px",
		padding: 8,
		"&:hover": {
			backgroundColor: "#2E2C3E",
		},
	},
}));

const UserInfo = ({ showOnlyAvatar, size = 40 }) => {
	const classes = useStyles({ showOnlyAvatar, size });

	const [anchorEl, setAnchorEl] = useState(null);

	const history = useHistory();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const {
		state: { user, timezoneName },
		dispatch,
	} = useAppContext();

	const logout = () => {
		history.push("/");
		localStorage.clear();
		dispatch({ type: ACTIONS.LOGOUT });
	};

	const username = user.username;
	const email = user.email;

	const open = Boolean(anchorEl);
	const id = open ? "user-info-popover" : undefined;

	return (
		<>
			<ListItem className={classes.userRoot} button dense onClick={handleOpen}>
				<div className={classes.avatar}>
					<Avatar src={null} sx={{ width: size, height: size }}>
						{username ? username[0].toUpperCase() : ""}
					</Avatar>
				</div>
				{!showOnlyAvatar && (
					<div className={classes.right}>
						<Typography className={classes.username}>
							{username}{" "}
							{timezoneName && (
								<span className={classes.email}>
                  (timezone - {timezoneName})
								</span>
							)}
						</Typography>
						<Typography className={classes.email}>{email}</Typography>
					</div>
				)}
			</ListItem>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				classes={{
					root: classes.popoverRoot,
					paper: classes.popoverPaper,
				}}
			>
				<Button
					className={classes.logoutBtn}
					onClick={logout}
					startIcon={<LogoutIcon />}
					color="white"
				>
          Log out
				</Button>
			</Popover>
		</>
	);
};

export default UserInfo;
