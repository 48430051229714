import React, { useState } from "react";
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as BuildIcon } from "icons/NarBuilder/builder.svg";
import { ReactComponent as PlugIcon } from "icons/plug.svg";
import { ReactComponent as ArrowIcon } from "icons/Union.svg";
import { ReactComponent as OpenIcon } from "icons/UI/OpenIcon.svg";
import BlackbirdTextLogo from "components/blackbird-text-logo";
import { ReactComponent as SettingsIcon } from "icons/UI/Settings.svg";
import { ReactComponent as DashboardIcon } from "icons/Dashboard.svg";
import { ReactComponent as FaqIcon } from "icons/UI/faq.svg";
import { DRAWER_WIDTH, DRAWER_CLOSED_WIDTH } from "pages/DashboardPage";
import UserSettings from "./UserSettingsPopup/UserSettings";
import UserInfo from "./UserSettingsPopup/UserInfo";
import clsx from "clsx";
import { useAppContext } from "context/Context";
import {
  ALL_PROJECT_INDEX,
  DASHBOARD_TAB,
  DATA_TAB,
  NARRATIVE_BUILDER_TAB,
} from "utils/constants";
import ProjectsList from "./ProjectsList";
import { useHistory, useParams } from "react-router-dom";
import useEventTracker from "api/hooks/useEventTracker";
import useTabs from "hooks/useTabs";
import { ReactComponent as DataUploadIcon } from "icons/DataUpload.svg";

const UPLOADER_URL = process.env.REACT_APP_UPLOADER_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    position: "relative",
  },
  drawer: {
    zIndex: 1250,
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
  },
  drawerClose: {
    width: DRAWER_CLOSED_WIDTH,
  },
  blackbirdLogoContainer: {
    padding: "42px 10px 30px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blackbirdLogo: {
    flexGrow: 1,
    cursor: "pointer",
  },
  drawerPaper: {
    overflow: (props) => (props.navOpen ? "auto" : "visible"),
    border: "none",
    background: "#1C192B",
    borderRight: "1.5px solid " + theme.palette.border.section,
    width: (props) => (props.navOpen ? DRAWER_WIDTH : DRAWER_CLOSED_WIDTH),
  },
  listItem: {
    padding: theme.spacing(1, 2.8),
    display: "flex",
    alignItems: "center",
    flexDirection: (props) => (props.navOpen ? "row" : "column"),
    "& .MuiListItemIcon-root": {
      margin: "0 auto",
      textAlign: "center",
      minWidth: (props) => (props.navOpen ? 32 : 0),
      "& svg": {
        maxWidth: 32,
        margin: "0 auto",
      },
    },
  },
  listItemText: {
    color: theme.palette.text.grey,
    fontWeight: "normal",
    "& .MuiTypography-root": {
      fontWeight: "inherit",
      fontSize: "inherit",
      whiteSpace: "inherit",
      lineHeight: "inherit",
    },
    marginBottom: 0,
    paddingLeft: (props) => (props.navOpen ? "14px" : 0),
    textAlign: (props) => (props.navOpen ? "left" : "center"),
    marginTop: (props) => (props.navOpen ? 0 : 8),
    lineHeight: "17px",
    fontSize: (props) => (props.navOpen ? "14px" : "12px"),
    whiteSpace: (props) => (props.navOpen ? "nowrap" : "normal"),
  },
  icon: {
    color: theme.palette.text.grey,
  },
  activeItem: {
    background: "rgba(36, 34, 52, 0.8)",
    "& .MuiListItemText-primary": {
      color: "#FFFFFF",
    },
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  openIcon: {
    position: "absolute",
    right: -47 / 2,
    display: (props) => (props.navOpen ? "none" : "block"),
    top: "19px",
    zIndex: 1000,
  },
  collapse: {
    marginLeft: 15,
    display: (props) => (props.navOpen ? "" : "none"),
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  userInfo: {
    marginTop: 30,
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
  },
  rotate: {
    transform: "rotate(180deg)",
  },
}));

const NavigationDrawer = ({
  setSpikeDate,
  navOpen,
  setNavOpen,
  validProject,
  hideProjects,
}) => {
  const classes = useStyles({ navOpen });
  const { sidebarTab } = useTabs();
  const { projectName, narrativeId } = useParams();
  const narrativeIndex = parseInt(narrativeId) || 0;
  const {
    state: { project, user },
  } = useAppContext();

  const url = projectName
    ? `/dashboard/${projectName}`
    : project?.name
    ? `/dashboard/${project.name}`
    : "/dashboard";

  const history = useHistory();
  const tracker = useEventTracker();

  const [anchorEl, setAnchorEl] = useState(null);

  const track = (value) => {
    tracker.track("Clicked sidebar navigation", "navigation", value, "Sidebar");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToDashboard = () => {
    if (narrativeIndex === ALL_PROJECT_INDEX) {
      history.push(url === "/dashboard" ? url : `${url}/overview`);
    } else {
      history.push(url === "/dashboard" ? url : `${url}/narrative/${narrativeIndex}/overview`);
    }
    setSpikeDate(null);
    track("Dashboard");
  };

  const goToNarrativeBuilder = () => {
    if (narrativeIndex === ALL_PROJECT_INDEX) {
      history.push(`${url}/discover`);
    } else {
      history.push(`${url}/narrative/${narrativeIndex}/refine`);
    }

    setSpikeDate(null);

    track("Narrative Builder");
  };

  const goToDataPage = () => {
    history.push(`/manage-data`);
  };

  const handleOpen = (event) => {
    track("Settings");
    setAnchorEl(event.currentTarget);
  };

  const openSidebar = () => {
    setNavOpen(true);
  };

  const hideSidebar = () => {
    setNavOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: navOpen,
        [classes.drawerClose]: !navOpen,
      })}
      classes={{
        root: classes.root,
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.blackbirdLogoContainer}>
        <BlackbirdTextLogo
          className={classes.blackbirdLogo}
          navOpen={navOpen}
        />
        <IconButton
          size="small"
          className={classes.collapse}
          onClick={hideSidebar}
          disableRipple
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <ArrowIcon />
        </IconButton>
      </div>
      <IconButton
        className={classes.openIcon}
        disableRipple
        sx={{
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent",
          },
        }}
        onClick={openSidebar}
      >
        <OpenIcon />
      </IconButton>
      <ListItem
        button
        dense
        className={clsx([
          classes.listItem,
          {
            [classes.activeItem]: sidebarTab === DASHBOARD_TAB,
          },
        ])}
        onClick={goToDashboard}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" className={classes.listItemText} />
      </ListItem>

      {!hideProjects && (
        <ProjectsList
          navOpen={navOpen}
          setNavOpen={(v) => {
            track("Projects");
            setNavOpen(v);
          }}
        />
      )}

      {validProject && (
        <ListItem
          button
          dense
          className={clsx([
            classes.listItem,
            {
              [classes.activeItem]: sidebarTab === NARRATIVE_BUILDER_TAB,
            },
          ])}
          onClick={goToNarrativeBuilder}
        >
          <ListItemIcon>
            <BuildIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Narrative Builder"
            className={classes.listItemText}
          />
        </ListItem>
      )}

      {user?.uploadAccess ? (
        <ListItem
          button
          dense
          className={clsx([
            classes.listItem,
            {
              [classes.activeItem]: sidebarTab === DATA_TAB,
            },
          ])}
          onClick={goToDataPage}
        >
          <ListItemIcon>
            <PlugIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Data" className={classes.listItemText} />
        </ListItem>
      ) : (
        <ListItem
          button
          dense
          className={classes.listItem}
          onClick={() => {
            const userToken = localStorage.getItem("jwtTokenUser");
            const user = JSON.parse(localStorage.getItem("user")) || {};
            const userEmail = user.email;
            const orgId = user.orgId;
            const orgName = user.orgName;

            window.open(
              `${UPLOADER_URL}/redirect?token=${userToken}&email=${userEmail}&orgId=${orgId}&orgName=${orgName}&userId=${user.id}`,
              "_blank"
            );

            track("Upload");
          }}
        >
          <ListItemIcon>
            <DataUploadIcon />
          </ListItemIcon>
          <ListItemText
            color="primary"
            primary="Upload"
            className={classes.listItemText}
          />
        </ListItem>
      )}

      <UserSettings anchorEl={anchorEl} handleClose={handleClose}>
        <ListItem
          button
          dense
          className={classes.listItem}
          onClick={handleOpen}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" className={classes.listItemText} />
        </ListItem>
      </UserSettings>

      <ListItem
        button
        dense
        className={classes.listItem}
        onClick={() => {
          window.open("/helpdesklogin", "_blank");
          track("Help Center");
        }}
      >
        <ListItemIcon>
          <FaqIcon />
        </ListItemIcon>
        <ListItemText
          color="primary"
          primary="Help Center"
          className={classes.listItemText}
        />
      </ListItem>
      <div className={classes.userInfo}>
        <UserInfo showOnlyAvatar={!navOpen} />
      </div>
    </Drawer>
  );
};

export default NavigationDrawer;
