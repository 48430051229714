/**
 * Notice, a little bit of explanation here!!!
 * # value = -1, means delete filter
 * # but for step sliders, value = 0 means delete.
 * # __filter and __option is used to keep original filter and selected option and process as we want
 */

import {
  COHORT_LABEL_MAPPINGS,
  EMOTIONS,
  EMOTION_MAPPINGS,
  PLATFORMS,
  SENTIMENTS,
  SENTIMENT_MAPPINGS,
  THRESHOLD_FIELDS,
  INVERTED_BOOLEANS,
  VALUE_FILTERS,
  BOOLEAN_GRAPHQL_FILTERS,
  DEFAULT_PROFILE,
  LANGUAGES,
  PARENT_USERS,
} from "utils/constants";
import { formatElasticSearchLabel, formatNumberkANDcomma } from "utils/format";
import { resolveCohortDisplayName } from "utils/cohorts";
import lodashObject from "lodash/object";

export const FILTER_TYPES = {
  step: "step",
  min_max: "min_max",
  categorical: "categorical",
  boolean: "boolean",
  date: "date",
  custom: "custom",
};

const DEFAULT_HARM_OPTIONS = [
  {
    label: "Low",
    value: "Low",
    num_threshold: 0.25,
    field: "low",
    profile: DEFAULT_PROFILE.id,
  },
  {
    label: "Medium",
    value: "Medium",
    num_threshold: 0.5,
    field: "medium",
    profile: DEFAULT_PROFILE.id,
  },
  {
    label: "High",
    value: "High",
    num_threshold: 0.75,
    field: "high",
    profile: DEFAULT_PROFILE.id,
  },
  {
    label: "Very High",
    value: "Very High",
    num_threshold: 1,
    field: "very_high",
    profile: DEFAULT_PROFILE.id,
  },
];

const checkIfExists = (val) => {
  return val !== "" && val !== undefined;
};

export const getValueNew = (f, value) => {
  // this empty object will be return as a signal to delete the filter
  // -1 will mean delete
  const empty = {
    [f.field]: {
      real: {},
      pillText: "",
      active: false,
      value: -1,
      isDynamic: f.isDynamic,
      elasticSearchField: f.elasticSearchField,
      __filter: f,
    },
  };

  switch (f.type) {
    case FILTER_TYPES.min_max: {
      let obj = {};

      if (checkIfExists(value[0]) && checkIfExists(value[1])) {
        obj = {
          ["min_" + f.field]: +value[0],
          ["max_" + f.field]: +value[1],
        };
      } else if (checkIfExists(value[0])) {
        obj = {
          ["min_" + f.field]: +value[0],
        };
      } else if (checkIfExists(value[1])) {
        obj = {
          ["max_" + f.field]: +value[1],
        };
      } else {
        return empty;
      }

      return {
        [f.field]: {
          __filter: f,
          real: obj,
          active: true,
          pillText: f.getPillText(f, null, value),
          value,
          isDynamic: f.isDynamic,
          elasticSearchField: f.elasticSearchField,
        },
      };
    }

    case FILTER_TYPES.categorical: {
      const rootField = f.field;
      const isBoolean = f.boolean; // filter is boolean, means all children are booleans
      const isMultiSelect = f.multi_select;

      let selected = isBoolean
        ? f.options
        : f.options.filter((d) => value.indexOf(d.value) > -1);

      if (selected.length) {
        let real = {};

        if (isBoolean) {
          selected.forEach((option) => {
            const selected = value.indexOf(option.value) > -1;
            real[option.field] = option.inverted
              ? selected
                ? undefined
                : true
              : selected;
          });
        } else if (isMultiSelect) {
          real[rootField] = selected.map((option) => option.field);
        } else {
          selected.forEach((option) => {
            real[option.field] = option.boolean ? true : option.threshold;
          });
        }

        // Add properties inside of an object instead of at the top level,
        // if 'requestPropertyGroup' is set on the filter configuration.
        if (f.requestPropertyGroup) {
          real = { [f.requestPropertyGroup]: real };
        }

        return {
          [f.field]: {
            __filter: f,
            __option: selected,
            pillText: f.getPillText(f, selected, value),
            real,
            active: true,
            value,
            isDynamic: f.isDynamic,
            elasticSearchField: f.elasticSearchField,
          },
        };
      }

      return empty;
    }
    default:
      return empty;
  }
};

const getMinMaxText = (filter, option, value) => {
  const [min, max] = value;

  let txt = "";

  if (min !== "" && max !== "") {
    txt = `${formatNumberkANDcomma(min)}-${formatNumberkANDcomma(max)}`;
  } else if (min !== "") {
    txt = `> ${formatNumberkANDcomma(min)}`;
  } else if (max !== "") {
    txt = `< ${formatNumberkANDcomma(max)}`;
  }

  if (txt) {
    return txt + " " + filter.title;
  }

  return "";
};

const getCategoricalText = (filter, option, selectedOptions) => {
  let firstTwo = selectedOptions.slice(0, 2);
  const rest = selectedOptions.slice(2);
  if (filter && filter.isFormatted) {
    firstTwo = firstTwo.map((f) => formatElasticSearchLabel(f));
  }
  return (
    (filter && filter.inverted ? "Hiding " : "") +
    firstTwo.join(", ") +
    (rest.length ? ` +${rest.length}` : "")
  );
};

const filters = {
  date: {
    order: 0,
    title: "Date",
    field: "date",
    type: FILTER_TYPES.date,
    alwaysVisible: true,
  },
  data: {
    order: 0.25,
    type: FILTER_TYPES.categorical,
    title: "Data",
    field: "data",
    boolean: true,
    alwaysVisible: true,
    options: [
      {
        label: "Original Posts",
        field: "is_share",
        value: "Show Original",
        pillText: "Original Posts",
        tooltip: "",
        inverted: true,
      },
      {
        label: "Local Shared Posts",
        field: "is_orig",
        value: "Show Shared",
        pillText: "Local Shared Posts",
        tooltip: "Retweets captured within the original data collection.",
        inverted: true,
      },
      {
        label: "Extended Data",
        field: "is_base",
        value: "Show Extended",
        pillText: "Extended Data",
        tooltip:
          "Posts which do not meet the collection rule--perhaps due to being outside the collection time window or not matching other metadata filters--but have been retweeted or commented on by at least one post that does meet the collection rule.",
        inverted: true,
      },
      {
        label: "Verified Accounts",
        field: "skip_verified",
        pillText: "Verified Accounts",
        value: "Show Verified",
        inverted: true,
      },
    ],
    getPillText: (filter, option, selectedOptions) => {
      let text = "";
      let inverted = filter.options.filter((x) => x.inverted);
      let noninverted = filter.options.filter((x) => !x.inverted);

      if (selectedOptions.length === filter.options.length) {
        text = "All data";
      } else if (selectedOptions.length === 0) {
        text = `Hiding ${inverted[0].pillText} and ${inverted[1].pillText}`;
      } else {
        let notchecked = inverted
          .filter((x) => selectedOptions.indexOf(x.value) === -1)
          .map((x) => x.pillText);

        if (notchecked.length) {
          text = "Hiding " + notchecked.join(", ");
        }

        let checked = noninverted
          .filter((x) => selectedOptions.indexOf(x.value) > -1)
          .map((x) => x.pillText);

        if (checked.length) {
          if (notchecked.length) text += " and ";
          text += "Showing " + checked.join(", ");
        }

        if (!notchecked.length && !checked.length) {
          text = filter.title;
        }
      }

      return text;
    },
  },
  risk_signals: {
    order: 0.5,
    type: FILTER_TYPES.categorical,
    title: "Risk signals",
    field: "risk_signals",
    requestPropertyGroup: "genericFilters",
    alwaysVisible: true,
    options: [
      {
        label: "Bot-like",
        field: "bot_score",
        value: "Bot-like",
        boolean: true,
      },
      {
        label: "Anomalous",
        field: "bbmi",
        value: "Anomalous",
        boolean: true,
      },
      // {
      //   label: "Known hoax",
      //   isBeta: true,
      //   boolean: true,
      //   field: "known_hoax",
      //   value: "Known hoax",
      // },
      {
        label: "Toxic content",
        field: "toxicity",
        value: "Toxic",
        boolean: true,
      },
      {
        label: "Influential",
        field: "pagerank",
        value: "Influential",
        boolean: true,
      },
    ],
    getPillText: getCategoricalText,
    tooltip: "",
  },
  harm: {
    order: 1,
    type: FILTER_TYPES.categorical,
    title: "Risk",
    field: "harm",
    multi_select: true,
    options: DEFAULT_HARM_OPTIONS,
    class: "harm-slider",
    disabled: false,
    getPillText: (filter, option, value) => {
      const suffix = value.length > 0 ? " " + filter.title : "";
      return getCategoricalText(filter, option, value) + suffix;
    },
  },
  is_harmful: {
    order: 1,
    type: FILTER_TYPES.categorical,
    title: "Risk",
    field: "is_harmful",
    options: [
      {
        label: "High Risk content only",
        value: true,
        field: "is_harmful",
        profile: 1,
        boolean: true,
      },
      // {
      //   label: "No harmful content",
      //   value: false,
      //   field: "is_harmful",
      //   profile: 1,
      //   inverted: true,

      // },
    ],
    disabled: true,
    class: "harm-slider",
    getPillText: (filter) => {
      return filter.title;
      // const suffix = value.length > 0 ? " " + filter.title : "";
      // return getCategoricalText(filter, option, value) + suffix;
    },
  },
  engagement: {
    order: 2,
    type: FILTER_TYPES.min_max,
    title: "Engagements",
    field: "engagement",
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  likes: {
    order: 3,
    type: FILTER_TYPES.min_max,
    title: "Likes",
    field: "likes",
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  shares: {
    order: 4,
    type: FILTER_TYPES.min_max,
    title: "Global Shares",
    field: "shares",
    tooltip: "Total retweets at the time of data ingestion.",
    getPillText: getMinMaxText,
  },
  user_group_names: {
    order: 4.1,
    multi_select: true,
    type: FILTER_TYPES.categorical,
    title: "User Groups",
    field: "user_group_names",
    usersFilter: true,
    options: [],
    addNew: "Create a user group",
    getPillText: getCategoricalText,
  },
  parent_group_names: {
    order: 4.2,
    multi_select: true,
    type: FILTER_TYPES.categorical,
    title: "Parent User Group",
    field: "parent_group_names",
    usersFilter: true,
    options: [],
    addNew: "Create a user group",
    getPillText: (filter, option, value) => {
      const prefix = value.length > 0 ? "Parent: " : "";
      return prefix + getCategoricalText(filter, option, value);
    },
  },
  min_cohort_scores: {
    order: 5,
    title: "Cohorts",
    type: FILTER_TYPES.categorical,
    field: "min_cohort_scores",
    requestPropertyGroup: "cohortFilters",
    options: [], // this will be overwritten in getFilters
    getPillText: getCategoricalText,
    tooltip: "",
  },
  emotion: {
    order: 6,
    title: "Emotion",
    type: FILTER_TYPES.categorical,
    field: "emotion",
    requestPropertyGroup: "emotionFilters",
    options: EMOTIONS.map((field) => ({
      label: EMOTION_MAPPINGS[field],
      value: EMOTION_MAPPINGS[field],
      field,
      boolean: true,
    })),
    getPillText: getCategoricalText,
    tooltip: "",
  },
  sentiment: {
    order: 7,
    title: "Sentiment",
    type: FILTER_TYPES.categorical,
    field: "sentiment",
    requestPropertyGroup: "sentimentFilters",
    options: SENTIMENTS.map((field) => ({
      label: SENTIMENT_MAPPINGS[field],
      value: SENTIMENT_MAPPINGS[field],
      field,
      boolean: true,
    })),
    getPillText: getCategoricalText,
    tooltip: "",
  },
  partisanship: {
    order: 9,
    type: FILTER_TYPES.categorical,
    title: "Partisan",
    class: "partisan-slider",
    multi_select: true,
    options: [
      {
        label: "Left",
        value: "Left",
        field: "left",
      },
      {
        label: "Center",
        field: "center",
        value: "Center",
      },
      {
        label: "Right",
        value: "Right",
        field: "right",
      },
    ],
    field: "partisanship",
    getPillText: (filter, option, value) => {
      const suffix = value.length > 0 ? " " + filter.title.toLowerCase() : "";
      return getCategoricalText(filter, option, value) + suffix;
    },
  },
  language: {
    order: 11,
    type: FILTER_TYPES.categorical,
    title: "Language",
    field: "language",
    multi_select: true,
    options: [],
    class: "",
    getPillText: (filter, option, value) => {
      const selectedOptions = value.map(
        (d) => filter.options.find((x) => x.value === d)?.label
      );
      const firstTwo = selectedOptions.slice(0, 2);
      const rest = selectedOptions.slice(2);

      return firstTwo.join(", ") + (rest.length ? ` +${rest.length}` : "");
    },
  },
};

const redditFilters = {
  date: filters.date,
  risk_signals: {
    order: 0.5,
    type: FILTER_TYPES.categorical,
    title: "Risk signals",
    field: "risk_signals",
    requestPropertyGroup: "genericFilters",
    alwaysVisible: true,
    options: [
      // {
      //   label: "Bot-like",
      //   field: "bot_score",
      //   value: "Bot-like",
      //   boolean: true,
      // },
      {
        label: "Anomalous",
        field: "bbmi",
        value: "Anomalous",
        boolean: true,
      },
      // {
      //   label: "Known hoax",
      //   isBeta: true,
      //   boolean: true,
      //   field: "known_hoax",
      //   value: "Known hoax",
      // },
      {
        label: "Toxic content",
        field: "toxicity",
        value: "Toxic",
        boolean: true,
      },
      {
        label: "Influential",
        field: "pagerank",
        value: "Influential",
        boolean: true,
      },
    ],
    getPillText: getCategoricalText,
    tooltip: "",
  },
  harm: filters.harm,
  is_harmful: filters.is_harmful,
  engagement: filters.engagement,
  // shares: {
  //   order: 4,
  //   type: FILTER_TYPES.min_max,
  //   title: "Shares",
  //   field: "shares",
  //   getPillText: getMinMaxText,
  // },
  user_group_names: filters.user_group_names,
  parent_group_names: filters.parent_group_names,
  comments: {
    order: 4.1,
    type: FILTER_TYPES.min_max,
    title: "Comments",
    field: "comment_ct",
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  upvotes: {
    order: 4.2,
    type: FILTER_TYPES.min_max,
    title: "Upvotes",
    field: "up_votes",
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  // downvotes: {
  //   order: 4.3,
  //   type: FILTER_TYPES.min_max,
  //   title: "Downvotes",
  //   field: "down_votes",
  //   getPillText: getMinMaxText,
  //   getTooltipText: getMinMaxText,
  // },
  min_cohort_scores: {
    ...filters.min_cohort_scores,
  }, // this will be overwritten in getFilters
  emotion: filters.emotion,
  sentiment: filters.sentiment,
  partisanship: filters.partisanship,
  language: filters.language,
};

export const harmFilter = filters.harm;

const RISK_SIGNAL_FIELDS = filters.risk_signals.options.map(
  ({ field }) => field
);
const DATA_FIELDS = filters.data.options.map(({ field }) => field);

const createDynamicFilter = (dynamicFilter) => {
  if (dynamicFilter.type && dynamicFilter.type) {
    const isCustom = dynamicFilter.options && dynamicFilter.options.length;
    switch (dynamicFilter.type) {
      case FILTER_TYPES.categorical:
        if (isCustom) {
          return {
            tooltip: "",
            getPillText: (filter, options, values) => {
              let retString = "";
              const activeOptions = options.filter((o) =>
                o.inverted
                  ? !values.includes(o.value)
                  : values.includes(o.value)
              );
              for (let i = 0; i < activeOptions.length; i++) {
                const option = activeOptions[i];
                retString += option.pillText;
                if (i < activeOptions.length - 1) {
                  retString += ", ";
                }
              }
              if (!activeOptions || !activeOptions.length) {
                retString += dynamicFilter.title;
              }
              return retString;
            },
            boolean: true,
            options: dynamicFilter.options.map((fo) => {
              return {
                label: fo.display_name,
                field: fo.field,
                value: fo.display_name,
                pillText: fo.pill_text,
                inverted: fo.inverted,
                tooltip: "",
              };
            }),
          };
        }

        return {
          tooltip: "",
          multi_select: true,
          getPillText: getCategoricalText,
          options: [],
        };
      case FILTER_TYPES.min_max:
        return {
          getPillText: getMinMaxText,
          getTooltipText: getMinMaxText,
        };
      case FILTER_TYPES.custom:
        return {
          title: "test",
          type: FILTER_TYPES.categorical,
          boolean: true,
          getPillText: (filter, option, value) => {
            const suffix =
              value.length > 0 ? " " + filter.title.toLowerCase() : "";
            return getCategoricalText(filter, option, value) + suffix;
          },
          options: dynamicFilter.options.map((fo) => {
            return {
              label: fo.display_name,
              field: fo.field,
              value: "Show comments",
              pillText: "Show comments",
              inverted: fo.inverted,
              tooltip: "",
            };
          }),
        };

      default:
        return {};
    }
  }
};

export const getFilters = (
  platform,
  flags,
  harmProfile,
  cohortsToShow,
  userGroups,
  languages,
  dynamicFilters,
  unsupportedFeatures,
) => {
  let filtersObject = { ...filters };

  if (platform === PLATFORMS.reddit) {
    filtersObject = { ...redditFilters };
  }

  if (dynamicFilters && dynamicFilters.length) {
    dynamicFilters.forEach((ff) => {
      filtersObject[ff.field] = {
        ...ff,
        isDynamic: true,
        ...createDynamicFilter(ff),
      };
    });
  }

  if (flags.multilingual) {
    filtersObject.language.options = languages
      .filter((d) => LANGUAGES[d.name])
      .map((d) => ({
        label: LANGUAGES[d.name].name,
        value: d.name,
        field: d.name,
      }));
  } else {
    delete filtersObject.language;
  }

  if (flags.userGroups) {
    filtersObject.user_group_names.options = userGroups.map((d) => ({
      id: d.id,
      label: d.name,
      value: d.name,
      field: d.name,
      editable: true,
      deletable: true,
      datum: d,
    }));
    filtersObject.parent_group_names.options = userGroups.map((d) => ({
      id: d.id,
      label: d.name,
      value: d.name,
      field: d.name,
      editable: true,
      deletable: true,
      datum: d,
    }));
  } else {
    delete filtersObject.user_group_names;
    delete filtersObject.parent_group_names;
  }

  if (filtersObject.parent_group_names)
  { 
    const parentUserSupported =
      unsupportedFeatures &&
      PARENT_USERS in unsupportedFeatures &&
      unsupportedFeatures[PARENT_USERS];
    filtersObject.parent_group_names.disabled = parentUserSupported;
  }

  if (harmProfile.id !== DEFAULT_PROFILE.id) {
    if (filtersObject.is_harmful) {
      filtersObject.is_harmful.disabled = false;
    }
    filtersObject.harm.disabled = true;
  } else {
    if (filtersObject.is_harmful) {
      filtersObject.is_harmful.disabled = true;
    }
    filtersObject.harm.disabled = false;
  }

  if (!flags.isBlackBirdEmployee) {
    filtersObject.risk_signals.options =
      filtersObject.risk_signals.options.filter(
        (d) => d.field !== "is_known_hoax"
      );
  }

  // create cohort filters using `cohortsToShow`
  if (cohortsToShow) {
    const mcs = filtersObject.min_cohort_scores;
    filtersObject = {
      ...filtersObject,
      min_cohort_scores: {
        ...mcs,
        options: cohortsToShow.map((cohort) => {
          const displayName =
            COHORT_LABEL_MAPPINGS[cohort] || resolveCohortDisplayName(cohort);
          return {
            label: displayName,
            value: displayName,
            field: cohort,
            boolean: true,
          };
        }),
      },
    };
  }

  return Object.values(filtersObject).sort((a, b) => {
    return a.order - b.order;
  });
};

// "serialize" is a misnomer
export const serializeFilterObj = (filterObjToBeSerialized) => {
  const serialized = {};
  for (const key in filterObjToBeSerialized) {
    lodashObject.assign(serialized, filterObjToBeSerialized[key].real || {});
  }

  serialized["dynamicFilters"] = getSerializedDynamicFilters(
    filterObjToBeSerialized
  );
  return {
    obj: filterObjToBeSerialized, // TODO there's no point in returning an argument
    serialized,
  };
};

function getSerializedDynamicFilters(filters) {
  let serializedDynamicFilters = undefined;
  const eligibleDynamicFilters = Object.values(filters).filter(
    (fil) =>
      fil.isDynamic &&
      fil.__filter &&
      fil.__filter.elasticSearchField &&
      fil.__filter.boolType
  );

  serializedDynamicFilters = eligibleDynamicFilters.map((df) =>
    createSerializedDynamicFilter(df, df.value)
  );
  return serializedDynamicFilters;
}

const createSerializedDynamicFilter = (filter, value) => {
  let valueArr = [value].flat();

  if (filter.__filter.type === FILTER_TYPES.min_max) {
    valueArr = [
      createSerializedDynamicFilterObj(filter, {
        min: value[0] ? value[0] : 0,
        max: value[1],
      }),
    ];
  } else {
    valueArr = value.map((v) => createSerializedDynamicFilterObj(filter, v));
  }
  return { field: filter.__filter.field, value: valueArr };
};

function createSerializedDynamicFilterObj(filter, value) {
  let dynamicFilterField = filter.__filter.elasticSearchField;
  if (filter.__filter.isParent) {
    dynamicFilterField += "." + value;
  }

  return {
    elasticSearchField: dynamicFilterField,
    boolType: filter.__filter.boolType,
    isTunable: filter.__filter.isTunable,
    value: value,
  };
}

export const getPostFilters = (filters, cohortNames) => {
  // we get the serialized values here;
  const postFilters = [];
  const seen = {};

  const entries = Object.keys(filters)
    .map((key) => {
      const filter = filters[key];

      if (typeof filter === "object" && key !== "dynamicFilters") {
        if (Array.isArray(filter)) {
          return [[key, Object.values(filter)]];
        }

        return Object.entries(filter);
      }

      return [[key, filter]];
    })
    .flat();

  for (const [filterField, value] of entries) {
    if (
      THRESHOLD_FIELDS.includes(filterField) ||
      cohortNames.includes(filterField)
    ) {
      //greater than threshold
      postFilters.push({ minValue: value, field: filterField });
    } else if (VALUE_FILTERS[filterField]) {
      if (INVERTED_BOOLEANS[filterField]) {
        postFilters.push({
          value: value ? undefined : true,
          field: filterField,
        });
      } else {
        postFilters.push({ value, field: filterField });
      }
    } else if (
      filterField.startsWith("min_") ||
      filterField.startsWith("max_")
    ) {
      const field = filterField.slice(4);
      if (seen[field]) continue;
      seen[field] = field;

      //check if min or max
      const isMin = filterField.startsWith("min_");
      const oppositeFilterPrefix = isMin ? "max_" : "min_";

      //get opposing filter
      const oppositeFilterValue = filters[`${oppositeFilterPrefix}${field}`];

      // if opposing filter is there
      if (oppositeFilterValue !== undefined) {
        const maxValue = isMin ? oppositeFilterValue : value;
        const minValue = !isMin ? oppositeFilterValue : value;
        postFilters.push({
          maxValue,
          minValue,
          field,
        });
      } else {
        const threshold = isMin ? "minValue" : "maxValue";
        postFilters.push({
          [threshold]: value,
          field,
        });
      }
    } else {
      postFilters.push({ value, field: filterField });
    }
  }
  return postFilters;
};

export const getDefaultPostFilters = (postFilters) => {
  const filters = postFilters || [];

  let defaultFields = Object.keys(INVERTED_BOOLEANS);

  defaultFields.forEach((f) => {
    !filters.find(({ field }) => field === f) &&
      filters.push({ field: f, value: true });
  });
  return filters;
};

export const getFilterRequestFromPostFilters = (postFilters, cohortNames) => {
  const filters = {};

  for (const { minValue, maxValue, value, field } of postFilters) {
    if (THRESHOLD_FIELDS.includes(field) || cohortNames.includes(field)) {
      filters[field] = minValue;
    } else if (VALUE_FILTERS[field]) {
      filters[field] = value;
    } else {
      if (minValue !== undefined) {
        filters[`min_${field}`] = minValue;
      }
      if (maxValue !== undefined) {
        filters[`max_${field}`] = maxValue;
      }
      if (maxValue === undefined && minValue === undefined) {
        filters[field] = value;
      }
    }
  }

  return filters;
};

export const getDefaultFilters = (postFilters, cohortNames, dynamicFilters) => {
  const filters = getDefaultPostFilters(postFilters);

  const defaultFilters = getFilterObjFromPostFilters(
    filters,
    cohortNames,
    dynamicFilters
  );
  return defaultFilters;
};

const getCommonFilter = (filterField, cohortNames) => {
  if (filters[filterField]) {
    return filters[filterField];
  } else if (EMOTION_MAPPINGS[filterField]) {
    return filters.emotion;
  } else if (SENTIMENT_MAPPINGS[filterField]) {
    return filters.sentiment;
  } else if (cohortNames.includes(filterField)) {
    filters.min_cohort_scores.options = cohortNames.map((cohort) => {
      const displayName =
        COHORT_LABEL_MAPPINGS[cohort] || resolveCohortDisplayName(cohort);
      return {
        label: displayName,
        value: displayName,
        field: cohort,
        boolean: true,
      };
    });

    return filters.min_cohort_scores;
  } else if (DATA_FIELDS.includes(filterField)) {
    return filters.data;
  } else if (RISK_SIGNAL_FIELDS.includes(filterField)) {
    return filters.risk_signals;
  }
  return undefined;
};

const getFilterValue = (
  commonFilter,
  req,
  requestField,
  filterField,
  isMin,
  isMax,
  seen,
  cohortNames
) => {
  if (
    commonFilter.type === FILTER_TYPES.boolean ||
    commonFilter.type === FILTER_TYPES.step
  ) {
    //return boolean or step value
    return commonFilter.inverted ? !req[requestField] : req[requestField];
  } else if (commonFilter.type === FILTER_TYPES.min_max) {
    //get opposite filter
    const oppositeFilterPrefix = isMin ? "max_" : "min_";
    const oppositeFilterValue = req[`${oppositeFilterPrefix}${filterField}`];

    //Set array in format [minValue, maxValue] as strings
    if (oppositeFilterValue !== undefined) {
      const maxValue = isMin ? oppositeFilterValue : req[requestField];
      const minValue = !isMin ? oppositeFilterValue : req[requestField];
      return [minValue, maxValue];
    } else if (isMin) {
      return [req[requestField], ""];
    } else if (isMax) {
      return ["", req[requestField]];
    }
  } else if (EMOTION_MAPPINGS[filterField]) {
    const filterObjValue = [];
    EMOTIONS.forEach((e) => {
      if (req[e] !== undefined) {
        filterObjValue.push(EMOTION_MAPPINGS[e]);
      }
      //set for all emotions in request
      seen[e] = true;
    });
    return filterObjValue;
  } else if (SENTIMENT_MAPPINGS[requestField]) {
    const filterObjValue = [];
    SENTIMENTS.forEach((s) => {
      if (req[s] !== undefined) {
        filterObjValue.push(SENTIMENT_MAPPINGS[s]);
      }
      //set for all sentiments in request
      seen[s] = true;
    });
    return filterObjValue;
  } else if (cohortNames.includes(filterField)) {
    const filterObjValue = [];
    cohortNames.forEach((c) => {
      if (req[c] !== undefined) {
        filterObjValue.push(COHORT_LABEL_MAPPINGS[c]);
      }
      //set for all cohorts in request
      seen[c] = true;
    });
    return filterObjValue;
  } else if (commonFilter.type === FILTER_TYPES.categorical) {
    const filterObjValue = [];
    commonFilter.options.forEach(({ field, value, threshold }) => {
      // threshold filter
      if (threshold !== undefined) {
        if (req[field] === threshold) {
          filterObjValue.push(value);
          seen[field] = true;
        }
      }
      // multiple select
      else if (commonFilter.multi_select) {
        const requestValue = req[commonFilter.field];
        if (requestValue && requestValue.includes(field)) {
          filterObjValue.push(value);
        }
      }
      // booleans
      else if (req[field]) {
        filterObjValue.push(value);
        seen[field] = true;
      }
    });

    return filterObjValue;
  }
};

export const getFilterObjFromPostFilters = (
  postFilters,
  cohortNames,
  dynamicFilters
) => {
  const req = getFilterRequestFromPostFilters(postFilters, cohortNames);
  const filterFields = Object.keys(req);

  let filterObj = {};
  const seen = {};
  for (let index = 0; index < filterFields.length; index++) {
    const requestField = filterFields[index];

    //check for min/max
    const notThreshold =
      !THRESHOLD_FIELDS.includes(requestField) &&
      !cohortNames.includes(requestField);
    const isMin = requestField.startsWith("min_");
    const isMax = requestField.startsWith("max_");
    const filterField =
      (isMin || isMax) && notThreshold ? requestField.slice(4) : requestField;

    //skip if seen
    if (seen[filterField]) continue;
    seen[filterField] = true;

    //lookup commonfilter from commonFilters
    const commonFilter = getCommonFilter(filterField, cohortNames);
    /// create default here
    //skip if no commonFilter found
    if (!commonFilter) continue;

    //get value to set for filter
    const filterObjValue = getFilterValue(
      commonFilter,
      req,
      requestField,
      filterField,
      isMin,
      isMax,
      seen,
      cohortNames
    );

    //get filter value representation
    const v = getValueNew(commonFilter, filterObjValue);

    filterObj = {
      ...filterObj,
      ...v,
    };
  }

  const defaultDynamicFilters = dynamicFilters.filter((df) => df.isDefault);

  for (const df of defaultDynamicFilters) {
    let pillText = "";
    const values = df.options;

    // TODO here to better serve dyanism - add inverted logic
    const activeValues = values.filter((v) =>
      postFilters.find((pf) => pf.field === v.field && pf.value)
    );
    const inactiveValues = values.filter((v) =>
      postFilters.find((pf) => pf.field === v.field && !pf.value)
    );

    for (let i = 0; i < inactiveValues.length; i++) {
      const value = inactiveValues[i];

      pillText += value.pill_text;

      if (i < inactiveValues.length - 1) {
        pillText += ", ";
      }
    }
    if (!inactiveValues || !inactiveValues.length) {
      pillText = df.title;
    }
    const real = {};

    for (const activeValue of activeValues) {
      real[activeValue.field] = undefined;
    }
    for (const inactive of inactiveValues) {
      real[inactive.field] = true;
    }

    filterObj[df.field] = {
      active: true,
      pillText: pillText,
      real: real,
      value: activeValues.map((av) => av.display_name),
      isDynamic: true,
      __filter: { ...df, isDynamic: true, ...createDynamicFilter(df) },
    };
  }

  const dynamicPostFilters = postFilters.find(
    (pdf) => pdf.field === "dynamicFilters"
  );
  if (dynamicPostFilters && dynamicPostFilters.value) {
    for (const dynamicPostFilter of dynamicPostFilters.value) {
      const storeDynamicFilter = dynamicFilters.find(
        (df) => df.field === dynamicPostFilter.field
      );

      if (storeDynamicFilter) {
        if (filterObj[dynamicPostFilter.field]) {
          filterObj[dynamicPostFilter.field].value.push(
            dynamicPostFilter.value
          );
          filterObj[dynamicPostFilter.field].value =
            filterObj[dynamicPostFilter.field].value.flat();

          let newRealVal = [
            filterObj[dynamicPostFilter.field].real[dynamicPostFilter.field],
          ];
          newRealVal.push(dynamicPostFilter.value);
          filterObj[dynamicPostFilter.field].real[dynamicPostFilter.field] =
            newRealVal.flat();
        } else {
          const valueArr = dynamicPostFilter.value.map((v) => v.value);

          filterObj[dynamicPostFilter.field] = {
            active: true,
            isDynamic: true,
            real: {
              [dynamicPostFilter.field]: valueArr,
            },
            value: valueArr,
            pillText: getCategoricalText(null, null, valueArr),
            __filter: {
              ...storeDynamicFilter,
              isDynamic: true,
              ...createDynamicFilter(storeDynamicFilter),
            },
          };
        }
      }
    }
  }

  Object.keys(filterObj).forEach((key) => {
    const d = filterObj[key];

    if (d.value === -1 && Object.keys(d.real).length === 0) {
      delete filterObj[key];
    }
  });

  //return serialized filters in form {arr, obj, serialized}
  const serializedFilterObj = serializeFilterObj(filterObj);
  return serializedFilterObj;
};

export const getGraphQLFilter = (
  keywords,
  start_date,
  end_date,
  excludeClusters,
  filterRequest,
  entities,
  cohortNames,
  harmProfile
) => {
  const filter = {
    keywords, //TODO merge with dynamic search
    start_date,
    end_date,
    clusters: excludeClusters || [],
    ...entities,
  };

  const post_filters = getPostFilters(filterRequest, cohortNames, harmProfile);

  post_filters.forEach((thresholdFilter) => {
    const { field, ...values } = thresholdFilter;

    if (BOOLEAN_GRAPHQL_FILTERS[field]) {
      filter[field] = values.value;
    } else {
      filter[field] = values;
    }
  });

  return filter;
};
