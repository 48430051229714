import PropertyList from "./PropertyList";
import PieChart from "components/UI/PieChart";
import { descSort } from "utils/sort";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("md")]: {
			flexDirection: "row"
		},
	}
}));

export default function DataDisplay({ data, setData, tab, shouldReanimate }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<PieChart
				name={tab}
				data={data}
				setData={setData}
				shouldReanimate={shouldReanimate}
				showLabels={false}
				height={300}
				useTooltip={true}
			/>
			<PropertyList
				data={data.sort(descSort)}
			/>
		</div>
	);
}
