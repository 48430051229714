import React from "react";
import {ReactComponent as ArtWork} from "icons/Uploader/Artwork.svg";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    paddingBottom: 30
  },
  subtitle: {
    color: "#8684A4",
    marginTop: 15,
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

export default function EmptyBanner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ArtWork width="180"/>

      <Typography>It’s empty here...</Typography>
    </div>
  );
}