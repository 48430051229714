import React from "react";
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "./Tooltip";
import { COHORT_TOOLTIPS } from "utils/constants";
import { resolveCohortDisplayName } from "utils/cohorts";

const useStyles = makeStyles(() => ({
	chipRoot: {
		background: "linear-gradient(250.87deg, rgba(88, 88, 213, 0.3) -2.92%, rgba(113, 88, 213, 0.3) 106.15%)",
		height: "24px",
	},
	chipSmall: {
		fontSize: "12px",
		fontWeight: 500,
		border: "1px solid #6757d4",
	}
}));

const CohortChip = (props) => {
	const classes = useStyles(props);

	const chip = (
		<Chip
			classes={{
				root: classes.chipRoot,
				sizeSmall: classes.chipSmall
			}}
			label={resolveCohortDisplayName(props.cohortName)}
			variant="outlined"
			size="small"
		/>
	);

	const tooltipText = COHORT_TOOLTIPS[props.cohortName];
	if (tooltipText) {
		return (<Tooltip text={tooltipText}>{chip}</Tooltip>);
	}
	else {
		return chip;
	}
};

export default CohortChip;
