import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SimpleTooltip from "components/UI/SimpleTooltip";
import { ReactComponent as EditNarrative } from "icons/EditNarrative.svg";
import { ReactComponent as DeleteIcon } from "icons/Delete.svg";
import { ReactComponent as VisibilityIcon } from 'icons/eye.svg';
import { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export default function TableActions({ onDelete, onEdit, onView }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    handleTooltipClose();
    onDelete();
  }

  return (
    <Box>
      {onView && (
        <IconButton onClick={onView}>
          <VisibilityIcon />
        </IconButton>
      )}
      {onEdit && (
        <IconButton onClick={onEdit}>
          <EditNarrative />
        </IconButton>
      )}
      {onDelete && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Box component={"span"}>
            <SimpleTooltip
              contentComp={
                <Box>
                  <Typography>
                    Are you sure you want to delete this job?
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Button sx={{ mr: 0.5 }} onClick={handleDelete} variant="contained">Delete</Button>
                    <Button sx={{ ml: 0.5 }} onClick={handleTooltipClose} variant="text">Cancel</Button>
                  </Box>
                </Box>
              }
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipClose}
              open={open}
            >
              <IconButton color="error" onClick={handleTooltipOpen}>
                <DeleteIcon />
              </IconButton>
            </SimpleTooltip>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}
