import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as AddIcon } from "icons/UI/add.svg";
import { ReactComponent as RemoveIcon } from "icons/UI/remove.svg";
import { Typography, IconButton, Alert } from "@mui/material";
import { useState } from "react";
import CustomDropdown from "components/UI/CustomDropdown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {},
	titleWrap: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		borderBottom: "1px solid #424242",
	},
	title: {
		color: "#AFAFAF",
		fontSize: 15,
		lineHeight: "17px",
	},
	slider: {
		marginBottom: 10,
	},
	dropdown: {
		minWidth: "40%",
		marginRight: "15px",
		borderRadius: 4,
		background: "#302E4F",
		"& .MuiSelect-select": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      paddingRight: "12px !important",
      paddingLeft: "10px !important",
    },
		"& .dropdown_icon": {
      right: "10px !important",
    },
	},
	queryRoot: {
		padding: theme.spacing(2, 0),
	},
	queryRow: {
		borderLeft: "1px solid " + theme.palette.slider.main,
		padding: theme.spacing(1, 0, 1, 2),
	},
	borderNot: {
		borderLeft: "1px solid red",
	},
	dropdownAndSlider: {
		width: "100%",
		display: "flex",
		alignItems: "center",
	},
	removeBtn: {
		display: "flex",
		alignItems: "center",
	},
	addBtn: {
		width: 36,
		minWidth: 36,
		height: 36,
		display: "flex",
		alignItems: "center",
	},
	switchBtn: {
		borderColor: "#302E4F",
		fontWeight: "normal",
		marginLeft: "0px !important",
		"&:hover": {
			border: "1px solid #AFAFAF !important"
		}
	},
	switchBtnActive: {
		backgroundColor: "#302E4F",
	},
	gap: {
		paddingTop: theme.spacing(3),
	},
	andOr: {
		paddingLeft: 24,
		position: "relative",
		marginBottom: theme.spacing(2),
		"&::before": {
			content: "\"\"",
			width: "20px",
			position: "absolute",
			top: "50%",
			left: "0%",
			height: "1px",
			background: "#282C38",
		},
		"&::after": {
			content: "\"\"",
			width: "calc(100% - 162px)",
			position: "absolute",
			top: "50%",
			right: "0%",
			height: "1px",
			background: "#282C38",
		},
	},
}));

export default function QueryBuilder({
	title,
	value,
	onChange,
	statusColor,
	showError,
	metricOptions,
	disabled = false
}) {
	const classes = useStyles({ statusColor });
	const [localValue, setLocalValue] = useState(value);

	const handleUpdate = (index, key, value) => {
		const datum = { ...localValue[index] };
		datum[key] = value;
		const newVal = [...localValue];
		newVal[index] = datum;
		setLocalValue(newVal);
		onChange(
			newVal, 
			key === "field" ? "select" : null, 
			metricOptions.find(d => d.value === value)?.label
		);
	};

	const handleRemove = (index) => {
		const newVal = [...localValue];
		const oldVal = localValue[index];
		newVal.splice(index, 1);
		setLocalValue(newVal);
		onChange(
			newVal, 
			"remove", 
			metricOptions.find(d => d.value === oldVal.field)?.label
		);
	};

	const handleAddNew = () => {
		const newVal = [...localValue];
		newVal.push({
			field: "",
			operator: "and",
			value: true,
			min: 0,
			max: 100,
		});
		setLocalValue(newVal);
		onChange(newVal);
	};

	const handleInsert = (index) => {
		const newVal = [...localValue];
		newVal.splice(index + 1, 0, {
			field: "",
			operator: "and",
			value: true,
			min: 0,
			max: 100,
		});
		setLocalValue(newVal);
		onChange(newVal);
	};

	return (
		<div className={classes.root}>
			<div className={classes.titleWrap}>
				<Typography className={classes.title}>{title}</Typography>
				<IconButton onClick={handleAddNew} disabled={disabled}>
					<AddIcon />
				</IconButton>
			</div>
			<div className={classes.queryRoot}>
				{showError && localValue.length === 0 && (
					<Alert severity="error" size="small">
            Fill the condition
					</Alert>
				)}
				{localValue.map((d, i) => (
					<>
						{i > 0 && (
							<div
								key={i + "gap"}
								className={clsx({
									[classes.gap]: d.operator === "or" || d.operator === "not",
								})}
							></div>
						)}
						<div
							key={i}
							className={clsx(classes.queryRow, {
								[classes.borderNot]: d.operator === "not",
							})}
						>
							{i > 0 && (
								<div className={classes.andOr}>
									<ButtonGroup variant="outlined" color="switchBtn">
										<Button
											disabled={disabled}
											className={clsx(classes.switchBtn, {
												[classes.switchBtnActive]: d.operator === "and",
											})}
											size="small"
											onClick={() => handleUpdate(i, "operator", "and")}
										>
                      AND
										</Button>
										<Button
											disabled={disabled}
											className={clsx(classes.switchBtn, {
												[classes.switchBtnActive]: d.operator === "or",
											})}
											size="small"
											onClick={() => handleUpdate(i, "operator", "or")}
										>
                      OR
										</Button>
										<Button
											disabled={disabled}
											className={clsx(classes.switchBtn, {
												[classes.switchBtnActive]: d.operator === "not",
											})}
											size="small"
											onClick={() => handleUpdate(i, "operator", "not")}
										>
                      NOT
										</Button>
									</ButtonGroup>
								</div>
							)}
							<div className={classes.dropdownAndSlider}>
								<div className={classes.dropdown}>
									<CustomDropdown
										searchEnabled
										transparent
										disabled={disabled}
										placeholder={"Select a metric"}
										options={metricOptions}
										value={d.field}
										onChange={(e) => handleUpdate(i, "field", e.target.value)}
									/>
								</div>
								<div className={classes.removeBtn}>
									<IconButton onClick={() => handleRemove(i)} disabled={disabled}>
										<RemoveIcon />
									</IconButton>
								</div>
								<div className={classes.addBtn}>
									{(i === localValue.length - 1 ||
                    localValue[i + 1]?.operator === "or" ||
                    localValue[i + 1]?.operator === "not" ||
                    (d.operator === "or" &&
                      localValue[i + 1]?.operator !== "and")) && (
										<IconButton onClick={() => handleInsert(i)} disabled={disabled}>
											<AddIcon />
										</IconButton>
									)}
								</div>
							</div>
						</div>
					</>
				))}
			</div>
		</div>
	);
}
