import { escapeJSON } from "utils/escaper";

import api, { analyticsApi } from "./axiosAnalytics";
import strapi from "./axiosStrapi";
import axios from "axios";
import { defaultReturnFields } from "./summaryReturnFields";
import explanations from "../utils/explanations.json";
import { handleError } from "./errors";
import { PigeonResponse } from "./response";
import * as qs from "qs";

// TODO rename "db" to "projectName"

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const getCancelToken = () => axios.CancelToken.source();

export const escape = (resp) => {
  if (typeof resp === "string") {
    let arr = [];

    try {
      arr = JSON.parse(escapeJSON(resp));
    } catch (error) {
      console.error(resp, error.message);
    }

    return arr;
  }

  return resp;
};

export const handleErrors =
  (f) =>
  async (...args) => {
    try {
      return await f(...args);
    } catch (error) {
      handleError(error);
      return null;
    }
  };

function getJwtTokenFromLocalStorage() {
  return localStorage.getItem("jwtTokenUser");
}

// LOGIN
export const login = async (username, password) => {
  const res = await strapi.post("/api/auth/local", {
    identifier: username,
    password,
  });

  return escape(res.data);
};

export const forgotPassword = async (email) => {
  const res = await strapi.post("/api/auth/forgot-password", {
    email,
  });
  return escape(res.data);
};

export const resetPassword = async (code, password, passwordConfirmation) => {
  const res = await strapi.post("/api/auth/reset-password", {
    code,
    password,
    passwordConfirmation,
  });
  return escape(res.data);
};

// PROJECT

export const getProjects = handleErrors(async () => {
  const token = getJwtTokenFromLocalStorage();
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await api.get("/projects/list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return escape(response.data.body);
});

const parseStrapiRes = (res) => {
  if (!res) {
    return res;
  }

  if (res.data) {
    return parseStrapiRes(res.data);
  }

  if (Array.isArray(res)) {
    return res.map((d) => {
      return parseStrapiRes(d);
    });
  }

  if (typeof res === "object" && res !== null) {
    if (res.id && res.attributes) {
      const id = res.id;
      return { id, ...parseStrapiRes(res.attributes) };
    } else {
      for (const attrKey of Object.keys(res)) {
        res[attrKey] = parseStrapiRes(res[attrKey]);
      }
      return res;
    }
  }

  return res;
};

export const getFiltersAPI = handleErrors(async (platform) => {
  const query = qs.stringify({
    filters: {
      platforms: {
        name: { $eq: platform },
      },
    },
    populate: {
      custom_options: {
        populate: "*",
      },
      field: {
        populate: "*",
      },
    },
  });

  const route = `/api/filters/?${query}`;

  const response = await strapi.get(route, {
    headers: {
      Authorization: `Bearer ${getJwtTokenFromLocalStorage()}`,
    },
  });
  const responseArr = parseStrapiRes(response.data);
  return responseArr;
});

export const getFieldEntries = handleErrors(({ db, field, platform }) => {
  return api
    .post(`${db}/field-entries/${field}`, { platform: platform })
    .then(({ data }) => {
      return escape(data.body);
    });
});

export const getProject = handleErrors(async (projectName) => {
  const response = await api.get(`/projects?name=${projectName}`);
  return escape(response.data.body);
});

export const checkDataValidityForProject = handleErrors(async (db, fieldsToCheck) => {
  const response = await api.post(`/field-exists/${db}`, fieldsToCheck);
  return escape(response.data.body);
});

export const getProjectConfigs = handleErrors(async (projectName) => {
  const response = await api.get(`/projects?name=${projectName}`);
  const fullProject = escape(response.data);

  if (!fullProject || !fullProject.data || !fullProject.data.attributes) {
    return null;
  }
  const { projectConfigs } = fullProject.data.attributes;
  if (projectConfigs && projectConfigs.data) {
    return projectConfigs.data;
  } else {
    return [];
  }
});

export const getPlatforms = handleErrors(({ db, req = {} }) => {
  return api
    .post(`/${db}/platforms`, req)
    .then(({ data }) => escape(data.body));
});

export const createNarrative = handleErrors((data) => {
  return api.post("/narrative", data).then(({ data }) => {
    return escape(data.body);
  });
});

export const getNarratives = handleErrors((data) => {
  const jwtToken = getJwtTokenFromLocalStorage();
  return axios
    .post(`${analyticsApi}/narrative/list`, data, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
    .then(({ data }) => escape(data.body));
});

export const updateNarrative = handleErrors((data) => {
  return api.put("/narrative", data).then(({ data }) => escape(data.body));
});

export const deleteNarrative = handleErrors((data) => {
  return api
    .delete("/narrative", { data })
    .then(({ data }) => escape(data.body));
});

// Concept section
export const loadConcepts = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/phrases/usage`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadConceptsRelated = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/phrases/related`, req, config)
    .then(({ data }) => escape(data.body));
});

// POSTS

export const loadPostsKeywords = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/clusters`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadPosts = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/posts`, req, config)
    .then(({ data }) => escape(data.body));
});

// ENTITIES

export const loadUsersKeywords = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/users`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadParentUsersKeywords = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/parents`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadHashtagsKeywords = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/hashtags`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadUrls = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/urls`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadSubreddits = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/subreddit`, req, config)
    .then(({ data }) => escape(data.body));
});

// SUMMARIES

export const loadActivity = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/activity`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadSummary = handleErrors(({ db, req = {}, config = {} }) => {
  return api.post(
    `/${db}/summary`,
    { returnFields: defaultReturnFields, ...req },
    config
  );
});

export const loadSummaryUserGroups = handleErrors(({ db, requests }) => {
  return Promise.all(
    requests.map((req) =>
      api.post(`/${db}/summary`, { returnFields: defaultReturnFields, ...req })
    )
  ).then((resp) => {
    const body = requests.reduce((obj, d, index) => {
      if (resp[index].data.body) {
        obj[d.user_group_names[0]] = resp[index].data.body[0]?.user_ct;
      }
      return obj;
    }, {});
    const response = new PigeonResponse(body, true);
    return {
      data: response,
    };
  });
});

export const loadImpactActivity = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/impact/activity`, req, config)
    .then(({ data }) => escape(data.body));
});

export const loadImpactSummary = handleErrors(({ db, req = {}, config }) => {
  return api
    .post(`/${db}/impact/summary`, req, config)
    .then(({ data }) => escape(data.body));
});

// HOAX

export const loadHoaxDetails = handleErrors(({ db, req = {}, config }) => {
  return api.post(`/${db}/hoaxes`, req, config).then(({ data }) => {
    return escape(data.body);
  });
});

// MISC
export const getZendeskToken = () => {
  return api
    .get("/zendeskauth")
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
    });
};

// only for chat widget
export const getZendeskToken2 = () => {
  return api
    .get("/zendeskauth2")
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const isUploader = () => {
  return api
    .get("/isuploader")
    .then(({ data }) => {
      return data.body;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getOrganizationPostCountByPlatform = handleErrors(async () => {
  const token = getJwtTokenFromLocalStorage();
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await api.get("/organization/countByPlatform", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return escape(response.data.body);
});

export const getOrganizationPostCount = handleErrors(async () => {
  const token = getJwtTokenFromLocalStorage();
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await api.get("/organization/count", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return escape(response.data.body);
});

export const getOrganizationPostCountCap = handleErrors(async () => {
  const token = getJwtTokenFromLocalStorage();
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await api.get("/organization/post_count_cap", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return escape(response.data.body);
});

// TODO this should be a GET request in pigeon
export const getThresholds = handleErrors((projectName) => {
  return api
    .post(`/${projectName}/thresholds`)
    .then(({ data }) => escape(data.body));
});

// TODO: use real endpoint url here
export const getExplanation = (entityType, explType) => {
  return new Promise((res) => {
    const obj = (explanations[explType] || {})[entityType];
    res(obj);
  });
};

export const getLabels = (projectName, labelType) => {
  return api
    .post(`/${projectName}/models/${labelType}/labels`)
    .then(({ data }) => {
      return escape(data);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const getChildKeys = ({ db, parent, platform, classifierProfile }) => {
  return api
    .post(`/${db}/child-keys/${parent}/${db}`, {
      platform: platform,
      harmClassifier: classifierProfile,
    })
    .then(({ data }) => {
      return escape(data.body);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const createHarmProfile = handleErrors(async (data) => {
  const response = await api.post("/harmProfile", data);
  return escape(response.data.body);
});

export const getHarmProfiles = handleErrors(async () => {
  const jwtToken = getJwtTokenFromLocalStorage();
  const response = await axios.get(`${analyticsApi}/harmProfile/list`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  return escape(response.data.body);
});

export const updateHarmProfile = handleErrors(async (data) => {
  const response = await api.put(`/harmProfile/${data.id}`, data);
  return escape(response.data.body);
});

export const deleteHarmProfile = handleErrors(async (harmProfileId) => {
  const response = await api.delete(`/harmProfile/${harmProfileId}`);
  return escape(response.data.body);
});

export const setHarmProfile = handleErrors(async (harmProfileId) => {
  const response = await api.get(`/harmProfile/select/${harmProfileId}`);
  return escape(response.data.body);
});

export const createClassifierProfile = handleErrors(async (data) => {
  const response = await api.post("/classifierProfile", data);
  return escape(response.data.body);
});

export const getClassifierProfiles = handleErrors(async () => {
  const jwtToken = getJwtTokenFromLocalStorage();
  const response = await axios.get(`${analyticsApi}/classifierProfile/list`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  return escape(response.data.body);
});

export const updateClassifierProfile = handleErrors(async (data) => {
  const response = await api.put(`/classifierProfile/${data.id}`, data);
  return escape(response.data.body);
});

export const deleteClassifierProfile = handleErrors(
  async (classifierProfileId) => {
    const response = await api.delete(
      `/classifierProfile/${classifierProfileId}`
    );
    return escape(response.data.body);
  }
);

export const setClassifierProfileAPI = handleErrors(
  async (classifierProfileId) => {
    const response = await api.get(
      `/classifierProfile/select/${classifierProfileId}`
    );
    return escape(response.data.body);
  }
);

// User Group
export const createUserGroup = handleErrors(async (data) => {
  const response = await api.post("/userGroup", data);
  return escape(response.data.body);
});

export const getUserGroups = handleErrors(async (projectID) => {
  const response = await api.get(`/userGroup/list/${projectID}`);
  return escape(response.data.body);
});

export const updateUserGroup = handleErrors(async (data) => {
  const response = await api.put("/userGroup", data);
  return escape(response.data.body);
});

export const deleteUserGroup = handleErrors(async (userGroupId) => {
  const response = await api.delete("/userGroup", {
    data: { id: userGroupId },
  });
  return escape(response.data.body);
});

export const getLanguages = handleErrors(async (projectName) => {
  const response = await api.post(`/${projectName}/languages`, {
    byPlatform: true,
  });
  return escape(response.data.body);
});

export const getAINarratives = handleErrors(async ({ db, req }) => {
  const response = await api.post(`/${db}/ai-narratives`, req);
  return response.data.body;
});

export const loadDomains = handleErrors(async ({ db, req }) => {
  const response = await api.post(`/${db}/domains`, req);
  return response.data.body;
});
