import React from "react";
import { Route, Redirect } from "react-router-dom";
export default function PrivateRoute({ children, ...rest }) {
	const jwtTokenUser = localStorage.getItem("jwtTokenUser");
	const isAuthenticated = jwtTokenUser;

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}
