import { ReactComponent as WebImporterIcon } from "icons/Uploader/WEB_IMPORTER.svg";
import Uploader from "./Uploader";
import { STAGED } from "../utils/constants";
import { useUploaderContext } from "../context/Context";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
      paddingTop: theme.spacing(4),
    },
  },
  left: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: `0px ${theme.spacing(2)}px`,
    },
  },
  right: {
    width: "100%",
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
  mainTitle: {
    fontWeight: "bold",
    fontSize: "52px",
    lineHeight: "62px",
    marginTop: 20,
    marginBottom: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: "38px",
      lineHeight: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "34px",
      lineHeight: "35px",
    },
  },
  subtitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "30px",
  },
  tos: {
    paddingTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkboxLabel: {
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "0.02em",
  },
  tosLink: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.02em",
    color: "#BBB6D2",
    whiteSpace: "nowrap",
    textDecoration: "none",
    paddingTop: 20,
    paddingBottom: 5,
    borderBottom: "1px dashed #C5C4CD",
  },
  checkbox: {},
}));

export default function Droparea(props) {
  const classes = useStyles();

  const {
    state: { documents, tosChecked },
  } = useUploaderContext();

  const error =
    documents.filter((d) => d.status === STAGED).length > 0 && !tosChecked;

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.left} sm={12} md={4}>
        <div>
          <WebImporterIcon />
        </div>

        <Typography className={classes.mainTitle}>
          Upload your dataset
        </Typography>

        <Typography variant={"subtitle2"}>
          We support .csv, .xls, .xlsx, .zip, .json, .pdf, and .ppt file formats
        </Typography>
      </Grid>

      <Grid item className={classes.right} sm={12} md={8}>
        <Uploader {...props} uploadBtnDisabled={!tosChecked} />

        <div className={classes.tos}>
          <div>
            <FormControl error={error}>
              <FormHelperText
                style={{
                  visibility: error ? "visible" : "hidden",
                }}
              >
                Please accept the terms of service
              </FormHelperText>
              <FormControlLabel
                classes={{
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox
                    required
                    className={classes.checkbox}
                    checked={tosChecked}
                    onChange={props.setChecked}
                    name="tos"
                    color="primary"
                  />
                }
                label="I am authorized to upload this data."
              />
            </FormControl>
            <FormHelperText>
              Imported datasets are deleted after processing.
            </FormHelperText>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
