import { escape, handleErrors } from "api/AnalyticsApi";
import { getJwtTokenFromLocalStorage } from "api/axiosAnalytics";
import strapi from "api/axiosStrapi";

export const getUserSettings = async () => {
	const res = await strapi.get("/api/users/me", {
		headers: {
			Authorization: `Bearer ${getJwtTokenFromLocalStorage()}`,
		},
	});
	return res.data.settings;
};

export const getUser = handleErrors(async () => {
	const res = await strapi.get("/api/users/me", {
		headers: {
			Authorization: `Bearer ${getJwtTokenFromLocalStorage()}`,
		},
	});
	return res.data;
});

export const setUserSettings = handleErrors(async (settings) => {
	const res = await strapi.put(
		"/api/users/me",
		{
			settings,
		},
		{
			headers: {
				Authorization: `Bearer ${getJwtTokenFromLocalStorage()}`,
			},
		}
	);
	return escape(res.data.settings);
});
