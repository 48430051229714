import React from "react";
import { PENDING, SUCCESS, DELETING, STAGED, DELETED } from "../utils/constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  state: {
    display: "flex",
    alignItems: "center",
    "& .dot": {
      marginRight: 10,
      width: 8,
      height: 8,
      borderRadius: "50%",
    },
  },
  staged: {
    color: "#d32f2f",
    "& .dot": {
      background: "#d32f2f",
    },
  },
  deleted: {
    color: "#39F3C5",
    "& .dot": {
      background: "#39F3C5",
    },
  },
  deleting: {
    color: "#66ff00",
    "& .dot": {
      background: "#66ff00",
    },
  },
  pending: {
    color: "#FF7324",
    "& .dot": {
      background: "#FF7324",
    },
  },
  success: {
    color: "#39F3C5",
    "& .dot": {
      background: "#39F3C5",
    },
  },
}));

export default function Status({ value }) {
  const classes = useStyles();

  let c = "";
  let txt = "";

  if (value === PENDING) {
    c = "pending";
    txt = "Uploading";
  } else if (value === STAGED) {
    c = "staged";
    txt = "Staged";
  } else if (value === DELETING) {
    c = "deleting";
    txt = "Clearing";
  } else if (value === DELETED) {
    c = "deleted";
    txt = "Deleted";
  } else if (value === SUCCESS) {
    c = "deleted";
    txt = "Success";
  }

  return (
    <div className={classes.state + " " + classes[c]}>
      <div className="dot"></div>
      <div>{txt}</div>
    </div>
  );
}
