import React, { useState } from "react";
import { Button, Popover, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as SettingsIcon } from "icons/Settings_2.svg";
import Settings from "./Settings";

const useStyles = makeStyles((theme) => ({
	root: {},
	btn: {
		color: "rgba(255,255,255,0.6)",
		fontWeight: 606
	},
	popoverRoot: {},
	popoverPaper: {
		background: "rgb(29, 26, 45)",
		border: "1px solid " + theme.palette.border.popup,
		width: 513,
	},
	title: {
		fontSize: "16px",
		lineHeight: "16px",
		color: "#fff",
		padding: 26,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	}
}));

export default function ProjectSettings() {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const id = open ? "settings-popover" : undefined;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<div>
			<Button
				startIcon={<SettingsIcon />}
				className={classes.btn}
				onClick={handleOpen}
			>
        Project Settings
			</Button>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				classes={{
					root: classes.popoverRoot,
					paper: classes.popoverPaper,
				}}
			>
				<Typography variant="subtitle1" className={classes.title}>
          Project settings
				</Typography>

				<Settings handleClose={handleClose} />
			</Popover>
		</div>
	);
}
