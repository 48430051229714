import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "api/AnalyticsApi";
import LoginBackground from "pages/Authentication/LoginBackground";
import InputValidate from "components/UI/InputValidate";
import Message from "./Message";

const useStyles = makeStyles((theme) => ({
	header: {
		marginTop: 0,
		marginBottom: 0,
	},
	subtitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		color: "rgba(208, 209, 230, 0.7)",
		fontSize: 14,
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	submit: {
		backgroundColor: "#40DDFF",
		"&:hover": {
			backgroundColor: "#40DDFF",
		},
	},
	back: {
		marginTop: "1rem",
		color: "#40DDFF",
	},
}));

function PasswordResetEmailPage() {
	const classes = useStyles();
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const onSubmit = async (event) => {
		event.preventDefault();
		setMessage(null);
		setLoading(true);
		try {
			const res = await forgotPassword(email);
			setMessage({
				type: "success",
				message: "Check your email for a password reset link",
			});
			setEmail("");
			setLoading(false);
			console.log(res);
		} catch (error) {
			setMessage({
				type: "error",
				message:
          "Error sending reset password link: make sure an account exists",
			});
			setLoading(false);
			console.error(error);
		}
	};

	const backToLogin = () => {
		history.push("/");
	};

	return (
		<LoginBackground>
			<h3 className={classes.header}>Forgot your password?</h3>

			<h6 className={classes.subtitle}>
        Don’t worry! Type your email to reset your password, we will send you
        further instructions
			</h6>

			{message && <Message {...message} />}

			<form autoComplete="true" onSubmit={onSubmit}>
				<div className={classes.input}>
					<InputValidate
						label={"Email Address"}
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						fieldProps={{
							disabled: loading
						}}
					/>
				</div>
				<Button
					type={"submit"}
					size={"large"}
					variant={"contained"}
					color={"primary"}
					className={classes.submit}
					fullWidth
					disabled={loading || !email}
				>
					{loading ? (
						<CircularProgress color="secondary" size={24} thickness={3} />
					) : (
						"Send"
					)}
				</Button>
			</form>

			<Button
				className={classes.back}
				variant="text"
				size="medium"
				onClick={backToLogin}
			>
        Back to Login
			</Button>
		</LoginBackground>
	);
}

export default PasswordResetEmailPage;
