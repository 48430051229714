import { makeStyles } from "@mui/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Alert } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		color: "#E8E9F3",
		fontSize: "16px",
		lineHeight: "18px",
		fontFamily: "Mulish",
		backgroundColor: theme.palette.background.$1,
		border: (props) =>
			`1.5px solid ${props.hasError ? theme.palette.red.$7 : "#302E4F"}`,
		borderRadius: 4,
		padding: theme.spacing(1, 2),
		marginTop: theme.spacing(0),
	},
}));

export default function TextAreaValidate(props) {
	const {
		errorMsg = "",
		placeholder = "",
		disabled,
		onChange,
		minRows = 1,
	} = props;

	const classes = useStyles({ hasError: !!errorMsg });

	return (
		<>
			<TextareaAutosize
				minRows={minRows}
				disabled={disabled}
				value={props.value}
				className={classes.root}
				placeholder={placeholder}
				onChange={onChange}
			/>
			{!!errorMsg && (
				<>
					<br></br>
					<br></br>
					<Alert variant="filled" severity={"error"}>
						{errorMsg}
					</Alert>
				</>
			)}
		</>
	);
}
