import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as PlusIcon } from "icons/Plus.svg";

const useStyles = makeStyles(() => ({
  root: {
    background: "#272438",
    boxShadow: "0px 0px 1px #131121, 0px 5px 5px -3px rgba(0, 0, 0, 0.25)",
    borderRadius: "2px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 143,
  },
  icon: {
    width: "140px",
    height: "100%",
    borderRight: "1px solid #302E4F",
    padding: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "100%",
      height: "100%"
    }
  },
  content: {
    flexGrow: 1,
    padding: "20px 35px",
    height: "100%",
  },
  name: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
  },
  site: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#6F7494",
  },
  button: {
    marginTop: "20px",
    color: "#302E4F",
  },
}));

export default function DataPartner({ icon, name, site, onConnectclick }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.content}>
        <div className={classes.name}>{name}</div>
        <div className={classes.site}>{site}</div>
        <div>
          <Button
            className={classes.button}
            startIcon={<PlusIcon />}
            variant={"contained"}
            color="primary"
            onClick={onConnectclick}
          >
            Connect
          </Button>
        </div>
      </div>
    </div>
  );
}
