import { useEffect } from "react";
import { getZendeskToken } from "api/AnalyticsApi";
import makeStyles from "@mui/styles/makeStyles";
import backgroundImage from "images/4k_LoginBackground.png";
import { Typography, Grid } from "@mui/material";
import BlackbirdTextLogo from "components/blackbird-text-logo";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100vw",
		height: "100vh",
		backgroundImage: `url(${backgroundImage})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		backgroundPosition: "right bottom",
	},
	outerContainer: {
		padding: theme.spacing(4, 4, 4, 4),
		[theme.breakpoints.up("sm")]: {
			height: "80%",
			paddingBottom: 200,
		},
		[theme.breakpoints.up("md")]: {
			height: "100%",
		},
	},
	logo: {
		marginBottom: theme.spacing(4),
		[theme.breakpoints.up("sm")]: {
			marginBottom: 0,
		},
	},
	text: {
		color: "#E8E9F3",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "21px",
	},
	mainTitle: {
		fontSize: "32px",
		lineHeight: "40px",
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			fontSize: "38px",
			lineHeight: "42px",
		},
		[theme.breakpoints.up("lg")]: {
			fontSize: "45px",
			lineHeight: "54px",
		},
	},
	login: {
		padding: theme.spacing(2, 4, 4, 4),
		backgroundColor: "rgba(34, 34, 55, 0.3)",
		border: "1px solid #292942",
		width: "100%",
		borderRadius: 12,
		backdropFilter: "blur(10px)",
		maxWidth: 410,
	},
	credential: {
		marginBottom: theme.spacing(1),
	},
	forgotPassword: {
		marginTop: "1rem",
	},
	header: {
		marginTop: 0,
	},
	left: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			justifyContent: "space-between",
			marginBottom: 0,
		},
	},
	right: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	blackbirdLogoContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const ZENDESK_URL = process.env.REACT_APP_ZENDESK_URL;

function ZendeskRedirectPage() {
	useEffect(() => {
		const zendeskRedirect = async () => {
			try {
				const token = await getZendeskToken();
				window.location.replace(`${ZENDESK_URL}/access/jwt?jwt=${token}`);
			} catch (err) {
				alert("Your account does not have access to Zendesk support");
			}
		};

		zendeskRedirect();
	}, []);

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid
				className={classes.outerContainer}
				container
				direction="row"
				justifyContent="space-around"
				spacing={0}
			>
				<Grid
					item
					container
					xs={12}
					sm={12}
					md={5}
					className={classes.left}
					direction="column"
					alignItems="flex-start"
				>
					<div className={classes.blackbirdLogoContainer}>
						<BlackbirdTextLogo login={true} />
					</div>

					<div>
						<Typography
							variant={"h3"}
							gutterBottom
							className={classes.mainTitle}
						>
              Redirecting to Zendesk...
						</Typography>

						<Typography className={classes.text}>
              The Constellation Risk Intelligence Dashboard empowers teams to
              proactively detect and contextualize information driven threats to
              accelerate response and mitigation.
						</Typography>
					</div>

					<div></div>
				</Grid>
				<Grid
					item
					container
					direction="column"
					justifyContent="center"
					xs={12}
					sm={12}
					md={5}
					className={classes.right}
				></Grid>
			</Grid>
		</div>
	);
}

export default ZendeskRedirectPage;
