import axios from "axios";
import { setupCache } from "axios-cache-adapter";

export const analyticsApi = process.env.REACT_APP_ANALYTIC_API || "/analyticapi";

const cache = setupCache({
	maxAge: 60 * 1000,
	exclude: {
		methods: ["patch", "put", "delete"],
	},
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
	adapter: cache.adapter,
	baseURL: analyticsApi,
	timeout: 600000,
});

export function getJwtTokenFromLocalStorage() {
	return localStorage.getItem("jwtTokenUser");
}

// Add auth header token for all requests
api.defaults.headers.common[
	"Authorization"
] = `Bearer ${getJwtTokenFromLocalStorage()}`;

export default api;

