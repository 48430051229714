import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  Suspense,
} from "react";
import {
  Redirect,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import NavigationDrawer from "./NavigationDrawer";
import TopBar from "./TopBar";
import {
  getClassifierProfiles,
  getHarmProfiles,
  getLanguages,
  getUserGroups,
  loadPostsKeywords,
  loadSummary,
  getProject,
  getProjects,
  getNarratives,
  getCancelToken,
  getLabels,
  getFiltersAPI,
  checkDataValidityForProject,
} from "api/AnalyticsApi";
import { useAppContext } from "context/Context";
import { getUser } from "api/userApi";
import { getDefaultFilters } from "pages/DashboardPage/Shared/RichTable/filterConfig";
import {
  Alert,
  Backdrop,
  IconButton,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import {
  defaultReturnFields,
  emotionAndSentimentFields,
} from "api/summaryReturnFields";
import Summary from "./Overview/Summary";
import { ACTIONS } from "context/reducer";
import {
  DASHBOARD_TAB,
  DEFAULT_PROFILE,
  DEFAULT_CLASSIFIER,
  DEFAULT_SORT,
  DESC_SORT_DIR,
  LANGUAGES_LIMIT,
  POST_COUNT_FIELD,
  QUERY_KEYS,
  VISUALIZE_TAB,
  EXCLUDED_COHORTS,
  LOCAL_STORAGE_KEYS,
  CACHE_AND_NETWORK,
  ALL_PROJECT,
  PLATFORM_CONFIG,
  PLATFORMS,
  PARENT_USERS,
} from "utils/constants";
import { useTheme } from "@emotion/react";

import useApi from "api/hooks/useApi";
import { Toaster, useToasterStore } from "react-hot-toast";
import { monitorIdentity } from "api/monitoring";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import * as Sentry from "@sentry/react";
import { Userpilot } from "userpilot";
import CloseIcon from "@mui/icons-material/Close";
import Drawers from "./Shared/Drawers/Drawers";
// import ZendeskWidget from "components/ZendeskWidget";
import { PLATFORMS_QUERY } from "api/graphQL/queries";
import { useLazyQuery } from "@apollo/client";
import { Route, Switch } from "react-router-dom";
import useTabs from "hooks/useTabs";
import Preloader from "components/UI/Preloader";
import ProjectNotFound from "./ProjectNotFound";

// Lazy loading
const Overview = React.lazy(() => import("./Overview"));
const EntityExplorer = React.lazy(() => import("./EntityExplorer"));
const Visualize = React.lazy(() => import("./Visualize"));
const NarrativeBuilder = React.lazy(() => import("./NarrativeBuilder"));

Userpilot.initialize("NX-143b6a46");

export const DRAWER_WIDTH = 264;
export const DRAWER_CLOSED_WIDTH = 76;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: 0,
    minHeight: "100vh",
  },
  content: {
    width: "100%",
    flexGrow: 1,
    paddingTop: 109,
    maxWidth: (props) =>
      `calc(100% - ${
        props.navOpen ? props.drawerWidth : props.drawerClosedWidth
      }px)`,
  },
  statsWrapper: {
    background:
      "linear-gradient(180deg, #1D1A2D 0%, rgba(29, 26, 45, 0.1) 100%)",
    marginLeft: 0,
    marginRight: 0,
    display: "flex",
    flex: 1,
    borderRadius: 6,
    position: "sticky",
    top: 109,
    zIndex: 1200,
  },
}));

export default function DashboardPage() {
  // Theme
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = smallScreen ? 0 : DRAWER_WIDTH;
  const drawerClosedWidth = smallScreen ? 0 : DRAWER_CLOSED_WIDTH;

  // Flags
  const flags = useFlags();

  // LD
  const ldClient = useLDClient();

  // Routing
  const { topTab, sidebarTab } = useTabs();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { projectName, narrativeId } = useParams();

  // Toasters
  const { toasts } = useToasterStore();
  const errorIsActive =
    toasts && toasts.filter((toast) => toast.visible).length > 0;

  // context
  const {
    dispatch,
    state: {
      user,
      start_date,
      end_date,
      narrative,
      project,
      analyzeSearch,
      platform,
      harmProfile,
      harmClassifier,
      projects,
      snackbarMessage,
      cohortNames,
      harmProfiles,
      harmClassifiers,
      dynamic_filters,
    },
  } = useAppContext();

  const narrativeIndex = parseInt(narrativeId) || 0;

  // States
  const [analyzeEntity, setAnalyzeEntity] = useState(null);
  const [drilledConcepts, setDrilledConcepts] = useState([]);
  const [navOpen, setNavOpen] = useState(false);
  const [validProject, setValidProject] = useState(!!projectName);

  const classes = useStyles({ navOpen, drawerWidth, drawerClosedWidth });

  const showSummary =
    sidebarTab === DASHBOARD_TAB && topTab !== VISUALIZE_TAB && validProject;

  Sentry.configureScope((scope) => scope.setTransactionName(projectName));

  // summary stats
  const discoverCancelToken = useRef();

  const getUpdatedDiscoverCancelToken = () => {
    discoverCancelToken.current && discoverCancelToken.current.cancel();
    discoverCancelToken.current = getCancelToken();
    return { cancelToken: discoverCancelToken.current.token };
  };

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({
      type: ACTIONS.SHOW_MESSAGE,
      payload: null,
    });
  };

  const objectMemo = useMemo(() => {
    //Do not load summary prematurely from Narrative Builder
    if (sidebarTab !== DASHBOARD_TAB) return { db: null };

    const analyzeFilters = narrative.analyze_filters
      ? narrative.analyze_filters.serialized
      : {};

    return {
      db: narrative.db,
      req: {
        start_date,
        end_date,
        platform,
        latestKeywords: narrative?.keywords,
        ...analyzeFilters,
        ...analyzeSearch,
        topic: narrative?.name,
        harmProfile,
        harmClassifier,
        returnFields: [...defaultReturnFields, ...emotionAndSentimentFields],
      },
    };
  }, [
    analyzeSearch,
    start_date,
    end_date,
    platform,
    narrative.name,
    narrative.db,
    narrative.keywords,
    sidebarTab,
    harmProfile,
    harmClassifier,
    narrative.analyze_filters,
  ]);

  const { data, isLoading: summaryLoading } = useApi({
    apiKey: QUERY_KEYS.summary,
    payload: objectMemo,
    apiFn: loadSummary,
  });

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_SUMMARY_LOADING,
      payload: summaryLoading,
    });
  }, [summaryLoading, dispatch]);

  const wildcardSummary = data ? data[0] : null;

  useEffect(() => {
    getFiltersAPI(platform).then((data) => {
      dispatch({
        type: ACTIONS.SET_PROJECT_DYNAMIC_FILTERS,
        payload: data ? data : [],
      });
    });
  }, [dispatch, platform]);

  const addNarrative = (n) => {
    dispatch({
      type: ACTIONS.ADD_NARRATIVE,
      payload: n,
    });
  };

  const _updateNarrative = (n, i) => {
    dispatch({
      type: ACTIONS.UPDATE_NARRATIVE,
      payload: {
        narrative: n,
        index: i,
      },
    });
  };

  const [getPlatforms] = useLazyQuery(PLATFORMS_QUERY, {
    fetchPolicy: CACHE_AND_NETWORK,
  });

  const changeProject = useCallback(
    (p, currentProjectID) => {
      if (p.projectId === currentProjectID) return;

      getProject(p.name).then(async (fullProject) => {
        const projectClassifierProfile = fullProject.classifierProfile
          ? fullProject.classifierProfile
          : DEFAULT_CLASSIFIER;

        dispatch({
          type: ACTIONS.SELECT_PROJECT,
          payload: {
            project: fullProject,
            narratives: [],
            loadingNarratives: true,
            spike_date: null,
            classifierProfile: projectClassifierProfile,
          },
        });

        const resp = await getPlatforms({
          variables: {
            project: {
              id: String(fullProject.projectId),
              name: fullProject.name,
            },
            narrative: {
              id: ALL_PROJECT,
              name: ALL_PROJECT,
              filter: {
                keywords: "*",
              },
            },
          },
        });

        // reset platform on project change
        if (resp && resp.data && resp.data.platforms) {
          const firstPlatform = resp.data.platforms?.platforms[0]?.name;
          const newPlatform = PLATFORMS[firstPlatform];

          const platforms =
            resp.data.platforms?.platforms
              .map((d) => ({
                disabled: !d.enabled,
                ...PLATFORM_CONFIG[d.name],
              }))
              .filter((d) => d) ?? [];

          dispatch({
            type: ACTIONS.SET_PLATFORMS,
            payload: platforms,
          });

          dispatch({
            type: ACTIONS.SET_PLATFORM,
            payload: newPlatform || PLATFORMS.twitter,
          });
        }

        getLabels(p.name, "cohorts").then((cohortData) => {
          if (cohortData?.body) {
            // Save the list of cohort names in the app context.
            const cohortNames = cohortData.body
              .map((cohort) => {
                return cohort.fieldName.replace("analysis.cohorts.", "");
              })
              .filter((d) => !EXCLUDED_COHORTS.includes(d));

            dispatch({
              type: ACTIONS.SET_COHORT_NAMES,
              payload: {
                cohortNames,
              },
            });
          }
        });
      });
    },
    [dispatch, getPlatforms]
  );

  const setDates = useCallback(
    (start, end) => {
      dispatch({
        type: ACTIONS.SET_DATES,
        payload: {
          start,
          end,
        },
      });
    },
    [dispatch]
  );

  const resetSpikeDate = useCallback(() => {
    dispatch({
      type: ACTIONS.SET_SPIKE_DATE,
      payload: null,
    });
  }, [dispatch]);

  const changeSpikeDate = (_spike_date) => {
    dispatch({
      type: ACTIONS.SET_SPIKE_DATE,
      payload: _spike_date,
    });
  };

  const resetSort = useCallback(() => {
    // reset sort state
    dispatch({
      type: ACTIONS.SET_ANALIZE_SORT,
      payload: {
        analyzeSortField: DEFAULT_SORT,
        analyzeSortDir: "DESC",
      },
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_ANALYZE_SEARCH,
      payload: {},
    });
  }, [topTab, dispatch]);

  useEffect(() => {
    const config = getUpdatedDiscoverCancelToken();

    const setNarrativeClusters = (narrativeClusters) => {
      dispatch({
        type: ACTIONS.SET_NARRATIVE_CLUSTERS,
        payload: narrativeClusters || [],
      });
    };

    if (project.projectId) {
      setNarrativeClusters([]);
      loadPostsKeywords({
        db: project.name,
        req: {
          keywords: "*",
          start_date: narrative.startDate,
          end_date: narrative.endDate,
          excludeClusters: [], //excludedClusters
          ...(narrative.analyze_filters?.serialized || {}),
          page_size: 10,
          num_sample_posts: 6,
          sort_fields: [POST_COUNT_FIELD],
          sort_directions: [DESC_SORT_DIR],
          platform,
          harmProfile,
          harmClassifier,
        },
        config,
      }).then((clusters) => {
        if (clusters) {
          setNarrativeClusters(clusters);
        }
      });
    }
  }, [
    history,
    dispatch,
    harmProfile,
    harmClassifier,
    narrative.post_filters,
    narrative.analyze_filters,
    narrative.endDate,
    narrative.startDate,
    project.projectId,
    project.name,
    platform,
  ]);

  useEffect(() => {
    const loadProjects = async () => {
      const projects = await getProjects();
      dispatch({
        type: ACTIONS.SET_PROJECTS,
        payload: projects || [],
      });
    };
    loadProjects();
  }, [dispatch]);

  useEffect(() => {
    const loadLanguages = async () => {
      const langByPlatform = await getLanguages(project.name);
      const languages = {};

      Object.keys(langByPlatform).forEach((platform) => {
        const arr = langByPlatform[platform];
        const sum = arr.reduce((s, d) => s + d.post_ct, 0) || 1;
        languages[platform] = arr.filter(
          (d) => d.post_ct / sum > LANGUAGES_LIMIT
        );
      });

      dispatch({
        type: ACTIONS.SET_LANGUAGES,
        payload: languages,
      });
    };
    if (project && project.name && flags.multilingual) {
      loadLanguages();
    }
  }, [dispatch, project, flags.multilingual]);

  useEffect(() => {
    if (projectName) {
      if (projectName === project.name) return;

      const projectMatchFromURL = projects.find((p) => p.name === projectName);

      if (projectMatchFromURL) {
        setValidProject(true);
        changeProject(projectMatchFromURL, project.projectId);
      } else if (projects.length) {
        setValidProject(false);
        history.push(`/dashboard/${projectName}/not-found`);
      }
    } else if (projects.length) {
      setValidProject(true);
      history.push(`/dashboard/${projects[0].name}/overview`);
    }
  }, [
    dispatch,
    changeProject,
    projects,
    projectName,
    project.projectId,
    project.name,
    history,
  ]);

  useEffect(() => {
    const load = async (proj) => {
      try {
        const data = await getNarratives({
          projectName: proj.name,
          projectId: proj.projectId,
        });
        const newNarratives =
          data && data.length
            ? data.map((n) => {
                const filters = getDefaultFilters(
                  n.postFilters,
                  cohortNames,
                  dynamic_filters
                );
                return {
                  ...n,
                  concepts: n.concepts || [],
                  db: proj.name,
                  post_filters: n.postFilters || [],
                  filters: filters.serialized,
                  analyze_filters: filters,
                  start_date: n.startDate,
                  end_date: n.endDate,
                  narrativeId: n.id,
                };
              })
            : [];
        dispatch({
          type: ACTIONS.SET_NARRATIVES,
          payload: {
            narratives: newNarratives,
            narrativeIndex: narrativeIndex >= newNarratives.length ? 0 : narrativeIndex,
          },
        });
      } catch (error) {
        dispatch({
          type: ACTIONS.SET_NARRATIVES,
          payload: {
            narratives: [],
            narrativeIndex: narrativeIndex,
          },
        });
      }
    };
    if (project && project.name && project.projectId) {
      load(project);
      resetSort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, setDates, resetSort, dispatch, cohortNames, dynamic_filters]);

  useEffect(() => {
    const jwtTokenUser = localStorage.getItem("jwtTokenUser");
    const isAuthenticated = jwtTokenUser;

    if (isAuthenticated && user && user.username && user.email) {
      Userpilot.identify(user.id, {
        name: user.username,
        email: user.email,
        created_at: user.createdAt,
      });
    }
  }, [user]);

  useEffect(() => {
    const checkDataValidity = async () => {
      if (projectName) {
        const resp = await checkDataValidityForProject(projectName, {
          fieldName: "parents",
          fieldType: "nested",
        });
        dispatch({
          type: ACTIONS.SET_UNSUPPORTED_FEATURE,
          payload: { featureName: PARENT_USERS, supported: resp },
        });
      }
    };
    checkDataValidity();
  }, [projectName, project.projectId, dispatch]);

  const location = useLocation();
  useEffect(() => {
    const jwtTokenUser = localStorage.getItem("jwtTokenUser");
    const isAuthenticated = jwtTokenUser;

    if (isAuthenticated) {
      Userpilot.reload();
    }
  }, [location]);

  useEffect(() => {
    resetSort();
  }, [platform, resetSort]);

  useEffect(() => {
    let isActive = true;

    const handleGetUser = async () => {
      const user = await getUser();

      if (isActive && user) {
        dispatch({
          type: ACTIONS.SET_USER,
          payload: {
            ...user,
          },
        });

        monitorIdentity({
          username: user.username,
          email: user.email,
          id: user.id,
          organization: user.organization.name,
        });

        const settings = user.settings;

        if (settings && settings.timezone) {
          const timezone = {
            Offset: settings.timezone.Offset,
            Name: settings.timezone.Name,
            Description: settings.timezone.Description,
          };

          dispatch({
            type: ACTIONS.SET_TIMEZONE,
            payload: timezone,
          });
        }
      }
    };

    handleGetUser();

    return () => {
      isActive = false;
    };
  }, [dispatch]);

  useEffect(() => {
    resetSpikeDate();
    setAnalyzeEntity(null);
    setDrilledConcepts([]);
  }, [projectName, resetSpikeDate]);

  useEffect(() => {
    async function initializeLDUser() {
      const strapiUser = await getUser();
      const ldUser = {
        key: project.name,
        name: strapiUser.username,
        email: strapiUser.email,
      };
      await ldClient?.identify(ldUser);
    }
    initializeLDUser();
  }, [ldClient, project.name]);

  useEffect(() => {
    const gerUserGroups = async () => {
      let groups = [];
      if (project.id) {
        groups = await getUserGroups(project.id);
      }
      if (groups) {
        dispatch({
          type: ACTIONS.SET_USER_GROUPS,
          payload: groups,
        });
      }
    };
    gerUserGroups();
  }, [dispatch, project.id]);

  useEffect(() => {
    const getProfiles = async () => {
      const harmClassifiers = await getClassifierProfiles();
      if (harmClassifiers && harmClassifiers.length) {
        const arr = [DEFAULT_CLASSIFIER];

        if (
          project.classifierProfile &&
          !harmClassifiers.some((d) => d.id === project.classifierProfile.id)
        ) {
          arr.push({
            isGlobal: true,
            ...project.classifierProfile,
          });
        }

        const completeHarmProfiles = [
          ...arr,
          ...harmClassifiers.map((d) => {
            return {
              ...d,
              isGlobal: d.id === project.classifierProfile?.id,
            };
          }),
        ];

        dispatch({
          type: ACTIONS.SET_HARM_CLASSIFIERS,
          payload: completeHarmProfiles,
        });
      }
    };
    getProfiles();
  }, [dispatch, project.classifierProfile]);

  // preselect harm classifier profile
  useEffect(() => {
    const loadClassifier = async () => {
      let userMatch = undefined;
      const previewClassifierProfileRef = await localStorage.getItem(
        LOCAL_STORAGE_KEYS.PREVIEW_CLASSIFIER_PROFILE_REF
      );
      if (previewClassifierProfileRef) {
        userMatch = harmClassifiers.find(
          (hp) => hp.id === parseInt(previewClassifierProfileRef)
        );
      }

      const newClassifierProfile = userMatch
        ? userMatch
        : project
        ? project.classifierProfile
        : null;
      if (newClassifierProfile) {
        dispatch({
          type: ACTIONS.SET_HARM_CLASSIFIER,
          payload: newClassifierProfile,
        });
      }
    };
    loadClassifier();
  }, [dispatch, harmClassifiers, narrative.analyze_filters, project]);

  useEffect(() => {
    const getProfiles = async () => {
      const harmProfiles = await getHarmProfiles(); // returns empty set for non bb users
      // blackbird user
      if (harmProfiles && harmProfiles.length) {
        const arr = [DEFAULT_PROFILE];

        // if project harm profile exists and custom harm profiles do not contain that profile, add it to the list
        if (
          project.harmProfile &&
          !harmProfiles.some((d) => d.id === project.harmProfile.id)
        ) {
          arr.push({
            isGlobal: true,
            ...project.harmProfile,
          });
        }

        const completeHarmProfiles = [
          ...arr,
          ...harmProfiles.map((d) => {
            return {
              ...d,
              isGlobal: d.id === project.harmProfile?.id,
            };
          }),
        ];

        dispatch({
          type: ACTIONS.SET_HARM_PROFILES,
          payload: completeHarmProfiles,
        });
      }
    };
    getProfiles();
  }, [dispatch, project.harmProfile]);

  // preselect harm profile
  useEffect(() => {
    const loadHarmProfile = async () => {
      let userMatch = undefined;
      const previewHarmProfileRef = await localStorage.getItem(
        LOCAL_STORAGE_KEYS.PREVIEW_HARM_PROFILE_REF
      );

      if (previewHarmProfileRef) {
        userMatch = harmProfiles.find(
          (hp) => hp.id === parseInt(previewHarmProfileRef)
        );
      }

      const newHarmProfile = userMatch
        ? userMatch
        : project
        ? project.harmProfile
        : null;
      if (newHarmProfile) {
        dispatch({
          type: ACTIONS.SET_HARM_PROFILE,
          payload: newHarmProfile,
        });
      }
    };

    loadHarmProfile();
  }, [dispatch, harmProfiles, narrative.analyze_filters, project]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <TopBar
        setSpikeDate={changeSpikeDate}
        setAnalyzeEntity={setAnalyzeEntity}
        setDrilledConcepts={setDrilledConcepts}
        validProject={validProject}
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        drawerWidth={drawerWidth}
        drawerClosedWidth={drawerClosedWidth}
        smallScreen={smallScreen}
      />

      {(!smallScreen || navOpen) && (
        <NavigationDrawer
          setSpikeDate={changeSpikeDate}
          navOpen={navOpen}
          setNavOpen={setNavOpen}
          validProject={validProject}
        />
      )}

      <main className={classes.content} id="main_content">
        {showSummary && (
          <div className={classes.statsWrapper}>
            <Summary summaryData={wildcardSummary || {}} />
          </div>
        )}

        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route path={[`${path}/overview`]}>
              <Overview
                setAnalyzeEntity={setAnalyzeEntity}
                drilledConcepts={drilledConcepts}
                setDrilledConcepts={setDrilledConcepts}
                setSpikeDate={changeSpikeDate}
                wildcardSummary={wildcardSummary}
              />
            </Route>
            <Route path={[`${path}/visualize`]}>
              <Visualize />
            </Route>
            <Route path={[`${path}/analyze`]}>
              <EntityExplorer
                entity={analyzeEntity}
                setEntity={setAnalyzeEntity}
                setSpikeDate={changeSpikeDate}
              />
            </Route>
            <Route path={[`${path}/discover`, `${path}/refine`]}>
              <NarrativeBuilder
                addNarrative={addNarrative}
                _updateNarrative={_updateNarrative}
              />
            </Route>
            <Route path={`${path}/not-found`}>
              <ProjectNotFound />
            </Route>
            <Route
              exact
              path={`${path}/`}
              render={() => {
                if (validProject) {
                  const redirectUrl = url.replace(/\/$/, "");
                  return <Redirect to={`${redirectUrl}/overview`} />;
                }
                return null;
              }}
            />
          </Switch>
        </Suspense>
      </main>

      <Drawers />

      <Toaster />

      {errorIsActive && (
        <Backdrop
          sx={{
            color: "rgba(0, 0, 0, 0.75)",
            zIndex: 1251,
          }}
          open={true}
        />
      )}

      {/* AS PER PREDASH-2844 */}
      {/* {flags.zendeskChat && <ZendeskWidget />} */}

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={4000}
        onClose={closeSnackBar}
        message={snackbarMessage?.message}
        color="primary"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {snackbarMessage && (
          <Alert
            onClose={closeSnackBar}
            variant="filled"
            severity={snackbarMessage.type}
          >
            {snackbarMessage.message}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
