export const TOGGLE_SNACK = "TOGGLE_SNACK";
export const SET_SNACK = "SET_SNACK";

export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const DELETING = "DELETING";
export const DELETED = "DELETED";
export const STAGED = "STAGED";
export const ADD_DOCS = "ADD_DOCS";
export const SET_DOCS = "SET_DOCS";
export const SELECT_DOCS = "SELECT_DOCS";
export const STAGE_DOCS = "STAGE_DOCS";
export const TOGGLE_UPLOADING = "TOGGLE_UPLOADING";
export const TOGGLE_TOS_CHECKED = "TOGGLE_TOS_CHECKED";

export const SET_SELECTED_FILES = "SET_SELECTED_FILES";

export const SET_TALKWALKER_OBJECT = "SET_TALKWALKER_OBJECT";

export const SET_TALKWALKER_CONNECTION = "SET_TALKWALKER_CONNECTION";

export const REFRESHABLE_JOB_STATUSES = [
  "In Queue",
  "Running",
]

export const initialState = {
  error: "",
  loading: false,
  isAuthenticated: false,
  snackMessage: "",
  snackOpen: false,
  snackError: false,
  snackDuration: 3500,
  uploading: false,
  tosChecked: false,

  documents: [],
  stagedDocuments: [],
  selectedDocuments: [],

  dataConnectors: [],
  dataConnectorsRefetch: false,

  talkwalkerObject: null,
  talkwalkerConnection: null,

  selectedFilesForJob: [],
};

export const ACCEPTED_FILES = `text/csv, text/plain, application/json, application/pdf, application/vnd.ms-powerpoint, 
  application/vnd.openxmlformats-officedocument.presentationml.presentation, application/ld+json, 
  application/x-bzip, application/x-bzip2, application/gzip, application/epub+zip, application/zip,
  application/x-7z-compressed, application/vnd.ms-excel, 
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json-lines, application/jsonl`;

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DESC_ORDER = "DESC";
export const ASC_ORDER = "ASC";

export const SET_DATA_CONNECTORS = "SET_DATA_CONNECTORS";
export const SET_DATA_CONNECTORS_REFETCH = "SET_DATA_CONNECTORS_REFETCH";