import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as TalkwalkerIcon } from "icons/Uploader/Talkwalker.svg";
import { ReactComponent as FileIcon } from "icons/Uploader/File.svg";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: "30px",
  },
  inner: {
    margin: "0 auto",
    display: "inline-block",
  },
  rect: {
    margin: "0 auto",
    background: theme.palette.background.$2,
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), inset 0px 0px 1px rgba(255, 255, 255, 0.15)",
    borderRadius: "6px",
    width: 145,
    height: 145,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      maxWidth: 83,
      maxHeight: 87,
    },
  },
  btns: {
    display: "flex",
  },
  btn: {
    display: "block",
    textAlign: "center",
    margin: "10px 15px",
  },
  btnTxt: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: 8,
  },
}));

const SelectFlow = ({ onSelect }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>
        <Typography>How do you want to create your new project?</Typography>
        <Box className={classes.btns}>
          <Button
            className={classes.btn}
            onClick={() => onSelect("fileUpload")}
          >
            <Box className={classes.rect}>
              <FileIcon />
            </Box>
            <Box>
              <Typography className={classes.btnTxt}>File Upload</Typography>
            </Box>
          </Button>
          <Button
            className={classes.btn}
            onClick={() => onSelect("talkwalker")}
          >
            <Box className={classes.rect}>
              <TalkwalkerIcon />
            </Box>
            <Box>
              <Typography className={classes.btnTxt}>
                Import from Talkwalker
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectFlow;
