import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: ({ backgroundColor }) => ({
    width: "337px",
    height: "112px",
    background: backgroundColor,
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  }),
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "18px 16px 18px 16px",
    flex: 1,
  },
  action: ({ actionBackground, borderTop, actionTextColor }) => ({
    backgroundColor: actionBackground,
    color: actionTextColor,
    width: "100%",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "17px",
    padding: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop,
    "&:hover": {
      backgroundColor: "#2E2C3E",
    },
  }),
  title: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: "white",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "17px",
    color: "rgba(255, 255, 255, 0.5)",
    marginTop: "4px",
  },
  icon: ({ iconColor }) => ({
    width: 18,
    height: 18,
    color: iconColor,
  }),
  iconContainer: ({ iconBackground }) => ({
    padding: "9px 10px 9px 10px",
    borderRadius: "4px",
    backgroundColor: iconBackground,
    marginRight: "12px",
  }),
  text: {},
}));

const ErrorSnack = ({
  title,
  icon,
  iconColor,
  iconBackground,
  actionBackground,
  backgroundColor,
  subtitle,
  borderTop,
  actionText,
  actionTextColor,
  onActionClick,
}) => {
  const classes = useStyles({
    iconColor,
    iconBackground,
    actionBackground,
    backgroundColor,
    borderTop,
    actionTextColor,
  });

  const Icon = icon;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} />
        </div>

        <div className={classes.text}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </div>
      </div>

      <Button className={classes.action} onClick={onActionClick}>
        {actionText}
      </Button>
    </div>
  );
};

export default ErrorSnack;
