import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { POST_CHAR_LIMIT } from "utils/constants";
import { getParagraphs } from "utils/format";
import HighlightedKeyword from "./HighlightedKeyword";

export default function TextTogglable({ text, limit = POST_CHAR_LIMIT, sx }) {
  const [readMoreOpen, setReadMoreOpen] = useState(false);

  const isLongText = text && text.length > limit;

  const emIndex = (text || "").indexOf("</em>") + 5;
  const splitIndex = Math.max(limit, emIndex);

  const visibleText =
    text && isLongText && !readMoreOpen
      ? text.slice(0, splitIndex) + "..."
      : text;

  const paragraphs = getParagraphs(visibleText);

  const styles = {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    ...sx,
  };

  return (
    <Box>
      {paragraphs.map((p, i) => (
        <Typography
          key={i}
          paragraph
          display={"block"}
          variant={"body2"}
          sx={styles}
        >
          <HighlightedKeyword html={p} />
          {isLongText && i === paragraphs.length - 1 && (
            <Button
              sx={{ py: 0, ml: 0.5 }}
              variant="text"
              color="primary"
              size="small"
              disableElevation
              disableRipple
              onClick={() => setReadMoreOpen(!readMoreOpen)}
            >
              {readMoreOpen ? "Show less" : "Read more"}
            </Button>
          )}
        </Typography>
      ))}
    </Box>
  );
}
