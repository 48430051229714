import React from "react";
import { Switch, Route } from "react-router-dom";
import LoginPage from "pages/Authentication/LoginPage";
import PasswordResetPage from "pages/Authentication/PasswordResetPage";
import DashboardPage from "pages/DashboardPage";
import NotFoundPage from "pages/NotFoundPage";
import PrivateRoute from "components/PrivateRoute";
import PasswordResetEmailPage from "pages/Authentication/PasswordResetEmailPage";
import * as Sentry from "@sentry/react";
import ZendeskRedirectPage from "pages/Authentication/ZendeskRedirectPage";
import "@uiw/react-textarea-code-editor/dist.css";
import "./App.css";
import ErrorBoundary from "components/Error/ErrorBoundary";
import DataUploader from "pages/DataUploader";

function App() {
	return (
		<Switch>
			<PrivateRoute path={`/manage-data`}>
				<ErrorBoundary>
					<DataUploader />
				</ErrorBoundary>
			</PrivateRoute>
			<PrivateRoute path={[
				"/dashboard/:projectName/narrative/:narrativeId",
				"/dashboard/:projectName",
			]}>
				<ErrorBoundary>
					<DashboardPage />
				</ErrorBoundary>
			</PrivateRoute>
			<PrivateRoute path={"/dashboard"}>
				<ErrorBoundary>
					<DashboardPage />
				</ErrorBoundary>
			</PrivateRoute>
			<Route exact path="/">
				<LoginPage />
			</Route>
			<Route exact path="/forgotpassword">
				<PasswordResetEmailPage />
			</Route>
			<Route exact path="/resetpassword">
				<PasswordResetPage />
			</Route>
			<Route exact path="/helpdesklogin">
				<ZendeskRedirectPage />
			</Route>
			<Route>
				<NotFoundPage />
			</Route>
		</Switch>
	);
}

export default Sentry.withProfiler(App);
