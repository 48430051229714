import { Button, Chip, Divider, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { deleteHarmProfile, setHarmProfile } from "api/AnalyticsApi";
import CustomDropdown from "components/UI/CustomDropdown";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { ReactComponent as EditIcon } from "icons/EditNarrative.svg";
import { ReactComponent as DeleteIcon } from "icons/Delete.svg";
import { removeHarmFromFilters } from "context/reducerUtilities";
import clsx from "clsx";
import { DEFAULT_PROFILE, LOCAL_STORAGE_KEYS } from "utils/constants";
import Hint from "components/UI/Hint";
import { Box } from "@mui/system";
import useEventTracker from "api/hooks/useEventTracker";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  label: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    transform: "translate(0, 0) scale(1)",
  },
  spaceTop: {
    marginTop: theme.spacing(1),
  },
  muiItem: {
    fontSize: 13,
    color: "#E7E7EF",
  },
  profileSelect: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
  },
  profileListItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "60%",
    marginRight: 10,
    borderRadius: 4,
    background: "#302E4F",
    "& .MuiSelect-select": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      paddingRight: "12px !important",
      paddingLeft: "10px !important",
    },
		"& .dropdown_icon": {
      right: "10px !important",
    },
  },
  manage: {
    padding: "10px 0px",
  },
  profileName: {
    flexGrow: 1,
    color: "#afafaf",
    fontSize: 14,
  },
  btns: {},
  chipRoot: {
    height: "24px",
    fontSize: "12px",
    fontWeight: 500,
  },
  chipRootCustom: {
    background: "#0D341B",
    border: "1px solid #218746",
  },
  chipRootDefault: {
    border: "1px solid #6757d4",
    background:
      "linear-gradient(250.87deg, rgba(88, 88, 213, 0.3) -2.92%, rgba(113, 88, 213, 0.3) 106.15%)",
  },
}));

export default function HarmTab() {
  const classes = useStyles();
  const tracker = useEventTracker();

  const {
    dispatch,
    state: { harmProfiles, harmProfile, narrative, project },
  } = useAppContext();
  const narrativeIndex = parseInt(useParams().narrativeId) || 0;

  const showMessage = (msg, type) => {
		dispatch({
			type: ACTIONS.SHOW_MESSAGE,
			payload: msg
				? {
					message: msg,
					type: type,
				}
				: null,
		});
	};

  const resetHarm = () => {
    const analyze_filters = removeHarmFromFilters(narrative.analyze_filters);
    dispatch({
      type: ACTIONS.UPDATE_NARRATIVE,
      payload: {
        narrative: {
          ...narrative,
          analyze_filters,
        },
        index: narrativeIndex,
      },
    });
  };

  const handleProfileChange = async (e) => {
    const p = harmProfiles.find((d) => d.id === e.target.value);
    if (p && p.id !== harmProfile.id) {
      const setHarmProfileRes = await setHarmProfile(p.isDefault ? 0 : p.id);
      if (setHarmProfileRes) {
        await localStorage.setItem(
          LOCAL_STORAGE_KEYS.PREVIEW_HARM_PROFILE_REF,
          p.id
        );
        dispatch({
          type: ACTIONS.SET_USER,
          payload: {
            ...setHarmProfileRes,
          },
        });
        tracker.track(
          "Applied risk profile",
          "select",
          p.name,
          "Custom Risk Profile"
        );
        resetHarm();
      }
    }
  };

  const handleSetToDefault = async () => {
    if (harmProfile.id) {
      const setHarmProfileRes = await setHarmProfile(0);
      if (setHarmProfileRes) {
        await localStorage.setItem(
          LOCAL_STORAGE_KEYS.PREVIEW_HARM_PROFILE_REF,
          0
        );
        tracker.track(
          "Applied risk profile",
          "select",
          "Blackbird default",
          "Custom Risk Profile"
        );
        dispatch({
          type: ACTIONS.SET_USER,
          payload: {
            ...setHarmProfileRes,
          },
        });
        resetHarm();
      }
    }
  };

  const handleCreate = () => {
    dispatch({
      type: ACTIONS.EDIT_HARM_PROFILE,
      payload: {},
    });
    dispatch({
      type: ACTIONS.SET_HARM_DRAWER_OPEN,
      payload: true,
    });
    // hidePopup();
  };

  // const handleCopy = (d) => {
  //   const copy = {
  //     id: getRandomId(),
  //     name: "copy of " + d.name,
  //     includeQuery: [...(d.includeQuery || [])],
  //     excludeQuery: [...(d.excludeQuery || [])],
  //     isDefault: false,
  //   };
  //   dispatch({
  //     type: ACTIONS.ADD_HARM_PROFILE,
  //     payload: copy
  //   });
  // };

  const handleDelete = async (d) => {
    if (d.id === harmProfile.id || d.id === globalProfile.id) {
      // profile in use
      showMessage(
        "This profile is in use or it is a global profile",
        "error",
      );
    } else {
      const deleteRes = await deleteHarmProfile(d.id);
    
      if (deleteRes) {
        dispatch({
          type: ACTIONS.REMOVE_HARM_PROFILE,
          payload: d,
        });
        showMessage(
          "Profile deleted",
          "success",
        );
        tracker.track(
          "Deleted risk profile",
          "remove",
          d.name,
          "Custom Risk Profile"
        );
      }
    }
  };

  const handleEdit = (d) => {
    tracker.track(
      "Clicked edit risk profile",
      "click",
      d.name,
      "Custom Risk Profile"
    );

    dispatch({
      type: ACTIONS.EDIT_HARM_PROFILE,
      payload: d,
    });
  };

  const globalProfile = project.harmProfile || DEFAULT_PROFILE;

  const chipClass = globalProfile.isDefault
    ? classes.chipRootDefault
    : classes.chipRootCustom;

  return (
    <div>
      {globalProfile && (
        <div className={classes.row}>
          <div className={classes.label}>Global Risk Profile</div>
          <div className={classes.profileSelect}>
            <Chip
              classes={{
                root: clsx(classes.chipRoot, chipClass),
              }}
              label={globalProfile.name}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
      )}

      <Divider light />

      {harmProfile && (
        <>
          <Divider light />
          <div className={classes.row}>
            <div className={classes.label}>Preview Risk Profile</div>
            <div className={classes.profileSelect}>
              <div className={classes.dropdown}>
                <CustomDropdown
                  transparent
                  value={harmProfile.id}
                  onChange={handleProfileChange}
                  options={harmProfiles.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </div>
              <Button
                disabled={harmProfile.isDefault}
                variant="outlined"
                color="primary"
                onClick={() => handleSetToDefault()}
              >
                Reset to default
              </Button>
              <Box sx={{ml: 1}}>
                <Hint tooltipText={"The Blackbird Default risk profile is an aggregation of multiple risk signals including anomalous, bot-like, toxicity, and negative sentiment."} />
              </Box>
            </div>
          </div>
        </>
      )}

      <Divider light />

      <div className={classes.row}>
        <div className={classes.label}>Manage Risk Profiles</div>
        <div className={classes.manage}>
          {harmProfiles
            .filter((d) => !d.isDefault)
            .map((d) => (
              <div className={classes.profileListItem} key={d.name}>
                <Typography variant="caption" className={classes.profileName}>
                  {d.name}
                </Typography>
                <div className={classes.btns}>
                  {/* <IconButton onClick={() => handleCopy(d)}>
                  <CopyIcon />
                </IconButton> */}
                  <IconButton onClick={() => handleEdit(d)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(d)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
        </div>
        <Button variant="outlined" color="primary" onClick={handleCreate}>
          Create Risk Profile
        </Button>
      </div>
    </div>
  );
}
