import { Link, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as VerifiedIcon } from "icons/Verified.svg";
import UserIcons from "utils/user-icons";
import Analyze from "components/UI/AnalyzeBtn";
import { useAppContext } from "context/Context";
import { PLATFORMS } from "utils/constants";
import OpenIn from "components/UI/OpenIn";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		width: "100%",
		alignItems: "center",
		paddingLeft: 14
	},
	avatar: {
		height: theme.spacing(3),
		width: theme.spacing(3),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
	},
	separator: {
		margin: "0px 6px",
	},
	separatorSm: {
		margin: "0px 3px",
	},
	dot: {
		margin: "0px 5px 0px 0px",
		alignContent: "center",
		fontSize: 16,
	},
	button: {
		padding: 0,
		border: "none",
		background: "transparent",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		marginRight: 16,
		marginLeft: 14
	},
	username: {
		fontSize: 13,
		fontWeight: "normal",
		color: "#fff",
		lineHeight: "16px"
	},
}));

const UserCell = ({
	profile_image_url,
	profile_url,
	screen_name,
	verified,
	seeInEntityExpl = null,
}) => {
	const classes = useStyles();
	const UserIcon = UserIcons[screen_name];

	const {
		state: { platform },
	} = useAppContext();

	const suffix = platform === PLATFORMS.reddit ? "u/" : "@";

	return (
		<div className={classes.root}>
			<OpenIn href={profile_url} />

			{seeInEntityExpl && (
				<Analyze
					className={classes.button}
					onClick={() => seeInEntityExpl("Users", suffix + screen_name)}
				/>
			)}

			<div className={classes.separatorSm}></div>

			<Avatar
				alt={screen_name}
				className={classes.avatar}
				src={profile_image_url}
			/>

			<Link
				target="_blank"
				href={profile_url}
				className={classes.username}
				underline="hover"
			>
				{suffix}
				{screen_name}
			</Link>

			<span>&nbsp;</span>

			{verified && <VerifiedIcon />}

			<span>&nbsp;</span>

			{UserIcon && <UserIcon />}
		</div>
	);
};

export default UserCell;
