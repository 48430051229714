import { Button, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as BackArrowIcon } from "icons/UI/arrow-left.svg";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import Explanation from "components/UI/Explanation";
import ExplanationEntity from "./ExplanationEntity";

const useStyles = makeStyles((theme) => ({
	root: {},
	btn: {
		fontWeight: 600,
		fontSize: 14,
		lineHeight: "16px",
		color: "rgba(255,255,255,0.6)",
	},
	drawer: {
		zIndex: 9999,
		flexShrink: 0,
		width: 380,
		height: "100vh",
	},
	drawerPaper: {
		width: 380,
		background: "#1B1927",
		border: "1px solid " + theme.palette.border.popup,
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		backdropFilter: "blur(80px)",
		borderRadius: "6px 0px 0px 6px",
	},
	header: {
		height: "63.39px !important",
		borderBottom: "1px solid #282C38",
		padding: theme.spacing(3.8, 1),
		display: "flex",
		alignItems: "center",
	},
	backBtn: {
		fontWeight: "700",
		fontSize: "16px",
		lineHeight: "16px",
		textTransform: "capitalize",
	},
	content: {
		padding: theme.spacing(2),
	},
	postWrap: {
		backgroundColor: "#333146",
		borderRadius: 6,
	},
	entityWrap: {
		marginBottom: theme.spacing(4),
	},
	url: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
}));

export default function ExplanationDrawer() {
	const classes = useStyles();

	const {
		dispatch,
		state: { explanation, explanationDrawerOpen: open },
	} = useAppContext();

	const handleClose = () => {
		dispatch({
			type: ACTIONS.SET_EXPLANATION,
			payload: {
				explanationDrawerOpen: false,
			},
		});
	};

	return (
		<Drawer
			className={classes.drawer}
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor={"right"}
			open={open}
			onClose={handleClose}
		>
			<div className={classes.header}>
				<Button
					variant="h3"
					startIcon={<BackArrowIcon />}
					className={classes.backBtn}
					onClick={handleClose}
				>
					{explanation.entityType} Details
				</Button>
			</div>
			<div className={classes.content}>
				{explanation && explanation.entity && (
					<ExplanationEntity
						type={explanation.entityType}
						entity={explanation.entity}
					/>
				)}

				{explanation && (
					<Explanation {...explanation} />
				)}
			</div>
		</Drawer>
	);
}
