import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { uploadFile } from "api/UploadApi";
import { useCallback, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import DataGridTable from "../components/DataGridTable";
import {
  addNewDocuments,
  setSelectedFiles,
  stageDocuments,
} from "../context/action-creators";
import { useUploaderContext } from "../context/Context";
import { PENDING } from "../utils/constants";
import { stageDocs } from "../utils/utils";
import Uploader from "./Uploader";
import { useBeforeunload } from "react-beforeunload";

const columns = [
  {
    field: "filename",
    headerName: "Filename",
    description: "File name",
    flex: 2,
  },
  {
    field: "documents",
    headerName: "Documents",
    description: "Documents",
    flex: 1.5,
    renderCell: (params) => {
      const parseInfo = params.row.parseInfo;

      if (parseInfo) {
        const { ParsedRows = 0, ErrorCount = 0 } = parseInfo;
        return `${ParsedRows} / ${ParsedRows + ErrorCount} Documents Selected`;
      }
      return "";
    },
  },
];

const FileUploadFlow = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const {
    dispatch,
    state: { documents, stagedDocuments },
  } = useUploaderContext();

  const [errorMsg, setErrorMsg] = useState(null);
  const [selected, setSelected] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);

  // todo: move to upload context and save chunks for the future
  const [uploadProgress, setUploadProgress] = useState({
    percent: 0,
    timeLeft: 100,
    filename: "",
  });

  const addDocuments = useCallback(
    (docs) => {
      dispatch(addNewDocuments(docs));
    },
    [dispatch]
  );

  const setStagedDocuments = (docs) => {
    dispatch(stageDocuments(docs));
  };

  const reset = () => {
    setUploading(false);

    setUploadProgress({
      percent: 0,
      timeLeft: 100,
      filename: "",
    });
    setProcessing(false);
  };

  const onUploadProgress = (progress, doc) => {
    const { total, loaded } = progress || { total: 1, loaded: 0 };

    setUploadProgress({
      percent: ((loaded / total) * 100).toFixed(1),
      timeLeft: 100,
      filename: doc.filename,
    });

    if (Math.round((loaded / total) * 100) >= 100) {
      setUploading(false);
      setProcessing(true);
    }
  };

  const upload = async (document) => {
    try {
      const formData = new FormData();

      formData.append("files", document.file, document.filename);

      setUploading(true);

      const res = await uploadFile(document, formData, (progress) =>
        onUploadProgress(progress, document)
      );

      addDocuments([
        {
          ...document,
          parseInfo: res?.parseInfo,
          status: res?.status,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploads = async (documentsToUpload) => {
    setStagedDocuments([]);

    for (const document of documentsToUpload) {
      await upload(document);
    }

    reset();
  };

  const onRowSelect = (s) => {
    const selectedFiles = documents.filter((d) => s.includes(d.id));
    setSelected(selectedFiles);
  };

  const onFilesAccepted = (newDocuments) => {
    if (newDocuments && newDocuments.length) {
      const newDocs = stageDocs(documents, stagedDocuments, newDocuments);
      setStagedDocuments(newDocs);
      handleUploads(newDocs.map((doc) => ({ ...doc, status: PENDING })));
    }
  };

  const handleContinue = () => {
    if (uploading) {
      setErrorMsg("Wait files to finish uploading");
    } else if (selected.length) {
      setUploading(false);
      dispatch(setSelectedFiles(selected));
      history.push(`${url}/configure`);
    } else {
      setErrorMsg("Please select files");
    }
  };

  useBeforeunload((event) => {
    if (uploading) {
      event.preventDefault();
    }
  });

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", mt: 5 }}>
      <Uploader
        onFilesAccepted={onFilesAccepted}
        onFilesRejected={() => {}}
        uploading={uploading}
        uploadProgress={uploadProgress}
        processing={processing}
      />
      {documents.length > 0 && (
        <>
          <Box sx={{ mt: 4 }}>
            <DataGridTable
              columns={columns}
              rows={documents}
              selectedRows={selected}
              onRowSelect={onRowSelect}
              noDataText="No files"
            />
          </Box>

          {errorMsg && (
            <Typography color="error" sx={{ mt: 2, mb: 2 }}>
              {errorMsg}
            </Typography>
          )}

          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button
              variant="contained"
              sx={{ minWidth: 180 }}
              onClick={handleContinue}
              disabled={uploading || processing}
            >
              Continue
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FileUploadFlow;
