import {
  TOGGLE_SNACK,
  STAGE_DOCS,
  SET_DOCS,
  ADD_DOCS,
  SELECT_DOCS,
  TOGGLE_UPLOADING,
  TOGGLE_TOS_CHECKED,
  SET_SELECTED_FILES
} from "../utils/constants";

export const toggleSnack = (payload) => {
  return {
    type: TOGGLE_SNACK,
    payload,
  };
};

export const addNewDocuments = function (payload) {
  return {
    type: ADD_DOCS,
    payload,
  };
};

export const setNewDocuments = function (payload) {
  return {
    type: SET_DOCS,
    payload,
  };
};

export const stageDocuments = function (payload) {
  return {
    type: STAGE_DOCS,
    payload,
  };
};

export const selectDocuments = function (payload) {
  return {
    type: SELECT_DOCS,
    payload,
  };
};

export const toggleUploading = function (payload) {
  return {
    type: TOGGLE_UPLOADING,
    payload,
  };
};

export const toggleTOS = function (payload) {
  return {
    type: TOGGLE_TOS_CHECKED,
    payload,
  };
};

export const setSelectedFiles = function (payload) {
  return {
    type: SET_SELECTED_FILES,
    payload,
  };
};
