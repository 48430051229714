import React from "react";
import { Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: ({ tabColor }) => tabColor,
		borderBottom: "1px solid #6F7397",
		paddingLeft: 15,
		paddingRight: 15,
		minHeight: 38,
		overflow: "initial !important",
	},
	indicator: {
		backgroundColor: "transparent",
		display: "none",
	},
	scroller: {
		overflow: "initial !important",
	},
	muiTab: {
		fontWeight: 400,
		color: "#77758E !important",
		padding: "10px",
		flexDirection: "row",
		backgroundColor: ({ tabColor }) => tabColor,
		margin: 0,
		flex: "1 1 auto",
		transform: "translateY(1px)",
		border: "1px solid transparent",
		minHeight: "38px !important",
		fontSize: "14px",
		lineHeight: "18px",
		"& .MuiTab-wrapper": {
			display: "flex",
			flexDirection: "row",
			flexFlow: "row",
			alignItems: "center"
		},
		"& .MuiTab-iconWrapper": {
			marginBottom: 0,
			marginRight: 10
		},
		"& .MuiTab-wrapper svg": {
			marginBottom: "0 !important"
		},
		"& .MuiSvgIcon-root": {
			marginBottom: 0,
			marginRight: 5
		},
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	},
	unSelected: {
		color: "#77758E !important",
		borderBottom: "1px solid #6F7397",
		borderLeft: "none",
		borderRight: "none"
	},
	selected: {
		color: "#ffffff !important",
		border: "1px solid #6F7397",
		borderBottom: "1px solid transparent"
	},
	disabled: {
		color: "rgba(255, 255, 255, 0.2) !important"
	}
}));

function a11yProps(index) {
	return {
		id: `keyEntity-tab-${index}`,
		"aria-controls": `keyEntity-tabpanel-${index}`,
	};
}

export default function CustomTabs(props) {
	const classes = useStyles({ tabColor: props.tabColor || "#1D1A2D" });

	return (
		<Tabs
			value={props.value}
			onChange={props.onChange}
			textColor={"primary"}
			variant={"fullWidth"}
			indicatorColor={"primary"}
			classes={{
				root: classes.root,
				indicator: classes.indicator,
				scroller: classes.scroller,
			}}
		>
			{props.tabs.map((tab, index) => {
				const Icon = tab.icon;
				return (
					<Tab
						icon={Icon ? <Icon /> : null}
						label={tab.name}
						key={tab.name}
						disabled={tab.disabled === true}
						classes={{
							root: classes.muiTab,
							textColorPrimary: classes.unSelected,
							disabled: classes.disabled,
							selected: classes.selected
						}}
						{...a11yProps(index)}
					/>
				);
			})}
		</Tabs>
	);
}