const fields = [
	"bbmi",
	"harmful_engagement",
	"harm",
	"bot_score",
	"negative_sentiment",
	"positive_sentiment",
	"toxicity",
	"partisanship",
	"happiness",
	"anger",
	"disgust",
	"sadness",
	"surprise",
	"neutral",
	"fear",
];

const config = {
	tweets: ["id", "text", ...fields],
	hashtags: ["id", ...fields],
	urls: ["id", ...fields],
	users: ["id", "display_name", "profile_image_url", "verified", ...fields],
};

function copyEntityToClipboad(d, target) {
	const columns = config[target];
	const text = `${columns.join("\t")}\n${columns.map(c => d[c]).join("\t")}`;
	return navigator.clipboard.writeText(text);
}

export default copyEntityToClipboad;
