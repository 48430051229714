import { Typography, Link, Button, Tooltip, Avatar, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as TwitterIcon } from "icons/social-media/Twitter.svg";
import { formatNumber, formatPostTime } from "utils/format";
import { ReactComponent as FakeIcon } from "icons/fake-website.svg";
import { ReactComponent as RedditIcon } from "icons/social-media/Reddit.svg";
import { ReactComponent as CopyClipboardIcon } from "icons/UI/copy.svg";
import { ReactComponent as ExtendedData } from "icons/NarBuilder/ExtendedData.svg";
import { ReactComponent as TimeIcon } from "icons/Clock.svg";
import { useAppContext } from "context/Context";
import Analyze from "components/UI/AnalyzeBtn";
import { HARMFUL_ENGAGEMENT_POSTS_LABEL, PLATFORMS } from "utils/constants";
import { getPostURL, getSubredditURL, getUserURL } from "utils/fns";
import OpenIn from "components/UI/OpenIn";
import PostOrigin from "components/UI/PostOrigin";
import TextTogglable from "components/UI/TextTogglable";
import HandleRemoved from "components/HandleRemoved";
import HighlightedKeyword from "components/UI/HighlightedKeyword";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3),
    "&:hover": {
      backgroundColor: (props) => (props.analyze ? null : "#242234"),
    },
  },
  timeIcon: {
    marginRight: theme.spacing(0.7),
  },
  footer: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#86899D",
  },
  btn: {
    border: "1px solid #282C38",
    padding: 0,
    width: 24,
    height: 24,
    minWidth: 24,
    borderRadius: 3,
    color: "#6F7494",
    marginLeft: 10,
    "&:hover": {
      border: "1px solid #282C38",
    },
  },
  button: {
    padding: 0,
    border: "none",
    background: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: 8,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
  },
  timestamp: {
    marginLeft: theme.spacing(1),
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "24px",
    color: "#6F7494",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
  },
  indicator: {
    width: 12,
    marginRight: 5,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dot: {
    width: 12,
    height: 12,
    backgroundColor: (props) => {
      if (props.indicator === "manipulated") {
        return "rgba(198, 54, 63, 0.9)";
      } else {
        return "rgba(44, 160, 44, 0.9)";
      }
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  origPost: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  clusterIcon: {
    marginRight: 5,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 15,
      height: 15,
    },
  },
  hoaxIcon: {
    width: 18,
    height: 18,
    marginLeft: 8,
    cursor: "pointer",
  },
  harmfulEng: {
    marginLeft: 8,
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 600,
    color: "#AFAFAF",
  },
  marginRight: {
    marginRight: 5,
  },
  userName: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "16.32px",
    color: "rgba(255,255,255,0.99)",
    display: "flex",
    alignItems: "center",
    marginRight: 5,
  },
  in: {
    color: "#6F7494",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "16.32px",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  avatar: {
    width: 22,
    height: 22,
    marginRight: 6,
  },
  divider: {
    margin: "0px 10px 0px 10px",
    fontWeight: 400,
    lineHeight: "35px",
    opacity: 0.3,
    fontSize: "12px",
  },
  extendedData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: 500,
    color: "#BABDD9",
    cursor: "pointer",
  },
  extendedDataIcon: {
    marginRight: "5px",
  },
}));

function Tweet({
  id,
  text,
  full_text,
  title,
  post_time,
  user_display_name,
  user_screen_name,
  indicator,
  noWrap,
  clusters = 0,
  known_hoax = 0,
  withHoax = false,
  withHarmfulEng = false,
  harmful_engagement = null,
  hoaxClick,
  analyze = false,
  seeInEntityExpl,
  is_cluster,
  profile_image_url,
  onCopy,
  ...rest
}) {
  const classes = useStyles({ indicator, analyze });
  const {
    state: { timezoneOffset, platform },
  } = useAppContext();

  const user_url = getUserURL({ user_screen_name, platform });
  const post_url = getPostURL({
    user_screen_name,
    id,
    platform,
    subreddit_name: rest.subreddit_name,
    post_url: rest.post_url || rest.post_original_url,
  });

  const post_body = platform === PLATFORMS.reddit ? full_text : text;

  let highlight_text = post_body;
  let post_title = title;

  if (rest.sample_posts && rest.sample_posts[0]?.highlight_text) {
    highlight_text = rest.sample_posts[0].highlight_text;
  } else if (rest.highlight_text) {
    highlight_text = rest.highlight_text;
  }

  if (rest.sample_posts && rest.sample_posts[0]?.highlight_title) {
    post_title = rest.sample_posts[0].highlight_title;
  } else if (rest.highlight_title) {
    post_title = rest.highlight_title;
  }

  const subreddit_name =
    rest.subreddit_name ||
    rest.subreddit_dot_name ||
    (rest.sample_posts && rest.sample_posts[0]?.subreddit_dot_name);

  const subreddit_url = getSubredditURL(subreddit_name);

  const suffix = platform === PLATFORMS.reddit ? "u/" : "@";

  const openIn = <OpenIn href={post_url} border />;

  const copyClipboad = (
    <Tooltip title="Copy to Clipboard" placement="top">
      <Button
        className={classes.btn}
        variant={"outlined"}
        color={"secondary"}
        onClick={onCopy}
      >
        <CopyClipboardIcon />
      </Button>
    </Tooltip>
  );

  const subreddit = platform === PLATFORMS.reddit && subreddit_name && (
    <>
      <Typography component="span" className={classes.in}>
        in
      </Typography>
      {
        <Link
          fontSize="small"
          underline="hover"
          className={classes.link}
          target="_blank"
          href={subreddit_url}
          sx={{
            color: "#fff",
          }}
        >
          r/{subreddit_name}
        </Link>
      }
    </>
  );

  return (
    <div className={classes.root}>
      {analyze && (
        <div className={classes.origPost}>
          <PostOrigin is_cluster={is_cluster} {...rest} />
          <Box sx={{ mr: 1.25 }}>{copyClipboad}</Box>
          <Box>{openIn}</Box>
        </div>
      )}

      {post_title && (
        <HandleRemoved text={post_title}>
          <Typography
            paragraph
            display={"block"}
            variant={"body2"}
            sx={{
              fontStyle: "normal",
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: "17px",
              lineHeight: "21px",
              marginBottom: "15px",
            }}
          >
            <HighlightedKeyword html={post_title} />
          </Typography>
        </HandleRemoved>
      )}

      <HandleRemoved text={highlight_text}>
        <TextTogglable
          text={highlight_text}
          sx={{
            fontSize:
              platform === PLATFORMS.reddit || !analyze ? "14px" : "17px",
            lineHeight:
              platform === PLATFORMS.reddit || !analyze ? "18px" : "25px",
          }}
        />
      </HandleRemoved>

      <div className={classes.flex}>
        {analyze && (
          <Analyze
            className={classes.button}
            onClick={() => seeInEntityExpl("Users", suffix + user_screen_name)}
          />
        )}

        <div className={classes.userName}>
          <Avatar className={classes.avatar} src={profile_image_url} />
          <Link
            underline="hover"
            className={classes.link}
            target="_blank"
            href={user_url}
          >
            {suffix}
            {user_screen_name}
          </Link>
          {subreddit}
        </div>

        <Typography variant={"body2"} className={classes.timestamp}>
          <TimeIcon className={classes.timeIcon} />
          {formatPostTime(post_time, timezoneOffset)}
        </Typography>

        {!rest.is_base && analyze && (
          <>
            <div className={classes.divider}>|</div>
            <Tooltip
              placement="top"
              title="Post does not meet the collection rule--perhaps due to being outside the collection time window or not matching other metadata filters--but was retweeted or commented on by at least one post that does meet the collection rule."
            >
              <div className={classes.extendedData}>
                <ExtendedData className={classes.extendedDataIcon} />
                Extended Data
              </div>
            </Tooltip>
          </>
        )}
      </div>

      {/* NOT SHOWN IN ANALYZE  */}
      {analyze ? null : (
        <div className={classes.footer}>
          <div className={classes.flex}>
            {indicator && (
              <div className={classes.indicator}>
                <div className={classes.dot}></div>
              </div>
            )}

            {platform === "twitter" && <TwitterIcon />}
            {platform === "reddit" && <RedditIcon />}

            {withHoax && known_hoax > 0 && (
              <div
                className={classes.hoaxIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  hoaxClick({
                    id,
                    platform,
                    post_url,
                    post_body,
                    post_time,
                    user_display_name,
                    user_screen_name,
                    indicator,
                    noWrap,
                    clusters,
                    known_hoax,
                    harmful_engagement,
                    ...rest,
                  });
                }}
              >
                <FakeIcon />
              </div>
            )}

            {withHarmfulEng && harmful_engagement !== null && (
              <div className={classes.harmfulEng}>
                {HARMFUL_ENGAGEMENT_POSTS_LABEL}:{" "}
                {formatNumber(harmful_engagement)}
              </div>
            )}
          </div>
          {post_url && openIn}
        </div>
      )}
    </div>
  );
}

export default Tweet;
