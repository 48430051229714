import React from "react";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.background.$3,
  },
  tooltip: {
    padding: 12,
    backgroundColor: theme.palette.background.$3,
    color: "#ffffff",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    maxWidth: 200,
  },
  tooltipPlacementTop: {
    margin: "5px 0px",
  },
}));

export default function SimpleTooltip({
  contentComp,
  text,
  children,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={classes}
      title={contentComp || text}
      placement="top"
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
