import axios from "axios";

const strapiApi = process.env.REACT_APP_STRAPI_API || "/apiv4";

const strapi = axios.create({
	baseURL: strapiApi,
	timeout: 600000,
});

export default strapi;
