import ErrorView from "./ErrorView";
import * as Sentry from "@sentry/react";

function ErrorBoundary({ children }) {
	return (
		<Sentry.ErrorBoundary fallback={<ErrorView />}>
			{children}
		</Sentry.ErrorBoundary>
	);
}

export default ErrorBoundary;
