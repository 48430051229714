import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomDropdown from "components/UI/CustomDropdown";
import TalkwalkerLogoUrl from "icons/Uploader/talkwalker-logo.png";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { addDataConnector, checkTalkwalkerStatus } from "api/UploadApi";
import { useUploaderContext } from "../context/Context";
import {
  SET_DATA_CONNECTORS_REFETCH,
  SET_TALKWALKER_CONNECTION,
} from "../utils/constants";
import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    margin: "0 auto",
    padding: "60px 0px",
    "& .MuiSelect-select": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      paddingRight: "12px !important",
      paddingLeft: "10px !important",
    },
    "& .dropdown_icon": {
      right: "10px !important",
    },
  },
  input: {
    borderRadius: 6,
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.background.$2,
      boxShadow: "0px 0px 1px #131121",
      borderRadius: 6,
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "15px",
    },
    "& fieldset": {
      borderColor: theme.palette.background.$2,
    },
  },
  talkwalkerLogo: {
    width: 83,
    height: 87,
  },
  mainTitle: {
    fontSize: "20px",
    lineHeight: "25px",
    color: "#fff",
    textAlign: "center",
  },
  greyLabel: {
    color: "#AFAFAF",
    fontSize: "15px",
    lineHeight: "19px",
    marginBottom: "10px",
  },
  checkboxLabel: {
    color: "#AFAFAF",
    fontSize: "14px",
    lineHeight: "18px",
  },
  divider: {
    padding: "40px 0px",
    textAlign: "center",
    color: "#6F7494",
    fontSize: "14px",
    lineHeight: "20px",

    "&>div": {
      position: "relative",
    },
    "&>div:before": {
      content: "' '",
      position: "absolute",
      top: 10,
      left: 0,
      width: "calc(50% - 30px)",
      borderBottom: "1px solid #302E4F",
    },
    "&>div:after": {
      content: "' '",
      position: "absolute",
      top: 10,
      right: 0,
      width: "calc(50% - 30px)",
      borderBottom: "1px solid #302E4F",
    },
  },
}));

const requiredText = "This field is required";
const chooseText = "Choose a saved connection or create a new connection.";
const keyInvalidText = "Api key is not valid.";

const TalkwalkerConnection = () => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();

  const {
    state: { dataConnectorsRefetch, dataConnectors },
    dispatch,
  } = useUploaderContext();

  const dataConnectorsList = [
    { label: "- Select -", value: "" },
    ...dataConnectors.map((d) => {
      return {
        value: d.id,
        label: d.name || d.id,
        disabled: !d.statusToggle,
      };
    }),
  ];

  const [socialPlatform, setSocialPlatform] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [name, setName] = useState("");

  const [saving, setSaving] = useState(false);
  const [statusOk, setStatusOk] = useState(false);
  const [checkingKey, setCheckingKey] = useState(false);

  const [apiKeyError, setApiKeyError] = useState(null);
  const [nameError, setNameError] = useState(null);

  const [generalError, setGeneralError] = useState(null);

  const onContinue = (payload) => {
    dispatch({
      type: SET_TALKWALKER_CONNECTION,
      payload,
    });
    history.push(url.replace("/talkwalker-connect", "/talkwalker"));
  };

  const handleContinue = async () => {
    setApiKeyError(null);
    setNameError(null);
    setGeneralError(null);

    if (socialPlatform) {
      onContinue({ selected: socialPlatform });
    } else if (apiKey && statusOk && name) {
      const user = JSON.parse(localStorage.getItem("user"));

      const payload = {
        data: {
          name,
          apiKey: apiKey,
          dataProvider: "Talkwalker",
          owner: user.id,
          organization: { id: user.orgId },
          statusToggle: true,
        },
      };

      setSaving(true);
      const resp = await addDataConnector(payload);
      setSaving(false);
      if (resp) {
        dispatch({
          type: SET_DATA_CONNECTORS_REFETCH,
          payload: !dataConnectorsRefetch,
        });
      }

      onContinue(true);
    } else if (apiKey && !statusOk) {
      setGeneralError(keyInvalidText);
    } else if (apiKey && !name) {
      setNameError("Specify connection name");
    } else {
      setGeneralError(chooseText);
    }
  };

  const handleStatusCheck = async () => {
    if (apiKey) {
      setApiKeyError(null);
      setNameError(null);
      setGeneralError(null);

      const res = await checkTalkwalkerStatus(apiKey);
      setCheckingKey(false);

      if (res && res.status_message === "OK") {
        setStatusOk(true);
      } else {
        setStatusOk(false);
        setGeneralError(keyInvalidText);
      }
    } else {
      setApiKeyError("Specify api key");
    }
  };

  return (
    <Box className={classes.root}>
      <Box sx={{ textAlign: "center", py: 2 }}>
        <img src={TalkwalkerLogoUrl} className={classes.talkwalkerLogo} />
      </Box>
      <Typography className={classes.mainTitle}>
        Connect to Talkwalker
      </Typography>
      <Box sx={{ mt: 5 }}>
        <Typography className={classes.greyLabel}>
          Choose from saved connections
        </Typography>
        <CustomDropdown
          placeholder={"- Select -"}
          value={socialPlatform}
          helperText={requiredText}
          onChange={(e) => setSocialPlatform(e.target.value)}
          options={dataConnectorsList}
        />
      </Box>

      {!socialPlatform && (
        <>
          <Box className={classes.divider}>
            <Box>OR</Box>
          </Box>
          <Box>
            <Typography className={classes.greyLabel}>
              Enter a new Talkwalker API key
            </Typography>

            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <TextField
                variant="outlined"
                placeholder="Paste API key here"
                fullWidth
                error={!!apiKeyError}
                helperText={apiKeyError}
                size="small"
                className={classes.input}
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                disabled={statusOk}
              ></TextField>
              <Button
                sx={{ ml: "10px" }}
                variant="outlined"
                disabled={checkingKey || statusOk}
                onClick={handleStatusCheck}
              >
                {checkingKey
                  ? "Connecting"
                  : statusOk
                  ? "Connected"
                  : "Connect"}
              </Button>
            </Box>
          </Box>

          {statusOk && (
            <Box sx={{ mt: "30px" }}>
              <Typography className={classes.greyLabel}>
                Name your Talkwalker profile
              </Typography>
              <TextField
                variant="outlined"
                placeholder="e.g. Client A"
                fullWidth
                error={!!nameError}
                helperText={nameError}
                size="small"
                className={classes.input}
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></TextField>
            </Box>
          )}
        </>
      )}

      {generalError && (
        <Typography color="error" sx={{ mt: 2, mb: 2 }}>
          {generalError}
        </Typography>
      )}

      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Button
          variant="contained"
          sx={{ minWidth: 180 }}
          onClick={handleContinue}
        >
          {saving ? "Saving..." : "Continue"}
        </Button>
      </Box>
    </Box>
  );
};

export default TalkwalkerConnection;
