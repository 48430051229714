import { useLocation } from "react-router-dom";
import { TAB_OPTIONS } from "utils/constants";

const useTabs = () => {
  const location = useLocation();
  const currentTabName = location.pathname.split("/").pop();
  const currentPath = location.pathname;

  const sidebarTab = Math.max(
    TAB_OPTIONS.findIndex((tabs) => {
      return  tabs.some((t) => {
        const fullPath = t.basePath + "/" + t.path;
        return t.path === currentTabName || currentPath.includes(fullPath);
      });
    }),
    0
  );

  const topTab = Math.max(
    TAB_OPTIONS[sidebarTab].findIndex((t) => {
      const fullPath = t.basePath + "/" + t.path;
      return t.path === currentTabName || currentPath.includes(fullPath);
    }),
    0
  );

  return {
    sidebarTab,
    topTab,
    topTabs: TAB_OPTIONS[sidebarTab]
  }
};

export default useTabs;
