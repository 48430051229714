import { useEffect, useState } from "react";
import moment from "moment";
import { FormHelperText, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "react-dates";
import { ReactComponent as LineIcon } from "icons/UI/dash.svg";
import { ReactComponent as TimeArrowIcon } from "icons/UI/time-arrow.svg";
import { getCorrectDate, getISOStringMoment, getTimeStr } from "utils/format";

const useStyles = makeStyles((theme) => ({
  timeInput: {
    "& .MuiInputBase-root": {
      background: theme.palette.background.$1,
      borderRadius: "0px !important",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "18px",
      border: "none",
    },
    "& fieldset": {
      borderRadius: 0,
      borderColor: "transparent",
    },
    "& input::-webkit-calendar-picker-indicator": {
      background: "none",
      display: "none",
    },
  },
  timePickerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "5px",
    marginBottom: "10px",
  },
  timePickerRoot: {
    display: "flex",
    alignItems: "center",
  },
	errorBorder: {
    border: `1px solid ${theme.palette.error.main}`,
  }
}));

export default function DateTimePicker({
  dateFrom,
  dateTo,
  onChange,
  datePickerProps = {},
  disabled,
  error,
  helperText,
}) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    setStartDate(dateFrom ? getCorrectDate(dateFrom) : null);
    setEndDate(dateTo ? getCorrectDate(dateTo) : null);
    setStartTime(dateFrom ? getTimeStr(dateFrom) : "00:00");
    setEndTime(dateTo ? getTimeStr(dateTo) : "23:59");
  }, [dateFrom, dateTo]);

  const handleChange = (startDate, endDate, startTime, endTime) => {
    setTimeout(
      () =>
        onChange(
          startDate ? getISOStringMoment(startDate, startTime) : null,
          endDate ? getISOStringMoment(endDate, endTime) : null
        ),
      0
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    handleChange(startDate, endDate, startTime, endTime);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
    handleChange(startDate, endDate, e.target.value, endTime);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
    handleChange(startDate, endDate, startTime, e.target.value);
  };

	const invalid = error && (!startDate || !endDate)

  return (
    <Box>
      <Box className={invalid ? classes.errorBorder : null}>
        <DateRangePicker
					disabled={disabled}
          startDate={startDate}
          startDateId="_start_date_id"
          endDate={endDate}
          endDateId="_end_date_id"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          appendToBody={true}
          isOutsideRange={() => false}
          noBorder={true}
          customArrowIcon={<LineIcon />}
          verticalSpacing={1}
          {...datePickerProps}
          maxDate={moment()}
          isDayBlocked={day => day.isAfter(moment())}
					startDatePlaceholderText="Start date"
          endDatePlaceholderText="End date"
        />
      </Box>

			{invalid && <FormHelperText variant="outlined" error={invalid}>
        {helperText}
      </FormHelperText>}

      <Box className={classes.timePickerRow}>
        <Box className={classes.timePickerRoot}>
          <TimeArrowIcon />
          <TextField
            className={classes.timeInput + " time_input"}
            type={"time"}
            value={startTime}
            onChange={handleStartTimeChange}
            size="small"
            sx={{ ml: 1, width: 100 }}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.timePickerRoot}>
          <TimeArrowIcon />
          <TextField
            className={classes.timeInput + " time_input"}
            type={"time"}
            value={endTime}
            onChange={handleEndTimeChange}
            size="small"
            sx={{ ml: 1, width: 100 }}
            disabled={disabled}
          />
        </Box>
      </Box>
    </Box>
  );
}
