import { ReactComponent as WarnIcon } from "icons/warning-sign.svg";
import { ReactComponent as VisibilityIcon } from "icons/visibility.svg";

const icons = {
	swati_gs: VisibilityIcon,
	CookedHillary: VisibilityIcon,
	"DoubleMinorityy ": VisibilityIcon,
	CarmineSabia: VisibilityIcon,
	MichaelCoudrey: VisibilityIcon,
	THeinrich22: VisibilityIcon,
	sonic1930: WarnIcon,
};

export default icons;
