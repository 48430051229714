import { makeStyles } from "@mui/styles";
import { ENTITY_TYPES } from "utils/constants";
import Tweet from "components/TweetCard/Tweet";
import UserCell from "../../EntityExplorer/components/UserCell";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	postWrap: {
		backgroundColor: "#333146",
		borderRadius: 6,
	},
	entityWrap: {
		marginBottom: theme.spacing(4),
	},
	url: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
}));

export default function ExplanationEntity({ type, entity }) {
	const classes = useStyles();

	return (
		<div className={classes.entityWrap}>
			{(type === ENTITY_TYPES.post || type === ENTITY_TYPES.cluster) && (
				<div className={classes.postWrap}>
					<Tweet {...entity} />
				</div>
			)}
			{type === ENTITY_TYPES.user && <UserCell {...entity} />}

			{type === ENTITY_TYPES.url && (
				<Typography variant={"body2"} className={classes.url} noWrap>
					<Link href={entity.id} target="_blank">
						{entity.id}
					</Link>
				</Typography>
			)}

			{type === ENTITY_TYPES.hashtag && (
				<Typography variant={"h6"}>#{entity.id}</Typography>
			)}
		</div>
	);
}
