import { Button, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as BackArrowIcon } from "icons/UI/arrow-left.svg";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Box } from "@mui/system";
import SelectFlow from "./SelectFlow";
import TalkwalkerFlow from "./TalkwalkerFlow";
import FileUploadFlow from "./FileUploadFlow";
import ConfigureProject from "./ConfigureProject";
import ProcessingPage from "./ProcessingPage";
import StepperNav from "./StepperNav";
import TalkwalkerConnection from "./TalkwalkerConnection";
import { useUploaderContext } from "../context/Context";
import { SET_TALKWALKER_OBJECT } from "../utils/constants";
import { setNewDocuments, stageDocuments, toggleUploading } from "../context/action-creators";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 4, 0, 4),
  },
  createProjectTxt: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#6F7494",
  },
}));

const CreateProject = () => {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const { dispatch } = useUploaderContext();

  const setUploading = (value) => {
    dispatch(toggleUploading(value));
  };

  const setStagedDocuments = (docs) => {
    dispatch(stageDocuments(docs));
  };

  const setDocuments = useCallback(
    (docs) => {
      dispatch(setNewDocuments(docs));
    },
    [dispatch]
  );

  const lastPageName = location.pathname
    .split("/")
    .filter((d) => d)
    .pop();

  const showBackArrow =
    lastPageName !== "create" && lastPageName !== "processing";

  const activeStep =
    lastPageName === "configure" || lastPageName === "processing" ? 1 : 0;

  const hideCancel = lastPageName === "processing";

  const completed = {};

  if (lastPageName === "configure") {
    completed[0] = true;
    completed[1] = false;
  } else if (lastPageName === "processing") {
    completed[0] = true;
    completed[1] = true;
  }

  const handleBack = () => {
    setUploading(false);
    history.goBack();
  };

  const handleCancel = () => {
    dispatch({
      type: SET_TALKWALKER_OBJECT,
      payload: null,
    });
    setUploading(false);
    setStagedDocuments([]);
    setDocuments([]);
    history.push("/manage-data/projects");
  };

  const handleSetFlow = (f) => {
    if (f === "fileUpload") {
      history.push(`${url}/fileupload`);
    } else {
      history.push(`${url}/talkwalker-connect`);
    }
  };

  const handleCreate = () => {
    dispatch({
      type: SET_TALKWALKER_OBJECT,
      payload: null,
    });
    history.push(`${url}/processing`);
  };

  return (
    <Box>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={3} sx={{ textAlign: "left" }}>
          <Typography className={classes.createProjectTxt}>
            Create a project
          </Typography>
          {showBackArrow && (
            <Button
              startIcon={<BackArrowIcon />}
              sx={{ mt: 1 }}
              onClick={handleBack}
            >
              Back
            </Button>
          )}
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "center" }}>
          <StepperNav activeStep={activeStep} completed={completed} />
        </Grid>
        {!hideCancel && (
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <Button color="error" variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>

      <Switch>
        <Route path={`${path}/:selectedFlow/configure`}>
          <ConfigureProject onCreate={handleCreate} />
        </Route>
        <Route path={`${path}/fileupload`}>
          <FileUploadFlow />
        </Route>
        <Route path={`${path}/talkwalker-connect`}>
          <TalkwalkerConnection />
        </Route>
        <Route path={`${path}/talkwalker`}>
          <TalkwalkerFlow />
        </Route>
        <Route path={`${path}/processing`}>
          <ProcessingPage />
        </Route>
        <Route exact path={`${path}/`}>
          <SelectFlow onSelect={handleSetFlow} />
        </Route>
      </Switch>
    </Box>
  );
};

export default CreateProject;
