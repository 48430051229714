import { useState } from "react";
import { Redirect } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Button, CircularProgress } from "@mui/material";
import { resetPassword } from "api/AnalyticsApi";
import { useQuery } from "utils/useQuery";

import LoginBackground from "pages/Authentication/LoginBackground";
import InputValidate from "components/UI/InputValidate";
import Message from "./Message";

const useStyles = makeStyles((theme) => ({
	header: {
		marginTop: 0,
		marginBottom: 0,
	},
	subtitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		color: "rgba(208, 209, 230, 0.7)",
		fontSize: 14,
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	submit: {
		backgroundColor: "#40DDFF",
		"&:hover": {
			backgroundColor: "#40DDFF",
		},
	},
	message: {
		background: "rgba(57, 243, 197, 0.1)",
		borderRadius: 4,
		color: "#39F3C5",
		textAlign: "center",
		marginBottom: theme.spacing(2),
		padding: theme.spacing(1),
		fontSize: 12,
	},
}));

function PasswordResetPage() {
	const classes = useStyles();
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [toLogin, setToLogin] = useState(false);

	const query = useQuery();
	const confirmationCode = query.get("code");

	const onSubmit = async (event) => {
		event.preventDefault();
		setMessage(null);
		setLoading(true);
		setToLogin(false);
		try {
			const res = await resetPassword(
				confirmationCode,
				password,
				passwordConfirmation
			);
			setMessage({
				type: "success",
				message: "Your password has been changed.",
			});
			setLoading(false);
			setTimeout(() => {
				setToLogin(true);
			}, 1500);
			console.log(res);
		} catch (error) {
			setMessage({
				type: "error",
				message:
          "Error resetting password: make sure the passwords match and the code is valid",
			});
			setToLogin(false);
			setLoading(false);
			console.error(error);
			localStorage.clear();
		}
	};

	if (!confirmationCode) return <Redirect to="/" />;
	if (message && message.type === "success" && toLogin) return <Redirect to="/" />;

	return (
		<LoginBackground>
			<h3 className={classes.header}>Hi!</h3>

			<h6 className={classes.subtitle}>
        Enter your new password
			</h6>

			{message && <Message {...message} />}

			<form autoComplete="true" onSubmit={onSubmit}>
				<div className={classes.input}>
					<InputValidate
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						label="Password"
						fieldProps={{
							type: "password",
						}}
					/>
				</div>

				<div className={classes.input}>
					<InputValidate
						value={passwordConfirmation}
						onChange={(event) => setPasswordConfirmation(event.target.value)}
						label="Repeat Password"
						fieldProps={{
							type: "password",
							disabled: loading
						}}
					/>
				</div>

				<Button
					type={"submit"}
					size={"large"}
					variant={"contained"}
					color={"primary"}
					fullWidth
					className={classes.submit}
					disabled={loading}
				>
					{loading ? (
						<CircularProgress color="secondary" size={24} thickness={3} />
					) : (
						"Submit"
					)}
				</Button>
			</form>
		</LoginBackground>
	);
}

export default PasswordResetPage;
