import { useCallback, useMemo } from "react";
import clsx from "clsx";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { AppBar, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DRAWER_WIDTH, DRAWER_CLOSED_WIDTH } from "pages/DashboardPage";
import AboutPopup from "./AboutPopup";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import {
  ALL_PROJECT,
  NARRATIVE_BUILDER_TAB,
  ALL_PROJECT_INDEX,
  DISCOVER_TAB,
} from "utils/constants";
import ProjectSettings from "pages/DashboardPage/Shared/Settings/ProjectSettings";
import MenuIcon from "@mui/icons-material/Menu";
import { deleteNarrative } from "api/AnalyticsApi";
import CustomDropdown from "components/UI/CustomDropdown";
import { calculateTopicFolders } from "utils/format";
import { ReactComponent as DBIcon } from "icons/DB.svg";
import { Link } from "react-router-dom";
import useEventTracker from "api/hooks/useEventTracker";
import useTabs from "hooks/useTabs";

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: (props) => `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: (props) => props.drawerWidth,
    minHeight: props => props.validProject ? 80 : "auto",
    padding: 0,
    boxShadow: "none",
    borderBottom: "none",
    zIndex: theme.zIndex.drawer + 1,
    backgroundImage: "none",
    top: 0,
  },
  backgroundPrimary: {
    backgroundColor: "#161323",
  },
  menuIconWrapper: {
    width: (props) => (props.navOpen ? DRAWER_WIDTH : DRAWER_CLOSED_WIDTH),
    margin: "auto",
    cursor: "pointer",
  },
  menuIcon: {
    marginLeft: "29px",
  },
  tabs: {},
  tab: {
    fontWeight: 600,
    fontSize: "13px",
    letterSpacing: "0.15em",
    textTransform: "uppercase",
    minWidth: 0,
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 0,
  },
  toolBar: {
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid " + theme.palette.border.section,
  },
  disabled: {
    color: "rgba(255, 255, 255, 0.2) !important",
    textDecoration: "line-through",
  },
  flexSpaceBetween: {
    borderBottom: "1px solid " + theme.palette.border.section,
    padding: theme.spacing(0, 4, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleWrapper: {
    padding: theme.spacing(1.25, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  projectTypography: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  title: {
    fontFamily: "Mulish",
    color: "#6F7494",
    fontSize: "10px",
    lineHeight: "15px",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  },
  crumb: {
    padding: theme.spacing(0, 2),
    position: "relative",
    overflow: "visible",
    "&:first-of-type": {
      padding: theme.spacing(0, 4),
      borderRight: "1px solid #282C38",
    },
    "&:nth-child(2)": {
      paddingLeft: theme.spacing(4),
    },
  },
  btn: {
    border: "none",
    padding: 0,
    minWidth: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      border: "none",
    },
  },
  appBarShift: {
    marginLeft: (props) =>
      props.navOpen ? props.drawerWidth : props.drawerClosedWidth,
    width: (props) =>
      `calc(100% - ${
        props.navOpen ? props.drawerWidth : props.drawerClosedWidth
      }px)`,
  },
  timePickerRoot: {
    minWidth: "30%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  resetBtn: {
    padding: 0,
    marginLeft: 5,
    color: "#A3A2A9",
  },
  submitBtn: {
    padding: 0,
    marginLeft: 10,
  },
  datePickerRange: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dash: {
    padding: "0px 8px",
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
  },
  line: {
    marginLeft: 5,
    paddingLeft: 5,
    borderLeft: "1px solid " + theme.palette.border.section,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TopBar({
  setSpikeDate,
  setAnalyzeEntity,
  setDrilledConcepts,
  navOpen,
  validProject,
  setNavOpen,
  drawerWidth,
  drawerClosedWidth,
  smallScreen,
}) {
  const tracker = useEventTracker();
  const classes = useStyles({ navOpen, drawerWidth, drawerClosedWidth, validProject });
  const { sidebarTab, topTabs, topTab } = useTabs();
  const { url } = useRouteMatch();
  const history = useHistory();

  const { projectName, narrativeId } = useParams();
  const narrativeIndex = parseInt(narrativeId) || 0;
  const {
    dispatch,
    state: {
      user,
      projects,
      project: activeProject,
      platform,
      platforms,
      project,
      narratives,
    },
  } = useAppContext();

  const availableTabs = topTabs.slice();

  const onPlatformChange = useCallback(
    (e) => {
      dispatch({
        type: ACTIONS.SET_PLATFORM,
        payload: e.target.value,
      });
      tracker.track(
        "Changed platform",
        "select",
        e.target.value,
        "Top navbar / platform"
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const onChangeHandler = useCallback(
    (_, newValue) => {
      // reset narrative to "ALL" if navigating from refine to discover
      if (
        newValue === DISCOVER_TAB &&
        sidebarTab === NARRATIVE_BUILDER_TAB &&
        narrativeIndex !== ALL_PROJECT_INDEX
      ) {
        navigateToNarrative(url, '/narrative/0/discover');
      }
      setSpikeDate(null);
      setAnalyzeEntity(null);
      setDrilledConcepts([]);
      tracker.track(
        "Clicked dashboard navigation",
        "navigation",
        availableTabs[newValue]?.name,
        "Top navbar"
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setSpikeDate,
      dispatch,
      setAnalyzeEntity,
      setDrilledConcepts,
      availableTabs,
    ]
  );


  const narrative = narratives[narrativeIndex];
  const activeNarrativeName = narrative?.name ?? ALL_PROJECT;
  const narrativeNames = narratives.map(({ name }) => name);

  const handleSelectNarrative = (event) => {
    const index = narratives.findIndex(
      ({ name }) => name === event.target.value
    );
    if (index > -1) {
      dispatch({
        type: ACTIONS.SELECT_NARRATIVE,
        payload: index,
      });
      if (sidebarTab === NARRATIVE_BUILDER_TAB) {
        if (narratives[index].name === ALL_PROJECT) {
          navigateToNarrative(url, '/discover');
        } else {
          navigateToNarrative(url, `/narrative/${index}/refine`);
        }
      }
      else
      {
        navigateToNarrative(url, `/narrative/${index}`);
      }
      tracker.track(
        "Selected narrative",
        "select",
        event.target.value,
        "Top bar / narrative"
      );
    }
  };

  const navigateToNarrative = (url, urlSuffixToNavigateTo) => {
    const urlParts = url.split("/");
    if (urlParts.indexOf("narrative") > -1) {
      urlParts.splice(urlParts.indexOf("narrative"), 2);
      const urlWithoutNarrativeSegment = urlParts.join("/");
      history.push(`${urlWithoutNarrativeSegment}${urlSuffixToNavigateTo}`);
    } else {
      history.push(`${url}${urlSuffixToNavigateTo}`);
    }
  };

  const showMessage = (msg, type) => {
    dispatch({
      type: ACTIONS.SHOW_MESSAGE,
      payload: msg
        ? {
            message: msg,
            type: type,
          }
        : null,
    });
  };

  const handleDeleteNarrative = async (narrativeName) => {
    const narrativeIndexToDelete = narratives.findIndex(
      ({ name }) => narrativeName === name
    );

    if (narrativeIndexToDelete > -1) {
      const nar = narratives[narrativeIndexToDelete];

      try {
        dispatch({
          type: ACTIONS.REMOVE_NARRATIVE,
          payload: narrativeIndexToDelete,
        });

        const newNarrativeIndex =
          narrativeIndexToDelete === narrativeIndex
            ? 0
            : narrativeIndex > narrativeIndexToDelete
            ? narrativeIndex - 1
            : narrativeIndex;
        navigateToNarrative(url, `/narrative/${newNarrativeIndex}/overview`);

        dispatch({
          type: ACTIONS.SELECT_NARRATIVE,
          payload: newNarrativeIndex,
        });

        await deleteNarrative({
          narrativeId: nar.narrativeId,
          projectName: nar.projectName,
        });

        tracker.track(
          "Deleted narrative",
          "remove",
          nar.narrativeId,
          "Top navbar / narrative"
        );

        showMessage("Narrative deleted.", "info");
      } catch (e) {
        console.error(e.message);
        showMessage("Error when deleting narrative.", "error");
      }
    }
  };

  const launchCreateProject = () => {
    history.push(`/manage-data/projects/create`);
  };

  const launchCreateNarrative = () => {
    dispatch({
      type: ACTIONS.SELECT_NARRATIVE,
      payload: ALL_PROJECT_INDEX,
    });
    navigateToNarrative(url, '/discover');
  };

  const launchRefineNarrative = (narrativeName) => {
    const narrativeIndex = narratives.findIndex(
      ({ name, platform: narrativePlatform }) =>
        narrativeName === name && narrativePlatform === platform
    );

    if (narrativeIndex > -1) {
      dispatch({
        type: ACTIONS.SELECT_NARRATIVE,
        payload: narrativeIndex,
      });
      navigateToNarrative(url, `/narrative/${narrativeIndex}/refine`);
    }
  };

  const narrativeOptions = narrativeNames.map((d) => ({
    value: d,
    label: d,
    deletable: d !== ALL_PROJECT,
    editable: d !== ALL_PROJECT,
  }));

  const platformOptions = platforms.map((d) => ({
    value: d.value,
    label: d.name,
    disabled: d.disabled,
  }));

  const allProjectOptions = useMemo(() => {
    const arr = calculateTopicFolders(projects || []);
    return arr
      .map((d) =>
        d.projects.map((x, i) => ({
          label: x.displayName ? x.displayName : x.name,
          value: String(x.projectId),
          showGroup: i === 0,
          groupName: d.name,
        }))
      )
      .flat();
  }, [projects]);

  const projectsMap = new Map(projects.map((d) => [d.projectId, d]));

  const onProjectChange = (e) => {
    const selectedProject = +e.target.value;
    const p = projectsMap.get(selectedProject);
    if (p) {
      tracker.track("Changed project", "select", p.name, "Top Nav / project");
      history.push(`/dashboard/${p.name}`);
    }
  };

  return (
    <AppBar
      position="fixed"
      classes={{
        colorPrimary: classes.backgroundPrimary,
      }}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: true,
      })}
    >
      <div className={classes.flexSpaceBetween}>
        <div className={classes.titleWrapper}>
          {smallScreen && (
            <div className={classes.menuIconWrapper}>
              <MenuIcon
                className={classes.menuIcon}
                onClick={() => setNavOpen(true)}
              ></MenuIcon>
            </div>
          )}

          <div className={classes.crumb}>
            <div className={classes.title}>PROJECT</div>
            {activeProject?.projectId ? (
              <CustomDropdown
                transparent
                searchEnabled
                value={String(activeProject?.projectId)}
                options={allProjectOptions}
                onChange={onProjectChange}
                itemIcon={<DBIcon />}
                onFooterClick={user?.uploadAccess ? launchCreateProject : null}
                footerText={user?.uploadAccess ? "Create a project" : null}
              />
            ) : (
              <Typography
                variant={"h6"}
                component={"h1"}
                className={classes.projectTypography}
              >
                {(project && (project.displayName || project.name)) ||
                  projectName ||
                  "..."}
              </Typography>
            )}
          </div>

          <div className={classes.crumb}>
            <div className={classes.title}>PLATFORM</div>
            <CustomDropdown
              value={platform}
              options={platformOptions}
              onChange={onPlatformChange}
              transparent
            />
          </div>

          <div className={classes.crumb}>
            <div className={classes.title}>NARRATIVE</div>
            <CustomDropdown
              value={activeNarrativeName}
              options={narrativeOptions}
              onEdit={launchRefineNarrative}
              onFooterClick={launchCreateNarrative}
              onDelete={handleDeleteNarrative}
              onChange={handleSelectNarrative}
              footerText={"Create a new narrative"}
              transparent
            />
          </div>
        </div>

        <div className={classes.rightSide}>
          <AboutPopup />
            <div className={classes.line}>
              <ProjectSettings />
            </div>
        </div>
      </div>

      {validProject && (
        <Toolbar variant="dense" className={classes.toolBar}>
          <Tabs
            value={topTab}
            onChange={onChangeHandler}
            indicatorColor={"primary"}
            textColor={"primary"}
            className={classes.tabs}
          >
            {availableTabs.map((tab, index) => {
              const cls = { root: classes.tab };

              if (!tab.active) {
                cls.disabled = classes.disabled;
              }

              const toLink =
                narrativeIndex === ALL_PROJECT_INDEX || tab.path === "discover"
                  ? `/dashboard/${projectName}/${tab.path}`
                  : `/dashboard/${projectName}/narrative/${narrativeIndex}/${tab.path}`;

              return (
                <Tab
                  key={tab.name}
                  label={tab.name}
                  classes={cls}
                  disabled={!tab.active}
                  {...a11yProps(index)}
                  component={Link}
                  to={toLink}
                />
              );
            })}
          </Tabs>
        </Toolbar>
      )}
    </AppBar>
  );
}

export default TopBar;
