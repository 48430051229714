import { PigeonError } from "./errors";

export class PigeonResponse {
  body: any;
  error: PigeonError;
  success: boolean;

  constructor(body: any, success: boolean, error?: PigeonError) {
    this.body = body;
    this.success = success;
    this.error = error;
  }
}

export const HTTPSuccessCode = 200;
