import React from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as ErrorIcon } from "icons/UI/error.svg";

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    padding: 6,
    cursor: "pointer",
  },
  head: {
    color: theme.palette.secondary.light,
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "12px",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    fontWeight: 800,
    minHeight: 24,
    textAlign: "center",
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  tableWrapper: {
    width: "100%",
    overflow: "auto",
  },
  table: {
    maxWidth: "100%",
    width: "100%",
    margin: 0,
  },
  headerRow: {},
  tableRow: {
    "&:hover": {
      backgroundColor: "#333155",
    },
  },
  thContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  th: {
    "&:first-child": {
      paddingRight: 15,
    },
    "&:hover": {
      backgroundColor: "#333155",
    },
  },
  errorTd: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  error: {
    background: "rgba(235, 104, 104, 0.1)",
    textAlign: "center",
    color: "rgba(242, 133, 133, 0.8)",
    width: "100%",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "35px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginRight: 8,
    },
  },
}));

const getValue = (col, d) => {
  if (col.field === "*") {
    return d;
  }

  let value = d[col.field];

  if (value === null || value === undefined) {
    return "-";
  }

  return typeof col.format === "function" ? col.format(value) : value;
};

export default function SimpleTable({ rows, columns }) {
  const classes = useStyles();

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            {columns.map((col, index) => {
              const Comp = col.headerComponent;
              return (
                <StyledTableCell key={index} className={classes.th}>
                  <div className={classes.thContent}>
                    {Comp ? <Comp /> : col.text}
                  </div>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((d) => {
            return (
              <>
                <TableRow className={classes.tableRow}>
                  {columns.map((col, i) => {
                    const Comp = col.Component;
                    const value = getValue(col, d);

                    let content = null;

                    if (Comp) {
                      content = <Comp value={value} error={d.error} />;
                    } else {
                      content = (
                        <Typography variant="body2">{value}</Typography>
                      );
                    }

                    return (
                      <StyledTableCell
                        key={i}
                        style={{ backgroundColor: col.cellBackground }}
                      >
                        {content}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
                {d.error ? (
                  <TableRow>
                    <StyledTableCell
                      colSpan={columns.length}
                      className={classes.errorTd}
                    >
                      <div className={classes.error}>
                        <ErrorIcon />
                        {d.error}
                      </div>
                    </StyledTableCell>
                  </TableRow>
                ) : null}
              </>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
