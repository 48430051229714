import { Tooltip } from "@mui/material";
import { ReactComponent as EntityExplorerIcon } from "icons/EntityExplorer.svg";

const AnalyzeBtn = ({ onClick, className }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Tooltip title="Analyze" placement="top">
      <button onClick={handleClick} className={className}>
        <EntityExplorerIcon />
      </button>
    </Tooltip>
  );
};

export default AnalyzeBtn;
