import { makeStyles } from "@mui/styles";
import { METRIC_COLORS } from "colors";

const useStyles = makeStyles(() => ({
	root: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	dot: {
		width: 15,
		height: 15,
		borderRadius: "50%",
		backgroundColor: METRIC_COLORS.risk
	}
}));

export default function HarmDot() {
	const classes = useStyles();
	return <div className={classes.root}>
		<div className={classes.dot}></div>
	</div>;
}