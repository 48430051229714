import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Hint from "components/UI/Hint";
import { formatNumber } from "utils/format";

const useStyles = makeStyles((theme) => {
	return {
		root: {
			display: "flex",
			alignItems: "center",
			width: "100%",
			justifyContent: "space-between",
			padding: theme.spacing(1, 2),
		},
		label: {
			color: "#9EA3B1",
			fontSize: 13,
			lineHeight: "14px",
			fontWeight: "normal",
			position: "relative",
			maxWidth: "150px",
		},
		value: {
			textAlign: "right",
		},
		mainNumber: {
			color: "#fff",
			fontWeight: "normal",
			fontSize: "14px",
			lineHeight: "18px",
		},
		percent: {
			color: "#9EA3B1",
			fontSize: 13,
		},
		loader: {
			padding: theme.spacing(1, 2),
		},
		skeleton: {
			width: "100%",
			background: theme.palette.tile.main,
			borderRadius: 6,
		},
		title: {
			height: "100%",
			maxWidth: "75%",
		},
		hover: {
			display: "inline-block",
			transform: "translateY(6px)",
		},
	};
});

export default function ChildSummary({
	value,
	percent,
	label,
	loading,
	tooltipText,
}) {
	const classes = useStyles();

	if (loading) {
		return (
			<div className={classes.loader}>
				<Skeleton
					className={classes.skeleton}
					variant="rectangular"
					height={30}
				/>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<div className={classes.label}>
					{label}
					{tooltipText && (
						<span className={classes.hover}>
							<Hint tooltipText={tooltipText} />
						</span>
					)}
				</div>
			</div>

			<div className={classes.value}>
				<div className={classes.mainNumber}>
					{value !== null ? formatNumber(value) : "-"}
				</div>
				<div className={classes.percent}>
					{percent !== null ? `(${percent}%)` : "-"}
				</div>
			</div>
		</div>
	);
}
