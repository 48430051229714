import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { ReactComponent as TickIcon } from "icons/Uploader/tick.svg";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  stepper: {
    "& .MuiStepConnector-root": {
      top: "16px !important",
    },
    "& .MuiStepConnector-line": {
      borderTopStyle: "dashed",
      borderTopColor: "#6F7494",
    },
  },
  stepButton: {
    "& .MuiStepLabel-label": {
      color: "#6F7494",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: "#fff",
    },
    "& .MuiStepLabel-label.Mui-completed": {
      color: "#fff",
    },
    "& .MuiStepLabel-iconContainer.Mui-completed": {
      backgroundColor: "#1C192B",
      borderRadius: "50%",
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiSvgIcon-root": {
      color: "#1C192B",
      width: 32,
      height: 32,
    },
    "& .MuiSvgIcon-root.Mui-active": {
      color: "#6257C0",
    },
    "& .MuiSvgIcon-root.Mui-completed": {
      color: "#6257C0",
    },
  },
}));

const steps = ["Choose data", "Configure project"];

const StepperNav = ({ activeStep, completed }) => {
  const classes = useStyles();
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      className={classes.stepper}
    >
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton
            disabled={false}
            icon={completed[index] ? <TickIcon /> : null}
            color="inherit"
            className={classes.stepButton}
          >
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperNav;
