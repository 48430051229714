import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";

const textMaps = {
	"[removed]": "Removed by Reddit",
	"[deleted by user]": "Deleted by User"
};

export default function HandleRemoved({ text, children }) {
	const found = textMaps[(text || "").trim()];

	if (found) {
		return (
			<Box sx={{ padding: "8px 0px" }}>
				<Chip 
					label={found} 
					variant="outlined"
					sx={{
						borderRadius: 13,
						color: "#FFFFFF",
						padding: "4px 10px",
						background: "#3B0501",
						border: "1px solid #880C02",
						fontSize: 12,
						lineHeight: "15px",
						"& .MuiChip-label": {
							padding: 0
						}
					}}
				/>
			</Box>
		);
	}
	return children;
}
