import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import { ACCEPTED_FILES } from "utils/constants";
import { ReactComponent as UploadIcon } from "icons/upload-icon.svg";
import { Alert, Button, Typography } from "@mui/material";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
	root: {},
	dropBox: {
		borderWidth: "1px",
		borderStyle: "dashed",
		borderColor: (props) => (props.boxActive ? "#25ACF7" : "#504C83"),
		borderSpacing: 3,
		borderRadius: 4,
		width: "100%",
		height: "180px",
	},
	dragInactiveParent: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	dropHere: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	uploadText: {
		marginTop: theme.spacing(1),
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "20px",
		color: "#FFFFFF",
	},
}));

const WRONG_FILE_TYPE_MSG = "Please upload a .csv file";

const FileUploader = ({ onDrop }) => {
	const [error, setError] = useState(null);

	const onDropAccepted = (acceptedFiles) => {
		if (acceptedFiles && acceptedFiles.length) {
			setError(null);
			onDrop(acceptedFiles);
		}
	};

	const onDropRejected = (rejectedFiles) => {
		if (rejectedFiles && rejectedFiles.length) {
			setError(WRONG_FILE_TYPE_MSG);
			setTimeout(() => setError(null), 3000);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			"text/csv": ACCEPTED_FILES,
		},
		multiple: false,
		onDropAccepted,
		onDropRejected,
	});

	const classes = useStyles({ boxActive: isDragActive });

	return (
		<div className={classes.root}>
			{!!error && (
				<>
					<Alert variant="filled" severity={"error"}>
						{error}
					</Alert>
					<br></br>
					<br></br>
				</>
			)}

			<div {...getRootProps()} className={classes.dropBox}>
				<input {...getInputProps()} />
				<DropItHere isDragActive={isDragActive} />
			</div>
		</div>
	);
};

function DropItHere({ isDragActive }) {
	const classes = useStyles();

	return isDragActive ? (
		<div className={classes.dropHere}>
			<Typography variant="body1" className={classes.uploadText}>
        Drop here
			</Typography>
		</div>
	) : (
		<div className={classes.dragInactiveParent}>
			<div>
				<UploadIcon />
			</div>
			<Typography variant="subtitle2" className={classes.uploadText}>
        Drag and drop .csv file here
			</Typography>
			<div>
				<br></br>
				<Button variant="contained" color="primary">
          Or, browse files
				</Button>
			</div>
		</div>
	);
}

export default FileUploader;
