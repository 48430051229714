import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { analyticsApi } from "api/axiosAnalytics";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/link-error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "js-sha256";
import { handleError } from "api/errors";

const httpLink = createHttpLink({
	uri: `${analyticsApi}/graphql`,
});

const linkChain = createPersistedQueryLink({ sha256 }).concat(httpLink);

const errorLink = onError((err) => {
	if (err.networkError) {
		handleError(err.networkError);
	} else if (err.graphQLErrors) {
		// Handle GraphQL syntax errors
	}
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("jwtTokenUser");
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const client = new ApolloClient({
	link: authLink.concat(errorLink).concat(linkChain),
	cache: new InMemoryCache(),
});

export default client;
