import { useEffect, useState } from "react";
import { Alert, Button, TextField, Typography } from "@mui/material";
import { useUploaderContext } from "../context/Context";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import {
  addDataConnector,
  checkTalkwalkerStatus,
  saveDataConnector,
} from "api/UploadApi";
import Preloader from "components/UI/Preloader";
import { toggleSnack } from "../context/action-creators";
import { SET_DATA_CONNECTORS_REFETCH } from "../utils/constants";

const useStyles = makeStyles(() => ({
  root: {
    padding: 32,
    minWidth: 500,
  },
  icon: {
    textAlign: "center",
  },
  inputs: {
    padding: "30px 0px",
  },
  label: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "15px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  input: {
    "& .MuiInputBase-input": {
      backgroundColor: "#1C192B !important",
      boxShadow: "0px 0px 1px #131121",
      border: "none",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "15px",
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

export default function DataConnection({ icon, closePopup, connectionEdit }) {
  const classes = useStyles();

  const {
    state: { dataConnectorsRefetch},
    dispatch,
  } = useUploaderContext();

  const [apiKey, setApiKey] = useState("");
  const [name, setName] = useState("");
  const [statusOk, setStatusOk] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);
  const [saving, setSaving] = useState(null);

  useEffect(() => {
    setApiKey(connectionEdit ? "******" : "");
    setName(connectionEdit?.name || "");
  }, [connectionEdit]);

  const handleStatusCheck = async () => {
    if (apiKey) {
      setLoading(true);
      setApiKeyError(null);
      setSaving(true);
      const res = await checkTalkwalkerStatus(apiKey);
      setSaving(false);
      setLoading(false);

      if (res && res.status_message === "OK") {
        setStatusOk(true);
      } else {
        setStatusOk(false);
      }
    } else {
      setApiKeyError("Specify api key");
    }
  };

  const handleSave = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      data: {
        name,
        apiKey: connectionEdit ? connectionEdit.secretKey : apiKey,
        dataProvider: "Talkwalker",
        owner: user.id,
        organization: {id: user.orgId}
      },
    };

    if (!connectionEdit) {
      payload.data.statusToggle = true
    }

    if (!name || !name.trim()) {
      return setNameError("Name is required");
    }

    setNameError(null);
    setApiKeyError(null);

    const fn = connectionEdit ? saveDataConnector : addDataConnector;
    setSaving(true);
    const resp = await fn(payload, connectionEdit?.id);
    setSaving(false);

    if (resp) {
      dispatch(
        toggleSnack({
          snackMessage:
            "Data connector " + (connectionEdit ? "updated" : "created"),
          snackOpen: true,
          snackDuration: 3000,
        })
      );
      dispatch({
        type: SET_DATA_CONNECTORS_REFETCH,
        payload: !dataConnectorsRefetch,
      });
      closePopup();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.icon}>{icon}</div>

      <div className={classes.inputs}>
        <div style={{ marginBottom: 20 }}>
          <Typography className={classes.label}>
            Data source nickname
          </Typography>
          <TextField
            placeholder="e.g. Social Data"
            error={!!nameError}
            helperText={nameError}
            variant="outlined"
            fullWidth
            size="small"
            className={classes.input}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></TextField>
        </div>

        {connectionEdit ? null : (
          <div>
            <Typography className={classes.label}>API key</Typography>
            <TextField
              variant="outlined"
              fullWidth
              error={!!apiKeyError}
              helperText={apiKeyError}
              size="small"
              className={classes.input}
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            ></TextField>
          </div>
        )}
      </div>

      <Box sx={{ mb: 2 }}>
        {statusOk !== null && !loading && (
          <Alert severity={statusOk ? "success" : "error"}>
            {statusOk
              ? "Authentication successful"
              : "Check your credentials and try again"}
          </Alert>
        )}
      </Box>

      <div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={saving}
          onClick={statusOk || connectionEdit ? handleSave : handleStatusCheck}
        >
          {saving ? <Preloader size={24} /> : (statusOk || connectionEdit) ? "Save Connection" : "Test Connection"}
        </Button>
      </div>
    </div>
  );
}
