import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Checkbox,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { dateStr, fileSizeStr } from "../utils/utils";
import Status from "./Status";

const drawerWidth = 420;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    maxWidth: "100%",
  },
  paper: {
    width: drawerWidth,
    maxWidth: "100%",
    background: "linear-gradient(247.48deg, #1E1C2C 11.19%, #1D1B2D 73.37%)",
    border: "1px solid #201C39",
    padding: theme.spacing(4),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 50,
  },
  editableField: {
    width: "100%",
    padding: "10px 0px",
    "&>div": {
      width: "100%",
    },
  },
  editables: {
    marginTop: 50,
    marginBottom: 60,
  },
  nonEditable: {
    padding: "10px 0px",
    "& label": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "35px",
      color: "#77758E",
      opacity: 0.99,
    },
  },
  saveBtn: {
    background: "linear-gradient(103.85deg, #40DDFF 40.11%, #40AFFF 92.1%)",
    boxShadow: "0px 4px 34px #3F307B",
  },
  cancelBtn: {
    border: "1px solid rgba(255, 255, 255, 0.7)",
    color: "rgba(255, 255, 255, 0.7)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.contrastText,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  exploratory: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  exploratoryText: {
    color: "#77758E",
  },
}));

export default function Sidebar({ doc, onClose }) {
  const classes = useStyles();
  const [data, setData] = useState({ ...doc });

  useEffect(() => {
    if (doc) {
      setData({ ...doc });
    }
  }, [doc]);

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.paper,
      }}
      anchor={"right"}
      open={doc !== null}
      onClose={onClose}
    >
      {doc !== null && (
        <>
          <div>
            <Button startIcon={<KeyboardBackspaceIcon />} onClick={onClose}>
              BACK
            </Button>
          </div>

          <div className={classes.editables}>
            <div className={classes.editableField}>
              <TextField
                label="Filename"
                placeholder="Filename"
                value={data.filename}
                disabled={true}
              />
            </div>

            <div className={classes.editableField}>
              <TextField
                label="Description"
                placeholder="Description"
                value={data.description}
                disabled={true}
              />
            </div>

            <div className={classes.exploratory}>
              <Typography className={classes.exploratoryText}>
                Exploratory
              </Typography>
              <Checkbox disabled checked={data.exploratory} />
            </div>
          </div>

          <div className={classes.nonEditable}>
            <label htmlFor="upload_date">Upload Date</label>
            <Typography id="upload_date">
              {doc.timestamp ? dateStr(doc.timestamp) : ""}
            </Typography>
          </div>

          <div className={classes.nonEditable}>
            <label htmlFor="file_size">File Size</label>
            <Typography id="file_size">
              {fileSizeStr(doc.size, true)}
            </Typography>
          </div>

          <div className={classes.nonEditable}>
            <label htmlFor="status">Status</label>
            <div id="status">
              <Status value={doc.status} />
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
}
