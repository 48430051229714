import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import Tabs from "components/UI/TabsNew";
import SimpleTable from "components/UI/SimpleTable";
import { VersionColumns } from "pages/DashboardPage/Shared/columns";
import CollectionRules from "./CollectionRules";
import { useAppContext } from "context/Context";
import HarmTab from "./HarmTab";
import ClassifierTab from "./ClassifierTab";
import { useFlags } from "launchdarkly-react-client-sdk";
import useEventTracker from "api/hooks/useEventTracker";

const useStyles = makeStyles((theme) => ({
	spacing: {
		padding: "10px 0px",
		marginBottom: 10,
	},
	tabs: {
		overflowX: "auto",
	},
	alignCenter: {
		textAlign: "center",
		paddingBottom: "16px",
	},
	table: {
		padding: "10px 20px 20px 20px",
		"& td": {
			color: "#E8E9F3",
			fontSize: "14px",
			fontWeight: "normal",
			padding: "10px 6px",
		},
		"& td:first-child": {
			textAlign: "left",
		},
		"& td:last-child": {
			width: 120,
			paddingTop: "4px",
			paddingBottom: "4px",
		},
		"& th": {
			paddingTop: 10,
			paddingBottom: 10,
			borderBottom: "1.2px solid " + theme.palette.border.popup,
		},
		"& th:last-child": {
			width: 120,
		},
	},
	footer: {
		padding: "0px 20px 20px 20px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& button": {
			flexGrow: 1,
		},
		"& button:first-child": {
			marginRight: 5,
		},
		"& button:last-child": {
			marginLeft: 5,
		},
	},
	content: {
		padding: theme.spacing(2),
	},
}));

const tabNames = {
	general: "General",
	classifiers: "Classifiers",
	harm: "Risk Profiles",
	version: "Version",
};

const createSettingTabs = (projectSettings, flags) => {
	const chosenConfig = projectSettings;

	const settingsTabs = [
		{
			name: tabNames.general,
			rows: [],
		},
		...(flags.classifierProfiles
			? [
				{
					name: tabNames.classifiers,
					rows: [],
				},
			]
			: []),
		...(flags.harmProfile
			? [
				{
					name: tabNames.harm,
					rows: [],
				},
			]
			: []),
			...(flags.isBlackBirdEmployee
				? [
					{
						name: tabNames.version,
						rows: [],
					},
				]
				: []),
	];

	const { analysisConfig } = chosenConfig;
	if(flags.isBlackBirdEmployee) {
	let versionTab = settingsTabs.find((x) => x.name === tabNames.version);
	if (analysisConfig && analysisConfig.data && analysisConfig.data.attributes) {
		const {
			aiModelVersions,
			algorithmVersions,
			harmComputation,
			postProcessing,
		} = analysisConfig.data.attributes;

		[
			aiModelVersions,
			algorithmVersions,
			harmComputation,
			postProcessing,
		].forEach((d) => {
			versionTab.rows.push(
				...Object.entries(d).map(([label, version]) => ({ label, version }))
			);
		});

		// if version tab is empty
		if (versionTab.rows.length === 0) {
			return settingsTabs.filter((d) => d.name !== tabNames.version);
		}
	}
	}

	return settingsTabs;
};

export default function Settings({ handleClose }) {
	const classes = useStyles();
	const flags = useFlags();
	const tracker = useEventTracker();
	const [currentTab, setCurrentTab] = useState(0);

	const {
		state: { projectSettings },
	} = useAppContext();

	const settingTabs = createSettingTabs(projectSettings || {}, flags);

	const tabName = settingTabs[currentTab]?.name;

	const tabChangeHandler = (e, newTab) => {
		const tab = settingTabs[newTab];

		if (tab && tabNames.harm) {
			tracker.track(
				"Clicked risk profiles tab",
				"click",
				"",
				"Custom Risk Profile"
			);
		}

		setCurrentTab(newTab || 0);
	};

	return (
		<div className={classes.root}>
			<div className={classes.tabs}>
				<Tabs
					value={currentTab}
					onChange={tabChangeHandler}
					tabs={settingTabs}
				/>
			</div>

			<div className={classes.content}>
				{tabName === tabNames.general && <CollectionRules />}

				{flags.harmProfile && projectSettings && tabName === tabNames.classifiers && (

					<ClassifierTab hidePopup={handleClose} />
				)}

				{tabName === tabNames.harm && <HarmTab hidePopup={handleClose} />}

				{tabName === tabNames.version && (
					<div className={classes.table}>
						<SimpleTable
							rows={settingTabs[currentTab].rows}
							columns={VersionColumns}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
