import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import WidgetHeader from "components/WidgetHeader";
import Tabs from "components/UI/TabsNew";
import Preloader from "components/UI/Preloader";
import DataDisplay from "./DataDisplay";
import { isNeutralEmotion } from "utils/emotions";
import { PLATFORMS } from "utils/constants";
import { useAppContext } from "context/Context";
import useEventTracker from "api/hooks/useEventTracker";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.widget.main,
    borderRadius: 6,
    marginTop: "20px",
    marginBottom: "3px",
  },
  header: {
    backgroundColor: theme.palette.widget.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  content: {
    padding: "12px 12px 24px 12px",
  },
  loading: {
    padding: "10px 0px",
    fontSize: 14,
  },
  noResults: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tabs: {
    marginTop: 10,
  },
}));

export const emotionSums = [
  "anger_ct",
  "disgust_ct",
  "fear_ct",
  "hapiness_ct",
  "neutral_emotion_ct",
  "sadness_ct",
  "surprise_ct",
];

export const emotionSumMappings = {
  anger_ct: "anger",
  disgust_ct: "disgust",
  fear_ct: "fear",
  hapiness_ct: "happiness",
  neutral_emotion_ct: "neutral",
  sadness_ct: "sadness",
  surprise_ct: "surprise",
  positive_ct: "positive",
  negative_ct: "negative",
  neutral_ct: "neutral",
};

export const pieChartColorMappings = {
  anger: "#F33939",
  disgust: "#c87606",
  fear: "#9639F3",
  happiness: "#39F3C5",
  joy: "#39F3C5",
  neutral: "#C5D5FF",
  sadness: "#438EFF",
  surprise: "#EFF339",
  positive: "#50C878",
  negative: "#F33939",
};

export default function Insight({ onMoreClick, wildcardSummary, platform }) {
  const classes = useStyles();
  const [tabs, setTabs] = useState([
    {
      name: "Emotion",
      active: true,
    },
    {
      name: "Sentiment",
      active: false,
    },
  ]);
  const tracker = useEventTracker();

  const [emotion, setEmotion] = useState([]);
  const [sentiment, setSentiment] = useState([]);
  const [isParsing, setIsParsing] = useState(true);
  const [shouldReanimate, setShouldReanimate] = useState(0);
  const {
    state: { summaryLoading },
  } = useAppContext();

  useEffect(() => {
    setIsParsing(true);
    if (!wildcardSummary || wildcardSummary.hapiness_ct === undefined) {
      setEmotion([]);
      setSentiment([]);
      setIsParsing(false);
      return;
    } else {
      const { post_ct } = wildcardSummary;
      const emotions = Object.entries(wildcardSummary);
      const emotionData = emotions
        .filter(([key]) => emotionSums.includes(key))
        .map(([key, value]) => ({
          color: pieChartColorMappings[emotionSumMappings[key]],
          name:
            emotionSumMappings[key].charAt(0).toUpperCase() +
            emotionSumMappings[key].slice(1),
          value: value / post_ct,
          sort_bot: isNeutralEmotion(key),
        }));
      setEmotion(emotionData);
      setSentiment([
        {
          color: pieChartColorMappings.positive,
          name: "Positive",
          value: wildcardSummary.positive_ct / post_ct,
        },
        {
          color: pieChartColorMappings.negative,
          name: "Negative",
          value: wildcardSummary.negative_ct / post_ct,
        },
        {
          color: pieChartColorMappings.neutral,
          name: "Neutral",
          value: wildcardSummary.neutral_ct / post_ct,
          sort_bot: true,
        },
      ]);
      setShouldReanimate((prev) => prev + 1);
    }
    setIsParsing(false);
  }, [wildcardSummary]);

  const tabIndex = tabs.findIndex((t) => t.active);

  const data = tabIndex === 0 ? emotion : sentiment;
  const setData = tabIndex === 0 ? setEmotion : setSentiment;

  const handleTabChange = (_, index) => {
    const newTabs = [...tabs].map((t) => ({ ...t, active: false }));
    newTabs[index].active = true;
    setTabs(newTabs);

    const tabName =  newTabs[index]?.name;
    tracker.track(
      `Clicked tab in "Blackbird Insights"`,
      "click",
      tabName,
      "Overview / Insights"
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <WidgetHeader
          noPadding
          title="Blackbird Insights"
          tooltipTitle="Aggregate emotion and sentiment analytics."
          onMoreClick={onMoreClick}
          noButton={platform === PLATFORMS.reddit}
        />
      </div>

      {!summaryLoading && !isParsing ? (
        <>
          <div className={classes.tabs}>
            <Tabs
              white
              value={tabIndex}
              onChange={handleTabChange}
              tabs={tabs}
            />
          </div>

          {data && data.length > 0 ? (
            <DataDisplay
              data={data}
              setData={setData}
              tab={tabs[tabIndex].name}
              shouldReanimate={shouldReanimate}
            />
          ) : (
            <div className={classes.noResults}>No Results</div>
          )}
        </>
      ) : (
        <div className={classes.loading}>
          <Preloader />
        </div>
      )}
    </div>
  );
}
