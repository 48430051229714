import { ReactComponent as ThumbsUpIcon } from "icons/ThumbsUp.svg";
import { ReactComponent as ThumbsDownIcon } from "icons/ThumbsDown.svg";
import { ReactComponent as NeutralDecisionIcon } from "icons/NeutralDecision.svg";
import { pieChartColorMappings } from "pages/DashboardPage/Overview/Insights";
import { HARMFUL_ENGAGEMENT_POSTS_LABEL, PLATFORMS } from "utils/constants";

export const totalPosts = (platform) => [
  {
    agg_type: "sum",
    field_name: "is_orig",
    return_name: "original_post_ct",
    label: "Original Posts",
    tooltipText:
      "Posts that are not a repost, but rather unique content entered by the account of interest.",
  },
  ...(platform === PLATFORMS.twitter
    ? [
        {
          agg_type: "sum",
          field_name: "post_engagement.is_share",
          return_name: "share_post_ct",
          label:
            platform === PLATFORMS.twitter
              ? "Local Shared Posts"
              : "Shared Posts",
          tooltipText:
            platform === PLATFORMS.twitter
              ? "Retweets captured within the original data collection."
              : "Posts that are reposted content.",
        },
      ]
    : []),
  {
    agg_type: "sum",
    field_name: "is_manipulated",
    return_name: "manipulated_post_ct",
    label: "Anomalous Posts",
    tooltipText: "Posts showing atypical patterns of content propagation.",
  },
  {
    agg_type: "sum",
    field_name: "is_toxic",
    return_name: "toxic_post_ct",
    label: "Toxic Posts",
    tooltipText:
      "Posts containing offensive speech, hate speech, insults or threats.",
  },
];

export const totalEngagements = (platform) => {
  const commonCols = [
    {
      agg_type: "sum",
      field_name: "harmful_engagements",
      return_name: "harmful_engagements",
      label: HARMFUL_ENGAGEMENT_POSTS_LABEL,
      tooltipText:
        "A cumulative measure of all likes and shares on posts identified as high risk.",
    },
  ];

  const twitterCols = [
    {
      agg_type: "sum",
      field_name: "post_engagement.like_count",
      return_name: "like_ct",
      label: "Likes",
    },
    {
      agg_type: "sum",
      field_name: "post_engagement.share_count",
      return_name: "share_ct",
      label: platform === PLATFORMS.twitter ? "Global Shares" : "Shares",
      tooltipText:
        platform === PLATFORMS.twitter
          ? "Total retweets for this post at the time of data ingestion."
          : "",
    },
  ];

  const redditCols = [
    {
      agg_type: "sum",
      field_name: "post_engagement.up_votes",
      return_name: "up_votes",
      label: "Upvotes",
    },
    // {
    //   agg_type: "sum",
    //   field_name: "post_engagement.down_votes",
    //   return_name: "down_votes",
    //   label: "Downvotes",
    // },
    {
      agg_type: "sum",
      field_name: "post_engagement.comment_count",
      return_name: "comment_ct",
      label: "Comments",
    },
  ];

  const cols = {
    [PLATFORMS.twitter]: twitterCols,
    [PLATFORMS.reddit]: redditCols,
  };

  return [...commonCols, ...(cols[platform] || [])];
};

export const totalUsers = (platform) => {
  if (platform === PLATFORMS.twitter) {
    return [
      {
        agg_type: "cardinality",
        field_name: "user_is_bot",
        return_name: "bot_user_ct",
        label: "Bot-like Users",
        tooltipText:
          "Displaying behavior consistent with that of a bot or click farm, where there is some degree of automation or other advanced strategies to produce rapid, high volume dissemination of content.",
      },
    ];
  }
  return [];
};

const sentStyle = {
  marginRight: 8,
};

const flex = {
  display: "flex",
  alignItems: "center",
};

export const sentiments = [
  {
    agg_type: "sum",
    field_name: "is_positive",
    return_name: "positive_ct",
    label: (
      <div style={flex}>
        <ThumbsUpIcon style={sentStyle} />
        Positive
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_negative",
    return_name: "negative_ct",
    label: (
      <div style={flex}>
        <ThumbsDownIcon style={sentStyle} />
        Negative
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_neutral",
    return_name: "neutral_ct",
    label: (
      <div style={flex}>
        <NeutralDecisionIcon style={sentStyle} />
        Neutral
      </div>
    ),
  },
];

const dot = {
  display: "inline-block",
  width: 12,
  height: 12,
  borderRadius: "50%",
  marginRight: 8,
};

export const emotions = [
  {
    agg_type: "sum",
    field_name: "is_neutral_emotion",
    return_name: "neutral_emotion_ct",
    label: (
      <div style={flex}>
        <span
          style={{ ...dot, backgroundColor: pieChartColorMappings.neutral }}
        ></span>
        Neutral
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_anger",
    return_name: "anger_ct",
    label: (
      <div style={flex}>
        <span
          style={{ ...dot, backgroundColor: pieChartColorMappings.anger }}
        ></span>
        Anger
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_happiness",
    return_name: "hapiness_ct",
    label: (
      <div style={flex}>
        <span
          style={{ ...dot, backgroundColor: pieChartColorMappings.happiness }}
        ></span>
        Happiness
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_disgust",
    return_name: "disgust_ct",
    label: (
      <div style={flex}>
        <span
          style={{ ...dot, backgroundColor: pieChartColorMappings.disgust }}
        ></span>
        Disgust
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_sadness",
    return_name: "sadness_ct",
    label: (
      <div style={flex}>
        <span
          style={{ ...dot, backgroundColor: pieChartColorMappings.sadness }}
        ></span>
        Sadness
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_surprise",
    return_name: "surprise_ct",
    label: (
      <div style={flex}>
        <span
          style={{ ...dot, backgroundColor: pieChartColorMappings.surprise }}
        ></span>
        Surprise
      </div>
    ),
  },
  {
    agg_type: "sum",
    field_name: "is_fear",
    return_name: "fear_ct",
    label: (
      <div style={flex}>
        <span
          style={{ ...dot, backgroundColor: pieChartColorMappings.fear }}
        ></span>
        Fear
      </div>
    ),
  },
];

export const totalUsersReturnField = [
  {
    agg_type: "cardinality",
    field_name: "user.id.keyword",
    return_name: "user_ct",
  },
];

export const defaultReturnFields = [
  {
    agg_type: "cardinality",
    field_name: "user.id.keyword",
    return_name: "user_ct",
  },
  {
    agg_type: "max",
    field_name: "created_at",
    return_name: "end_date",
  },
  {
    agg_type: "min",
    field_name: "created_at",
    return_name: "start_date",
  },
  {
    agg_type: "sum",
    field_name: "post_engagement.total",
    return_name: "engagement",
  },
];

export const emotionAndSentimentFields = [
  {
    agg_type: "sum",
    field_name: "is_neutral_emotion",
    return_name: "neutral_emotion_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_anger",
    return_name: "anger_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_happiness",
    return_name: "hapiness_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_disgust",
    return_name: "disgust_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_sadness",
    return_name: "sadness_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_surprise",
    return_name: "surprise_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_fear",
    return_name: "fear_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_positive",
    return_name: "positive_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_negative",
    return_name: "negative_ct",
  },
  {
    agg_type: "sum",
    field_name: "is_neutral",
    return_name: "neutral_ct",
  },
];
