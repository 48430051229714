import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    '& *:not(.progress_bar)': {
      zIndex: 2
    }
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "65%",
    padding: theme.spacing(1.5, 2),
  },
  right: {
    width: "35%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  progressBar: {
    zIndex: 0,
    position: "absolute",
    width: (props) => props.p + "%",
    height: "100%",
    background: "linear-gradient(101.93deg, #40DDFF 35.36%, #40AFFF 81.2%)",
    boxShadow: "0px 4px 34px #3F307B",
    borderRadius: "4px",
  },
  cancelBtn: {
    color: "#ffffff",
    borderColor: "#ffffff",
    opacity: 0.9,
    width: "100%",
  },
  progress: {
    fontWeight: 900,
    fontSize: "28px",
    lineHeight: "32px",
  },
  uploading: {
    fontWeight: 900,
    fontSize: "14px",
    letterSpacing: "0.15em",
    textTransform: "uppercase",
  },
  timeLeft: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.02em",
  },
  filename: {
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "18px",
    marginBottom: 5
  }
}));

export default function UploadProgress({ uploadProgress, cancelUpload, processing }) {
  const { percent } = uploadProgress || { percent: 0, bytes: 0, timeLeft: 100, filename: "" };
  const classes = useStyles({ p: percent });

  return (
    <div className={classes.root}>
      <div className={classes.progressBar + " progress_bar"}></div>

      <div className={classes.left}>
        <div>
          <Typography className={classes.filename}>{uploadProgress.filename}</Typography>
          <Typography className={classes.uploading}>
            {percent < 100 ? "UPLOADING..." : processing ? "PROCESSING..." : "FINISHED"}
          </Typography>
          {!processing && <Typography className={classes.progress}>{percent}%</Typography>}
        </div>
      </div>

      {cancelUpload && <div className={classes.right}>
        <Button
          variant="outlined"
          className={classes.cancelBtn}
          onClick={cancelUpload}
        >
          {percent < 100 ? "CANCEL UPLOAD" : "DONE"}
        </Button>
      </div>}
    </div>
  );
}
