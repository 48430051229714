import React from "react";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
	arrow: {
		color: "#191826"
	},
	tooltip: {
		padding: 12,
		backgroundColor: "#191826",
		color: "#D0D1E6",
		fontFamily: "Mulish",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "12px",
		maxWidth: 200,
		wordBreak: "normal"
	},
	tooltipPlacementTop: {
		margin: "5px 0px"
	}
}));

const TooltipContent = (props) => {
	const Comp = props.contentComp;

	return (
		<div>
			{
				Comp ? <Comp {...props} /> : props.text
			}
		</div>
	);
};

export default function CustomTooltip(props) {
	const classes = useStyles();

	return (
		<Tooltip 
			arrow 
			classes={classes} 
			title={<TooltipContent {...props} />} 
			placement="top"
		>
			{props.children}
		</Tooltip>
	);
}