import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	parent: { textAlign: "center", width: "100%" },
	progress: {},
	circle: {
		rx: 10,
		ry: 10,
	},
}));

const Preloader = ({ size = 48, thickness = 8 }) => {
	const classes = useStyles();

	return (
		<div className={classes.parent}>
			<CircularProgress
				color="primary"
				size={size}
				thickness={thickness}
				classes={{ circle: classes.circle }}
			/>
		</div>
	);
};

export default Preloader;
