import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactComponent as ErrorIcon } from "icons/LoginError.svg";
import { ReactComponent as SuccessIcon } from "icons/LoginSuccess.svg";

const useStyles = makeStyles((theme) => ({
	common: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 4,
		textAlign: "center",
		marginBottom: theme.spacing(2),
		padding: theme.spacing(1),
		fontSize: 12,
		"& svg": {
			marginRight: 8,
		},
	},
	error: {
		color: "#CD4051",
		background: "rgba(205, 64, 81, 0.06)",
	},
	success: {
		color: theme.palette.primary.main,
		background: "rgba(57, 243, 197, 0.1)",
	},
}));

export default function Message({ type, message }) {
	const classes = useStyles();
	return (
		<div
			className={clsx([
				classes.common,
				type === "error" ? classes.error : classes.success,
			])}
		>
			{type === "error" ? <ErrorIcon /> : <SuccessIcon />} {message}
		</div>
	);
}
