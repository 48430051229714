import { Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import CustomTabs from "components/UI/TabsNew";
import DocumentsTab from "./DocumentsTab";
import TimeZoneTab from "./TimeZoneTab";
import UserGroupTab from "./UserGroupTab";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
	popoverRoot: {},
	popoverPaper: {
		background: theme.palette.background.popup,
		border: "1px solid " + theme.palette.border.popup,
		width: 450,
	},
	content: {
		padding: theme.spacing(3),
	},
	divider: {
		margin: "12px 0px",
	},
}));

const tabNames = {
	docs: "Documents",
	users: "Users",
	timezone: "Time zone"
};

export default function UserSettings({ anchorEl, handleClose, children }) {
	const classes = useStyles();

	const [tab, setTab] = useState(0);
	const flags = useFlags();

	const open = Boolean(anchorEl);
	const id = open ? "user-settings-popover" : undefined;

	const tabs = [
		{ name: tabNames.docs },
		...(flags.userGroups ? [{ name: tabNames.users }] : []),
		{ name: tabNames.timezone },
	];

	const tabName = tabs[tab]?.name;

	return (
		<>
			{children}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				classes={{
					root: classes.popoverRoot,
					paper: classes.popoverPaper,
				}}
			>
				<div className={classes.content}>
					<CustomTabs
						value={tab}
						onChange={(e, v) => setTab(v)}
						tabs={tabs}
						tabColor="#272438"
					/>
					{tabName === tabNames.docs && <DocumentsTab />}
					{tabName === tabNames.users && <UserGroupTab hidePopup={handleClose} />}
					{tabName === tabNames.timezone && <TimeZoneTab />}
				</div>
			</Popover>
		</>
	);
}
