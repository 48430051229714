import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToApp from '@mui/icons-material/ExitToApp';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  content: {
    margin: "0 auto",
    maxWidth: 1280,
    [theme.breakpoints.up("md")]: {
      maxWidth: 800,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1000,
    },
  },
  colorBar: {
    background: "linear-gradient(90.19deg, #40DDFF 14.84%, #EC7A73 81.03%)",
    display: "flex",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    justifyContent: "space-between",
  },
  clearContainer: {
    flexGrow: 1,
    justifyContent: "flex-end",
    display: "flex",
  },
  editBtnContainer: {
    flexGrow: 1,
  },
  editBtn: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.11)",
    color: "#161323",
  },
  deleteBtn: {
    border: "1px solid #161323",
    color: "#161323",
  },
}));

export default function SelectionBar({ selectedDocuments, onEdit, onDelete }) {
  const classes = useStyles();

  const deleteDocs = () => onDelete(selectedDocuments);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.colorBar}>
          {selectedDocuments.length === 1 && (
            <div className={classes.editBtnContainer}>
              <Button
                startIcon={<ExitToApp />}
                className={classes.editBtn}
                variant={"contained"}
                onClick={() => onEdit(selectedDocuments[0])}
              >
                View Details
              </Button>
            </div>
          )}
          <div className={classes.clearContainer}>
            <Button
              startIcon={<DeleteIcon />}
              className={classes.editBtn}
              variant={"outlined"}
              onClick={deleteDocs}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
