import { Grid, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as ExpandIcon } from "icons/expand.svg";
import Hint from "./UI/Hint";

const useStyles = makeStyles(() => ({
	text: {
		fontWeight: 500,
	},
	header: {
		paddingLeft: (props) => (props.noPadding ? 0 : 15),
		paddingRight: (props) => (props.noPadding ? 0 : 15),
	},
	btn: {
		border: "none",
		padding: 5,
		minWidth: "auto",
		"&:hover": {
			border: "none",
		},
	},
}));

export default function WidgetHeader(props) {
	const classes = useStyles(props);

	return (
		<Grid
			container
			direction="row"
			justifyContent="space-around"
			alignItems="center"
			alignContent="center"
			wrap={"nowrap"}
			className={classes.header}
		>
			{props.title && (
				<Grid container alignItems="center">
					<Typography variant={"h6"} className={classes.text}>
						{props.title}
					</Typography>

					<Hint tooltipText={props.tooltipTitle} />
				</Grid>
			)}

			{props.extraContent}

			{props.noButton ? null : (
				<Button
					className={classes.btn}
					variant={"outlined"}
					color={"secondary"}
					onClick={props.onMoreClick}
				>
					<ExpandIcon />
				</Button>
			)}
		</Grid>
	);
}
