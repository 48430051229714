import { makeStyles } from "@mui/styles";
import { Link, Tooltip } from "@mui/material";
import { ReactComponent as OpenExternIcon } from "icons/OpenExtern.svg";
import { useAppContext } from "context/Context";
import { PLATFORM_CONFIG } from "utils/constants";

const useStyles = makeStyles(() => ({
	linkIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: props => props.border ? "1px solid #282C38" : "none",
		padding: 0,
		width: 24,
		height: 24,
		minWidth: 24,
		borderRadius: 3,
		color: "#6F7494",
		"&:hover": {
			border: props => props.border ? "1px solid #282C38" : "none",
		},
	},
}));

const OpenIn = ({ href, border }) => {
	const classes = useStyles({ border });
	const {
		state: { platform },
	} = useAppContext();

	const platformConfig = PLATFORM_CONFIG[platform];
	const title = `View on ${platformConfig?.name || platform}`;

	return (
		<Tooltip title={title} placement="top">
			<Link
				underline={"none"}
				className={classes.linkIcon}
				target="_blank"
				href={href}
			>
				<OpenExternIcon fontSize="small" />
			</Link>
		</Tooltip>
	);
};

export default OpenIn;
