import { EventTypes, trackUserEvent } from "api/userEventTrackingApi";
import { useAppContext } from "context/Context";

export default function useEventTracker() {
	const {
		state: { narrative, project, platform },
	} = useAppContext();

	return {
		track: (message, type, value, path) => {
			const eventType = EventTypes[type] || String(type);

			trackUserEvent(message, {
				type: eventType,
				value,
				path,
				project: project.name,
				narrative: narrative.name,
				platform,
			});
		},
	};
}
