import { makeStyles } from "@mui/styles";
import { ReactComponent as UserMaleIcon } from "icons/NarBuilder/user-male.svg";
import { ReactComponent as ClusterIcon } from "icons/NarBuilder/cluster.svg";
import { ReactComponent as CommentIcon } from "icons/UI/comment.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	roundBox: {
		borderRadius: 4,
		padding: "7px 10px 7px 15px",
		fontWeight: "700",
		fontSize: "13px",
		lineHeight: "16px",
		display: "flex",
		alignItems: "center",
		"& svg": {
			marginRight: 10,
		},
	},
	cluster: {
		backgroundColor: "rgba(236, 236, 236, 0.1)",
		color: "#ECECEC",
	},
	orig: {
		backgroundColor: "rgba(57, 243, 197, 0.1)",
		color: theme.palette.primary.main,
	},
	shared: {
		backgroundColor: "rgba(181, 192, 255, 0.1)",
		color: "#B5C0FF",
	},
	comment: {
		backgroundColor: "rgba(86, 149, 236, 0.2)",
		color: "#9CC6FF",
	},
}));

const getTweetOrigin = ({ is_cluster, is_share, is_comment }) => {
	let text = "";
	let icon = null;

	if (is_cluster) {
		text = "CLUSTER";
		icon = ClusterIcon;
	} else if (is_share) {
		text = "SHARED POST";
		icon = UserMaleIcon;
	} else if (is_comment) {
		text = "COMMENT";
		icon = CommentIcon;
	} else {
		text = "ORIGINAL POST";
		icon = UserMaleIcon;
	}

	return { text, icon };
};

export default function PostOrigin({ is_cluster, is_comment, is_share }) {
	const classes = useStyles();
	const { text: OriginText, icon: OriginIcon } = getTweetOrigin({
		is_cluster,
		is_comment,
		is_share,
	});

	return (
		<div
			className={clsx(classes.roundBox, {
				[classes.cluster]: is_cluster,
				[classes.orig]: !is_cluster && !is_share,
				[classes.shared]: !is_cluster && is_share,
				[classes.comment]: !is_cluster && is_comment,
			})}
		>
			{<OriginIcon />}
			{OriginText}
		</div>
	);
}
